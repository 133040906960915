import React from 'react';
import { Button, IconButton, Tooltip, Whisper } from 'rsuite';

const ButtonHint = ({ hint, children, ...rest }) => {
  return (
    <Whisper
      trigger="hover"
      placement="top"
      speaker={<Tooltip>{hint}</Tooltip>}
    >
      <Button {...rest}>{children}</Button>
    </Whisper>
  );
};

const IconButtonHint = ({ hint, children, ...rest }) => {
  return (
    <Whisper
      trigger="hover"
      placement="top"
      speaker={<Tooltip>{hint}</Tooltip>}
    >
      <IconButton {...rest}>{children}</IconButton>
    </Whisper>
  );
};

export { ButtonHint, IconButtonHint };
