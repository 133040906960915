import {
  COMMON_PLACEHOLDER,
  COMMON_TRITTICUS_STATUS,
  RSUITE5_PLACEMENT,
  RSUITE5_SIZE,
  RSUITE_BLOCK,
  RSUITE_CLEANABLE,
  makeColums,
  RSUITE5_HINT_AS_TOOLTIP,
  COMMON_TRITTICUS,
  COMMON_ARRAY_OPTIONS,
  MUI_SIZE_NO_LARGE,
  COMMON_FULL_WIDTH_SIZE,
  MUI_FLOATING_LABEL,
  MUI_VARIANT,
  MANTINE_SIZE,
  MANTINE_RADIUS,
  MANTINE_VARIANT,
  MANTINE_SECTIONS,
  MANTINE_CLEARABLE,
  MANTINE_POINTER,
  MANTINE_ERROR_WITH_STYLE,
  MANTINE_WITH_SCROLL_AREA,
  COMMON_FILTER_OPTIONS
} from '../costants/index.js';

import { manifest as SelectManifest } from './select.js';

const manifest = {
  label: 'MultiSelect',
  category: 'general',
  name: 'multiselect',
  frameworks: [
    'react-antd',
    //'react',
    //'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    ...COMMON_TRITTICUS,
    {
      ...COMMON_ARRAY_OPTIONS,
      label: 'Multiselect options'
    },
    COMMON_FILTER_OPTIONS,
    COMMON_PLACEHOLDER,
    COMMON_TRITTICUS_STATUS
  ],
  'react-material-ui': [
    makeColums(
      MUI_SIZE_NO_LARGE,
      {
        component: 'toggle',
        name: 'autoWidth',
        label: 'Auto width',
        hint: 'If true, the width of the popover will automatically be set according to the items inside the menu, otherwise it will be at least the width of the select input.',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      MUI_FLOATING_LABEL,
      MUI_VARIANT
    )
  ],
  'react-bootstrap': [
  ],
  'react-antd': [
    ...SelectManifest['react-antd'],
    makeColums(
      {
        name: 'maxTagCount',
        label: 'Max tags',
        component: 'input-number',
        allowClear: true,
        min: 1,
        step: 1,
        hint: 'Maximum number of selectable elements',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'maxTagPlaceholder',
        component: 'input-text',
        label: 'Max tags',
        hint: 'Placeholder to show when max number of element is reached',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'maxTagTextLength',
        label: 'Max tag text',
        component: 'input-number',
        allowClear: true,
        min: 1,
        step: 1,
        hint: 'Maximum length of text tags',
        'react-rsuite5': {
          tooltip: true
        }
      }
    )
  ],
  'react-rsuite5': [
    makeColums(
      RSUITE5_PLACEMENT,
      RSUITE5_SIZE,
      {
        name: 'multiselectMode',
        label: 'Variant',
        component: 'select',
        searchable: false,
        block: true,
        options: [
          { value: 'tag', label: 'Tag' },
          { value: 'plain', label: 'Plain' }
        ],
        hint: 'Show the selected values as tag element or plain string',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    {
      component: 'columns',
      columns: [
        { name: 'one', size: 1 },
        { name: 'two', size: 1 },
        { name: 'three', size: 1 }
      ],
      fields: {
        one: [
          RSUITE_BLOCK
        ],
        two: [
          {
            id: 'select-rsuite-2',
            label: 'Searchable',
            name: 'searchable',
            component: 'toggle'
          }
        ],
        three: [
          RSUITE_CLEANABLE
        ]
      }
    },
    makeColums(
      RSUITE5_HINT_AS_TOOLTIP,
      {
        name: 'preventOverflow',
        label: 'Prevent overflow',
        component: 'toggle',
        hint: 'Prevent floating element overflow',
        'react-rsuite5': {
          tooltip: true
        }
      },
      []
    )
  ],
  'react-mantine': [
    makeColums(
      MANTINE_SIZE,
      MANTINE_RADIUS,
      MANTINE_VARIANT
    ),
    COMMON_FULL_WIDTH_SIZE,
    MANTINE_SECTIONS,
    makeColums(
      {
        name: 'limit',
        component: 'input-number',
        label: 'Limit',
        min: 1,
        step: 1,
        hint: 'Maximum number of options displayed at a time, Infinity by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'maxDropdownHeight',
        component: 'input-number',
        label: 'Dropdown height',
        min: 1,
        step: 10,
        hint: 'max-height of the dropdown, only applicable when withScrollArea prop is true, 250 by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'maxValues',
        component: 'input-number',
        label: 'Max values',
        min: 1,
        hint: 'Maximum number of values, Infinity by default',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'searchable',
        component: 'toggle',
        label: 'Searchable',
        hint: 'Determines whether the select should be searchable, false by default',
        'react-rsuite5': {
          tooltip: true
        },
        script: `if (searchable) {
          enable('nothingFoundMessage');
        } else {
          disable('nothingFoundMessage');
        }`
      },
      {
        name: 'nothingFoundMessage',
        component: 'input-text-i18n',
        label: 'Nothing found',
        hint: 'Message displayed when no option matched current search query, only applicable when searchable prop is set',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      MANTINE_CLEARABLE,
      {
        name: 'defaultDropdownOpened',
        component: 'toggle',
        label: 'Dropdown open',
        hint: 'Uncontrolled dropdown initial opened state',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'hidePickedOptions',
        component: 'toggle',
        label: 'Hide selected',
        hint: 'Determines whether picked options should be removed from the options list, false by default',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      MANTINE_POINTER,
      {
        name: 'selectFirstOptionOnChange',
        component: 'toggle',
        label: 'Select first',
        hint: 'Determines whether the first option should be selected when value changes, false by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'withCheckIcon',
        component: 'toggle',
        label: 'Check icon',
        hint: 'Determines whether check icon should be displayed near the selected option label, true by default',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      MANTINE_ERROR_WITH_STYLE,
      MANTINE_WITH_SCROLL_AREA,
      []
    )
  ],
  defaultValues: {
    'react-mantine': {
      'react-mantine': {
        withCheckIcon: true,
        withErrorStyles: true,
        withScrollArea: true
      }
    },
    'react-antd': {
      'react-antd': {
        ...SelectManifest.defaultValues['react-antd']['react-antd']
      },
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react-rsuite5': {
      'react-rsuite5': {
        block: true,
        multiselectMode: 'plain'
      },
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    }
  }
};

export { manifest };
