import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { FaWpforms } from 'react-icons/fa';

import FormGenerator from '../../../../components/lets-form';
import Manifests from '../../../../manifest';

import './form-editor.scss';
import { createFormForm } from './helpers/create-form-form';

const FormEditor = ({
  form,
  locale,
  locales,
  onChange = () => {},
  onCompleteTranslation = () => {},
  framework
}) => {
  const manifest = Manifests.forms[framework];
  const [editForm, setForm] = useState(createFormForm({ manifest, framework, locales, locale }));

  useEffect(
    () => {
      setForm(createFormForm({ manifest, framework, locales, locale, onCompleteTranslation }))
    },
    [form, framework, locale, locales, manifest, onCompleteTranslation]
  )

  const handleChange = useCallback(
    (values, opts) => {
      // propagate changes
      onChange({ ...form, ...values });
    },
    [onChange, form]
  );

  if (!manifest) {
    return (
      <div>
        no configuration for this framework
      </div>
    );
  }

  const Icon = manifest.icon ?? FaWpforms;

  return (
    <div className="form-editor">
      <div className="component-info">
        <div className="icon">
          <Icon id="form-editor-icon" key="form-editor-icon"   size={24}/>
        </div>
        <div className="info">
          <span className="name">{manifest.label}</span>
        </div>
      </div>
      <FormGenerator
        form={editForm}
        framework="react-rsuite5"
        defaultValues={_.omit(form, 'fields', 'version')}
        hideToolbar={true}
        onChange={handleChange}
      />
    </div>
  );
};

export { FormEditor };
