import { gql } from '@apollo/client';

export const GET_VERSIONS = gql`
query($formId: ID!) {
  form: forms_by_id(id: $formId) {
    name,
    description,
    versions(
      limit: 20,
      sort: "-date_created"
    ) {
      id,
      comment,
      date_created
    }
  }
}
`;

export const GET_VERSION = gql`
query($versionId: ID!) {
  version: forms_versions_by_id(id: $versionId) {
    id,
    json,
    comment,
    date_created
  }
}`;

export const DELETE_VERSION = gql`
mutation($versionId: ID!) {
  version: delete_forms_versions_item(id: $versionId) {
    id
  }
}`;

export const SAVE_FORM_VERSION = gql`
mutation ($version: create_forms_versions_input!) {
  version: create_forms_versions_item(data: $version) {
    id
 }
}
`;