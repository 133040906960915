import { MembershipPlans, useModal } from '../components';

export const useMembership = () => {
  const { open, close } = useModal({
    view: MembershipPlans,
    size: 'lg',
    name: 'show_plans',
    align: 'center',
    labelSubmit: 'Close',
    labelCancel: null
  });

  return {
    openMembershipPlans: open,
    closeMembershipPlans: close
  };
};
