import React, { useState } from 'react';
import { Nav, Checkbox } from 'rsuite';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { CopyAndPasteButton } from '../../../components';
import {
  generateVanillaCode,
  generateVanillaJsonCode,
  generateMarkupCode
} from '../../../components/code-generation';

const ShowCode = ({ value }) => {
  const [tab, setTab] = useState('main');
  const [withJson, setWithJson] = useState(true);

  let codeString;
  if (withJson) {
    if (tab === 'main') {
      codeString = generateVanillaCode({
        form: value.form,
        framework: value.framework,
        locale: value.locale
      });
    } else if (tab === 'json') {
      codeString = generateVanillaJsonCode(value.form, value.framework);
    }
  } else {
    codeString = generateMarkupCode(value.form, value.framework);
  }

  return (
    <div className="show-code">
      <Checkbox
        checked={withJson}
        label="Markpu"
        onChange={() => {
          setWithJson(value => !value);
        }}
      >Export using JSON form schema
      </Checkbox>
      <Nav
        onSelect={key => setTab(key)}
        appearance="subtle"
        className="sidebar-navigator"
      >
        <Nav.Item eventKey='main' active={tab === 'main'}>React view (my-view.js)</Nav.Item>
        {withJson && (
          <Nav.Item eventKey='json' active={tab === 'json'}>JSON Form schema (form.json)</Nav.Item>
        )}

        <CopyAndPasteButton
          label="Copy to clipboard"
          size="sm"
          notification={tab === 'main' ?
            'my-view.js succesfully copied to clipboard' :
            'form.json succesfully copied to clipboard'
          }
          text={codeString}
        />
      </Nav>
      <SyntaxHighlighter
        className="syntax"
        showLineNumbers={true}
        language="javascript"
        style={nightOwl}
        customStyle = {{
          marginTop: '0px',
          marginBottom: '0px'
        }}
      >
        {codeString}
      </SyntaxHighlighter>
    </div>
  );
};

export { ShowCode };
