import React, { useCallback, useState } from 'react';
import { Header, Container, Content, Placeholder, Notification, useToaster } from 'rsuite';
import { useNavigate, Link } from 'react-router-dom';
import { useQuery, gql, useApolloClient } from '@apollo/client';
import _ from 'lodash';

import { useModal, FeatureButton, EmptyPlaceholder, WelcomeBox, DasboardLabel } from '../../components';
//import { ProjectEdit } from '../../components/lets-form-forms';
import { NewProjectModal } from './views/new-project';
import { ProjectCard } from './views/project-card';
import { SharedFormCard } from './views/shared-form-card';
import { PlaygroundFormCard } from './views/playground-form-card';

import { NavigationBar } from '../../layout';
import { useTracking, useCurrentUser } from '../../hooks';

import './dashboard.scss';

const GET_PROJECTS = gql`
query($userId: String!) {
  projects(sort: "name") {
    id,
    name,
    description,
    date_created,
    date_updated
  }
  counters: forms_aggregated(
    groupBy: "projectId"
  ) {
    group
    count {
      projectId
    }
  }
  shared_forms(
    filter: {
      user_created: {
        id: {
          _eq: $userId
        }
      }
    }
  ) {
    id,
    name,
    shareCode,
    date_created
  }
  playground_forms(
    filter: {
      user_created: {
        id: {
          _eq: $userId
        }
      }
    }
  ) {
    id,
    formId,
    name,
    shareCode,
    date_created,
    height
  }
}`;

export const DELETE_PROJECT = gql`
mutation($id: ID!) {
  delete_projects_item(id: $id) {
    id
  }
}
`;


const DashboardPage = () => {
  useTracking();
  const { user, isAuthenticated, hasFeatureFlag } = useCurrentUser({ redirectToLogin: true });

  const navigate = useNavigate();
  const toaster = useToaster();
  const client = useApolloClient();
  const [initialLoading, setInitialLoading] = useState(true);
  const [mutationLoading, setMutationLoading] = useState(false);
  const { data: rawData, loading: projectsLoading, refetch: reloadProjects } = useQuery(GET_PROJECTS, {
    fetchPolicy: 'network-only',
    skip: !user,
    variables: {
      userId: user?.id
    },
    onCompleted: () => {
      setInitialLoading(false);
    }
  });

  let data;
  if (!projectsLoading && rawData) {
    // collect counters for eacj project
    const counters = rawData.counters.reduce(
      (acc, { group, count }) => {
        return ({ ...acc, [String(group.projectId)]: count.projectId })},
      {}
    );

    // glue counters into project
    data = {
      projects: rawData.projects.map(project => ({
        ...project,
        formsCount: counters[project.id]
      })),
      shared_forms: rawData.shared_forms,
      playground_forms: rawData.playground_forms
    };
  }


  const { open: openEditProject, close: closeEditProject } = useModal({
    view: NewProjectModal,
    size: 'sm',
    name: 'createNewProject',
    align: 'center',
    labelSubmit: null,
    labelCancel: null,
    title: 'Create new project'
  });

  const handleSelect = useCallback(
    async (key, project) => {
      if (key === 'cmd:deleteProject') {
        if (window.confirm(`Delete project "${project.name}" and all its forms?`)) {
          try {
            setMutationLoading(true);
            await client.mutate({
              mutation: DELETE_PROJECT,
              variables: { id: project.id }
            });
            await reloadProjects();
            setMutationLoading(false);
          } catch(e) {
            setMutationLoading(false);
            toaster.push(
              <Notification type="error" header="Error!" closable>
                <div>
                  <b>Something went wrong</b> deleting project <b>{project.name}</b>, please try again later.
                </div>
              </Notification>,
              { placement: 'topEnd' }
            );
            console.error(`LetsForm error deleting project ${project.name}`);
          }
        }
      } else if (key === 'cmd:editProject') {
        navigate(`/projects/${project.id}`);
      }
    },
    [client, reloadProjects, toaster, navigate]
  );

  const handleClick = useCallback(
    async () => {
      const savedProject = await openEditProject({}, { user });
      closeEditProject();
      if (savedProject) {
        // redirect to project page
        navigate(`/projects/${savedProject.id}`);
      }
    },
    [closeEditProject, navigate, openEditProject, user]
  );

  const loading = projectsLoading || initialLoading || mutationLoading;

  // can use: create new project
  let canCreateProject = true;
  let canCreateWarning = undefined;
  if (!initialLoading && data && data.projects && data.projects.length >= user.plan.maxProjects) {
    canCreateProject = false;
    canCreateWarning = (
      <span>
        You reached the maximun number of projects ({user.plan.maxProjects}) for the current plan <b>{user.plan.name}</b>,
        please upgrade to create more projects
      </span>
    );
  }

  console.log('data', data)

  return (
    <div>
      <Header>
        <NavigationBar />
      </Header>
      <Container className="lf-page-dashboard">
        <Content className="project-detail">
          <div className="welcome">
            <WelcomeBox />
            <div className="name">
              {isAuthenticated === undefined && (
                <Placeholder rows={2} rowMargin={10} active />
              )}
              {isAuthenticated && (
                <>
                  <Link to="/settings" className="link_dashboard">{user.full_name}</Link>
                </>
              )}
            </div>
          </div>

          <DasboardLabel className="lf-label-projects">
            Projects
            <FeatureButton
              canUse={canCreateProject}
              warning={canCreateWarning}
              size="xs"
              disabled={loading}
              appearance="ghost"
              style={{ marginLeft: '20px' }}
              onClick={handleClick}
            >
              Create project
            </FeatureButton>
          </DasboardLabel>

          <div className="projects">
            {!initialLoading && data && (
              <>
                {data.projects.map((project, idx) => (
                  <ProjectCard
                    key={project.id}
                    project={project}
                    disabled={loading}
                    onSelect={handleSelect}
                    canUse={idx < user.plan.maxProjects}
                  />
                ))}
              </>
            )}
            {initialLoading && (
              <Placeholder.Paragraph rowMargin={10} rows={3} active />
            )}
            {!initialLoading && _.isEmpty(data?.projects) && (
              <EmptyPlaceholder>
                There are no projects, click on the button above to create one
              </EmptyPlaceholder>
            )}
          </div>

          <DasboardLabel
            description="A shared form is a snapshot of one your saved form that you can share with your friends or collegues"
          >
            Shared forms
          </DasboardLabel>
          <div className="shared-forms">
            {!initialLoading && data && (
              <>
                {data.shared_forms.map((sharedForm, idx) => (
                  <SharedFormCard
                    key={sharedForm.id}
                    sharedForm={sharedForm}
                    onRemoved={reloadProjects}
                  />
                ))}
              </>
            )}
            {initialLoading && (
              <Placeholder.Paragraph rowMargin={10} rows={3} active />
            )}
            {!initialLoading && _.isEmpty(data?.shared_forms) && (
              <EmptyPlaceholder>
                There are no shared forms, to create one: open a saved form then
                {' '}
                <em>Form</em> &rarr; <em>Shared form</em>
              </EmptyPlaceholder>
            )}
          </div>
          {hasFeatureFlag('playground') && (
            <>
              <DasboardLabel
                description="Use Form Playground to create an embeddable test space for your React components"
              >
                Playground forms
              </DasboardLabel>

              <div className="playground-forms">
                {!initialLoading && data && (
                  <>
                    {data.playground_forms.map((playgroundForm, idx) => (
                      <PlaygroundFormCard
                        key={playgroundForm.id}
                        playgroundForm={playgroundForm}
                        onRemoved={reloadProjects}
                      />
                    ))}
                  </>
                )}
                {initialLoading && (
                  <Placeholder.Paragraph rowMargin={10} rows={3} active />
                )}
                {!initialLoading && _.isEmpty(data?.shared_forms) && (
                  <EmptyPlaceholder>
                    There are no playground forms, to create one: open a saved form then
                    {' '}
                    <em>Form</em> &rarr; <em>Shared form</em>
                  </EmptyPlaceholder>
                )}
              </div>
            </>
          )}
        </Content>
      </Container>
    </div>
  );
};

export { DashboardPage }
