import React, { useCallback } from 'react';
import { Dropdown } from 'rsuite';
import _ from 'lodash';

import { useTracking } from '../../../hooks';
import { useFormBuilderStore } from '../../../pages/builder/state';
import { useModal } from '../../../components';

import { ShowCode } from './show-code-modal';

const ExportToReactCodeMenuItem = ({ disabled }) => {
  const tracking = useTracking();
  const form = useFormBuilderStore(state => state.form);
  const framework = useFormBuilderStore(state => state.framework);
  const locale = useFormBuilderStore(state => state.locale);

  const { open: openShowCode, close: closeShowCode } = useModal({
    view: ShowCode,
    size: 'lg',
    name: 'show_code',
    align: 'center',
    labelSubmit: 'Close',
    labelCancel: null,
    title: 'Export to React code'
  });

  const handleShowCode = useCallback(
    async () => {
      tracking.sendEvent('form.export-code');
      await openShowCode({
        form,
        framework,
        locale: !_.isEmpty(form.locales) ? locale : null
      });
      closeShowCode();
    },
    [tracking, openShowCode, form, framework, locale, closeShowCode]
  );

  return (
    <Dropdown.Item onSelect={handleShowCode}>Export to React</Dropdown.Item>
  );
};

export { ExportToReactCodeMenuItem };
