import React, { useState, useCallback } from 'react';
import { ButtonToolbar, Button, useToaster, Notification } from 'rsuite';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import _ from 'lodash';

import { TextareaCopyClipboard } from '../../../components/textarea-copy-clipboard';
import { AlchemicLoader } from '../../../components/alchemic-loader';

import './share-form.scss';

const GET_SHARED_FORM = gql`
query($formId: String) {
  shared_forms(
    filter: {
      formId: {_eq: $formId }
    }) {
    id,
    json,
    shareCode,
    date_created,
    formId,
    name
  }
}`;

const DELETE_SHARED_FORM = gql`
mutation($id: ID!) {
  delete_shared_forms_item(id: $id) {
    id
  }
}`;

const CREATE_SHARED_FORM = gql`
mutation($sharedForm: create_shared_forms_input!) {
  sharedForm: create_shared_forms_item(data: $sharedForm) {
    id,
    formId,
    name,
    shareCode,
    date_created
  }
}
`;

const LETS_FORM_DESIGNER_URL = process.env.NODE_ENV === 'development' ?
  'http://localhost:3000' : 'https://designer.letsform.dev';

const ShareForm = ({ value, onSubmit, onCancel }) => {
  const [sharedForm, setSharedForm] = useState();
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const toaster = useToaster();

  const {loading: loadingSharedForm } = useQuery(GET_SHARED_FORM, {
    variables: {
      formId: value.formId
    },
    skip: !value.formId,
    fetchPolicy: 'network-only',
    onCompleted: result => {
      console.log('loaded', result)
      if (!_.isEmpty(result.shared_forms)) {
        setSharedForm(result.shared_forms[0])
      }
    }
  });

  let shareLink;
  if (sharedForm) {
    shareLink = `${LETS_FORM_DESIGNER_URL}/f/${sharedForm.shareCode}`;
  }

  const handleDeleteLink = useCallback(
    async () => {
      try {
        setLoading(true);
        await client.mutate({
          mutation: DELETE_SHARED_FORM,
          variables: {
            id: sharedForm.id
          }
        });
        setLoading(false);
        toaster.push(
          <Notification type="info" header="Deleted!" closable>
            <div>
              The shared form was deleted successfully!
            </div>
          </Notification>,
          { placement: 'topEnd' }
        );
        onSubmit({});
      } catch(e) {
        toaster.push(
          <Notification type="error" header="Error" closable>
            <div>
              <b>Something went wrong</b> deleting the shared form, please try again later.
            </div>
          </Notification>,
          { placement: 'topEnd' }
        );
        setLoading(false);
      }
    },
    [client, onSubmit, sharedForm, toaster]
  );

  const handleCreateSharedFormLink = useCallback(
    async () => {
      try {
        setLoading(true);
        const response = await client.mutate({
          mutation: CREATE_SHARED_FORM,
          variables: {
            sharedForm: {
              json: JSON.stringify({
                ...value.form,
                framework: value.framework,
                size: value.size
              }, null, 2),
              name: value.form.name,
              formId: value.formId,
              shareCode: Math.random().toString(36).slice(2)
            }
          }
        });
        setLoading(false);
        toaster.push(
          <Notification type="info" header="Created!" closable>
            <div>
              The shared form was created succesfully
            </div>
          </Notification>,
          { placement: 'topEnd' }
        );
        setSharedForm(response.data.sharedForm);
      } catch(e) {
        toaster.push(
          <Notification type="error" header="Error!" closable>
            <div>
              <b>Something went wrong</b> creating the shared form, please try again later.
            </div>
          </Notification>,
          { placement: 'topEnd' }
        );
        setLoading(false);
      }
    },
    [client, toaster, value.form, value.formId, value.framework, value.size]
  );

  if (loadingSharedForm) {
    return (
      <div className="lf-share-form">
        <AlchemicLoader margin={50} />
      </div>
    );
  }

  return (
    <div className="lf-share-form">
      {shareLink && (
        <>
          <div className="description">
            Use this link to share the current form to a team mate. <br/>
            Any further changes to the form will not impact the shared form, un-shre the form and share it again to apply changes.
          </div>
          <TextareaCopyClipboard
            title="Copied!"
            description="Shared form link succesfully copied to clipboard"
          >{shareLink}</TextareaCopyClipboard>

          <ButtonToolbar>
            {value.formId && (
              <Button
                disabled={loading}
                appearance="ghost"
                onClick={handleDeleteLink}
              >Delete link</Button>
            )}
            <Button
              appearance="primary"
              disabled={loading}
              onClick={() => onSubmit(sharedForm)}
            >Close</Button>
          </ButtonToolbar>
        </>
      )}
      {!shareLink && (
        <>
          <div className="warning">
            Click on the button below to create a sharable link to this form.<br/>
            <b>The link is public, anyone can see it, pay attention on not including sensible information.</b><br/>
          </div>
          <ButtonToolbar>
            <Button
              disabled={loading}
              appearance="primary"
              onClick={handleCreateSharedFormLink}
            >Create Shared Form link</Button>
            <Button
              appearance="default"
              disabled={loading}
              onClick={onCancel}
            >Close</Button>
          </ButtonToolbar>
        </>
      )}
    </div>
  );
};

export { ShareForm };
