import React, { useCallback, useEffect, useState } from 'react';
import { Button, Loader } from 'rsuite';
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import WarningRoundIcon from '@rsuite/icons/WarningRound';

import { formatBytes } from '../lets-form/helpers/format-bytes';

import './download-package.scss';

const DownloadGenericPackage = ({ value }) => {
  const { pack, packageName, instructions } = value || {};
  const [loading, setLoading] = useState(true);
  const [packageFile, setPackageFile] = useState();

  const isShowSaveFilePickerSupported = window.showSaveFilePicker != null;

  useEffect(
    () => {
      const run = async () => {
        const res = await fetch('/api/package/create', {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          },
          body: JSON.stringify({
            package: pack
          })
        });
        if (res.status === 200) {
          const content = await res.blob();
          setPackageFile(content);
          setLoading(false);
        } else {
          setLoading(false);
        }
      };
      run();
    },
    [pack]
  );

  const handleDownload = useCallback(
    async () => {
      const opts = {
        suggestedName: packageName,
        types: [{
          description: 'Zipped React Package',
          accept: {'application/zip': ['.zip']},
        }],
      };
      const handle = await window.showSaveFilePicker(opts);
      const writable = await handle.createWritable();
      await writable.write(packageFile);
      await writable.close();
    },
    [packageFile, packageName]
  );

  return (
    <div className="lf-download-package">
      {!loading && packageFile && (
        <>
          <CheckRoundIcon
            size="5em"
            color="green"
          />
          <div className="message">
            Package created succesfully
          </div>
          {instructions && (
            <>
              <div className="instructions-heading">
                Click on the button below to download the package.<br/>
                Unzip it and run these commands:
              </div>
              <pre className="instructions">{instructions}</pre>
            </>
          )}
          {isShowSaveFilePickerSupported && (
            <Button
              appearance="primary"
              onClick={handleDownload}
            >Download "{packageName}"</Button>
          )}
          {!isShowSaveFilePickerSupported && (
            <a
              className="rs-btn rs-btn-primary"
              appearance="primary"
              download={packageName}
              href={URL.createObjectURL(packageFile)}
            >Download "{packageName}"</a>
          )}
          <div className="size">File size: <b>{formatBytes(packageFile.size)}</b></div>
        </>
      )}
      {!loading && !packageFile && (
        <div>
          <WarningRoundIcon
            size="5em"
            color="red"
          />
          <div className="message">
            Something went wrong with the package creation
          </div>
          <span>
            Please try again later.
          </span>
        </div>
      )}
      {loading && (
        <div className="loader">
          <Loader
            speed="normal"
            size="md"
            content={'Creating package'}
          />
        </div>
      )}
    </div>
  );
};

export { DownloadGenericPackage };
