/* eslint-disable no-template-curly-in-string */
import {
  COMMON_TRITTICUS_STATUS,
  makeColums,
  COMMON_PLACEHOLDER,
  COMMON_TRITTICUS,
  MUI_DATE_FORMAT,
  MUI_FORMAT_DENSITY,
  MUI_FLOATING_LABEL,
  MUI_SIZE,
  MUI_VARIANT,
  MUI_COLORS,
  COMMON_FULL_WIDTH_SIZE,
  MANTINE_SIZE,
  MANTINE_RADIUS,
  MANTINE_VARIANT,
  MANTINE_SECTIONS,
  MANTINE_POINTER,
  ANTD_ALLOW_CLEAR,
  ANTD_PLACEMENT,
  ANTD_SIZE,
  ANTD_VARIANT,
  ANTD_HINT_AS_TOOLTIP
} from '../costants/index.js';
import { omitFields } from '../helpers/omit-fields.js';

import { manifest as DateTimeManifest } from './datetime.js';

const manifest = {
  label: 'Time',
  category: 'general',
  description: 'Time input',
  name: 'time',
  frameworks: [
    'react-antd',
    'react',
    //'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    ...COMMON_TRITTICUS,
    COMMON_PLACEHOLDER,
    COMMON_TRITTICUS_STATUS
  ],
  'react-bootstrap': [],
  'react-material-ui': [
    makeColums(
      MUI_SIZE,
      MUI_VARIANT,
      MUI_COLORS
    ),
    MUI_DATE_FORMAT,
    {
      name: 'views',
      label: 'Views',
      component: 'multiselect',
      cleanable: true,
      fullWidth: true,
      placeholder: 'Select views',
      options: [
        { value: 'hours', label: 'hours' },
        { value: 'minutes', label: 'minutes' },
        { value: 'seconds', label: 'seconds' }
      ]
    },
    makeColums(
      MUI_FLOATING_LABEL,
      {
        name: 'ampm',
        component: 'toggle',
        label: 'AM or PM',
        hint: '12h/24h view for hour selection clock',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'ampmInClock',
        component: 'toggle',
        label: 'AM/PM bottom',
        hint: 'Display ampm controls under the clock (instead of in the toolbar)',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'forceMobile',
        component: 'toggle',
        label: 'Force mobile',
        hint: 'Force mobile component',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'closeOnSelect',
        component: 'toggle',
        label: 'Close on select',
        hint: 'If true, the popover or modal will close after submitting the full date.',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'disableFuture',
        component: 'toggle',
        label: 'Disable feature',
        hint: 'If true, disable values after the current date for date components, time for time components and both for date time components.',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'disableOpenPicker',
        component: 'toggle',
        label: 'Disable picker',
        hint: 'If true, the open picker button will not be rendered (renders only the field)',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'disablePast',
        component: 'toggle',
        label: 'Disable past',
        hint: 'If true, disable values before the current date for date components, time for time components and both for date time components',
        'react-rsuite5': {
          tooltip: true
        }
      },
      []
    ),
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      {
        name: 'minTime',
        component: 'input-mask',
        label: 'Min time',
        mask: '${d}${d}:${d}${d}:${d}${d}',
        showMask: true
      },
      {
        name: 'maxTime',
        component: 'input-mask',
        label: 'Max time',
        mask: '${d}${d}:${d}${d}:${d}${d}',
        showMask: true
      }
    ),
    makeColums(
      MUI_FORMAT_DENSITY,
      {
        name: 'minutesStep',
        component: 'input-number',
        label: 'Minute step',
        hint: 'Step over minutes',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    /*
    DOES NOTHING
    {
      name: 'orientation',
      component: 'select',
      label: 'Orientation',
      options: makeOptions(['landscape', 'portrait'])
    },
    */
    makeColums(
      {
        name: 'timeStepsHours',
        component: 'input-number',
        label: 'Steps hours',
        min: 1,
        step: 1,
        hint: 'The time steps between two time unit options. For example, if timeStep.minutes = 8, then the available minute options will be [0, 8, 16, 24, 32, 40, 48, 56]',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'timeStepsMinutes',
        component: 'input-number',
        label: 'Steps mins',
        min: 1,
        step: 1
      },
      {
        name: 'timeStepsSeconds',
        component: 'input-number',
        label: 'Steps secs',
        min: 1,
        step: 1
      }
    )
  ],
  'react-rsuite5': [
    ...omitFields(DateTimeManifest['react-rsuite5'], ['weekOptions', 'format']),
    {
      name: 'format',
      component: 'select',
      label: 'Time format',
      options: [
        { label: '24:00', value: 'HH:mm' },
        { label: '24:00:00', value: 'HH:mm:ss' },
        { label: '12:00:00 AM/PM', value: 'hh:mm:ss aaa' },
        { label: '12:00 AM/PM', value: 'hh:mm aaa' }
      ]
    }
  ],
  'react-antd': [
    makeColums(
      ANTD_SIZE,
      ANTD_VARIANT,
      []
    ),
    {
      name: 'format',
      label: 'Date format',
      component: 'input-text',
      hint: 'Dayjs format to display input value, "HH:mm:ss" by default. Check https://day.js.org/docs/en/display/format for reference'
    },
    makeColums(
      {
        name: 'hourStep',
        component: 'input-number',
        label: 'Hour step',
        min: 1,
        step: 1,
        fullWidth: true,
        hint: 'Interval between hours in picker',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'minuteStep',
        component: 'input-number',
        label: 'Minute step',
        min: 1,
        step: 1,
        fullWidth: true,
        hint: 'Interval between minutes in picker',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'secondStep',
        component: 'input-number',
        label: 'Second step',
        min: 1,
        step: 1,
        fullWidth: true,
        hint: 'Interval between seconds in picker',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      ANTD_ALLOW_CLEAR,
      {
        name: 'hideDisabledOptions',
        component: 'toggle',
        label: 'Hide disabled',
        hint: 'Whether hide the options that can not be selected',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'inputReadOnly',
        component: 'toggle',
        label: 'Inner read-only',
        hint: 'Set the readonly attribute of the input tag (avoids virtual keyboard on touch devices)',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'needConfirm',
        component: 'toggle',
        label: 'Need confirm',
        hint: 'Need click confirm button to trigger value change',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'showNow',
        component: 'toggle',
        label: 'Show now',
        hint: 'Whether to show Now button on panel',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'use12Hours',
        component: 'toggle',
        label: 'Use 12 Hours',
        hint: 'Display as 12 hours format, with default format h:mm:ss a',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    ANTD_HINT_AS_TOOLTIP,
    ANTD_PLACEMENT,
  ],
  'react-mantine': [
    makeColums(
      MANTINE_SIZE,
      MANTINE_RADIUS,
      MANTINE_VARIANT
    ),
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      {
        name: 'minTime',
        component: 'input-mask',
        label: 'Min time',
        mask: '${d}${d}:${d}${d}:${d}${d}',
        showMask: true
      },
      {
        name: 'maxTime',
        component: 'input-mask',
        label: 'Max time',
        mask: '${d}${d}:${d}${d}:${d}${d}',
        showMask: true
      }
    ),
    makeColums(
      {
        name: 'withSeconds',
        component: 'toggle',
        label: 'With seconds',
        hint: 'Determines whether seconds input should be rendered',
        'react-rsuite5': {
          tooltip: true
        }
      },
      MANTINE_POINTER,
      {
        name: 'showBrowserPicker',
        component: 'toggle',
        label: 'Show picker',
        hint: 'Show browser native picker (if supported)',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    MANTINE_SECTIONS
  ],
  defaultValues: {
    'react-mantine': {
      'react-mantine': {}
    },
    'react-antd': {
      'react-antd': {
        format: 'HH:mm:ss',
        allowClear: true,
        showNow: true
      }
    },
    'react-material-ui': {
      'react-material-ui': {
        closeOnSelect: true,
        ampmInClock: true,
        formatDensity: 'dense',
        minutesStep: 1

      }
    },
    'react-rsuite5': {
      'react-rsuite5': {
        format: 'HH:mm:ss'
      }
    }
  },
  // same as Date
  dependencies: DateTimeManifest.dependencies
};

export { manifest };
