/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { Button, Checkbox } from 'rsuite';

import './signup-to-save-form.scss';

const SignupToSaveForm = ({
  onAccept = () => {},
  onCancel = () => {}
}) => {
  const handleCancel = useCallback(
    e => {
      e.preventDefault();
      onCancel();
    },
    [onCancel]
  );
  const handleChange = useCallback(
    (value, checked) => {
      if (checked) {
        localStorage.setItem('lf_dontAskToSaveForm', true);
      } else {
        localStorage.removeItem('lf_dontAskToSaveForm');
      }
    },
    []
  );

  return (
    <div className="lf-signup-save-form">
      <h3>Save your form!</h3>
      <p>
        Don't risk to lose your work, signup and save your form <b>it's free</b>.
      </p>
      <div className="dont-ask">
        <Checkbox onChange={handleChange}>Don't ask again</Checkbox>
      </div>
      <div className="buttons">
        <div className="left">
          <a href="#" onClick={handleCancel}>No thanks, maybe later</a>
        </div>
        <div className="right">
          <Button
            appearance="primary"
            onClick={onAccept}
          >Yes, signup</Button>
        </div>
      </div>
    </div>
  );
};

export { SignupToSaveForm };
