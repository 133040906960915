import { definePlugin } from '../../code-plug';

import { SaveButtonProject } from './views/save-button-project';

definePlugin('builder-save-project', ({ registerView }) => {

  registerView(
    'form-builder-toolbar',
    SaveButtonProject,
    {
      end: true,
      key: 'form-builder-toolbar-save-project'
    }
  );
});
