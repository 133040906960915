import {
  COMMON_HIDDEN,
  COMMON_NAME,
  COMMON_ALIGN_SELF,
  makeColums
} from '../costants/index.js';

const manifest = {
  label: 'Three Columns',
  category: 'layout',
  name: 'three-columns',
  hidden: true,
  frameworks: [
    'react-antd',
    'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    COMMON_NAME,
    {
      name: 'layout',
      component: 'select',
      cleanable: true,
      label: 'Layout',
      placeholder: 'Select layout',
      options: [
        { value: 'layout-0-1-0', label: 'auto - 100% - auto' },
        { value: 'layout-1-0-0', label: '100% - auto - auto' },
        { value: 'layout-1-1-1', label: '1 - 1 - 1' },
        { value: 'layout-1-1-2', label: '1 - 1 - 2' },
        { value: 'layout-1-2-1', label: '1 - 2 - 1' },
        { value: 'layout-2-1-1', label: '2 - 1 - 1' },
        { value: 'layout-1-1-3', label: '1 - 1 - 3' },
        { value: 'layout-1-3-1', label: '1 - 3 - 1' },
        { value: 'layout-3-1-1', label: '3 - 1 - 1' }
      ]
    },
    makeColums(
      {
        ...COMMON_ALIGN_SELF,
        name: 'leftAlignment',
        label: 'Left align'
      },
      {
        ...COMMON_ALIGN_SELF,
        name: 'centerAlignment',
        label: 'Center align'
      },
      {
        ...COMMON_ALIGN_SELF,
        name: 'rightAlignment',
        label: 'Right align'
      }
    ),
    COMMON_HIDDEN
  ],
  'react-rsuite5': [

  ],
  'form-generator': {
    hideValidation: true,
    addField: [
      'leftFields',
      'centerFields',
      'rightFields'
    ]
  },
  defaultValues: {
    'react-rsuite5': {
      layout: 'layout-1-1'
    },
    'react': {
      layout: 'layout-1-1'
    },
    'react-bootstrap': {
      layout: 'layout-1-1'
    },
    'react-material-ui': {
      layout: 'layout-1-1'
    },
    'react-antd': {
      layout: 'layout-1-1'
    }
  }
};

export { manifest };
