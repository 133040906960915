import { cleanUp } from '../lets-form';
import { createCDNUrl } from '../../helpers';

export const generateJson = (form, framework, { formatted = true } = {}) => {
  let j = cleanUp(
    {
      '$schema': `${createCDNUrl(`/schemas/${framework}/form.json`)}`,
      ...form
    },
    framework
  );

  if (formatted) {
    return JSON.stringify(j, null, 2);
  }
  return j;
};
