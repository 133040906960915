import React from 'react';
import { Whisper, Tooltip, IconButton } from 'rsuite';
import CloseIcon from '@rsuite/icons/Close';

import { useFormBuilderStore } from '../../../pages/builder/state';

import './close-button.scss';

const CloseButton = () => {
  const close = useFormBuilderStore(state => state.close);

  return (
    <Whisper
      trigger="hover"
      placement="left"
      speaker={<Tooltip>Close form designer</Tooltip>}
    >
      <IconButton
        appearance="ghost"
        size="xs"
        className="lf-btn-close-builder"
        icon={<CloseIcon />}
        onClick={close}
      />
    </Whisper>
  );
};

export { CloseButton }