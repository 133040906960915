import React, { useCallback, useState } from 'react';
import { Dropdown } from 'rsuite';
import _ from 'lodash';

import { useFormBuilderStore } from '../../../pages/builder/state';
import { useModal } from '../../../components/modal';
import { useTracking } from '../../../hooks';

import { PublishFormModal } from './publish-form';

const PublishFormMenuItem = () => {
  const [sharedForm, setSharedForm] = useState();
  const tracking = useTracking();
  const formId = useFormBuilderStore(state => state.formId);
  const size = useFormBuilderStore(state => state.size);
  const framework = useFormBuilderStore(state => state.framework);
  const form = useFormBuilderStore(state => state.form);

  const { open: openShareForm, close: closeShareForm } = useModal({
    view: PublishFormModal,
    size: 'sm',
    name: 'share_form',
    align: 'center',
    labelSubmit: null,
    labelCancel: null,
    title: 'Publish Form'
  });

  const handleClick = useCallback(
    async () => {
      tracking.sendEvent('form.share');
      const newSharedForm = await openShareForm({
        sharedForm,
        form,
        formId,
        framework,
        size
      });
      closeShareForm();

      if (newSharedForm && Object.keys(newSharedForm).length !== 0) {
        setSharedForm(newSharedForm)
      } else if (_.isObject(newSharedForm) && Object.keys(newSharedForm).length === 0) {
        setSharedForm(null);
      }
    },
    [closeShareForm, form, formId, framework, openShareForm, sharedForm, size, tracking]
  );


  return (
    <Dropdown.Item onSelect={handleClick}>Publish form</Dropdown.Item>
  );
};

export { PublishFormMenuItem };
