import React from 'react';
import { Button } from 'rsuite';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';

const CreateHelpButton = link => {
  return () => {
    return (
      <Button
        appearance="default"
        startIcon={<HelpOutlineIcon />}
        style={{ float: 'left' }}
        href={link} 
        target="_blank" 
      >Help</Button>
    );
  };
};

export { CreateHelpButton };
