export const ArrowDash = ({ 
  width = 100, 
  height = 100, 
  color = '#336699',
  flip = false 
}) => (
  <svg width={width} height={height} style={flip ? { transform: 'scale(-1)'} : null} viewBox="0 0 532 537" xmlns="http://www.w3.org/2000/svg">
    <path id="Percorso" fill="none" stroke={color} strokeWidth="32" strokeOpacity="0.9" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="18.56 41.28" strokeDashoffset="0" d="M 423 113 C 390.443512 121.182922 275.994873 179.324677 222.266998 236.867706 C 159.227707 304.383362 188.989853 373.433838 179 451"/>
    <path id="path1" fill="none" stroke={color} strokeWidth="32" strokeOpacity="0.9" strokeLinecap="round" strokeLinejoin="round" d="M 94 360 C 160.767441 397.661682 170.25769 459.884003 172.348404 461.679657 C 175.486038 464.374451 222.716156 393.289917 277 385"/>
  </svg>
);
