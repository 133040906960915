/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import './alchemic-loader.scss';

const SYMBOLS = [
  '🝓', '🝒', '🜒', '🜲', '🝖', '🝘', '🝧', '🝦', '🝤', '🝰', '🝑', '🜒', 
  '🜍', '🜋', '🜥', '🜠', '🜡', '🜢', '🜣', '🜤', '🜘', '🜙', '🜚', '🜛', '🜜', 
  '🜝', '🜞', '🜟', '🝀', '🝂', '🝃', '🝊', '🝋', '🝌', '🝍', '🝤', '🝥', '🝦',
  '🜺',	'🜻',	'🜼', '🜽', '🜾',	'🜿', '🜁', '🜂', '🜃', '🜐', '🜑', '🝡', '🝢',	
  '🝣', '🜰', '🜱', '🜩', '🜪', '🜫', '🜬', '🜭', '🜮', '🜯'
];
 
const AlchemicLoader = ({ margin }) => {
  const [counter, setCounter] = useState(0);
  const [symbol, setSymbol] = useState(SYMBOLS[_.random(0, SYMBOLS.length -1)]);

  useEffect(
    () => {
      const timerId = setInterval(
        () => {
          setCounter(i => i + 1);
          if ((counter % 10) === 0) {
            setSymbol(SYMBOLS[_.random(0, SYMBOLS.length -1)]);
          }
        },
        100
      );
      return () => clearInterval(timerId);
    }
  );

  return (
    <div className="lf-alchemic-loader" style={margin && { marginTop: `${margin}px`, marginBottom: `${margin}px` }}>
      <div className={classNames('slide', { in: counter === 0 || (counter % 10) !== 0})}>
        {symbol}
      </div>
    </div>
  );
};

export { AlchemicLoader };
