import React, { useCallback } from 'react';
import { Button, Tag } from 'rsuite';
import { useNavigate } from 'react-router-dom';

import { CopyAndPasteButton } from '../../../../components';
import { getPlaygroundIframe } from '../../../playground-configurator/helpers/get-playground-iframe';

import './playground-form-card.scss';

const PlaygroundFormCard = ({ playgroundForm, onRemoved = () => {} }) => {
  const navigate = useNavigate();

  const handleDeleteLink = useCallback(
    () => {
      navigate(`/playground/${playgroundForm.shareCode}`);
    },
    [navigate, playgroundForm]
  );

  const link = getPlaygroundIframe({
    name: playgroundForm.name,
    shareCode: playgroundForm.shareCode,
    height: playgroundForm.height
  });
  const d = new Date(playgroundForm.date_created);

  return (
    <div className="lf-playground-form">
      <Tag>{d.toLocaleDateString()}</Tag>
      {' '}
      <b>{playgroundForm.name}</b>
      <div className="buttons">
        <Button
          onClick={handleDeleteLink}
          size="xs"
          appearance="ghost"
          className="btn-view"
        >View</Button>
        <CopyAndPasteButton
          text={link}
          size="xs"
          float={null}
          label="Copy IFrame"
          notification="Playground IFrame code succesfully copied to clipboard"
        />
      </div>
    </div>
  );
};

export { PlaygroundFormCard };
