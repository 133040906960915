import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFormBuilderStore } from '../../../pages/builder/state';
import { useModal } from '../../../components';
import { useTracking } from '../../../hooks';
import { useNotification } from '../../../hooks';
import { useFormVersion } from '../../../pages/project-form/hooks/save-form-version';
import { SaveComment } from '../../../components/lets-form-forms';

import { SaveButtonWithOptions } from './save-with-buttons';

const SaveButtonProject = ({ disabled }) => {
  const tracking = useTracking();
  const navigate = useNavigate();
  const notification = useNotification();

  const form = useFormBuilderStore(state => state.form);
  const projectId = useFormBuilderStore(state => state.projectId);
  const formId = useFormBuilderStore(state => state.formId);
  const setDisabledToolbar = useFormBuilderStore(state => state.setDisabledToolbar);
  const { saveFormVersion, saveFormVersionWithComment } = useFormVersion(formId, projectId)

  const { open: openVersionComment, close: closeVersionComment } = useModal({
    view: function(props) {
      const { onCancel, onSubmit } = props;
      return (
        <SaveComment
          defaultValues={props.value}
          onSubmit={onSubmit}
          onReset={onCancel}
        />
      );
    },
    size: 'sm',
    name: 'editVersionComment',
    align: 'center',
    labelSubmit: null,
    labelCancel: null,
    title: 'Version comment'
  });

  const handleSave = useCallback(
    async (event) => {
      tracking.sendEvent('form.save');

      try {
        let formId;
        if (event === 'cmd:save') {
          setDisabledToolbar(true);
          formId = await saveFormVersion({ name: form.name, form });
          setDisabledToolbar(false);
          // notify if not closing
          notification.success(
            'Save form',
            <><b>{form.name}</b> was saved succesfully!</>
          );
          // if formId, then the form is new, push new url
          if (formId) {
            window.history.pushState({}, null, `/projects/${projectId}/forms/${formId}`);
          }
        } else if (event === 'cmd:saveAndClose') {
          setDisabledToolbar(true);
          formId = await saveFormVersion({ name: form.name, form });
          navigate(`/projects/${projectId}`);
        } else if (event === 'cmd:saveWithComment') {
          const values = await openVersionComment();
          await closeVersionComment();
          if (values) {
            setDisabledToolbar(true);
            formId = await saveFormVersionWithComment({
              name: form.name,
              form,
              comment: values.comment
            });
            navigate(`/projects/${projectId}`);
          }
        }
      } catch(e) {
        notification.error('Save form', e);
      }
    },
    [tracking, setDisabledToolbar, saveFormVersion, form, notification, projectId, navigate, openVersionComment, closeVersionComment, saveFormVersionWithComment]
  );

  return (
    <SaveButtonWithOptions
      onClick={handleSave}
      disabled={disabled}
    />
  );
};

export { SaveButtonProject };
