/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { FiCopy } from 'react-icons/fi';
import classNames from 'classnames';

import { useNotification } from '../../hooks';

import './textarea-copy-clipboard.scss';

const TextareaCopyClipboard = ({
  children,
  truncate = true,
  className,
  width,
  title = 'Dependencies',
  description = 'Dependencies copied to clipboard!'
}) => {
  const notification = useNotification();

  const handleClick = useCallback(
    e => {
      e.preventDefault();
      navigator.clipboard.writeText(children);
      notification.success(title, description);
     },
    [children, description, notification, title]
  );

  return (
    <div
      className={classNames('lf-textarea-clipboard', className, { truncate })}
      style={width ? { width: `${width}px`} : undefined}
    >
      <a className="btn-copy" href="#" onClick={handleClick}>
        <FiCopy size={20} />
      </a>
      <pre className="code-install">
        {children}
      </pre>

    </div>
  );
};

export { TextareaCopyClipboard };
