import React, { useState, useCallback } from 'react';
import { Input } from 'rsuite';

import { validateJSONForm } from '../../../components/lets-form';

import './import-form.scss';

const ImportForm = ({ onChange }) => {
  const [code, setCode] = useState('');
  const [error, setError] = useState(null);

  const handleChange = useCallback(
    value => {
      setCode(value);
      let json;
      try {
        json = JSON.parse(value)
      } catch (e) {
        setError('Not a valid JSON');
        onChange(null);
        return;
      }
      onChange(json);
      // show errors
      const validation = validateJSONForm(json);
      if (validation) {
        setError(validation);
        return;
      }
      setError(null);
    },
    [onChange]
  );

  return (
    <div className="lf-import-form">
      {error && (
        <div className="errors">
          <b>JSON Errors</b><br/>
          {error}
        </div>
      )}
      <Input
        as="textarea"
        style={{ width: '100%', height: '400px' }}
        value={code}
        onChange={handleChange}>
      </Input>
    </div>
  )
};

export { ImportForm };
