import React, { useCallback } from 'react';
import { Dropdown } from 'rsuite';

import { useFormBuilderStore } from '../../../pages/builder/state';

const FORM_PREVIEW_SIZES = [
  { label: '25 %', value: 6 },
  { label: '33 %', value: 8 },
  { label: '42 %', value: 10 },
  { label: '50 %', value: 12 },
  { label: '63 %', value: 15 },
  { label: '75% ', value: 18 },
  { label: '100 %', value: 24 }
];

const PreviewSizeMenuItem = () => {
  const size = useFormBuilderStore(state => state.size);
  const setSize = useFormBuilderStore(state => state.setSize);

  const handleSelectSize = useCallback(
    size => {
      const newSize = parseInt(size, 10);
      setSize(newSize);
    },
    [setSize]
  );

  return (
    <Dropdown.Menu title="Preview size">
      {FORM_PREVIEW_SIZES.map(item => (
        <Dropdown.Item
          key={item.value}
          eventKey={item.value}
          active={size === item.value}
          onSelect={handleSelectSize}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.label}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  );
};

export { PreviewSizeMenuItem };
