import React, { useEffect, useState } from 'react';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const PreviewJson = ({ json }) => {
  const [stringified, setStringified] = useState();
  useEffect(
    () => {
      try {
        setStringified(JSON.stringify(json, null, 2))
      } catch(e) {
        console.error('Error stringifying JSON:', e, json);
      }
    },
    [json]
  );

  if (stringified) {
    return (
      <SyntaxHighlighter
        language="json"
        style={nightOwl}
        customStyle = {{
          lineHeight: '1.2',
          fontSize: '0.9em',
          marginTop: '0px',
          marginBottom: '0px'
        }}
        codeTagProps = {{
          style: {
            lineHeight: "inherit",
            fontSize: "inherit"
          }
        }}
      >
        {stringified}
      </SyntaxHighlighter>
    );
  }
};

export { PreviewJson };
