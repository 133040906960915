import React, { useCallback } from 'react';
import { Whisper, Popover, Tag } from 'rsuite';
import InfoRoundIcon from '@rsuite/icons/InfoOutline';
import classNames from 'classnames';
import _ from 'lodash';

import { Fields, FRAMEWORKS } from '../../../components/lets-form';
import { FrameworkIcons } from '../../../components';

const IsSupportedByFramework = control => {
  return framework => {
  // there's a React control in manifest
  // there's an explicit list of frameworks
  return (Fields[control.name] != null && Fields[control.name][framework] != null)
    || (_.isArray(control.frameworks) && (control.frameworks.includes(framework) || control.frameworks.includes('*')));
  };
};


const ControlCard = ({
  control,
  onClick = () => {},
  framework,

}) => {
  const Icon = control.icon;
  const isSupportedByFramework = IsSupportedByFramework(control);
  const disabled = !isSupportedByFramework(framework);
  const handleClick = useCallback(
    e => {
      e.preventDefault();
      if (!disabled) {
        onClick(control);
      }
    },
    [control, disabled, onClick]
  );


  const content = (
    <Popover className="lf-control-popover" title={control.label} style={{ width: '300px' }}>
      <div>
      {control.description}
      </div>
      <div className="tag">
        <Tag size="sm" color="violet">{control.name}</Tag>
      </div>
      <div className="icons">
        {FRAMEWORKS
          .filter(framework => isSupportedByFramework(framework))
          .map(framework => {
            const Icon = FrameworkIcons[framework];
            return <Icon key={framework} size={24} />
          })
        }
      </div>
    </Popover>
  );

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href="#"
      key={control.name}
      className={classNames('control', { disabled })}
      onClick={handleClick}
    >
      {Icon && <div className="icon"><Icon size={24}/></div>}
      <div className="description">
        <div className="name">{control.label}</div>
      </div>
        <Whisper
          placement="rightStart"
          trigger="hover"
          speaker={content}
        >
          <div className="info">
            <InfoRoundIcon size="1em"/>
          </div>
        </Whisper>
    </a>
  );
};

export { ControlCard };
