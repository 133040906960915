import { makeOptions } from "./helpers";

export const ANTD_SIZE = {
  name: 'size',
  label: 'Size',
  component: 'select',
  placeholder: 'Select size',
  cleanable: true,
  options: [
    { value: 'small', label: 'Small' },
    { value: 'default', label: 'Default' },
    { value: 'large', label: 'Large' }
  ]
};

export const ANTD_VARIANT = {
  name: 'variant',
  label: 'Variant',
  component: 'select',
  placeholder: 'Select variant',
  cleanable: true,
  options: makeOptions(['outlined', 'borderless', 'filled'])
};

export const ANTD_TYPE = {
  name: 'type',
  label: 'Type',
  component: 'select',
  placeholder: 'Select type',
  cleanable: true,
  options: [
    { value: 'primary', label: 'Primary' },
    { value: 'default', label: 'Default' },
    { value: 'dashed', label: 'Dashed' },
    { value: 'text', label: 'Text' },
    { value: 'link', label: 'Link' }
  ]
};

export const ANTD_PLACEMENT = {
  name: 'placement',
  label: 'Placement',
  component: 'select',
  placeholder: 'Select placement',
  placement: 'autoVerticalStart',
  cleanable: true,
  options: [
    { value: 'bottomLeft', label: 'Bottom left' },
    { value: 'bottomRight', label: 'Bottom right' },
    { value: 'topLeft', label: 'Top left' },
    { value: 'topRight', label: 'Top right' }
  ]
};

export const ANTD_PLACEMENT_EXTENDED = {
  name: 'placement',
  label: 'Placement',
  component: 'select',
  placeholder: 'Select placement',
  placement: 'autoVerticalStart',
  cleanable: true,
  options: [
    { value: 'top', label: 'Top' },
    { value: 'left', label: 'Left' },
    { value: 'right', label: 'Right' },
    { value: 'bottom', label: 'Bottom' },
    { value: 'topLeft', label: 'Top left' },
    { value: 'topRight', label: 'Top right' },
    { value: 'bottomLeft', label: 'Bottom left' },
    { value: 'bottomRight', label: 'Bottom right' },
    { value: 'topLeft', label: 'Top left' },
    { value: 'topRight', label: 'Top right' },
    { value: 'leftTop', label: 'Left top' },
    { value: 'leftBottom', label: 'Left Bottom' },
    { value: 'rightTop', label: 'Right top' },
    { value: 'rightBottom', label: 'Right Bottom' }
  ]
};

export const ANTD_PREFIXES = {
  name: 'fixes',
  component: 'columns',
  columns: [
    { name: 'one', size: 1 },
    { name: 'two', size: 1 }
  ],
  fields: {
    one: [
      {
        label: 'Prefix',
        name: 'prefix',
        component: 'input-text',
      }
    ],
    two: [
      {
        label: 'Postfix',
        name: 'postfix',
        component: 'input-text',
      }
    ]
  }
};

export const ANTD_ALLOW_CLEAR = {
  name: 'allowClear',
  label: 'Clear button',
  component: 'toggle'
};

export const ANTD_COUNT_CHARS = {
  name: 'showCount',
  label: 'Count chars',
  component: 'toggle'
};

export const ANTD_HINT_AS_TOOLTIP = {
  label: 'Hint as tooltip',
  name: 'tooltip',
  component: 'toggle'
};

export const ANTD_SIZE_ONLY_SMALL = {
  id: 'size',
  label: 'Size',
  name: 'size',
  component: 'select',
  placeholder: 'Select size',
  options: [
    { value: 'default', label: 'Default' },
    { value: 'small', label: 'Small' }
  ]
};

export const ANTD_WIDTH = {
  name: 'width',
  label: 'Width',
  component: 'input-number',
  allowClear: true,
  min: 1,
  step: 10
};

export const ANTD_LOCALES = [
  { label: 'Arabic', value: 'ar_EG'},
  { label: 'Azerbaijani', value: 'az_AZ'},
  { label: 'Bulgarian', value: 'bg_BG'},
  { label: 'Bangla (Bangladesh)', value: 'bn_BD'},
  { label: 'Belarusian', value: 'by_BY'},
  { label: 'Catalan', value: 'ca_ES'},
  { label: 'Czech', value: 'cs_CZ'},
  { label: 'Danish', value: 'da_DK'},
  { label: 'German', value: 'de_DE'},
  { label: 'Greek', value: 'el_GR'},
  { label: 'English (United Kingdom)', value: 'en_GB'},
  { label: 'English', value: 'en_US'},
  { label: 'Spanish', value: 'es_ES'},
  { label: 'Basque', value: 'eu_ES'},
  { label: 'Estonian', value: 'et_EE'},
  { label: 'Persian', value: 'fa_IR'},
  { label: 'Finnish', value: 'fi_FI'},
  { label: 'French (Belgium)', value: 'fr_BE'},
  { label: 'French (Canada)', value: 'fr_CA'},
  { label: 'French (France)', value: 'fr_FR'},
  { label: 'Irish (Ireland)', value: 'ga_IE'},
  { label: 'Galician (Spain)', value: 'gl_ES'},
  { label: 'Hebrew', value: 'he_IL'},
  { label: 'Hindi', value: 'hi_IN'},
  { label: 'Croatian', value: 'hr_HR'},
  { label: 'Hungarian', value: 'hu_HU'},
  { label: 'Indonesian', value: 'id_ID'},
  { label: 'Italian', value: 'it_IT'},
  { label: 'Icelandic', value: 'is_IS'},
  { label: 'Japanese', value: 'ja_JP'},
  { label: 'Georgian', value: 'ka_GE'},
  { label: 'Kurdish (Kurmanji)', value: 'kmr_IQ'},
  { label: 'Kannada', value: 'kn_IN'},
  { label: 'Kazakh', value: 'kk_KZ'},
  { label: 'Khmer', value: 'km_KH'},
  { label: 'Korean', value: 'ko_KR'},
  { label: 'Lithuanian', value: 'lt_LT'},
  { label: 'Latvian', value: 'lv_LV'},
  { label: 'Macedonian', value: 'mk_MK'},
  { label: 'Malayalam (India)', value: 'ml_IN'},
  { label: 'Mongolian', value: 'mn_MN'},
  { label: 'Malay (Malaysia)', value: 'ms_MY'},
  { label: 'Norwegian', value: 'nb_NO'},
  { label: 'Dutch (Belgium)', value: 'nl_BE'},
  { label: 'Dutch', value: 'nl_NL'},
  { label: 'Polish', value: 'pl_PL'},
  { label: 'Portuguese (Brazil)', value: 'pt_BR'},
  { label: 'Portuguese', value: 'pt_PT'},
  { label: 'Romanian', value: 'ro_RO'},
  { label: 'Russian', value: 'ru_RU'},
  { label: 'Sinhalese / Sinhala', value: 'si_LK'},
  { label: 'Slovak', value: 'sk_SK'},
  { label: 'Serbian', value: 'sr_RS'},
  { label: 'Slovenian', value: 'sl_SI'},
  { label: 'Swedish', value: 'sv_SE'},
  { label: 'Tamil', value: 'ta_IN'},
  { label: 'Thai', value: 'th_TH'},
  { label: 'Turkish', value: 'tr_TR'},
  { label: 'Turkmen', value: 'tk_TK'},
  { label: 'Urdu (Pakistan)', value: 'ur_PK'},
  { label: 'Ukrainian', value: 'uk_UA'},
  { label: 'Vietnamese', value: 'vi_VN'},
  { label: 'Chinese (Simplified)', value: 'zh_CN'},
  { label: 'Chinese (Traditional)', value: 'zh_TW'}
];

export const ANTD_LOCALES_PICKER = {
  name: 'locale',
  label: 'Locale',
  component: 'select',
  cleanable: true,
  searchable: true,
  placeholder: 'Select locale',
  options: ANTD_LOCALES
};

export const ANTD_MIN_MAX = {
  name: 'limits',
  component: 'columns',
  columns: [
    { name: 'one', size: 1 },
    { name: 'two', size: 1 },
    { name: 'three', size: 1 }
  ],
  fields: {
    one: [
      {
        label: 'Min',
        name: 'min',
        component: 'input-number',
      }
    ],
    two: [
      {
        label: 'Step',
        name: 'step',
        component: 'input-number',
      }
    ],
    three: [
      {
        label: 'Max',
        name: 'max',
        component: 'input-number',
      }
    ]
  }
};

export const ANTD_MAX_LENGTH = {
  name: 'maxLength',
  label: 'Max length',
  component: 'input-number',
  min: 1,
  step: 1
};
