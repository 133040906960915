import React, { useState, Fragment, useContext, useReducer, useCallback } from 'react';
import _ from 'lodash';

import reducer from './reducer';
import ModalWrapper from './model-wrapper';

// const isValid = validation => Object.values(validation).every(item => !item.hasError);

const ModalContext = React.createContext({});

/**
 * useModal
 * @param {Object} props
 * @param {React.View} props.view
 * @param {String} props.align Alignment of the buttons: left, center, right
 * @param {String} props.title Title of the modal
 * @param {String} props.labelSubmit Submit button
 * @param {String} props.labelCancel Cancel button
 * @param {String} props.className
 * @param {String} props.size Size of the modal
 * @param {React} props.custom Custom view, additional button in the footer
 * @param {String} props.enableSummit Enable or disable the submit button, takes as argument the current value of the form
 * @return {Object}
 * @return {Function} return.open Open the modal setting the initial state of the modal
 * @return {Function} return.update Update the internal state of the modal and wait
 * @return {Function} return.validate Set the validation info for the form
 * @return {Function} return.error Set the server side error for the form
 */
const useModal = props => {
  const context = useContext(ModalContext);
  const [localProps] = useState(props);

  const { dispatch } = context;

  const [id] = useState(_.uniqueId('modal_'));

  const open = useCallback(
    (modalProps, params) => {
      return new Promise(resolve => dispatch({ type: 'appendView', id, resolve, initialValue: modalProps, ...localProps, ...params }))
    },
    [id, localProps, dispatch]
  );
  const close = useCallback(
    () => dispatch({ type: 'removeView', id }),
    [id, dispatch]
  );
  const validate = useCallback(
    validation => dispatch({ type: 'mergeModalProps', id, props: { validation } }),
    [dispatch, id]);
  const disable = useCallback(
    () => dispatch({ type: 'mergeModalProps', id, props: { disabled: true } }),
    [dispatch, id]);
  const enable = useCallback(
    () => dispatch({ type: 'mergeModalProps', id, props: { disabled: false } }),
    [dispatch, id]);
  const error = useCallback(
    error => dispatch({ type: 'mergeModalProps', id, props: { error } }),
    [dispatch, id]
  );
  const update = useCallback(
    value => {
    return new Promise(resolve => dispatch({ type: 'appendView', id, resolve, updateValue: value }))
    },
    [dispatch, id]
  );
  const openWith = useCallback(
    async (modalProps, enableSubmit) => {
      let result = await open(modalProps, { enableSubmit });
      close();
      return result;
    },
    [close, open]
  );
  
  return {
    open,
    close,
    error,
    validate,
    disable,
    enable,
    openWith,
    update
  };
};

const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { modals: [] });
  const { modals } = state;

  return (
    <ModalContext.Provider value={{
      dispatch,
    }}>
      {!_.isEmpty(modals) && (
        <Fragment>
          {modals.map(({ id, view, props }) => <ModalWrapper view={view} key={id} {...props} />)}
        </Fragment>
      )}
      {children}
    </ModalContext.Provider>
  );
};

export { ModalProvider, useModal };