import React from 'react';

import Button from 'rsuite/Button';

import './error-boundary.scss';


const ViewErrorBoundary = ({ error }) => {

  const issueUrl = `https://github.com/guidone/lets-form/issues/new?labels=bug&body=` 
    + encodeURIComponent(`**Error stack trace:**\n\n${error.stack}\n\n**How to replicate:**\n\n`);

  return (
    <div className="lf-error-boundary">
      <h3>Something wrong happened</h3>
      <h5>Error description</h5>
      <div className="error-description">
        
        {error.message}
      </div>
      <h5>Error stack trace</h5>
      <div className="error-stack">
        {error.stack}
      </div>
      <div className="buttons">
        <Button
          href={issueUrl}
          target="_blank"
          appearance="primary"
        >
          Open issue on GitHub
        </Button>
      </div>
    </div>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      error: null
    };
  }

  static getDerivedStateFromError(error, errorInfo) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log('error', error);
    console.log('errorInfo m', error.message)
    console.log('errorInfo s', error.stack)
    console.log('errorInfo n', error.name)
  }

  render() {
    const { error, hasError } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <ViewErrorBoundary error={error} />
      )
    }

    return this.props.children; 
  }
}

export { ErrorBoundary, ViewErrorBoundary };