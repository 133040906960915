import React, { useState, useCallback } from 'react';
import { useQuery, gql, useApolloClient } from '@apollo/client';
import _ from 'lodash';

import LetsForm, { formHelper } from '../../../components/lets-form/react-rsuite5';
import SaveIntoProjectJson from '../../../components/lets-form-forms/save-into-project.json';
import { useNotification } from '../../../hooks';

import './save-into-project.scss';

const GET_PROJECT = gql`
query {
  projects {
    id,
    name
  }
}`;

const SAVE_PROJECT = gql`
mutation(
  $name: String!,
  $projectId: ID!,
  $json: String) {
 form: create_forms_item(data: {
   name: $name,
   projectId: {
     id: $projectId
   },
   versions: [
    {
      json: $json
    }
   ]
 }) {
   id,
   projectId {
    id
   }
 }
}
`;

const SaveIntoProject = ({
  value,
  onCancel,
  onSubmit = () => {}
}) => {
  const client = useApolloClient();
  const notification = useNotification();
  const [disabled, setDisabled] = useState(false);
  const { loading, data } = useQuery(GET_PROJECT);

  const { form } = value;

  const handleSubmit = useCallback(
    async values => {
      setDisabled(true);
      try {
        const newForm = await client.mutate({
          mutation: SAVE_PROJECT,
          variables: {
            name: values.name,
            projectId: values.projectId,
            json: JSON.stringify(value.form)
          }
        });

        notification.info(
          'Saved!',
          <div>
            Form <b>{values.name}</b> was saved successfully!
          </div>
        );

        setDisabled(false);
        onSubmit(newForm.data.form);
      } catch(e) {
        notification.error(
          'Error!',
          <div>
            <b>Something went wrong</b> saving your form <b>{values.name}</b>, please try again later.
          </div>
        );
        console.error(`LetsForm error saving form ${values.name}`);
        setDisabled(false);
      }
    },
    [client, notification, onSubmit, value]
  );

  return (
    <div>
      <LetsForm
        form={
          formHelper(SaveIntoProjectJson)
            .skip(loading)
            .setField('projectId', {
              options: _.orderBy(
                data?.projects.map(project => ({ value: project.id, label: project.name })),
                project => project.label.toLowerCase()
              )
            })
            .form()
        }
        hideCancel={true}
        defaultValues={!_.isEmpty(form.name) ? { name: form.name } : null}
        disabled={disabled || loading}
        onSubmit={handleSubmit}
        onReset={onCancel}
      />
    </div>
  );
};

export { SaveIntoProject };
