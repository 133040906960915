import { makeColums } from './helpers.js';

export const REACT_SIZE_WIDTH = makeColums(
  {
    name: 'size',
    component: 'select',
    label: 'Size',
    placeholder: 'Select size',
    cleanable: true,
    block: true,
    options: [
      { value: '1', label: '100%' },
      { value: '3-4', label: '75%' },
      { value: '2-3', label: '66%' },
      { value: '1-2', label: '50%' },
      { value: '1-3', label: '33%' },
      { value: '1-4', label: '25%' },
      { value: 'custom', label: 'custom...' }
    ],
    placement: 'autoVerticalStart',
    script: `
    if (size === 'custom') {
      enable('width');
    } else {
      disable('width');
    }
    `
  },
  {
    name: 'width',
    label: 'Width',
    component: 'input-number',
    disabled: true,
    min: 1,
    step: 10,
    allowClear: true
  }
);