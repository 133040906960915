import React from 'react';

const ModalConfirmOverwrite = ({ value }) => {
  return (
    <div>
      <b>It's not possible to import the selected template and merge with the current form.</b><br/>
      The current form has some field names which already exists in the selected template: <em>{value.fieldNames}</em>.<br/>
      Either rename or remove those field to use this template, cannot exist two field with the same name in a form.
    </div>
  );
};

export { ModalConfirmOverwrite };
