/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import { Input, Button, ButtonToolbar } from 'rsuite';
import { usePostHog } from 'posthog-js/react';

import { useNotification } from '../../hooks';
import { useModal } from '../modal';

import './ask-feedback.scss';

const FormFeedback = ({ onSubmit, onCancel }) => {
  const [value, setValue] = useState('');

  const handleSend = useCallback(
    () => {
      onSubmit({ feedback: value });
    },
    [onSubmit, value]
  );

  return (
    <div className="lf-leave-feedback-modal">
      What can we do to improve <b>LetsForm Designer</b>?
      <Input
        onChange={setValue}
        value={value}
        as="textarea"
        rows={3}
        placeholder="Start typing..."
        className="feedback"
        style={{ resize: 'none' }}
      />
      <ButtonToolbar align="center" style={{ justifyContent: 'center' }}>
        <Button
          disabled={value === ''}
          appearance="primary"
          onClick={handleSend}
        >Send feedback</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </ButtonToolbar>
    </div>
  )
};

const AskFeedback = () => {
  const posthog = usePostHog();
  const notification = useNotification();
  const { open, close } = useModal({
    view: FormFeedback,
    size: 'sm',
    name: 'enterPromoCode',
    align: 'center',
    labelSubmit: null,
    labelCancel: null,
    title: 'Leave feedback',
  });
  const handleFeedback = useCallback(
    async e => {
      e.preventDefault();
      const response = await open();
      await close();

      if (response) {
        // send feedback
        posthog.capture('survey sent', {
          $survey_id: '018fe8b5-d477-0000-f2ba-892b082df4b5',
          $survey_response: response.feedback
        });

        notification.success(
          'Feedback',
          <div>
            The feedback was submitted succesfully!<br/>
            <b>Thanks</b>
          </div>
        );
      }
    },
    [close, notification, open, posthog]
  );


  return (
    <div className="lf-ask-feedback">
      <Button color="orange" size="xs" onClick={handleFeedback} appearance="primary">Leave feedback</Button>
    </div>
  );
};

export { AskFeedback };
