import React, { useCallback, useState } from 'react';

import { useNotification } from '../../../hooks';
import { PromoCode } from '../../lets-form-forms';
import { ErrorBox } from '../../error-box';

const ActivatePromoCode = ({ onSubmit, value }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const notification = useNotification();

  const { access_token, user } = value;

  const handleChange = useCallback(
    () => setError(null),
    []
  );

  const handleActivate = useCallback(
    async values => {
      try {
        setLoading(true);
        const res = await fetch(`/api/promo`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${access_token}`
          },
          body: JSON.stringify({
            promo_code: values.promo_code,
            organizationId: user.organization.id
          })
        });
        const activation = await res.json();
        setLoading(false);
        // check result
        if (activation.error) {
          setError(activation.error);
        } else if (activation.status === 'active') {
          onSubmit(true);
          notification.success(
            'Promo code',
            <div>The promo code was succesfully activated!</div>
          );
        } else {
          notification.error(
            'Promo code',
            <div><b>Something went wrong</b> trying to activate the promo code, try again later.</div>
          );
        }        
      } catch(e) {
        notification.error(
          'Promo code',
          <div><b>Something went wrong</b> trying to activate the promo code, try again later.</div>
        );
        setLoading(false);
      }
    },
    [access_token, notification, onSubmit, user]
  );

  return (
    <div>
      {error && (
        <ErrorBox 
          error={error}
        />
      )}
      <PromoCode
        hideCancel={true}
        onSubmit={handleActivate}
        disabled={loading}
        onChange={handleChange}
      /> 
    </div>
  );
};

export { ActivatePromoCode };
