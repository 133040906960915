import React, { useCallback, useState } from 'react';
import {
  Button,
  Dropdown,
  ButtonGroup,
  Whisper,
  IconButton,
  Popover
} from 'rsuite';
import ArrowDownIcon from '@rsuite/icons/ArrowDown';

const SaveButtonWithOptions = ({
  disabled,
  onClick
}) => {
  const [choice, setChoice] = useState();

  const renderSaveMenu = ({ onClose, left, top, className }, ref) => {
    const handleSelect = eventKey => {
      setChoice(eventKey);
      onClose();
    };
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu onSelect={handleSelect}>
          <Dropdown.Item eventKey="cmd:saveAndClose">Save and close</Dropdown.Item>
          <Dropdown.Item eventKey="cmd:saveWithComment">Save with comment...</Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  };

  const handleClick = useCallback(
    () => onClick('cmd:save'),
    [onClick]
  );
  const handleClose = useCallback(
    () => {
      if (choice) {
        onClick(choice);
      }
    },
    [onClick, choice]
  );

  return (
    <ButtonGroup>
    <Button
      size="sm"
      appearance="primary"
      onClick={handleClick}
      disabled={disabled}        
    >Save</Button>
    <Whisper 
      placement="bottomStart" 
      trigger="click" 
      speaker={renderSaveMenu}
      onClose={handleClose}
    >
      <IconButton 
        icon={<ArrowDownIcon />} 
        size="sm" 
        appearance="primary"
        disabled={disabled} 
      />
    </Whisper>
  </ButtonGroup>
  );
};

export { SaveButtonWithOptions };
