/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import Whisper from 'rsuite/Whisper';
import Tooltip from 'rsuite/Tooltip';
import classNames from 'classnames';

import './add-field-button.scss';

const AddFieldButton = ({
  field,
  target = 'fields',
  subtarget = null,
  context,
  onClick = () => {},
  large = false
}) => {
  const handleClick = useCallback(
    e => {
      e.preventDefault();
      onClick(field, target, subtarget, context);
    },
    [onClick, field, subtarget, target, context]
  );

  let ofWhat;
  if (field?.component === 'group') {
    ofWhat = 'group';
  } else if (field?.component === 'two-columns' || field?.component === 'three-columns' || field?.component === 'columns') {
    ofWhat = 'this column';
  } else if (field?.component === 'array') {
    ofWhat = 'this list of field';
  } else if (field?.component === 'tabs') {
    ofWhat = 'this tab';
  }


  const tooltip = (
    <Tooltip>
      {field && (
        <span>Add field to {ofWhat} of <em>"{field.name}"</em></span>
      )}
      {!field && <span>Add field at the end of the form</span>}
      </Tooltip>
  );

  return (
    <div className={classNames('lf-add-button', { large })}>
      <Whisper
        placement="right"
        speaker={tooltip}
        trigger="hover"
      >
        <a
          href="#"
          onClick={handleClick}
        >ADD FIELD</a>
      </Whisper>
    </div>
  );
};

export { AddFieldButton };
