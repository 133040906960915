/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useCallback } from 'react';

import { useCurrentUser } from '../../hooks';
import { SignInUpBannerForm } from '../sign-in-up';

const WithLogin = (Component, initialMode = 'auto') => {
  return ({ modalSize, setModalSize, modalTitle, setModalTitle, ...props }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isAuthenticated } = useCurrentUser();
    const [previous,] = useState({
      size: modalSize,
      title: modalTitle
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(
      () => {
        if (!isAuthenticated) {
          setModalSize('lg');
          setModalTitle(null);
        }
      },
      [isAuthenticated, setModalSize, setModalTitle]
    );

    const handleOnLogin = useCallback(
      () => {
        setModalSize(previous.size);
        setModalTitle(previous.title);
      },
      [previous, setModalSize, setModalTitle]
    );

    return (
      <div>
        {isAuthenticated && (
          <Component {...props}/>
        )}
        {!isAuthenticated && (
          <SignInUpBannerForm
            initialMode={initialMode}
            onLogin={handleOnLogin}
          />
        )}
      </div>
    );
  };
};

export { WithLogin };
