import React from 'react';
import { Button } from 'rsuite';

import CheckOutlineIcon from '@rsuite/icons/CheckOutline';

import './index.scss';

const SuccessPlaceholder = ({
  children,
  title = 'Successfully done!',
  buttonLabel = 'Close',
  button = false,
  onClick = () => {},
  maxWidth = null
}) => {

  return (
    <div className="lf-error-placeholder">
      <CheckOutlineIcon style={{fontSize: '4em'}} color="#00A86B"/>
      <br />
      <b>{title}</b>
      {children && (
        <>
          <br />
          <div 
            className="legend" 
            style={maxWidth != null ? { maxWidth } : undefined}
          >{children}</div>
        </>
      )}
      {button && (
        <div style={{ marginTop: '30px' }}>
          <Button
            appearance="primary"
            onClick={onClick}            
          >{buttonLabel}</Button>
        </div>
      )}
    </div>
  );
};

export { SuccessPlaceholder };
