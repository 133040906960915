import { formHasComponents } from "../components/lets-form";

/**
 * Legend
 *
 * extraCode: extracode to be inserted before the component definition MyForm
 * letsFormStartWrapper: wrapper code (i.e. a <Provider>) that wraps the <LetsForm> component
 * letsFormEndWrapper: see above
 */

const FrameworkReactRsuite5 = {
  label: 'RSuite 5',
  name: 'rsuite5',
  letsFormLibrary: 'lets-form/react-rsuite5',
  // TODO are those used anymore?
  cssImports: [
    'rsuite/dist/rsuite.min.css'
  ],
  css: [
    { package: 'rsuite', path: '/dist/rsuite.min.css' }
  ],
  jsImports: [
    // only add custom provider if locales are selected
    ({ form }) => form.locales && form?.locales?.length > 0 ?
      `import CustomProvider from 'rsuite/CustomProvider';` : null
  ],
  letsFormStartWrapper: ({ form }) => form.locales && form?.locales?.length > 0 ?
    '<CustomProvider locale={locale}>' : null,
  letsFormEndWrapper: ({ form }) => form.locales && form?.locales?.length > 0 ?
    '</CustomProvider>' : null
};

const FrameworkReactMaterialUI = {
  label: 'Material UI',
  name: 'materialui',
  letsFormLibrary: 'lets-form/react-material-ui',
  letsFormStartWrapper: ({ form, locale }) => {
    if (formHasComponents(form, ['date', 'datetime'])) {
      return `<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="${locale?.split('-')[0].toLowerCase() || 'en'}">`;
    }
    return null;
  },
  letsFormEndWrapper: ({ form }) => formHasComponents(form, ['date', 'datetime']) ?
    '</LocalizationProvider>' : null
};

const FrameworkReactAntd = {
  label: 'Ant Design',
  name: 'antd',
  letsFormLibrary: 'lets-form/react-antd',
  letsFormStartWrapper: ({ form, locale }) => {
    if (form.locales && form?.locales?.length > 0) {
      return `<ConfigProvider locale={locale}>`;
    }
    return null;
  },
  letsFormEndWrapper: ({ form }) => form.locales && form?.locales?.length > 0 ?
    '</ConfigProvider>' : null
};

const FrameworkReact = {
  label: 'React',
  name: 'react',
  letsFormLibrary: 'lets-form/react'
};

const FrameworkReactBootstrap = {
  label: 'React Bootstrap',
  name: 'react-bootstrap',
  letsFormLibrary: 'lets-form/react-bootstrap',
  cssImports: [
    'bootstrap/dist/css/bootstrap.min.css'
  ],
  css: [
    { package: 'bootstrap', path: '/dist/css/bootstrap.min.css' }
  ]
};

const FrameworkReactMantine = {
  label: 'React Mantine',
  name: 'react-mantine',
  letsFormLibrary: 'lets-form/react-mantine',
  jsImports: [`import { createTheme, MantineProvider } from '@mantine/core';`],
  cssImports: ['@mantine/core/styles.css'],
  css: [
    { package: '@mantine/core', path: '/styles.css' }
  ],
  extraCode:
    'const theme = createTheme({\n' +
    '  /** Put your mantine theme override here */\n' +
    '});\n',
  letsFormStartWrapper: '<MantineProvider theme={theme}>',
  letsFormEndWrapper: '</MantineProvider>',
  htmlImportFactory: `const innerFormElement = React.createElement(
  LetsForm,
  {
    form,
    onSubmit: values => alert(JSON.stringify(values, null, 2))
  }
);
const formElement = React.createElement(
  MantineProvider,
  {},
  innerFormElement
);`
};

const Frameworks = {
  'react-rsuite5': FrameworkReactRsuite5,
  'react-antd': FrameworkReactAntd,
  'react-bootstrap': FrameworkReactBootstrap,
  'react': FrameworkReact,
  'react-material-ui': FrameworkReactMaterialUI,
  'react-mantine': FrameworkReactMantine
};

export const LETS_FORM_VERSION = process.env.REACT_APP_LETS_FORM_VERSION;
export default Frameworks;
