/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import { Timeline, Loader } from 'rsuite';
import { format } from 'date-fns';

import FormGenerator from '../../components/lets-form';
import { useNotification } from '../../hooks';
import { ErrorPlaceholder } from '../error-placeholder';
import { EmptyPlaceholder } from '../empty-placeholder';
import { EnsureFrameworkProvider } from '../ensure-framework-provider';

import { FormVersionButtons } from './views';
import './forms-versions.scss';
import { GET_VERSIONS, GET_VERSION, DELETE_VERSION, SAVE_FORM_VERSION } from './queries';

const extractVersionId = e => e.target.getAttribute('data-version') 
  || e.target.parentElement.getAttribute('data-version')
  || e.target.parentElement.parentElement.getAttribute('data-version');

const FormVersionsModal = ({ value }) => {
  const client = useApolloClient();
  const notification = useNotification();
  const [manualLoading, setLoading] = useState(false);
  const [manualError, setError] = useState(false);
  const [currentForm, setCurrentForm] = useState(null);
  const [currentVersion, setVersion] = useState();
  const { data, loading: graphqlLoading, error: graphqlError, refetch } = useQuery(GET_VERSIONS, {
    variables: {
      formId: value.formId
    },
    onCompleted: (data) => {
      if (data?.form?.versions && data.form.versions.length) {
        setVersion(data.form.versions[0].id);
      }
    }
  });

  useEffect(
    () => {
      const run = async () => {
        setLoading(true);
        const data = await client.query({ 
          query: GET_VERSION,
          variables: { versionId: currentVersion }
        });
        setLoading(false);
        // check error
        const version = data?.data?.version;
        if (!version) {
          setError(true);
          return;
        }
        // parse it
        let json;
        try {
          json = JSON.parse(version.json);
        } catch(e) {
          setError(true);
          return;
        }
        // finally set
        setCurrentForm(json);
      };
      if (currentVersion) {
        run();
      }
    },
    [client, currentVersion]
  );

  const isItemActive = useCallback(
    index => {
      if (data?.form?.versions 
        && data.form.versions[index] != null
        && data.form.versions[index].id === currentVersion) {
          return true;
        }
      return false;
    },
    [data, currentVersion]
  );

  const handleClick = useCallback(
    async e => {
      e.preventDefault();
      const versionId = extractVersionId(e);
      setVersion(versionId);
    },
    []
  );

  const handleDelete = useCallback(
    async version => {
      if (!window.confirm('Delete this form schema version?')) {
        return;
      }
      // execute
      setLoading(true);      
      const deleteData = await client.mutate({ 
        mutation: DELETE_VERSION,
        variables: { versionId: version.id }
      });

      // check error
      const deletedVersion = deleteData?.data?.version;
      if (!deletedVersion) {
        notification.error(
          'Form Schema version',
          <div><b>Something went wrong</b> trying to delete the form schema version.</div>
        );       
      } else {
        notification.success(
          'Form Schema version',
          <div>Form schema version delete successfully!</div>
        );
        await refetch(); 
      }
      setLoading(false);
    },
    [client, notification, refetch]
  );

  const handleRestore = useCallback(
    async version => {
      const run = async () => {
        // confirm
        if (!window.confirm('Restore this form schema version?')) {
          return;
        }
        setLoading(true);
        // fetch version
        const previousVersionData = await client.query({ 
          query: GET_VERSION,
          variables: { versionId: version.id }
        });
        const previousVersion = previousVersionData?.data?.version;
        // check
        if (!previousVersion) {
          notification.error(
            'Form Schema version',
            <div><b>Something went wrong</b> trying to restore the form schema version.</div>
          );
          return;  
        }
        // save new version
        const newVersionData = await client.mutate({
          mutation: SAVE_FORM_VERSION,
          variables: {
            version: {
              json: previousVersion.json,
              comment: previousVersion.comment ? 
                `Restored: ${previousVersion.comment}` : 
                `Restored from ${new Date(previousVersion.date_created).toLocaleDateString()}`,
              formId: {
                id: value.formId
              }
            }
          }
        });
        const newVersion = newVersionData?.data?.version;
        // check save is ok
        if (newVersion) {
          await refetch();
          notification.success(
            'Form Schema version',
            <div>Form schema version restored successfully!</div>
          );
        } else {
          notification.error(
            'Form Schema version',
            <div><b>Something went wrong</b> trying to restore the form schema version.</div>
          );
        }
        setLoading(false);
      }
      run();
    },
    [client, notification, refetch, value]
  );

  const loading = graphqlLoading || manualLoading;
  const error = graphqlError || manualError; 

  // Warning: don't try to move Timeline.Item in a sub-component, the active item
  // option stops working
  return (
    <div className="lf-form-versions-modal">
      {graphqlLoading && (
        <div className="loader">
          <Loader size="md" />
        </div>
      )}
      {error && (
        <ErrorPlaceholder />
      )}
      {!error && !graphqlLoading && data?.form?.versions.length > 1 && (
        <div className="layout">
          <div className="left">
            {currentForm && (
              <EnsureFrameworkProvider framework={value.framework}>        
                <FormGenerator
                  form={currentForm}
                  framework={value.framework || 'react-rsuite5'}
                  demo={true}
                  disabled={loading}
                />
              </EnsureFrameworkProvider>
            )}
          </div>
          <div className="right">
            {!graphqlLoading && (
              <Timeline 
                align="left"
                isItemActive={isItemActive}
              >
                {(data?.form?.versions || []).map((version, idx) => (
                  <Timeline.Item key={version.id}>
                    <a 
                      href="#"
                      onClick={handleClick}
                      data-version={version.id}
                      className="timeline-item"
                    >
                      <div>
                        <span className="day">{format(new Date(version.date_created), 'dd/MM/yyyy')}</span>
                        {' '}
                        <span className="time">{format(new Date(version.date_created), 'HH:mm')}</span>                      
                        <FormVersionButtons
                          version={version}
                          onDelete={handleDelete}
                          onRestore={handleRestore}
                          hideRestore={idx === 0}
                        />
                                                      
                      </div>
                      {version.comment && <span>{version.comment}</span>}
                    </a>
                  </Timeline.Item>            
                ))}
              </Timeline>
            )}    
          </div>
        </div>
      )}
      {!error && !graphqlLoading && data?.form?.versions.length <= 1 && (
        <div className="empty">
          <EmptyPlaceholder center>
            The are not previous versions for this form.<br/>
            Versions are created automatically, to be sure to save with a new version, in the form page
            click on "Save" - "Save with comment..." 
          </EmptyPlaceholder>
        </div>  
      )}
    </div>
  );
};

export { FormVersionsModal };
