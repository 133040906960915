const manifest = {
  label: 'Form React',
  fields: [

  ],
  dependencies: {
    packages: {
      'react': '18.2.0',
      'react-dom': '18.2.0'
    }
  }
};

export { manifest };
