import _ from 'lodash';

const defaultForm = form => {
  if (_.isEmpty(form)) {
    return {
      version: 2,
      layout: 'vertical',
      validationMode: 'onSubmit',
      labelSubmit: {
        "en-US": "Submit",
        "af-ZA": "Indien",
        "ar": "يُقدِّم",
        "bg-BG": "Изпращане",
        "ca-AD": "Presentar",
        "cs-CZ": "Předložit",
        "cy-GB": "Chyflwynem",
        "da-DK": "Indsend",
        "de-AT": "Einreichen",
        "de-CH": "Einreichen",
        "de-DE": "Einreichen",
        "el-GR": "υποβάλλουν",
        "en-GB": "Submit",
        "es-CL": "Entregar",
        "es-ES": "Entregar",
        "es-MX": "Entregar",
        "et-EE": "Esita",
        "eu": "-En azpian jarri",
        "fa-IR": "ارسال",
        "fi-FI": "Lähetä",
        "fr-CA": "Soumettre",
        "fr-FR": "Soumettre",
        "he-IL": "שלח",
        "hi-IN": "जमा करना",
        "hr-HR": "podnijeti",
        "hu-HU": "Beküldés",
        "id-ID": "Kirim",
        "is-IS": "Sendu",
        "it-IT": "Invia",
        "ja-JP": "送信",
        "km-KH": "ដាក់រេកាមបង្គាប់",
        "ko-KR": "제출하다",
        "la": "Submitto",
        "lt-LT": "Pateikti",
        "lv-LV": "Iesniegt",
        "mn-MN": "Бууж өгөх",
        "nb-NO": "Sende inn",
        "nl-NL": "Indienen",
        "pl-PL": "Składać",
        "pt-BR": "Enviar",
        "pt-PT": "Enviar",
        "ro-RO": "Trimite",
        "ru-RU": "Представлять на рассмотрение",
        "sk-SK": "Predložiť",
        "sl-SI": "Oddaj",
        "sr-RS": "прихвати",
        "sv-SE": "Skicka in",
        "th-TH": "ส่ง",
        "tr-TR": "Göndermek",
        "uk-UA": "Подавати",
        "vi-VN": "Nộp",
        "zh-CN": "提交",
        "zh-TW": "提交",
        "nn-NO": "Submit"
      },
      labelCancel: {
        "en-US": "Cancel",
        "af-ZA": "Kanselleer",
        "ar": "يلغي",
        "bg-BG": "Отказ",
        "ca-AD": "Cancel · lar",
        "cs-CZ": "zrušení",
        "cy-GB": "Chansliff",
        "da-DK": "Afbestille",
        "de-AT": "Stornieren",
        "de-CH": "Stornieren",
        "de-DE": "Stornieren",
        "el-GR": "Ματαίωση",
        "en-GB": "Cancel",
        "es-CL": "Cancelar",
        "es-ES": "Cancelar",
        "es-MX": "Cancelar",
        "et-EE": "Tühistama",
        "eu": "Indargabetu",
        "fa-IR": "لغو کردن",
        "fi-FI": "Peruuttaa",
        "fr-CA": "Annuler",
        "fr-FR": "Annuler",
        "he-IL": "לְבַטֵל",
        "hi-IN": "रद्द करना",
        "hr-HR": "Otkazati",
        "hu-HU": "Megszünteti",
        "id-ID": "Membatalkan",
        "is-IS": "Hætta við",
        "it-IT": "Annulla",
        "ja-JP": "キャンセル",
        "km-KH": "លប់ចោល",
        "ko-KR": "취소",
        "la": "Inrito",
        "lt-LT": "Atšaukti",
        "lv-LV": "Atcelt",
        "mn-MN": "Цуаах",
        "nb-NO": "Avbryt",
        "nl-NL": "Annuleren",
        "pl-PL": "Anulować",
        "pt-BR": "Cancelar",
        "pt-PT": "Cancelar",
        "ro-RO": "Anulare",
        "ru-RU": "Отмена",
        "sk-SK": "Zrušiť",
        "sl-SI": "Odpovedati",
        "sr-RS": "Поништити, отказати",
        "sv-SE": "Annullera",
        "th-TH": "ยกเลิก",
        "tr-TR": "İptal etmek",
        "uk-UA": "Скасувати",
        "vi-VN": "Hủy bỏ",
        "zh-CN": "取消",
        "zh-TW": "取消",
        "nn-NO": "Cancel"
      },
      fields: []
    };
  }
  return form;
};

export { defaultForm };
