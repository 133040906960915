/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import _ from 'lodash';

import { useFormBuilderStore } from '../../../pages/builder/state';

import { ArrowDash } from '../../../assets/icons';

import { useEvent } from '../../../code-plug';

const StartEmptyForm = () => {
  const form = useFormBuilderStore(state => state.form);
  const emitOpenTemplate = useEvent('selectTemplate');

  return (
    <>
      {_.isEmpty(form.fields) && (
        <div className="start-here">
          <b>Start here!</b><br/>
          Click on the button below to add a field to your empty form or select one from the
          {' '}
          <a href="#" onClick={emitOpenTemplate}>forms template gallery</a>
          <ArrowDash />
        </div>
      )}
    </>
  );
};

export { StartEmptyForm };