import React, { useCallback } from 'react';
import { Dropdown } from 'rsuite';

import { FRAMEWORKS, FRAMEWORKS_LABELS } from '../../../components/lets-form';
import { FrameworkIcons as FrameworkIconsCopy } from '../../../components/framework-icons'
import { useFormBuilderStore } from '../../../pages/builder/state';

import { IconRSuite } from './icon-rsuite';
import { IconAntD } from './icon-antd';

const FrameworkIcons = {
  ...FrameworkIconsCopy,
  'react-rsuite5': IconRSuite,
  'react-antd': IconAntD
};

const labelsFramework = FRAMEWORKS
  .reduce((acc, framework, idx) => ({ ...acc, [framework]: FRAMEWORKS_LABELS[idx] }), {});

const FRAMEWORK_PICKER_OPTIONS =
  Object.keys(labelsFramework)
  .map(framework => ({
    label: labelsFramework[framework],
    value: framework,
    Icon: FrameworkIcons[framework]
  }))
  .sort(({ label }) => label)

const FrameworksMenuItem = () => {
  const framework = useFormBuilderStore(state => state.framework);
  const setFramework = useFormBuilderStore(state => state.setFramework);

  const handleSelectFramework = useCallback(
    framework => setFramework(framework),
    [setFramework]
  );

  return (
    <Dropdown.Menu title="Framework">
      {FRAMEWORK_PICKER_OPTIONS.map(item => {
        let Icon = FrameworkIcons[item.value];
        return (
          <Dropdown.Item
            key={item.value}
            eventKey={item.value}
            onSelect={handleSelectFramework}
            active={framework === item.value}
            icon={Icon ? <Icon /> : <span/>}
          >
            {item.label}
          </Dropdown.Item>
        );
    })}
    </Dropdown.Menu>
  );
};

export { FrameworksMenuItem };
