import { COMMON_HIDDEN, COMMON_LABEL, COMMON_NAME, makeColums } from '../costants/index.js';

const manifest = {
  label: 'Group',
  category: 'layout',
  name: 'group',
  description: 'Visually Group a set of fields, can be collapsed',
  frameworks: [
    'react-antd',
    'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    { ...COMMON_NAME, hint: undefined },
    COMMON_LABEL,
    COMMON_HIDDEN,
    makeColums(
      {
        name: 'align',
        component: 'select',
        label: 'Header alignment',
        block: true,
        placeholder: 'Select align',
        options: [
          { value: 'left', label: 'Left' },
          { value: 'center', label: 'Center' },
          { value: 'right', label: 'Right' }
        ]
      },
      {
        component: 'toggle',
        name: 'collapsible',
        label: 'Collapsible',
        script: `if (collapsible) {
          enable('open');
        } else {
          disable('open');
        }`
      },
      {
        component: 'toggle',
        name: 'open',
        label: 'Initially open',
      }
    ),
    {
      name: 'border',
      component: 'select',
      label: 'Borders',
      options: [
        { value: 'top', label: 'Top' },
        { value: 'bottom', label: 'Bottom' },
        { value: 'topBottom', label: 'Top & Bottom' },
        { value: 'boxed', label: 'Box' }
      ]
    }
  ],
  'react-rsuite5': [

  ],
  'form-generator': {
    hideValidation: true,
    addField: true
  },
  defaultValues: {
    'react-rsuite5': {
      border: 'top',
      collapsible: true,
      open: true,
      align: 'center'
    },
    'react': {
      border: 'top',
      collapsible: true,
      open: true,
      align: 'center'
    },
    'react-mui': {
      border: 'top',
      collapsible: true,
      open: true,
      align: 'center'
    },
    'react-antd': {
      border: 'top',
      collapsible: true,
      open: true,
      align: 'center'
    },
    'react-bootstrap': {
      border: 'top',
      collapsible: true,
      open: true,
      align: 'center'
    }
  }
};

export { manifest };
