import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const WarningBox = ({
  title,
  icon = 'exclamation-circle',
  children,
  hover,
  className
}) => {

  // ban, circle, check, close, question2, refresh2, circle-o-notch, exclamation, square-o, sun-o, times-circle-o
  return (
    <div className={classNames('ui-warning-box', className)}>
      <div className="icon">

      </div>
      <div className="text">
        <b>{title}</b>
        <div className="description">
          {children}
        </div>
      </div>
    </div>
  );
};
WarningBox.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  hover: PropTypes.oneOf(['ban', 'circle', 'check', 'close', 'question', 'refresh', 'notch', 'exclamation', 'square', 'sun', 'times'])
};

export default WarningBox;