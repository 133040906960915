import _ from 'lodash';

import { LETS_FORM_VERSION } from '../../frameworks';
import Manifests from '../../manifest';

import { reduceFields } from '../lets-form';

const getPackageJsonDependencies = ({ form, locale, framework }) => {

  let packages = {
    'lets-form': LETS_FORM_VERSION,
    'react-hook-form': '7.51.1'
  };

  if (Manifests.forms &&
    Manifests.forms[framework] &&
    Manifests.forms[framework].dependencies &&
    Manifests.forms[framework].dependencies.packages
    ) {
      packages = { ...packages, ...Manifests.forms[framework].dependencies.packages };
    }

  // collect all used components
  const components = _.uniq(reduceFields(
    form.fields,
    (field, acc) => [...acc, field.component],
    []
  ));

  // cycle all components and add packages
  components.forEach(component => {

    if (Manifests[component] &&
      Manifests[component].dependencies &&
      Manifests[component].dependencies[framework] &&
      Manifests[component].dependencies[framework].packages
    ) {
      // copy over all dependencies if not exist
      Object.keys(Manifests[component].dependencies[framework].packages)
        .forEach(pack => {
          if (!packages[pack]) {
            packages[pack] = Manifests[component].dependencies[framework].packages[pack];
          }
        });
    }
  });

  return packages;
};

export { getPackageJsonDependencies };