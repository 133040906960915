import React, { useEffect, useState } from 'react';

import { MarkdownText, AlchemicLoader } from '../../../components';

import './changelog.scss';

const CHANGELOG_URL = 'https://raw.githubusercontent.com/guidone/lets-form/main/CHANGELOG-DESIGNER.md';

const Changelog = () => {
  const [changelog, setChangelog] = useState();

  useEffect(
    () => {
      const run = async () => {
        const res = await fetch(CHANGELOG_URL);
        const txt = await res.text();
        setChangelog(txt);
      };
      run();
    },
    []
  );

  return (
    <div className="lf-changelog">
      {!changelog && (
        <AlchemicLoader margin={150} />
      )}
      {changelog && (
        <MarkdownText>
          {changelog}
        </MarkdownText>
      )}
    </div>
  );
};

export { Changelog };
