import {
  ANTD_MIN_MAX,
  ANTD_PLACEMENT_EXTENDED,
  COMMON_TRITTICUS,
  COMMON_TRITTICUS_STATUS,
  makeColums,
  RSUITE5_HINT_AS_TOOLTIP,
  RSUITE5_MIN_MAX,
  MUI_COLORS,
  COMMON_FULL_WIDTH_SIZE,
  MUI_SIZE_NO_LARGE,
  COMMOM_MARKS,
  COMMON_MIN_MAX,
  MANTINE_SIZE,
  MANTINE_COLORS,
  MANTINE_RADIUS
} from '../costants/index.js';

const manifest = {
  label: 'Slider',
  category: 'general',
  name: 'slider',
  frameworks: [
    'react-antd',
    //'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    ...COMMON_TRITTICUS,
    COMMON_TRITTICUS_STATUS
  ],
  'react-rsuite5': [
    RSUITE5_HINT_AS_TOOLTIP,
    RSUITE5_MIN_MAX,
    makeColums(
      {
        name: 'vertical',
        label: 'Vertical',
        component: 'toggle'
      },
      {
        name: 'progress',
        label: 'Progress',
        component: 'toggle',
        hint: 'Show sliding progress bar',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'showTooltip',
        label: 'Tooltip',
        component: 'toggle',
        hint: 'Whether to show Tooltip when sliding',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    {
      name: 'graduated',
      label: 'Graduated',
      component: 'toggle',
      script: `
      if (values.graduated) {
        show('marks');
      } else {
        hide('marks');
      }
      `
    },
    {
      ...COMMOM_MARKS,
      name: 'marks'
    }
  ],
  'react-material-ui': [
    makeColums(
      MUI_SIZE_NO_LARGE,
      MUI_COLORS
    ),
    {
      name: 'limits',
      component: 'columns',
      columns: [
        { name: 'one', size: 1 },
        { name: 'two', size: 1 },
        { name: 'three', size: 1 }
      ],
      fields: {
        one: [
          {
            label: 'Min',
            name: 'min',
            component: 'input-number',
          }
        ],
        two: [
          {
            label: 'Step',
            name: 'step',
            component: 'input-number',
          }
        ],
        three: [
          {
            label: 'Max',
            name: 'max',
            component: 'input-number',
          }
        ]
      }
    },
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      {
        component: 'select',
        name: 'valueLabelDisplay',
        label: 'Label display',
        placeholder: 'Select value display',
        hint: 'How to show the value of the label',
        options: [
          { value: 'auto', label: 'Auto' },
          { value: 'on', label: 'On' },
          { value: 'off', label: 'Off' }
        ],
        'react-rsuite5': {
          tooltip: true,
          cleanable: true
        }
      },
      {
        component: 'toggle',
        name: 'showMarks',
        label: 'Show marks'
      }
    ),
    {
      ...COMMOM_MARKS,
      label: 'Custom marks',
      name: 'customMarks'
    }
  ],
  'react-bootstrap': [
    {
      name: 'limits',
      component: 'columns',
      columns: [
        { name: 'one', size: 1 },
        { name: 'two', size: 1 },
        { name: 'three', size: 1 }
      ],
      fields: {
        one: [
          {
            label: 'Min',
            name: 'min',
            component: 'input-number',
          }
        ],
        two: [
          {
            label: 'Step',
            name: 'step',
            component: 'input-number',
          }
        ],
        three: [
          {
            label: 'Max',
            name: 'max',
            component: 'input-number',
          }
        ]
      }
    }
  ],
  'react-antd': [
    makeColums(
      {
        name: 'keyboard',
        label: 'Keyboard',
        component: 'toggle',
        hint: 'Support using keyboard to move handlers',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'dots',
        label: 'Dots',
        component: 'toggle',
        hint: 'Whether the thumb can drag over tick only',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'reverse',
        label: 'Reverse',
        component: 'toggle',
        hint: 'Reverse the component',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    ANTD_MIN_MAX,
    makeColums(
      {
        name: 'tooltipOpen',
        component: 'checkbox',
        label: 'Tooltip',
        indeterminate: true,
        hint: 'Tooltip behaviour, checked always open, unchecked always close',
        'react-rsuite5': {
          tooltip: true
        }
      },
      { ...ANTD_PLACEMENT_EXTENDED, name: 'tooltipPlacement' }
    ),
    {
      ...COMMOM_MARKS,
      name: 'marks'
    },
    {
      name: 'included',
      label: 'Included',
      component: 'toggle',
      hint: 'Make effect when marks not null, true means containment and false means coordinative',
      'react-rsuite5': {
        tooltip: true
      }
    },
  ],
  'react-mantine': [
    makeColums(
      MANTINE_SIZE,
      MANTINE_COLORS,
      MANTINE_RADIUS
    ),
    COMMON_MIN_MAX,
    {
      ...COMMOM_MARKS,
      name: 'marks'
    },
    makeColums(
      {
        name: 'precision',
        component: 'input-number',
        label: 'Precision',
        hint: 'Number of significant digits after the decimal point',
        min: 1,
        step: 1,
        'react-rsuite5': {
          tooltip: true
        }
      },
      [],
      []
    ),
    makeColums(
      {
        name: 'inverted',
        component: 'toggle',
        label: 'Inverted',
        hint: 'Determines whether track value representation should be inverted, false by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'labelAlwaysOn',
        component: 'toggle',
        label: 'Always on',
        hint: 'Determines whether the label should be visible when the slider is not being dragged or hovered, false by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'showLabelOnHover',
        component: 'toggle',
        label: 'Show label',
        hint: 'Determines whether the label should be displayed when the slider is hovered, true by default',
        'react-rsuite5': {
          tooltip: true
        }
      }
    )
  ],
  defaultValues: {
    'react-mantine': {
      min: 0,
      max: 100,
      step: 1
    },
    'react-material-ui': {
      min: 0,
      max: 100,
      step: 10,
      fullWidth: true
    },
    'react-rsuite5': {
      'react-rsuite5': {
        progress: true,
        graduated: false
      },
      min: 0,
      max: 100,
      step: 10
    },
    'react-bootstrap': {
      min: 0,
      max: 100,
      step: 10
    },
    'react-antd': {
      min: 0,
      max: 100,
      step: 10,
      'react-antd': {
        tooltipOpen: null
      }
    }
  }
};

export { manifest };