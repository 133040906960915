const manifest = {
  label: 'Form Bootstrap',
  fields: [
  ],
  dependencies: {
    packages: {
      'react': '18.2.0',
      'react-dom': '18.2.0',
      'bootstrap': '5.2.3',
      'react-bootstrap': '2.7.3'
    }
  }
};

export { manifest };
