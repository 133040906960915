import React, { useCallback, useState } from 'react';
import { Button, ButtonToolbar, Steps, Loader, CheckPicker, Message } from 'rsuite';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import WarningRoundIcon from '@rsuite/icons/WarningRound';
import _ from 'lodash';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { ErrorPlaceholder, SuccessPlaceholder, FeatureButton } from '../../../../components';
import { ExportToGithub } from '../../../../components/lets-form-forms';
import { useCurrentUser, useNotification } from '../../../../hooks';
import { getMainSite } from '../../../../helpers';

import './export-package.scss';
import { generateImportCode, formatBytes, nextLabel, isValidGitHubProject } from './helpers';

const ExportPackage = ({
  onCancel: onClose,
  project,
  forms,
  exportType: type = 'download' // download, github
}) => {
  const { user } = useCurrentUser();
  const notification = useNotification();
  const [step, setStep] = useState(1);
  const [selectedForms, setSelectedForms] = useState(forms.map(option => option.value));
  
  const [params, setParams] = useState({
    selectedForms: forms.map(option => option.value),
    branch: 'main'
  })
  
  const [packageFile, setPackageFile] = useState();
  const [loading, setLoading] = useState(null);
  const [result, setResult] = useState(undefined);
  const [error, setError] = useState();

  let maxSteps;
  if (type === 'download') {
    maxSteps = 3;
  } else if (type === 'github' || type === 'package') {
    maxSteps = 4;
  }

  let link;
  if (type === 'download') {
    link = getMainSite() + '/lets-form-designer/export-to-file';
  } else if (type === 'github') {
    link = getMainSite() + '/lets-form-designer/export-to-github';
  }
  
  const previewCode = generateImportCode(
    forms
      .filter(option => selectedForms.includes(option.value))
      .map(option => option.label),
    project,
    type
  );

  const handleDownload = useCallback(
    async () => {
      const opts = {
        suggestedName: `${project.name}.zip`,
        types: [{
          description: 'Zipped React Package',
          accept: {'application/zip': ['.zip']},
        }],
      };
      const handle = await window.showSaveFilePicker(opts);
      const writable = await handle.createWritable();
      await writable.write(packageFile);
      await writable.close();

      onClose();
    },
    [onClose, packageFile, project]
  );

  const mergeParams = useCallback(
    values => setParams(params => ({ ...params, ...values })),
    []
  );

  const isNextDisabled = useCallback(
    (project, step, type) => {
      if (step === 1 && type === 'github' && !isValidGitHubProject(project)) {
        return true;
      }
      return false;
    },
    []
  );

  const handleNext = useCallback(
    async () => {
      if (step === 2 && type === 'download') {
        // generate zip file
        setLoading('Generating package');
        try {
          const response = await fetch('/api/package/download', {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            method: 'POST',
            body: JSON.stringify({
              projectId: project.id,
              packageName: project.packageName,
              forms: selectedForms
            })
          });

          if (response.status === 200) {
            const content = await response.blob();
            setPackageFile(content);
          } else {
            notification.error('Download package', await response.json());
          }
        } catch(e) {
          // do nothing
          notification.somethingWentWrong(e);
        }
        setLoading(null);
        setStep(3);
      } else if (step === 3 && type === 'github') {
        setLoading('Pushing to GitHub');
        try {
          const { branch, comment } = params;        
          const response = await fetch('/api/package/github', {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            },
            method: 'POST',
            body: JSON.stringify({
              projectId: project.id,
              forms: selectedForms,
              branch, 
              comment
            })
          });
          if (response.status === 200) {
            setResult(await response.json());
          } else {
            const e = await response.json();
            notification.error('Download package', e);
            setError(e);
          }          
        } catch(e) {
          notification.somethingWentWrong();
          setError(e);
        }
        setLoading(null);        
        setStep(4);
      } else {
        setStep(step => step + 1);
      }
    },
    [params, project, selectedForms, step, type, notification]
  );

  return (
    <div className="lf-export-package">
      {type === 'download' && (
        <Steps current={step-1}>
          <Steps.Item title="Select forms" />
          <Steps.Item title="Preview" />
          <Steps.Item title="Download package" />
        </Steps>
      )}
      {type === 'github' && (
        <Steps current={step-1}>
          <Steps.Item title="Select forms" />
          <Steps.Item title="Preview" />
          <Steps.Item title="GitHub branch" />
          <Steps.Item title="Done" />
        </Steps>
      )}
      {type === 'package' && (
        <Steps current={step-1}>
          <Steps.Item title="Select forms" />
          <Steps.Item title="Version" />
          <Steps.Item title="Preview" />
          <Steps.Item title="Release" />
        </Steps>
      )}

      {step === 1 && type === 'download' && (
        <div className="step step-1">          
          <div>
            Generate <em>.zip</em> containing the selected forms, the files can be dropped in your project
            and imported directly in your React code, see example in step 2.<br/>
            <a target="_blank" href="/lets-form-designer/export-to-file">Learn more here</a>.
          </div>        
          <div className="select-projects">
            <b>Select form to export</b>
            <CheckPicker
              block
              data={forms}
              value={selectedForms}
              onChange={forms => {
                setSelectedForms(forms);
                setPackageFile(null);
              }}
              searchable={false}
            />
          </div>
        </div>
      )}

      {step === 1 && type === 'github' && (
        <div className="step step-1">
          {!isValidGitHubProject(project) && (
            <Message type="warning" style={{ marginBottom: '15px' }}>
              <b>Invalid project parameters</b>, click on "edit" button and provide the valid values form:{' '}
              <em>githubRepository</em>, <em>githubToken</em>, <em>targetPath</em> and <em>branch</em>.
            </Message>  
          )}
          <div>
            Push the selected forms of the project directly to your GitHub repository: setup the repository URL, token and target path
            in the project settings.<br/> 
            After pulling changes from remote repository, the forms can be imported directly in your React code, see example in step 2.<br/>
            <a target="_blank" href="/lets-form-designer/export-to-github">Learn more here</a>.
          </div>
          <div className="select-projects">
            <b>Select form to export</b>
            <CheckPicker
              block
              data={forms}
              value={selectedForms}
              onChange={forms => {
                setSelectedForms(forms);
                setPackageFile(null);
              }}
              searchable={false}
            />
          </div>
        </div>
      )}

      {step === 2 && (type === 'download' || type === 'github') && (
        <div className="step step-2">
          <SyntaxHighlighter
            className="syntax"
            showLineNumbers={true}
            language="javascript"
            style={nightOwl}
            customStyle = {{
              marginTop: '0px',
              marginBottom: '0px'
            }}
          >
            {previewCode}
          </SyntaxHighlighter>
        </div>
      )}

      {step === 3 && type === 'github' && (
        <div className="step step-3 step-3-github">
          <div style={{ marginBottom: '15px' }}>
            The selected forms will be commited and pushed to the folder <code>{project.targetPath}</code>
          </div>
          <ExportToGithub 
            defaultValues={_.pick(params, 'branch', 'comment')}
            disabled={loading}
            onChange={mergeParams}
            hideToolbar={true}
          />
        </div>
      )}

      {step === 3 && type === 'download' && (
        <div className="step step-3">
          {packageFile && (
            <>
              <CheckRoundIcon
                size="5em"
                color="green"
              />

              <div className="message">
                Package created succesfully
              </div>

              <Button appearance="primary"
                onClick={handleDownload}
              >
                Download "{project.name}.zip"
              </Button>
              <div className="size">File size: <b>{formatBytes(packageFile.size)}</b></div>
            </>
          )}
          {!packageFile && (
            <>
              <WarningRoundIcon
                size="5em"
                color="red"
              />

              <div className="message">
                Something went wrong with the package creation
              </div>
              <span>
                Please try again later.
              </span>
            </>
          )}
        </div>
      )}

      {step === 4 && type === 'github' && (
        <div className="step step-4">
          {result != null && (
            <SuccessPlaceholder
              title="Forms pushed succesfully"
              maxWidth={600}
            >
              Forms pushed succesfully <a href={result.object.url} target="_blank" rel="noreferrer">to GitHub</a><br/>
              SHA: <code>{result.object.sha}</code>
            </SuccessPlaceholder>
          )}
          {error != null && (
            <ErrorPlaceholder
              title="Something went wrong"
            >
              Something went wrong pushing forms to GitHub: {error.error}
            </ErrorPlaceholder>
          )}
        </div>
      )}

      <div className="buttons">
        <div className="left">
          {loading && (
            <div className="loader">
              <Loader speed="normal" size="sm" content={loading || 'Creating package'} />
            </div>
          )}
          {!loading && step !== maxSteps && (
            <div className="closer">
              <ButtonToolbar>
                <Button onClick={onClose}>Close</Button>
                {link && (
                  <Button
                    appearance="default"
                    startIcon={<HelpOutlineIcon />}
                    style={{ float: 'left' }}
                    href={link} 
                    target="_blank" 
                  >Help</Button>
                )}
            </ButtonToolbar>
            </div>
          )}
        </div>
        <div className="right">
          <ButtonToolbar style={{ justifyContent: 'right' }}>
            {step > 1 && step < maxSteps && (
              <Button
                disabled={loading != null}
                onClick={() => {
                  setStep(step => step - 1);
                }}
              >
                Previous
              </Button>
            )}
            {step < maxSteps && (
              <FeatureButton
                appearance="primary"
                disabled={loading != null || isNextDisabled(project, step, type)}
                onClick={handleNext}
                badge="Pro"
                opacity={false}
                canUse={user.plan.featPackage}
              >{nextLabel(step, type)}</FeatureButton>
            )}
            {step === maxSteps && (
              <Button
                appearance="primary"                
                onClick={() => {
                  onClose();
                }}
              >Close</Button>
            )}

          </ButtonToolbar>
        </div>
      </div>
    </div>
  );
};

export { ExportPackage };
