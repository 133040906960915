import React from 'react';


import { GenericSelectTemplate } from './generic-select-template';

const SelectTemplateMenuItem = ({ disabled }) => {
  return (
    <GenericSelectTemplate disabled={disabled} />
  );
};

export { SelectTemplateMenuItem };
