import {
  COMMON_HIDDEN,
  COMMON_NAME,
  COMMON_MARGIN_MIN_MAX,
  makeColums
} from '../costants/index.js';

const manifest = {
  label: 'Placeholder Image',
  category: 'layout',
  name: 'placeholder-image',
  description: 'Image placeholder',
  frameworks: [
    'react-antd',
    'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    { ...COMMON_NAME, hint: null },
    {
      name: 'url',
      component: 'input-text',
      textarea: true,
      label: 'Image URL'
    },
    makeColums(
      {
        name: 'align',
        label: 'Alignment',
        component: 'select',
        placeholder: 'Select alignment',
        cleanable: true,
        block: true,
        options: [
          { value: 'left', label: 'Left' },
          { value: 'center', label: 'Center' },
          { value: 'right', label: 'Right' }
        ]
      },
      COMMON_HIDDEN
    )
  ],
  'react-rsuite5': [
    ...COMMON_MARGIN_MIN_MAX
  ],
  'react-antd': [
    ...COMMON_MARGIN_MIN_MAX
  ],
  'react': [
    ...COMMON_MARGIN_MIN_MAX
  ],
  'react-material-ui': [
    ...COMMON_MARGIN_MIN_MAX
  ],
  'react-bootstrap': [
    ...COMMON_MARGIN_MIN_MAX
  ],
  'react-mantine': [
    ...COMMON_MARGIN_MIN_MAX
  ],
  'form-generator': {
    hideValidation: true
  },
  defaultValues: {
    'react-rsuite5': {
      url: 'https://unpkg.com/lets-form-icons@1.0.1/generic.svg',
      'react-rsuite5': {
        maxWidth: 40,
        maxHeight: 40
      }
    },
    'react': {
      url: 'https://unpkg.com/lets-form-icons@1.0.1/generic.svg',
      'react': {
        maxWidth: 40,
        maxHeight: 40
      }
    },
    'react-antd': {
      url: 'https://unpkg.com/lets-form-icons@1.0.1/generic.svg',
      'react-antd': {
        maxWidth: 40,
        maxHeight: 40
      }
    },
    'react-material-ui': {
      url: 'https://unpkg.com/lets-form-icons@1.0.1/generic.svg',
      'react-material-ui': {
        maxWidth: 40,
        maxHeight: 40
      }
    },
    'react-bootstrap': {
      url: 'https://unpkg.com/lets-form-icons@1.0.1/generic.svg',
      'react-bootstrap': {
        maxWidth: 40,
        maxHeight: 40
      }
    }
  }
};

export { manifest };
