import React, { useCallback, useState } from 'react';
import { Button, Tag } from 'rsuite';
import { gql, useApolloClient } from '@apollo/client';

import { useNotification } from '../../../../hooks';
import { CopyAndPasteButton } from '../../../../components';

import './shared-form-card.scss';

const DELETE_SHARED_FORM = gql`
mutation($id: ID!) {
  delete_shared_forms_item(id: $id) {
    id
  }
}`;

const LETS_FORM_DESIGNER_URL = process.env.NODE_ENV === 'development' ? 
  'http://localhost:3000' : 'https://designer.letsform.dev';

const SharedFormCard = ({ sharedForm, onRemoved = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const notification = useNotification();

  const handleDeleteLink = useCallback(
    async () => {
      if (!window.confirm(`Delete shared form "${sharedForm.name}"`)) {
        return;
      }
      try {
        setLoading(true);
        await client.mutate({
          mutation: DELETE_SHARED_FORM,
          variables: {
            id: sharedForm.id
          }
        });
        setLoading(false);
        notification.success('Shared form', 'The shared form was deleted successfully!');
        onRemoved();
      } catch(e) {
        notification.error('Shared form', e);
        setLoading(false);
      }
    },
    [client, notification, onRemoved, sharedForm]
  );

  const link = `${LETS_FORM_DESIGNER_URL}/f/${sharedForm.shareCode}`;
  const d = new Date(sharedForm.date_created);

  return (
    <div className="lf-shared-form">
      <Tag>{d.toLocaleDateString()}</Tag>
      {' '}
      <b>{sharedForm.name}</b> - {link} 
      <div className="buttons">
        <Button 
          onClick={handleDeleteLink}
          size="xs"
          appearance="ghost"
          className="btn-remove"
          disabled={loading}
        >Delete</Button>
        <CopyAndPasteButton 
          text={link}
          size="xs"
          float={null}
          label="Copy link"
          disabled={loading}
          notification="Shared form link succesfully copied to clipboard"
        />
      </div>
    </div>
  );
};

export { SharedFormCard };
