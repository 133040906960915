/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import TrashIcon from '@rsuite/icons/Trash';
import ReloadIcon from '@rsuite/icons/Reload';
import { ButtonGroup } from 'rsuite';

import { IconButtonHint } from '../../button-hint';

const FormVersionButtons = ({ 
  version, 
  onDelete, 
  onRestore,
  hideRestore = false 
}) => {
  const handleDelete = useCallback(
    () => onDelete(version),
    [onDelete, version]
  );
  const handleRestore = useCallback(
    () => onRestore(version),
    [onRestore, version]
  );

  return (
    <ButtonGroup 
      appearance="ghost"
      size="xs" 
      style={{ marginLeft: '10px', marginTop: '-2px' }}
    >
      {!hideRestore && (
        <IconButtonHint 
          icon={<ReloadIcon/>}
          hint="Restore form to this version"
          data-version={version.id} 
          onClick={handleRestore}
        />
      )}
      <IconButtonHint 
        icon={<TrashIcon/>}
        hint="Delete this version"
        data-version={version.id} 
        onClick={handleDelete}
      />
    </ButtonGroup>
  );
};

export { FormVersionButtons };
