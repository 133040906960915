import { gql } from '@apollo/client';

export const UPDATE_PROJECT = gql`
mutation(
  $id: ID!,
  $project: update_projects_input!) {
  update_projects_item(id: $id, data: $project) {
    id,
    name,
    framework,
    description,
    package,
    githubRepository,
    branch,
    targetPath,
    githubToken
  }
}
`;


export const GET_PROJECT = gql`
query Projects($projectId: ID! $id: GraphQLStringOrFloat) {
  project: projects_by_id(id: $projectId) {
    id,
    name,
    framework,
    description,
    package,
    githubRepository,
    branch,
    targetPath,
    githubToken
  }
  forms(filter: {
    projectId: {
      id: { _eq: $id }
    }
  }) {
    id,
    date_created,
    name,
    description,
    versions(sort: "-id", limit: 1) {
      id,
      date_created,
      date_updated
    },
    projectId {
      name,
      id,
      date_created
    }
  }
}
`;

export const DELETE_FORM = gql`
mutation($id: ID!) {
  delete_forms_item(id: $id) {
    id
  }
}
`;

export const UPDATE_FORM = gql`
mutation($id: ID!, $form: update_forms_input!) {
  update_forms_item(id: $id, data: $form) {
    id,
    name,
    description
  }
}`;
