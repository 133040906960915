import React from 'react';



import { MobileForm } from '../lets-form-forms';

import './phone-placeholder.scss';
import { LogoExtended } from '../../assets/logo-large';

const PhonePlaceholder = () => {
  //const { isMobile } = useMobileDetect();
  
return (
  <div className="lf-phone-placeholder">
    <div className="header">
      <LogoExtended width={300} height={90} style={{ marginTop: '5%', margin: '0 auto' }} />
    </div>
    
    <div className="info">
      <b>LetsForm Designer</b> is not optimized on smaller screens, leave your email to get a reminder 
      to try <b>LetsForm</b> when you're on a desktop computer
    </div>

    <MobileForm 
    />
  </div>
)
};

export { PhonePlaceholder };
