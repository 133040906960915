/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Header, Container, Content, Button } from 'rsuite';

import { NavigationBar } from '../../layout';

import LetsForm, { LfField } from '../../components/lets-form/react-rsuite5/index';

import { jsonToReact } from '../../helpers/json-to-react';


const Loader = () => <div>Loading...</div>;

const FORM = {
  "$schema": "https://unpkg.com/lets-form@0.8.3/schemas/react-rsuite5/form.json",
  "version": 1,
  "layout": "vertical",
  "fields": [
    {
      "name": "field_1",
      "label": "Field 1",
      "component": "input-text"
    },
    /*{
      "name": "imported_field",
      "label": "I am imported",
      "component": "antd-rate",
      "count": 7
    },*/
    {
      name: 'text',
      label: 'allora?',
      component: 'testcmp'
    }
  ],
  "name": "Rate2"
};



const TestPage = () => {

  const [context, setContext] = useState({
    myToken: '123456-token-42'
  });

  /*

          <LfField component="input-text" name="test1" label="Test 1" />

          <LfField component="input-text" name="test2" label="Test 22222" />
          <LfGroup name="gruppetto" label="sto cazzo" collapsible={false}>
            <LfField component="input-number" name="test-number" label="Test number" />
          </LfGroup>
          <LfColumns name="barabba_colonne">
            <LfColumn test="barabba">
              <LfField component="input-text" name="test4" label="Test first column" />
            </LfColumn>
            <LfColumn>
              <LfField component="input-text" name="test5" label="Test second column" />
            </LfColumn>
          </LfColumns>
          <LfArray name="my_array" label="elementi">
            <LfField component="input-text" name="test_array_1" label="Test array 1" />
            <LfField component="input-text" name="test_array_2" label="Test array 2" />
          </LfArray>
          <div>io non c'entro nulla, ma smettila buffone</div>

            importMap={
            {
            'antd-rate': {
              url: 'https://cdn.jsdelivr.net/npm/antd@5.19.0/dist/antd.min.js',
              defaultExport: false,
              exportedKey: 'Rate'
            },
            'testcmp': {
              url: 'https://cdn.jsdelivr.net/npm/react-simple-star-rating@5.1.7/+esm',
              type: 'esm'
              //defaultExport: false,
              //exportedKey: 'Editor'
            }

            //'antd-rate': 'https://cdn.jsdelivr.net/npm/antd@latest/es/index.js'
          }}


  */


  return (
    <>
      <Container style={{ padding: '50px 200px' }}>
        <Content className="project-detail">

        <Button
          onClick={() => {
            setContext(context => ({
              ...context,
              myToken: context.myToken + 'A'
            }))
          }}
        >Test</Button>

        <LetsForm
          layout="vertical"
          className="lf-test"
          framework="react-rsuite5"
          name="Rate2"
          //locales={["it-IT","en-GB"]}
          labelSubmit="scemo"
          labelCancel="cancellone"
          buttonsAlign="center"
          loader={Loader}
          context={context}
          onSubmit={values => {
            console.log('form values', values);
          }}
        >
          <LfField
            component="input-text"
            label={{"en-GB":"Field 1","it-IT":"Campo 1"}}
            name="field_1"
            id="field_1_65"
            hint={{"en-GB":"Some help here","it-IT":"Qualche aiuto qui"}}
            required={true}
            fullWidth={true}
            script={`
              console.log('mytoken', context('myToken'))
              toggle('field_2', 'disabled');
              `}
          />
          <LfField
            component="input-number"
            label="Field 2"
            name="field_2"
            disabled={true}
            id="field_2_177"
            required={true}
            allowClear={true}
            validation={{"minLength":10,"message":"No minimo dieci ragazzo"}}
          />
        </LetsForm>

        </Content>
      </Container>
    </>
  );
};

//url: 'https://esm.sh/antd?external=react,react-dom',

export { TestPage };
