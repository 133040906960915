import React from 'react';
import classNames from 'classnames';

import { EmptyBox } from '../../assets/icons';

import './index.scss';

const EmptyPlaceholder = ({
  children,
  title = 'There\'s nothing here!',
  center = false
}) => {

  return (
    <div className={classNames('lf-empty-placeholder', { center })}>
      <EmptyBox width={48} height={48} color="#aaaaaa" />
      <br />
      <b>{title}</b>
      {children && (
        <>
          <br />
          <div className="legend">{children}</div>
        </>
      )}
    </div>
  )
};

export { EmptyPlaceholder };
