import { definePlugin } from '../../code-plug';

import { LocalesSelector } from './views/locales-selector';

/**
 * Plugin: builder-locales-selector
 * - persist: persist local value in local storage "locale"
 */
definePlugin('builder-locales-selector', ({ registerView }, { persist = false }) => {

  registerView(
    'form-builder-toolbar',
    LocalesSelector,
    {
      key: 'form-builder-toolbar-locales-selector',
      persist,
      start: true
    }
  );
});
