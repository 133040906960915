import React from 'react';

// se non faccio cosi' mannaggia il cazzo si nasconde icona dentro il form menu
// vaffanculo a crome e l'svg, ti si ribaltassero le budella dopo che te lo buttano in culo
export const IconRSuite = ({ width = 24, height = 24 }) => (
  <svg viewBox="0 0 138 138" version="1.1" xmlns="http://www.w3.org/2000/svg" width={`${width}px`} height={`${height}px`} preserveAspectRatio="xMidYMin slice">
    <title>React Suite</title>
    <defs>
      <linearGradient x1="71.5906675%" y1="12.5658792%" x2="45.577567%" y2="114.749969%" id="linearGradient-01">
        <stop stopColor="#6594ED" offset="0%"></stop>
        <stop stopColor="#316BD9" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="67.6269531%" y1="0%" x2="50%" y2="78.0639648%" id="linearGradient-02">
        <stop stopColor="#EC5060" offset="0%"></stop>
        <stop stopColor="#EA7480" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="67.6269531%" y1="0%" x2="50%" y2="79.2449951%" id="linearGradient-03">
        <stop stopColor="#EC5060" offset="0%"></stop>
        <stop stopColor="#EA7480" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-22" transform="translate(3.000000, 6.000000)">
        <polyline className="polyline-axis" stroke="url(#linearGradient-01)" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round" points="111 31 57 0 19 22 95 104 57 126 3 95"></polyline>
        <polyline className="polyline-limb" id="Path-5-Copy-7" stroke="url(#linearGradient-02)" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round" transform="translate(22.000000, 63.000000) scale(-1, -1) translate(-22.000000, -63.000000) " points="41 31 3 54 41 95 41 52"></polyline>
        <polyline className="polyline-limb" stroke="url(#linearGradient-03)" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round" points="111 31 73 54 111 95 111 52"></polyline>
        <circle className="circle" fill="#6594ED" cx="3" cy="95" r="3"></circle>
        <circle fill="#6594ED" cx="111" cy="31" r="3"></circle>
      </g>
    </g>
  </svg>
);