const manifest = {
  label: 'Form React+Mantine',
  fields: [],
  dependencies: {
    packages: {
      'react': '18.2.0',
      'react-dom': '18.2.0',
      '@mantine/core': '^7.9.0',
      '@mantine/hooks': '^7.9.0'
    }
  }
};

export { manifest };
