import { definePlugin } from '../../code-plug';

import { PublishFormMenuItem } from './views/publish-form-button';

definePlugin('builder-publish-form', ({ registerView }, params) => {

  registerView(
    'form-builder-toolbar',
    PublishFormMenuItem,
    {
      key: 'form-builder-publish-form',
      parent: 'Form',
      ...params
    }
  );
});
