/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { PreviewJson } from '../../../components';

import './preview-response.scss';

const isError = e => e instanceof Error;

const PreviewResponse = ({ response }) => {

  const [json, setJson] = useState();

  useEffect(
    () => {
      const run = async () => {
        try {
          const json = await response.json();
          setJson(json);
        } catch(e) {
          // do nothing
        }
      };
      run();
    }
  );

  return (
    <div className="lf-preview-response">
      {response.status && (
        <div className="status">
          Status:&nbsp;
          <span
            className={classNames('code', {
              green: response.status < 400,
              red: response.status >= 400
            })}
          >{response.status}</span>
        </div>
      )}
      <div className="url">
        {response.url}
      </div>
      {json && (
        <PreviewJson json={json} />
      )}
      {isError(response) && (
        <div className="error">
          {response.message}
        </div>
      )}
    </div>
  );
};

export { PreviewResponse };
