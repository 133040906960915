import { definePlugin } from '../../code-plug';

import { AskFeedback } from '../../components/ask-feedback';

definePlugin('leave-feedback', ({ registerView }, params) => {

  registerView(
    params?.region ?? 'form-builder-canvas',
    AskFeedback
  );
});
