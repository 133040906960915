import React from 'react';
import { SelectPicker, Input, InputGroup, Grid, Row, Col } from 'rsuite';
import ClearIcon from '@rsuite/icons/legacy/CloseCircle';

import './template-search-toolbar.scss';

const TemplateSearchToolbar = ({
  filters = {},
  onChange = () => {},
  categories = []
}) => {

  return (
    <div className="lf-template-search-toolbar">
      <Grid fluid>
        <Row>
          <Col xs={6}>
            <SelectPicker
              size="sm"
              block
              placeholder="Filter category"
              value={filters?.categoryId}
              data={categories.map(o => ({ value: o.id, label: o.name }))}
              cleanable={true}
              searchable={false}
              onChange={value => onChange({ ...filters, categoryId: value })}
            />
          </Col>
          <Col xs={6}>
            <InputGroup inside size="sm">
              <Input
                size="sm"
                className="input-search"
                placeholder="Search"
                value={filters?.search}
                cleanable={true}
                onChange={value => onChange({ ...filters, search: value })}
              />
              {filters.search && (
                <InputGroup.Button size="sm" onClick={() => onChange({ ...filters, search: '' })}>
                  <ClearIcon size="sm" />
                </InputGroup.Button>
              )}
            </InputGroup>
          </Col>
        </Row>
      </Grid>




    </div>
  );
};

export { TemplateSearchToolbar };
