import React from 'react';
import { Modal, Button } from 'rsuite';

const ShowError = ({
  children,
  title,
  onClose = () => {}
}) => {

  return (
    <Modal open={true} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} appearance="primary">
          Got it, close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { ShowError };
