import { definePlugin } from '../../code-plug';

import { TestToolbarContainer } from './views/test-toolbar-container';

definePlugin('builder-test-toolbar', ({ registerView }, params) => {

  registerView(
    'form-builder-canvas',
    TestToolbarContainer
  );
});
