import { ANTD_SIZE, makeColums } from '../costants/index.js';

const manifest = {
  label: 'Form Ant Design',
  fields: [
    makeColums(
      {
        name: 'labelColSpan',
        component: 'input-number',
        label: 'Label col. span',
        allowClear: true,
        min: 0,
        max: 24,
        step: 1
      },
      {
        name: 'labelColOffset',
        component: 'input-number',
        label: 'Label col. offset',
        allowClear: true,
        min: 0,
        max: 24,
        step: 1
      }
    ),
    makeColums(
      {
        name: 'wrapperColSpan',
        component: 'input-number',
        label: 'Field col. span',
        allowClear: true,
        min: 0,
        max: 24,
        step: 1
      },
      {
        name: 'wrapperColOffset',
        component: 'input-number',
        label: 'Field col. offset',
        allowClear: true,
        min: 0,
        max: 24,
        step: 1
      }
    ),
    makeColums(
      ANTD_SIZE,
      {
        name: 'layout',
        label: 'Layout',
        component: 'select',
        block: true,
        options: [
          { value: 'inline', label: 'Inline' },
          { value: 'horizontal', label: 'Horizontal' },
          { value: 'vertical', label: 'Vertical' }
        ]
      },
      []
    ),
    makeColums(
      {
        name: 'labelAlign',
        label: 'Label align',
        component: 'select',
        placeholder: 'Select align',
        cleanable: true,
        options: [
          { value: 'left', label: 'Left' },
          { value: 'right', label: 'Right' }
        ]
      },
      {
        name: 'labelWrap',
        label: 'Label wrap',
        component: 'toggle'
      },
      {
        name: 'requiredMark',
        label: 'Show required mark',
        component: 'toggle'
      }
    )
  ],
  dependencies: {
    packages: {
      'react': '18.2.0',
      'react-dom': '18.2.0',
      'antd': 'latest'
    }
  }
};

export { manifest };
