import { COMMON_HIDDEN, COMMON_NAME, makeColums, makeOptions } from '../costants/index.js';

const manifest = {
  label: 'Divider',
  category: 'layout',
  description: 'Layout divider, customizable with size and color',
  name: 'divider',
  frameworks: [
    'react-antd',
    'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    COMMON_NAME,
    makeColums(
      {
        component: 'input-number',
        label: 'Thickness',
        name: 'size',
        hint: 'Thickness of the divider',
        'react-rsuite5': {
          min: 0,
          step: 1,
          max: 100,
          postfix: 'px',
          tooltip: true
        }
      },
      {
        component: 'input-text',
        label: 'Color',
        name: 'color',
        placeholder: 'i.e. #999999',
        inputType: 'color',
        'react-rsuite5': {}
      }
    ),
    COMMON_HIDDEN
  ],
  'react-mantine': [
    {
      name: 'label',
      component: 'input-text-i18n',
      label: 'Label'
    },
    {
      name: 'labelPosition',
      component: 'select',
      label: 'Label position',
      options: makeOptions(['left', 'center', 'right'])
    }
  ],
  'form-generator': {
    hideValidation: true
  },
  defaultValues: {
    'react-mantine': {
      'react-mantine': {
        labelPosition: 'left'
      }
    },
    'react-rsuite5': {
      color: '#999999',
      size: 1
    },
    'react': {
      color: '#999999',
      size: 1
    },
    'react-bootstrap': {
      color: '#999999',
      size: 1
    },
    'react-material-ui': {
      color: '#999999',
      size: 1
    },
    'react-antd': {
      color: '#999999',
      size: 1
    }
  }
};

export { manifest };
