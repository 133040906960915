import { definePlugin } from '../../code-plug';

import { ShareFormMenuItem } from './views/share-form-button';

definePlugin('builder-share-form', ({ registerView }) => {

  registerView(
    'form-builder-toolbar',
    ShareFormMenuItem,
    {
      parent: 'Form',
      key: 'form-builder-toolbar-share-form'
    }
  );
});
