import React from 'react';
import _ from 'lodash';
import { FlexboxGrid, Button, IconButton, ButtonToolbar, Whisper, Tooltip } from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';

import { FrameworksIcons } from '../../../pages/builder/components';
import { MarkdownText } from '../../../components';
import { LocalesList } from '../../../components';

const TemplateItem = ({
  template,
  onSelect = () => {},
  onPreview = () => {},
  disabled = false
}) => {

  return (
    <FlexboxGrid align="top" justify="space-between" className="template">
      <FlexboxGrid.Item colspan={17}>
        <div className="name">
          {template.name}
          {!_.isEmpty(template.version) && (
            <span className="version">v{template.version}</span>
          )}
        </div>
        <MarkdownText className="description">{template.description}</MarkdownText>
        <div className="meta">
          {!_.isEmpty(template.locales) && (
            <LocalesList max={3} locales={template.locales} />
          )}
        </div>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={6} className="buttons">
        <ButtonToolbar>
          <Button
            appearance="primary"
            className="btn-use-template"
            size="sm"
            disabled={disabled}
            onClick={() => onSelect(template)}
          >Use template</Button>
          <Whisper
            speaker={<Tooltip>Preview template</Tooltip>}
            trigger="hover"
            placement="top"
          >
            <IconButton
              size="sm"
              onClick={() => onPreview(template)}
              icon={<SearchIcon />}
            />
          </Whisper>
        </ButtonToolbar>
        {!_.isEmpty(template.frameworks) && <FrameworksIcons width={16} height={16} frameworks={template.frameworks}/>}
        {!_.isEmpty(template.frameworks) && template.frameworks.includes('*') && (
            <div>All frameworks</div>
          )}
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export { TemplateItem };
