import React, { useState, useCallback } from 'react';
import _ from 'lodash';
import Whisper from 'rsuite/Whisper';
import Popover from 'rsuite/Popover';


import { ModuleComponent } from '../lets-form/common/module';

import { MarkdownText } from '../markdown-text';

import { LogoExtended } from '../../assets/logo-large';


import FormGenerator from '../lets-form/index';

import './lets-form-playground.scss';


const getLetsFormDesignerUrl = () => {
  return process.env.NODE_ENV === 'development' ?
  process.env.REACT_APP_DEVELOPMENT_LETS_FORM_DESIGNER_SITE : process.env.REACT_APP_PRODUCTION_LETS_FORM_DESIGNER_SITE;
}



// should import import '@smastrom/react-rating/style.css'

const LetsFormPlayground = ({
  form,
  componentUrl,
  configPanelWidth = 350,
  defaultExport = true,
  exportedKey,
  defaultValues = {},
  name,
  description,
  zoom = 1.0
}) => {
  const [currentProps, setCurrentProps] = useState(defaultValues);
  //const [error, setError] = useState();
  const error = false;
  //const [isLoading, setIsLoading] = useState(false);
  const isLoading = false;
  //const [isValid, setIsValid] = useState(false);
  //const Component = CurrentComponent?.component;

  /*

  useEffect(
    () => {
      // skip if nothing
      if (_.isEmpty(componentUrl) || (!defaultExport && _.isEmpty(exportedKey))) {
        setIsValid(false);
        return;
      }

      const script2 = document.createElement('script');
      script2.type = 'importmap';
      script2.innerHTML = `{
  "imports": {
    "lets-form/react-rsuite5": "https://raw.esm.sh/lets-form@0.7.15/dist/react-rsuite5-esm/index.js",
    "react-hook-form": "https://esm.sh/react-hook-form@7.51.1?external=react,react-dom",
    "react": "https://esm.sh/react@18.2.0",
    "react-dom": "https://esm.sh/react-dom@18.2.0",
    "myLib": "${componentUrl}"
  }
}`;
      document.body.appendChild(script2);


      const script3 = document.createElement('script');
      script3.type = 'module';
      const installCode = `import { createElement } from 'react';
import { createRoot } from 'react-dom';
${defaultExport ? `import MyComponent from 'myLib';` : `import { ${exportedKey} as MyComponent } from 'myLib';`}

const container = document.getElementById('component-previewer');
const root = createRoot(container);

const formElement = createElement(MyComponent, {});
root.render(formElement);

document.getElementById('lf-playground')
  .addEventListener(
    'lf_unmount',
    () => root.unmount()
  )
document.getElementById('lf-playground')
  .addEventListener(
    'lf_refresh',
    e => root.render(createElement(MyComponent, e.detail))
  );
`;
      console.log('IMPORT', installCode);
      script3.innerHTML = installCode;
      document.body.appendChild(script3);

      setIsLoading(false);
      setIsValid(true);
      setError(null);

      return () => {
        document.body.removeChild(script3);
        document.body.removeChild(script2);
        if (document.getElementById('lf-playground')) {
          document.getElementById('lf-playground').dispatchEvent(new CustomEvent('lf_unmount'));
        }
      };
    },
    [componentUrl, defaultExport, exportedKey]
  );

  */

  const handleChange = useCallback(
    values => {
      // dispatch event to update react view
      //const event = new CustomEvent('lf_refresh', { detail: values });
      //document.getElementById('lf-playground').dispatchEvent(event);
      // store the current value of the component
      setCurrentProps(values);
    },
    []
  );

  return (
    <div className="lf-playground" id="lf-playground">
      <div className="lf-playground-container">
        <div className="lf-logo">
          <Whisper
            trigger="hover"
            placement="topStart"
            speaker={<Popover
              visible
              arrow
            >
              Open this in LetsForm
            </Popover>}
          >
            <div>
              <a
                target="_blank"
                href={getLetsFormDesignerUrl()/* + '/import?form=' + serializeForm(form, name)*/}
                rel="noreferrer">
                <LogoExtended width={100} />
              </a>
            </div>
          </Whisper>
        </div>

        <div className="left">
          {!_.isEmpty(name) && (
            <div className="playground-name">{name}</div>
          )}
          <div id="component-previewer" style={zoom ? { zoom } : undefined}>

            <ModuleComponent
              componentUrl={componentUrl}
              defaultExport={defaultExport}
              exportedKey={exportedKey}
              {...currentProps}
            />

          </div>

          {!isLoading && error && (
            <div>
              something wrong happened
            </div>
          )}
          {isLoading && (
            <div>loading...</div>
          )}

        </div>
        <div className="right" style={{
          flex: `0 0 ${configPanelWidth}px`
        }}>
          {!_.isEmpty(description) && (
            <MarkdownText className="playground-description">{description}</MarkdownText>
          )}
          <FormGenerator
            framework="react-rsuite5"
            form={form}
            hideToolbar={true}
            disabled={isLoading || error != null}
            defaultValues={defaultValues}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export { LetsFormPlayground };
