import {
  COMMON_PLACEHOLDER,
  COMMON_TRITTICUS_STATUS,
  RSUITE5_PLACEMENT,
  RSUITE5_SIZE,
  makeColums,
  RSUITE5_HINT_AS_TOOLTIP,
  COMMON_TRITTICUS
} from '../costants/index.js';

const manifest = {
  label: 'Locales',
  category: 'advanced',
  description: 'Select one or more locales, returns an array (i.e. it-IT, en-GB, ...)',
  name: 'multiselect-language',
  fields: [
    ...COMMON_TRITTICUS,
    COMMON_PLACEHOLDER,
    COMMON_TRITTICUS_STATUS
  ],
  'react-material-ui': [
  ],
  'react-bootstrap': [
  ],
  'react-antd': [],
  'react-rsuite5': [
    makeColums(
      RSUITE5_PLACEMENT,
      RSUITE5_SIZE
    ),
    RSUITE5_HINT_AS_TOOLTIP
  ],
  defaultValues: {
    'react-antd': {
      'react-antd': {
        bordered: true,
        listHeight: 256,
        virtual: true,
        showArrow: true
      }
    },
    'react-rsuite5': {
      'react-rsuite5': {
        block: true
      }
    }
  }
};

export { manifest };
