/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import { IconButton, ButtonGroup } from 'rsuite';
import TrashIcon from '@rsuite/icons/Trash';
import GearIcon from '@rsuite/icons/Gear';
import ShareOutlineIcon from '@rsuite/icons/ShareOutline';

import Connectors from '../../../connectors';
import { useModal } from '../../../components';
import { ConnectorConfigurationModal } from './connector-configuration-modal';
import { lfError } from '../../../components/lets-form/helpers/lf-log';

import './connector-item.scss';

const ConnectorItem = ({
  connector,
  idx,
  disabled = false,
  onChange = () => {},
  onRemove = () => {}
}) => {
  const [loading, setLoading] = useState(false);
  const { open, close } = useModal({
    view: ConnectorConfigurationModal,
    labelSubmit: null,
    labelCancel: null,
    size: 'sm'
  })

  const handleClick = useCallback(
    async () => {
      // find connector
      const connectorDefinition = Connectors.find(c => c.name === connector.name);
      if (!connectorDefinition) {
        lfError(`Unable to find connector ${connector.name}`);
        return;
      }

      // load form and cors form
      setLoading(true);
      let connectorConfigurationCors;
      const connectorConfigurationForm = await import(`../../../connectors/configuration-forms/${connectorDefinition.configurationForm}`)
      if (!connectorDefinition.cors) {
        connectorConfigurationCors = await import('../../../components/lets-form-forms/cors-proxy.json');
      }
      setLoading(false);

      // open modal with cors if needed
      const options = await open(
        {
          form: connectorConfigurationForm,
          formCors: connectorConfigurationCors,
          values: connector.options
        },
        {
          title: `Configure connector "${connectorDefinition.displayName}"`
        }
      );
      await close();

      // if saved, propagate
      if (options) {
        onChange(idx, connector, options);
      }
    },
    [close, connector, idx, onChange, open]
  );

  const handleRemove = useCallback(
    () => {
      onRemove(idx, connector);
    },
    [connector, idx, onRemove]
  );

  const connectorMeta = Connectors.find(c => c.name === connector.name) ?? {};

  return (
    <div className="lf-connector-item">
      {connectorMeta.icon && (
        <img className="icon" src={connectorMeta.icon} width="16" alt={connector.displayName} />
      )}
      {!connectorMeta.icon && (
        <ShareOutlineIcon width={16} height={16} />
      )}
      <div className="name">{connectorMeta.displayName}</div>
      <div className="description">{connectorMeta.description}</div>
      <ButtonGroup className="toolbar">
        <IconButton
          disabled={disabled || loading}
          size="xs"
          appearance="ghost"
          icon={<GearIcon />}
          onClick={handleClick}
        />
        <IconButton
          disabled={disabled || loading}
          size="xs"
          appearance="ghost"
          icon={<TrashIcon />}
          onClick={handleRemove}
        />
      </ButtonGroup>
    </div>
  );
};

export { ConnectorItem };
