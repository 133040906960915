/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Divider, Button } from 'rsuite';
import classNames from 'classnames';

import LetsForm, { formHelper } from '../../../components/lets-form/react-rsuite5';
import { ErrorBox } from '../../error-box';
import { IconGitHub } from '../../../assets/icons';
import { useCurrentUser, useNotification, useTracking } from '../../../hooks';

import { WaitingConfirmation } from './waiting-confirmation';
import LOGIN_FORM from '../../lets-form-forms/login.json';
import SIGNUP_FORM from '../../lets-form-forms/sign-up.json';
import '../sign-in-up-form.scss';

const REDIRECT_URL = process.env.NODE_ENV === 'development' ?
  'http://localhost:3000/github' : 'https://designer.letsform.dev/github';

const SignInUpForm = ({
  initialMode,
  onLogin = () => {},
  className
}) => {
  const { getMe, user } = useCurrentUser();
  const tracking = useTracking();
  let initialInitialMode = 'signIn';
  if (!_.isEmpty(initialMode) && initialMode !== 'auto') {
    initialInitialMode = initialMode;
  } else if (!_.isEmpty(localStorage.getItem('lf_email'))) {
    initialInitialMode = 'signIn';
  }
  const [mode, setMode] = useState(initialInitialMode); // signIn, signUp, forgotPassword, auto
  const [newUser, setNewUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const notification = useNotification();

  useEffect(
    () => {
      tracking.sendEvent(`user.${mode}`);
      // hide Chatra badge on login, it overlaps
      if (window.Chatra) {
        window.Chatra.hide();
      }
      return () => {
        if (window.Chatra) {
          window.Chatra.show();
        }
      }
    },
    [tracking, mode]
  );

  const handleCleanupAccessToken = useCallback(
    () => {
      // clean the access token in local storage when using GitHub button, otherwise
      // access token and cookie may conflict
      localStorage.removeItem('access_token');
    },
    []
  );

  const handleForgotPassword = useCallback(
    async (values) => {
      try {
        setLoading(true);
        const res = await fetch('https://dashboard.letsform.dev/auth/password/request', {
          method: 'POST',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({
            email: values.email
          })
        });
        setLoading(false);
        setMode('signIn');

        if (res.status >= 200 && res.status < 300) {
          notification.info(
            'Password Reset!',
            <div>
              <b>Password reset requested</b> If you have a registered account, you'll receive an email with
              the link to reset your password.<br />
              Don't forget to check the spam folder!
            </div>
          );
        } else {
          notification.error(
            'Error!',
            <div>
              <b>Something went wrong</b> with your password reset request, please try again later.
            </div>
          );
        }
      } catch(e) {
        setLoading(false);
        notification.somethingWentWrong();
      }
    },
    [notification]
  );

  const handleSignup = useCallback(
    async (values) => {
      try {
        if (_.isEmpty(values.email) || _.isEmpty(values.password)) {
          return;
        }

        setLoading(true);
        const res = await fetch('/api/users', {
          method: 'POST',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({
            user: _.pick(values, 'first_name', 'last_name', 'email', 'password')
          })
        });
        const json = await res.json();

        if (res.status === 200) {
          // store credentials
          const user = json.user;
          localStorage.setItem('lf_email', user.email);

          setLoading(false);
          setError(false);
          setNewUser(user);
          setMode('waitingConfirmation');
          tracking.sendEvent('user.signed-up');
        } else {

          setLoading(false);
          if (json.code === 'RECORD_NOT_UNIQUE') {
            setError('This email is already present, if you\'ve lost the password, try with "forgot password"');
          } else if (json.code === 'FAILED_VALIDATION' || json.code === 'INVALID_PASSWORD') {
            setError('Password is too weak, password must have at least a lowercase and uppercase char, a digit and a symbol');
          } else {
            setError(true);
          }
        }
      } catch(e) {
        setLoading(false);
        notification.somethingWentWrong();
      }
    },
    [notification, tracking]
  );


  const handleSubmit = useCallback(
    async (values) => {
      try {
        if (_.isEmpty(values.email) || _.isEmpty(values.password)) {
          return;
        }
        setLoading(true);

        const res = await fetch('https://dashboard.letsform.dev/auth/login', {
          method: 'POST',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify({
            ...values,
            mode: 'json'
          })
        });
        const json = await res.json();

        if (res.status === 200) {
          // store credentials
          localStorage.setItem('access_token', json.data.access_token);
          localStorage.setItem('refresh_token', json.data.refresh_token);
          localStorage.setItem('token_expires_at', new Date(new Date().getTime() + json.data.expires).toISOString());
          // refresh current user
          await getMe({ token: json.data.access_token });
          setLoading(false);
          setError(false);
          tracking.sendEvent('user.signed-in');
          onLogin();
        } else {
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          setLoading(false);
          setError(`Invalid credentials. Did you forget to click the link in the activation email,
          check your email (and the spam folder)!`);
        }
      } catch(e) {
        setLoading(false);
        notification.somethingWentWrong();
      }
    },
    [getMe, notification, onLogin, tracking]
  );

  const handleChange = useCallback(
    () => {
      setError(false);
    },
    []
  );

  const handleSwitchToSignIn = useCallback(
    e => {
      e.preventDefault();
      setMode('signIn')
    },
    []
  );

  const handleSwitchToSignUp = useCallback(
    e => {
      e.preventDefault();
      setMode('signUp')
    },
    []
  );

  const customForgot = useMemo(
    () => (
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          setMode('forgotPassword');
        }}
      >Forgot password?</a>
    ),
    []
  );

  const customBackToLogin = useMemo(
    () => (
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          setMode('signIn');
        }}
      >Go to login</a>
    ),
    []
  );

  const handleConfirmation = useCallback(
    () => setMode('signIn'),
    []
  );

  return (
    <div className={classNames('lf-sign-in-up', className)}>
      {user === null && (
        <>
          {(mode === 'signIn' || mode === 'forgotPassword') && (
            <div>
              <div className="label">
                Sign In
              </div>
              <div className="not-a">
                Not a <b>LetsForm</b> user? <a href="#" onClick={handleSwitchToSignUp}>Sign up</a>
              </div>
            </div>
          )}
          {mode === 'signIn' && (
            <LetsForm
              className="lf-login-form"
              disabled={loading}
              form={LOGIN_FORM}
              defaultValues={{
                email: !_.isEmpty(localStorage.getItem('lf_email')) ? localStorage.getItem('lf_email') : undefined
              }}
              debug
              onSubmit={handleSubmit}
              onChange={handleChange}
              custom={customForgot}
            />
          )}
          {mode === 'forgotPassword' && (
            <LetsForm
              className="lf-login-form"
              disabled={loading}
              form={
                formHelper(LOGIN_FORM)
                  .set('labelSubmit', 'Send recover link')
                  .hide('password')
                  .form()
              }
              onSubmit={handleForgotPassword}
              onChange={handleChange}
              custom={customBackToLogin}
            />
          )}
          {mode === 'signUp' && (
            <>
              <div>
                <div className="label">
                  Sign Up
                </div>
                <div className="not-a">
                  Already a <b>LetsForm</b> user? <a href="#" onClick={handleSwitchToSignIn}>Sign in</a>
                </div>
              </div>
              <LetsForm
                className="lf-signup-form"
                disabled={loading}
                form={SIGNUP_FORM}
                debug
                onSubmit={handleSignup}
                onChange={handleChange}
              />
            </>
          )}
          {mode === 'waitingConfirmation' && (
            <WaitingConfirmation
              user={newUser}
              onConfirmation={handleConfirmation}
            />
          )}
        </>
      )}
      {user !== undefined && user !== null && (
        <>
          <div className="label">Signed In</div>
          <div className="info">You're alread signed-in, proceed to the <Link to="/dashboard">dashboard</Link></div>
        </>
      )}
      {error && <ErrorBox error={error}/>}
      {user === null && (mode === 'signIn' || mode === 'signUp') && (
        <div className="providers">
          <Divider>Or</Divider>
          <Button
            className="black"
            onClick={handleCleanupAccessToken}
            href={`https://dashboard.letsform.dev/auth/login/github?redirect=${REDIRECT_URL}`}
            appearance="primary"
            startIcon={<IconGitHub color="#ffffff" />}
          >
            {mode === 'signIn' ? 'Login' : 'Sign up'} with GitHub
          </Button>
        </div>
      )}
    </div>
  );
};

export { SignInUpForm };
