import React from 'react';

import { AlchemicLoader } from '../../components/index.js';

import './block-404.scss';

const Block404 = () => {
  return (
    <div className="lf-block-404">
      <div className="loader">
        <AlchemicLoader />
      </div>
      <div className="code">404</div>
      <div className="message">
        This page could not be found.
      </div>
    </div>
  );
};

export { Block404 };
