import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, IconButton, Whisper, Tooltip } from 'rsuite';
import { formatDistanceToNow } from 'date-fns'
import ArrowDownLineIcon from '@rsuite/icons/ArrowDownLine';
import classNames from 'classnames';

import './form-card.scss';

const renderIconButton = (props, ref) => {
  return (
    <IconButton
      {...props}
      ref={ref}
      icon={<ArrowDownLineIcon />}
      color="blue"
      appearance="ghost"
      size="sm"
    />
  );
};

const FormCard = ({
  form,
  disabled,
  onSelect = () => {},
  canUse = true
}) => {

  const handleSelect = useCallback(
    key => {
      onSelect(key, form);
    },
    [form, onSelect]
  );

  const inner = (
    <div className={classNames('lf-form-card', { disabled, 'cannot-use': !canUse })}>
      {canUse && (
        <Dropdown
          renderToggle={renderIconButton}
          disabled={disabled}
          onSelect={handleSelect}
          noCaret
          placement="bottomEnd"
          className="form-menu" title="&nbsp;" appearance="primary" size="xs" >
          <Dropdown.Item eventKey="cmd:editForm">Edit form</Dropdown.Item>
          <Dropdown.Item eventKey="cmd:editFormDescription">Edit description</Dropdown.Item>
          <Dropdown.Item eventKey="cmd:previewForm">Preview</Dropdown.Item>
          <Dropdown.Item eventKey="cmd:formVersions">Versions...</Dropdown.Item>
          <Dropdown.Separator />
          <Dropdown.Item eventKey="cmd:deleteForm">Delete</Dropdown.Item>
        </Dropdown>
      )}
      {canUse && (
        <Link
          className="name"
          to={`/projects/${form.projectId.id}/forms/${form.id}`}
        >{form.name}</Link>
      )}
      {!canUse && (
        <span className="name">{form.name}</span>
      )}
      <div className="description">{form.description}</div>
      <div className="date">
        {formatDistanceToNow(new Date(form.versions[0].date_updated || form.versions[0].date_created))}
      </div>
    </div>
  );

  if (canUse) {
    return inner;
  } else {
    return (
      <Whisper
        placement="top"
        trigger="hover"
        speaker={
          <Tooltip>
            This form is not longer accessible due to the current plan limitation, ,
            please upgrade to use it again
          </Tooltip>
        }
      >
        {inner}
      </Whisper>
    );
  }
};

export { FormCard };
