import _ from 'lodash';

import { generateFormJs } from './generate-form-js';
import { generateMainCode } from './generate-main-code';
import { generateCraHtml } from './generate-cra-html';
import { getPackageJsonDependencies } from './get-package-dependencies';
import { generateFormCss } from './generate-form-css';

const manifestJson = `{
  "short_name": "React App",
  "name": "Create React App Sample",
  "icons": [],
  "start_url": ".",
  "display": "standalone",
  "theme_color": "#000000",
  "background_color": "#ffffff"
}
`;

const generateCRABundle = ({ form, framework, locale }) => {

  const appName = 'cra-' + _.kebabCase(form.name);

  const deps = getPackageJsonDependencies({ framework, form, locale });
  let depsJSON = Object.keys(deps)
    .map(dep => `    "${dep}": "${deps[dep]}"`);

  const packageJson = `{
  "name": "${appName}",
  "version": "0.1.0",
  "private": true,
  "dependencies": {
    "@testing-library/jest-dom": "^5.17.0",
    "@testing-library/react": "^13.4.0",
    "@testing-library/user-event": "^13.5.0",
    "react-scripts": "5.0.1",
    "web-vitals": "^2.1.4",
${depsJSON.join(',\n')}
  },
  "scripts": {
    "start": "craco start",
    "build": "craco build",
    "test": "craco test",
    "eject": "craco eject"
  },
  "eslintConfig": {
    "extends": [
      "react-app",
      "react-app/jest"
    ]
  },
  "browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  },
  "devDependencies": {
    "@craco/craco": "^7.1.0"
  }
}
  `;

  const cracoConfig = `module.exports = {
  webpack: {
    configure: {
      module: {
        rules: [
          {
            test: /\\.m?js$/,
            resolve: {
              fullySpecified: false,
            },
          },
        ],
      },
    },
  },
};
`;

  return {
    appName,
    files: {
      [`${appName}/package.json`]: {
        content: packageJson
      },
      [`${appName}/craco.config.js`]: {
        content: cracoConfig
      },
      [`${appName}/src/form.css`]: {
        content: generateFormCss()
      },
      [`${appName}/src/form.js`]: {
        content: generateFormJs(form, framework)
      },
      [`${appName}/public/index.html`]: {
        content: generateCraHtml()
      },
      [`${appName}/public/manifest.json`]: {
        content: manifestJson
      },
      [`${appName}/src/index.js`]: {
        content: generateMainCode({
          form,
          framework,
          locale
        })
      }
    }
  };
};

export { generateCRABundle };