import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, IconButton, Whisper, Tooltip } from 'rsuite';
import ArrowDownLineIcon from '@rsuite/icons/ArrowDownLine';
import classNames from 'classnames';

import { MarkdownText } from '../../../../components';

import './project-card.scss';

const renderIconButton = (props, ref) => {
  return (
    <IconButton
      {...props}
      ref={ref}
      icon={<ArrowDownLineIcon />}
      color="blue"
      appearance="ghost"
      size="sm"
    />
  );
};


const ProjectCard = ({
  project,
  disabled,
  onSelect = () => {},
  canUse = true
}) => {

  const handleSelect = useCallback(
    key => {
      onSelect(key, project);
    },
    [project, onSelect]
  );

  const inner = (
    <div className={classNames('lf-project-card', { disabled, 'cannot-use': !canUse })}>
      {canUse && (
        <Dropdown
          renderToggle={renderIconButton}
          disabled={disabled}
          onSelect={handleSelect}
          noCaret
          placement="bottomEnd"
          className="form-menu" title="&nbsp;" appearance="primary" size="xs" >
          <Dropdown.Item eventKey="cmd:editProject">Edit project</Dropdown.Item>
          <Dropdown.Separator />
          <Dropdown.Item eventKey="cmd:deleteProject">Delete</Dropdown.Item>
        </Dropdown>
      )}
      {canUse && (
        <Link
          className="name"
          to={`/projects/${project.id}`}
        >{project.name}</Link>  
      )}
      {!canUse && (
        <span className="name">{project.name}</span>
      )}
      <MarkdownText className="description">
        {project.description}  
      </MarkdownText>      
      <div className="count">          
        {project.formsCount ?
          <><b>{project.formsCount}</b> forms</>        
          : <>&nbsp;</>
        }
      </div>          
    </div>
  );

  if (canUse) {
    return inner;
  } else {
    return (
      <Whisper
        placement="top"
        trigger="hover"
        speaker={
          <Tooltip>
            This form is not longer accessible due to the current plan limitation,
            please upgrade use it again
          </Tooltip>
        }
      >
        {inner}
      </Whisper>
    );
  }
};

export { ProjectCard };
