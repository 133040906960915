import {
  COMMON_DUOS_STATUS,
  makeColums,
  RSUITE5_SIZE,
  COMMON_TRITTICUS,
  RSUITE5_APPEARANCE_ALL,
  RSUITE5_HINT_AS_TOOLTIP,
  ANTD_TYPE,
  ANTD_SIZE,
  ANTD_HINT_AS_TOOLTIP,
  MUI_COLORS,
  MUI_BUTTON_VARIANT,
  MUI_SIZE,
  MANTINE_SIZE,
  MANTINE_RADIUS,
  MANTINE_VARIANT,
  COMMON_FULL_WIDTH_SIZE,
  MANTINE_SECTIONS,
  MANTINE_POINTER,
  MANTINE_ERROR_WITH_STYLE
} from '../costants/index.js';

const manifest = {
  label: 'Upload',
  category: 'general',
  name: 'upload',
  frameworks: [
    'react-antd',
    'react',
    //'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  description: 'Upload file control, returns an array of json with file info and blob',
  fields: [
    ...COMMON_TRITTICUS,
    COMMON_DUOS_STATUS,
    {
      component: 'array',
      label: 'Accepted files',
      name: 'accept',
      layout: 'vertical',
      arrayType: 'arrayOfString',
      alignOffset: 2,
      hint: 'Accepted files, file extension or mime type: .doc, .pdf, video/*, image/png, etc. ',
      fields: [
        {
          component: 'input-text',
          name: 'acceptedFile',
          'react-rsuite5': {
            fullWidth: true
          }
        }
      ]
    }
  ],
  'react': [
    {
      name: 'uploadButtonLabel',
      component: 'input-text-i18n',
      label: 'Upload button'
    },
    {
      component: 'toggle',
      label: 'Multiple',
      name: 'multiple',
      hint: 'Allow selecting multiple files',
      'react-rsuite5': {
        tooltip: true
      }
    }
  ],
  'react-antd': [
    {
      component: 'toggle',
      label: 'Multiple',
      name: 'multiple',
      hint: 'Allow selecting multiple files',
      'react-rsuite5': {
        tooltip: true
      }
    },
    {
      component: 'select',
      name: 'listType',
      label: 'List type',
      hint: 'Built-in stylesheets, support for four types: text, picture, picture-card or picture-circle',
      options: [
        { value: 'text', label: 'Text' },
        { value: 'picture', label: 'Picture' },
        { value: 'picture-card', label: 'Picture - Card' },
        { value: 'picture-circle', label: 'Picture - Circle' }
      ],
      'react-rsuite5': {
        searchable: false,
        tooltip: true
      }
    },
    {
      component: 'input-number',
      name: 'maxCount',
      label: 'Max files',
      min: 1,
      step: 1
    },
    {
      component: 'toggle',
      name: 'showUploadList',
      label: 'Show upload list'
    },

    makeColums(
      'uploadButtonGroup',
      {
        name: 'uploadButtonLabel',
        component: 'input-text-i18n',
        label: 'Upload button'
      },
      { ...ANTD_TYPE, name: 'uploadButtonAppearance' },
      { ...ANTD_SIZE, name: 'uploadButtonSize' }
    ),

    {
      component: 'toggle',
      label: 'Draggable area',
      name: 'draggable',
      script: `
      if (draggable) {
        show('draggableGroup');
        hide('uploadButtonGroup');
      } else {
        hide('draggableGroup');
        show('uploadButtonGroup');
      }`
    },
    makeColums(
      'draggableGroup',
      {
        name: 'draggableText',
        component: 'input-text-i18n',
        label: 'Draggable zone text',
        'react-rsuite5': {
          fullWidth: true
        }
      },
      {
        name: 'draggableHeight',
        component: 'input-number',
        label: 'Draggable zone height',
        step: 10,
        min: 10
      }
    ),
    ANTD_HINT_AS_TOOLTIP
  ],
  'react-rsuite5': [
    makeColums(
      {
        component: 'select',
        label: 'List type',
        name: 'listType',
        block: true,
        options: [
          {
            value: 'text',
            label: 'Text'
          },
          {
            value: 'picture-text',
            label: 'Picture text'
          },
          {
            value: 'picture',
            label: 'Picture'
          }
        ],
        'react-rsuite5': {
          cleanable: false,
          searchable: false
        }
      },
      {
        component: 'input-number',
        label: 'Max preview size',
        name: 'maxPreviewFileSize',
        hint: 'Set the maximum limit for preview files',
        'react-rsuite5': {
          step: 1024,
          tooltip: true
        }
      }
    ),
    makeColums(
      RSUITE5_HINT_AS_TOOLTIP,
      {
        name: 'fileListVisible',
        component: 'toggle',
        label: 'Visible file list'
      },
      {
        component: 'toggle',
        label: 'Disable file list',
        name: 'disabledFileItem'
      }
    ),
    makeColums(
      {
        component: 'toggle',
        label: 'Multiple',
        name: 'multiple',
        hint: 'Allow selecting multiple files',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        component: 'toggle',
        label: 'Removable',
        name: 'removable'
      },
      {
        component: 'toggle',
        label: 'Draggable area',
        name: 'draggable',
        script: `
        if (draggable) {
          show('draggableGroup');
          hide('uploadButtonGroup');
        } else {
          hide('draggableGroup');
          show('uploadButtonGroup');
        }`
      }
    ),
    makeColums(
      'uploadButtonGroup',
      {
        name: 'uploadButtonLabel',
        component: 'input-text-i18n',
        label: 'Upload button'
      },
      { ...RSUITE5_APPEARANCE_ALL, name: 'uploadButtonAppearance' },
      { ...RSUITE5_SIZE, name: 'uploadButtonSize' }
    ),
    makeColums(
      'draggableGroup',
      {
        name: 'draggableText',
        component: 'input-text-i18n',
        label: 'Draggable zone text',
        'react-rsuite5': {
          fullWidth: true
        }
      },
      {
        name: 'draggableHeight',
        component: 'input-number',
        label: 'Draggable zone height',
        step: 10,
        min: 10
      }
    )
  ],
  'react-bootstrap': [

  ],
  'react-material-ui': [
    {
      component: 'toggle',
      label: 'Multiple',
      name: 'multiple',
      hint: 'Allow selecting multiple files',
      'react-rsuite5': {
        tooltip: true
      }
    },
    makeColums(
      'uploadButtonGroup',
      {
        name: 'uploadButtonLabel',
        component: 'input-text-i18n',
        label: 'Upload button'
      },
      { ...MUI_BUTTON_VARIANT, name: 'uploadButtonVariant' },
      { ...MUI_SIZE, name: 'uploadButtonSize' }
    ),
    MUI_COLORS,
    {
      component: 'input-text',
      name: 'uploadButtonIcon',
      label: 'Button icon',
      'react-rsuite5': {
        fullWidth: true
      },
      placeholder: 'http:// ...'
    }
  ],
  'react-mantine': [
    {
      name: 'placeholder',
      component: 'input-text-i18n',
      label: 'Placeholder'
    },
    makeColums(
      MANTINE_SIZE,
      MANTINE_RADIUS,
      MANTINE_VARIANT
    ),
    COMMON_FULL_WIDTH_SIZE,
    MANTINE_SECTIONS,
    makeColums(
      {
        name: 'clearable',
        component: 'toggle',
        label: 'Clearable'
      },
      {
        name: 'multiple',
        component: 'toggle',
        label: 'Multiple',
        hint: 'Determines whether user can pick more than one file, false by default',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      MANTINE_POINTER,
      MANTINE_ERROR_WITH_STYLE
    )
  ],
  'form-generator': {
    html5: false
  },
  defaultValues: {
    'react': {
      'react': {

      }
    },
    'react-bootstrap': {
      'react-bootstrap': {

      }
    },
    'react-rsuite5': {
      'react-rsuite5': {
        removable: true,
        multiple: true,
        disabledFileItem: false,
        draggable: false,
        fileListVisible: true,
        listType: 'text',
        uploadButtonLabel: 'Upload'
      }
    },
    'react-material-ui': {
      'react-material-ui': {
        uploadButtonLabel: 'Select file',
        uploadButtonVariant: 'contained',
        multiple: false
      }
    },
    'react-antd': {
      'react-antd': {
        listType: 'text'
      }
    }
  }
};

export { manifest };