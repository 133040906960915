import _ from 'lodash';

import { mapFields } from '../components/lets-form';

const applyChange = (item, newField) => {
  if (item.idx != null && item.subKey != null) {
    // replace items of array
    // clone the array
    newField[item.key] = [...newField[item.key]];
    // clone the object inside the array
    newField[item.key][item.idx] = { ...newField[item.key][item.idx] };
    // if i18n object
    if (_.isObject(newField[item.key][item.idx][item.subKey])) {
      newField[item.key][item.idx][item.subKey][item.targetLocale] = item.translation;
    }
  } else if (item.idx == null && item.subKey != null) {
    // clone the object
    newField[item.key] = { ...newField[item.key] };
    // clone the item inside the object
    newField[item.key][item.subKey] = { ...newField[item.key][item.subKey] };
    // if is i18n object
    if (_.isObject(newField[item.key][item.subKey])) {
      newField[item.key][item.subKey][item.targetLocale] = item.translation;
    }
  } else {
    // replace normal strings
    if (_.isObject(newField[item.key])) {
      newField[item.key] = {
        ...newField[item.key],
        [item.targetLocale]: item.translation
      };
    }
  }
  return newField;
}

const reconcileFormWithGroceryList = (form, groceryList) => {
  return {
    ...form,
    fields: mapFields(
      form.fields,
      field => {
        // filter all translated tokens for this field
        const itemsForField = groceryList.filter(item => item.name === field.name);
        // if no items for this field, then leave unchanged
        if (itemsForField.length === 0) {
          return field;
        }

        // glue all new translations
        let newField = { ...field };
        itemsForField
          .filter(item => !_.isEmpty(item.translation))
          .forEach(item => {
            if (item.framework) {
              // translations patches must be applied within a framework subset
              newField[item.framework] = applyChange(item, newField[item.framework]);
            }
            else {
              newField = applyChange(item, newField);
            }            
        });

        return newField;
      })
  };
};

export { reconcileFormWithGroceryList };
