import React from 'react';
import { Dropdown } from 'rsuite';

import { useEvent } from '../../../code-plug';

const AddFieldMenuItem = () => {
  const callAddField = useEvent('addField');

  return (
    <Dropdown.Item onSelect={callAddField}>Add field</Dropdown.Item>
  );
};

export { AddFieldMenuItem };
