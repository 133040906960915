import React from 'react';
import _ from 'lodash';
import { Tag, Whisper, Popover } from 'rsuite';

import './tag-popover.scss';

const TagPopover = ({ children, color, text, title }) => {
  const inner = <Tag className="lf-tag-popover" color={color}>{children}</Tag>

  if (!_.isEmpty(text)) {
    return (
      <Whisper
        trigger="hover"
        placement="leftStart"
        speaker={
          <Popover
            title={!_.isEmpty(title) ? title : children}
            className="lf-tag-popover-balloon"
          >
            {text}
          </Popover>
        }
      >
        {inner}
      </Whisper>
    );
  }
  return inner;
};

export { TagPopover };
