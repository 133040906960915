import { TbBrandReactNative } from 'react-icons/tb';

import { IconBootstrap, IconMaterialUI, IconRSuite, IconAntD, IconMantine } from '../../assets/icons';

const FrameworkIcons = {
  'react-bootstrap': IconBootstrap,
  'react-material-ui': IconMaterialUI,
  'react-rsuite5': IconRSuite,
  'react': ({ width = 24 }) => <TbBrandReactNative size={width} color="#61dafb" />,
  'react-antd': IconAntD,
  'react-mantine': IconMantine
};

export { FrameworkIcons };
