import React, { useState, useCallback } from 'react';
import _ from 'lodash';


const SENTENCES = [
  'Hello',
  'Ciao',
  'Howdy',
  'Bonjour',
  'Hola',
  'Olá',
  'Kia Ora',
  'Geia',
  'Zdravo',
  'Nǐ hǎo',
  'Namaste',
  'Kon’nichiwa',
  'Ahoj',
  'Guten tag',
  'Hallo',
  'Cześć',
  'Salām',
  'Hallå'
];

const WelcomeBox = () => {
  const [idx, setIdx] = useState(_.random(0, SENTENCES.length - 1));

  const handleChange = useCallback(
    () => {
      let newIdx = idx;
      while(newIdx === idx) {
        newIdx = _.random(0, SENTENCES.length - 1);
      }
      setIdx(newIdx);      
    },
    [idx]
  );

  return (
    <div 
      onClick={handleChange}
      className="lf-welcome-box" style={{ cursor: 'pointer' }}>
      {SENTENCES[idx]}
    </div>
  );
};

export { WelcomeBox };
