import _ from 'lodash';

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
};

export const makeVariableName = str => _.upperFirst(_.camelCase(str.replace(/[^A-Za-z0-9 ]/g, '')));

/**
 * cleanupPath
 * Remove leading and trailing slash
 * @param {*} s 
 */
const cleanupPath = s => {
  return (s || '').replace(/^\//, '').replace(/\/$/, '');
};

export const generateImportCode = (selectedForms, project, type) => {
  let packageRef;
  if (type === 'github') {
    packageRef = '/' + cleanupPath(project.targetPath);
  } else if (type === 'download') {
    packageRef = './your-downloaded-forms';
  } else if (type === 'npm') {
    packageRef = project.packageName;
  }

  let theImport;
  if (_.isEmpty(selectedForms)) {
    return null;
  } else if (selectedForms.length === 1) {
    theImport = `import { ${makeVariableName(selectedForms[0])}} from '${packageRef}'`;
  } else {
    const vars = selectedForms.map(makeVariableName);
    theImport = `import {\n  ${vars.join(',\n  ')}\n} from '${packageRef}'`;
  }

  return `${theImport}

const SomeComponent = () => {

  return (
    <${makeVariableName(selectedForms[0])}
      defaultValues={{
        // some initial values
      }}
      onChange={value => {
        // changed values in the form
      }}
    />
  );
};`;
};

export const nextLabel = (step, type) => {
  if (step === 3 && type === 'github') {
    return 'Commit & Push';
  }
  if (step === 2 && type === 'download') {
    return 'Create .zip file';
  }
  return 'Next';
};

export const isValidGitHubProject = project => !_.isEmpty(project.githubRepository) &&
  !_.isEmpty(project.githubToken) &&
  !_.isEmpty(project.targetPath) &&
  !_.isEmpty(project.branch);
  