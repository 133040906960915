import React, { useCallback } from 'react';
import { Button } from 'rsuite';

import { MarkdownText } from '../../../components';

import './news-detail.scss';


const NewsDetail = ({ value, onCancel }) => {

  const handleClose = useCallback(
    () => onCancel(),
    [onCancel]
  );

  return (
    <div className="lf-news">
      <div className="date">{new Date(value.date_created).toLocaleDateString()}</div>
      <h1>{value.title}</h1>
      <MarkdownText>
        {value.body}
      </MarkdownText>
      <div className="buttons">
        <Button
          appearance="primary"
          onClick={handleClose}
        >Close</Button>
      </div>
    </div>
  );
};

export { NewsDetail };
