import React, { useState, useEffect } from 'react';
import { Modal, Button, IconButton } from 'rsuite';
import _ from 'lodash';
import classNames from 'classnames';
import CloseIcon from '@rsuite/icons/Close';

import ShowErrors from '../show-error';

import './style.scss';

const ModalWrapper = ({
  view: InnerView,
  initialValue,
  updateValue,
  disabled = false,
  title,
  onSubmit = () => {},
  onCancel = () => {},
  labelSubmit = 'Save',
  labelCancel = 'Cancel',
  className,
  size = 'md',
  error,
  validation: validationProp,
  enableSubmit = () => true,
  align = 'right',
  custom,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue);
  const [currentSize, setCurrentSize] = useState(size);
  const [currentTitle, setCurrentTitle] = useState(title);
  const [submitEnabled, setSubmitEnabled] = useState(true);
  const [validation, setValidation] = useState(validationProp);
  useEffect(() => {
    setValidation(validationProp);
  }, [validationProp]);
  useEffect(() => {
    // set the status in case of update
    if (updateValue != null) {
      setValue(updateValue);
    }
  }, [updateValue]);

  useEffect(
    () => {
      setSubmitEnabled(enableSubmit(initialValue));
    },
    [enableSubmit, initialValue]
  );

  return (
    <Modal
      backdrop
      open
      onClose={null}
      size={currentSize}
      overflow={false}
      className={classNames('ui-modal-wrapper', className, {
        [align]: true,
        'rs-modal-xmd': currentSize === 'xmd'
      })}
    >
      {(!_.isEmpty(currentTitle) || _.isFunction(currentTitle)) && (
        <Modal.Header>
          <Modal.Title>{_.isFunction(currentTitle) ? title(currentTitle) : currentTitle}</Modal.Title>
        </Modal.Header>
      )}
      <IconButton
        appearance="ghost"
        size="md"
        className="btn-close"
        icon={<CloseIcon />}
        onClick={onCancel}
      />
      <Modal.Body>
        {error != null && <ShowErrors error={error}/>}
        <InnerView
          disabled={disabled}
          value={value}
          validation={validation}
          error={error}
          onChange={value => {
            setValue(value);
            setSubmitEnabled(enableSubmit(value));
            setValidation(null)
          }}
          onCancel={onCancel}
          onSubmit={submittedValue => onSubmit(submittedValue || value)}
          setModalSize={setCurrentSize}
          setModalTitle={setCurrentTitle}
          modalSize={currentSize}
          modalTitle={currentTitle}
          {...rest}
        />
      </Modal.Body>
      {(_.isFunction(custom) || !_.isEmpty(labelCancel) || !_.isEmpty(labelSubmit)) && (<Modal.Footer>
        {_.isFunction(custom) ? custom(value) : custom}
        {!_.isEmpty(labelCancel) && (
          <Button onClick={() => onCancel()} appearance="subtle">
            {labelCancel}
          </Button>
        )}
        {!_.isEmpty(labelSubmit) && (
          <Button
            disabled={disabled || !submitEnabled}
            appearance="primary"
            onClick={() => onSubmit(value)}
          >
            {labelSubmit}
          </Button>
        )}
      </Modal.Footer>)}
    </Modal>
  );
};

export default ModalWrapper;