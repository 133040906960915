import { definePlugin } from '../../code-plug';

import { StartHereArrow } from './views/start-here';
import { updateEditUsageCount } from './helpers/edit-usage';

definePlugin('builder-start-here', ({ registerView, listenTo }) => {

  // register the big button at the bottom of the form to add a field
  registerView(
    'form-builder-footer',
    StartHereArrow,
    {
      key: 'form-builder-start-here'
    }
  );

  // listen to edit field and increase counter
  listenTo(
    'builderEditField',
    () => updateEditUsageCount()
  )
});
