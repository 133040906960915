import React from 'react';
import { Button } from 'rsuite';
import { GrCodeSandbox } from 'react-icons/gr';

import { generateCodeSandbox } from '../../../components/code-generation';

import './button-codesandbox.scss';

const OpenCodesandbox = ({
  form,
  framework,
  locale
}) => {
  let condeSandboxLink = generateCodeSandbox({ framework, form, locale });

  return (
    <form action="https://codesandbox.io/api/v1/sandboxes/define" method="POST" target="_blank">
      <input type="hidden" name="parameters" value={condeSandboxLink} />
        <Button
          startIcon={<GrCodeSandbox color="#ffffff"/>}
          appearance="primary"
          size="lg"
          type="submit"
          className="lf-open-codesandbox">Open in Codesandbox
        </Button>
    </form>
  );
};

export { OpenCodesandbox };
