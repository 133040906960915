import { definePlugin } from '../../code-plug';

import { AddFieldButtonLarge } from './views/add-field-button-large';
import { AddFieldMenuItem } from './views/add-field-menu-item';
import { AddFieldButtonSmall } from './views/add-field-button-small';

definePlugin('builder-add-field', ({ registerView }) => {

  // register the big button at the bottom of the form to add a field
  registerView(
    'form-builder-footer',
    AddFieldButtonLarge,
    {
      key: 'form-builder-add-field',
      event: 'addField'
    }
  );

  // register the toolbar menu item to add a field
  registerView(
    'form-builder-toolbar',
    AddFieldMenuItem,
    {
      key: 'form-builder-add-field-item',
      event: 'installTemplate',
      parent: 'Fields'
    }
  );

  // register small add button field to be shown inside layout elements
  // like columns, array, etc
  registerView(
    'form-builder-inner-footer',
    AddFieldButtonSmall,
    {
      key: 'form-builder-inner-footer-add-field'
    }
  );
});
