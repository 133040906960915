/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Button, Loader } from 'rsuite';
import classNames from 'classnames';
import { FaCcAmex, FaCcApplePay, FaCcVisa, FaCcMastercard } from 'react-icons/fa'

import { microdown } from '../micro-down';
import { useCurrentUser, useNotification } from '../../hooks';
import { useModal } from '../modal';
import { IconCheck } from '../../assets/icons';

import { ActivatePromoCode } from './views/activate-promo-code';

import './plans.scss';

const GET_PLANS = gql`
query($environment: String!) {
  plans(
    filter: {
      status: {
        _eq: "published"
      },
      environment: {
        _eq: $environment
      }
    },
    sort: "sort"
  ) {
    id
    name,
    code,
    status,
    environment,
    long_description,
    price,
    stripe_price
  }
}`;


const Plan = ({
  plan,
  user,
  onSubscribe, generating,
  disabled = false,
}) => {

  const currentPlan = plan.id === String(user.organization.planId);
  const expireAt = new Date(user.organization.expire_at);

  return (
    <div
      className={classNames('plan', { selected: currentPlan, disabled })}
      key={plan.code}
    >
      {currentPlan && (
        <div className="triangle">
          <IconCheck color="#ffffff"/>
        </div>
      )}

      <div className="head">
        <div className="name">{plan.name}</div>
        <div className="price">
          <div className="tag">
            ${plan.price}
          </div>
          {plan.price !== 0 && (
            <div className="label">
              per<br />month
            </div>
          )}
        </div>
      </div>

      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: microdown.parse(plan.long_description || '') }}
      />

      <br />
      {plan.price !== 0 && !currentPlan && (
        <div className="button">
          <Button
            appearance="primary"
            disabled={generating != null || disabled}
            onClick={() => onSubscribe(plan)}
          >{generating === plan.id ? 'Redirecting...' : 'Subscribe'}</Button>
        </div>
      )}
      {plan.price !== 0 && currentPlan && (
        <div className="expire">
          Renew on <me>{expireAt.toLocaleDateString()}</me>
        </div>
      )}
    </div>
  );
};


const MembershipPlans = () => {
  const { user, access_token, getMe } = useCurrentUser();
  const { error: notifyError } = useNotification();
  const [generatingLink, setGeneratingLink] = useState();
  const [refreshing, setRefreshing] = useState(false);

  const { loading, error, data } = useQuery(GET_PLANS, {
    variables: {
      environment: process.env.NODE_ENV
    }
  });

  const { open: openPromoCode, close: closePromoCode } = useModal({
    view: ActivatePromoCode,
    size: 'sm',
    name: 'enterPromoCode',
    align: 'center',
    labelSubmit: null,
    labelCancel: null,
    title: 'Promo code',
  });

  const handleSubscribe = useCallback(
    async (plan) => {
      console.log('subscribing to ', plan);
      setGeneratingLink(plan.id);
      const resSession = await fetch('/api/checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${access_token}`
        },
        body: JSON.stringify({
          userId: user.id,
          planId: plan.id,
          organizationId: user.organization.id
        })
      });
      if (resSession.status !== 200) {
        setGeneratingLink(null);
        notifyError(
          'Something went wrong',
          'Something went wrong with the payment gateway, please retry in a while'
        );
        return;
      }
      const session = await resSession.json();

      if (session && session.url) {
        window.location = session.url;
      }
    },
    [access_token, notifyError, user]
  );

  const handlePromoCode = useCallback(
    async e => {
      e.preventDefault();
      const result = await openPromoCode({ user, access_token });
      closePromoCode();
      if (result) {
        setRefreshing(true);
        await getMe({ token: access_token });
        setRefreshing(false);
      }
    },
    [openPromoCode, user, access_token, closePromoCode, getMe]
  );

  return (
    <div className="lf-membership-plans">
      {loading && (
        <Loader className="loader" size="md" />
      )}
      {!loading && !error && (
        <>
          <div className="plans">
            <div className="plans">
              {data.plans.map(plan => (
                <Plan
                  user={user}
                  key={plan.code}
                  plan={plan}
                  onSubscribe={handleSubscribe}
                  generating={generatingLink}
                  disabled={refreshing}
                />
              ))}
            </div>
          </div>
          <div className="info">
            Supported payment methods{' '}
            <span className="icons">
              <FaCcAmex size={24}/>{' '}
              <FaCcApplePay size={24} />{' '}
              <FaCcVisa size={24} />{' '}
              <FaCcMastercard size={24}/>{' '}
            </span>
            &nbsp;&bull;{' '}
            <a href="/terms" target="_blank">Terms and Conditions</a>
            &nbsp;&bull;{' '}
            <a href="#" onClick={handlePromoCode}>Use promo code</a>
          </div>
        </>
      )}
    </div>
  );
};

export { MembershipPlans };
