import {
  ANTD_HINT_AS_TOOLTIP,
  ANTD_SIZE_ONLY_SMALL,
  COMMON_TRITTICUS,
  COMMON_TRITTICUS_STATUS,
  makeColums,
  RSUITE5_HINT_AS_TOOLTIP,
  MUI_DISABLE_RIPPLE,
  MUI_TRITTICUS_1,
  RSUITE5_SIZE,
  MANTINE_SIZE,
  MANTINE_RADIUS,
  MANTINE_COLORS,
  makeOptions
} from '../costants/index.js';

const manifest = {
  label: 'Toggle',
  category: 'general',
  name: 'toggle',
  frameworks: [
    'react-antd',
    //'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    ...COMMON_TRITTICUS,
    COMMON_TRITTICUS_STATUS
  ],
  'react-material-ui': [
    MUI_TRITTICUS_1,
    MUI_DISABLE_RIPPLE
  ],
  'react-rsuite5': [
    RSUITE5_SIZE,
    makeColums(
      {
        name: 'checkedChildren',
        label: 'Label for ON status',
        component: 'input-text-i18n',
        width: 130,
        placeholder: ''
      },
      {
        name: 'unCheckedChildren',
        label: 'Label for OFF status',
        component: 'input-text-i18n',
        width: 130,
        placeholder: ''
      }
    ),
    RSUITE5_HINT_AS_TOOLTIP
  ],
  'react-antd': [
    ANTD_SIZE_ONLY_SMALL,
    ANTD_HINT_AS_TOOLTIP,
    makeColums(
      {
        name: 'checkedChildren',
        label: 'Label for ON status',
        component: 'input-text-i18n',
        width: 130,
        placeholder: ''
      },
      {
        name: 'unCheckedChildren',
        label: 'Label for OFF status',
        component: 'input-text-i18n',
        width: 130,
        placeholder: ''
      }
    )
  ],
  'react-mantine': [
    makeColums(
      MANTINE_SIZE,
      MANTINE_RADIUS,
      {
        name: 'labelPosition',
        component: 'select',
        label: 'Label position',
        block: true,
        options: makeOptions(['left', 'right']),
        searchable: false
      }
    ),
    makeColums(
      {
        name: 'offLabel',
        component: 'input-text',
        label: 'Off label',
        hint: '	Inner label when the Switch is in unchecked state',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'onLabel',
        component: 'input-text',
        label: 'On label',
        hint: 'Inner label when the Switch is in checked state',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    MANTINE_COLORS
  ],
  'form-generator': {
    //hideValidation: true
  }
};

export { manifest };
