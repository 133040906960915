import {
  COMMON_NAME,
  COMMON_HIDDEN,
  makeColums,
  makeOptions,
  MANTINE_COLORS,
  MANTINE_RADIUS,
  MANTINE_SIZE,
  MANTINE_AUTO_CONTRAST
} from '../costants/index.js';

const STEPS_COMMON = {
  component: 'columns',
  columns: [
    { name: 'one', size: 0 },
    { name: 'two', size: 1 }
  ],
  name: 'array-option-item',
  fields: {
    one: [
      {
        name: 'value',
        component: 'input-text',
        placeholder: 'value',
        size: 'sm',
        width: 100,
        fullWidth: false
      }
    ],
    two: [
      {
        name: 'label',
        component: 'input-text-i18n',
        placeholder: 'label',
        size: 'sm',
        fullWidth: true
      }
    ]
  }
};

const STEPS_DESCRIPTION = {
  name: 'description',
  component: 'input-text-i18n',
  placeholder: 'description',
  size: 'sm',
  fullWidth: true
};

const STEPS_SUBTITLE = {
  name: 'subtitle',
  component: 'input-text-i18n',
  placeholder: 'sub title',
  size: 'sm',
  fullWidth: true
};

const STEPS_ICON = {
  name: 'icon',
  component: 'input-text',
  placeholder: 'i.e. http://my-icon',
  size: 'sm',
  fullWidth: true
};


const manifest = {
  label: 'Steps',
  category: 'layout',
  description: 'Layout component to split the form in multiple steps',
  name: 'steps',
  frameworks: [
    'react-antd',
    //'react',
    //'react-bootstrap',
    //'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    COMMON_NAME,
    COMMON_HIDDEN,
    makeColums(
      {
        name: 'labelNext',
        label: 'Next Button Label',
        component: 'input-text-i18n',
        placeholder: 'i.e. Next',
      },
      {
        name: 'labelPrevious',
        label: 'Previous Button Label',
        component: 'input-text-i18n',
        placeholder: 'i.e. Previous',
      }
    ),
    {
      name: 'align',
      component: 'select',
      label: 'Buttons alignment',
      options: makeOptions(['left', 'center', 'right']),
      searchable: false
    }
  ],
  'react-rsuite5': [
    {
      component: 'array',
      name: 'steps',
      label: 'Steps definition',
      maxHeight: 350,
      fields: [
        STEPS_COMMON,
        STEPS_DESCRIPTION,
        STEPS_ICON
      ]
    },
    {
      component: 'toggle',
      name: 'small',
      label: 'Small'
    },
    {
      component: 'select',
      name: 'status',
      label: 'Current step status',
      searchable: false,
      cleanable: true,
      options: makeOptions(['finish', 'wait', 'process', 'error'])
    }
  ],
  'react-material-ui': [

  ],
  'react-bootstrap': [

  ],
  'react': [

  ],
  'react-antd': [
    {
      component: 'array',
      name: 'steps',
      label: 'Steps definition',
      maxHeight: 350,
      fields: [
        STEPS_COMMON,
        STEPS_SUBTITLE,
        STEPS_DESCRIPTION,
        STEPS_ICON
      ]
    },
    {
      component: 'select',
      name: 'stepsType',
      label: 'Steps type',
      searchable: false,
      cleanable: true,
      options: makeOptions(['default', 'navigation', 'inline']),
      hint: `Change the layout of the steps, some optoions not available for type "inline"`,
      script: `if (stepsType === 'inline') {
        hide('small');
        hide('percent');
        hide('clickable');
        hide('labelPlacement');
        arraySetValue('steps', 'description', 'hidden', true);
        arraySetValue('steps', 'subtitle', 'hidden', true);
        arraySetValue('steps', 'icon', 'hidden', true);
      } else {
        show('small');
        show('percent');
        show('clickable');
        show('labelPlacement');
        arraySetValue('steps', 'description', 'hidden', false);
        arraySetValue('steps', 'subtitle', 'hidden', false);
        arraySetValue('steps', 'icon', 'hidden', false);
      }`,
      'react-rsuite5': {
        tooltip: true
      }
    },
    makeColums(
      'statusGroup',
      {
        component: 'select',
        name: 'status',
        label: 'Current step status',
        searchable: false,
        cleanable: true,
        options: makeOptions(['finish', 'wait', 'process', 'error']),
        script: `
        if (!status || status === 'process') {
          enable('percent');
        } else {
          disable('percent');
        }
        `
      },
      {
        component: 'input-number',
        name: 'percent',
        label: 'Status percent',
        min: 0,
        max: 100,
        step: 1,
        hint: 'Completion percentage of current step',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        component: 'toggle',
        name: 'small',
        label: 'Small'
      },
      {
        component: 'toggle',
        name: 'clickable',
        label: 'Clickable',
        hint: 'Make steps clickable',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        component: 'select',
        name: 'labelPlacement',
        label: 'Label placement',
        searchable: false,
        cleanable: true,
        options: makeOptions(['horizontal', 'vertical'])
      }
    )
  ],
  'react-mantine': [
    {
      component: 'array',
      name: 'steps',
      label: 'Steps definition',
      maxHeight: 350,
      fields: [
        STEPS_COMMON,
        STEPS_DESCRIPTION,
        STEPS_ICON
      ]
    },
    makeColums(
      MANTINE_COLORS,
      MANTINE_RADIUS,
      MANTINE_SIZE
    ),

    makeColums(
      {
        name: 'iconPosition',
        component: 'select',
        label: 'Icon position',
        options: makeOptions(['left', 'right']),
        block: true,
        hint: 'Icon position relative to the step body, "left" by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'iconSize',
        component: 'input-number',
        label: 'Icon size',
        hint: 'Determines whether steps should wrap to the next line if no space is available, true by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'wrap',
        component: 'toggle',
        label: 'Wrap',
        hint: 'Determines whether steps should wrap to the next line if no space is available, true by default',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'allowStepClick',
        component: 'toggle',
        label: 'Allow step click',
        hint: 'Set to false to disable clicks on step',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'allowStepSelect',
        component: 'toggle',
        label: 'Allow step select',
        hint: 'Should step selection be allowed',
        'react-rsuite5': {
          tooltip: true
        }
      },
      MANTINE_AUTO_CONTRAST
    )



  ],
  defaultValues: {
    'react': {

    },
    'react-rsuite5': {
      'react-rsuite5': {

      },
      steps: [
        { value: 'one', label: 'Step 1' },
        { value: 'two', label: 'Step 2' },
        { value: 'three', label: 'Step 3' }
      ]
    },
    'react-antd': {
      stepsType: 'default',
      steps: [
        { value: 'one', label: 'Step 1' },
        { value: 'two', label: 'Step 2' },
        { value: 'three', label: 'Step 3' }
      ]
    },
    'react-material-ui': {

    },
    'react-bootstrap': {
    },
    'react-mantine': {
      'react-mantine': {
        allowStepClick: true,
        allowStepSelect: true
      }
    }
  }
};

export { manifest };
