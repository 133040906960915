import {
  ANTD_HINT_AS_TOOLTIP,
  ANTD_SIZE,
  COMMON_TRITTICUS,
  COMMON_TRITTICUS_STATUS,
  makeColums,
  RSUITE5_HINT_AS_TOOLTIP,
  COMMON_ARRAY_OPTIONS,
  MUI_DIRECTION,
  MUI_TRITTICUS_1,
  MANTINE_COLORS,
  MANTINE_SIZE,
  MANTINE_RADIUS,
  makeOptions,
  MANTINE_ARRAY_OPTIONS,
  MANTINE_AUTO_CONTRAST
} from '../costants/index.js';

const manifest = {
  label: 'Radio Group',
  category: 'general',
  name: 'radio-group',
  frameworks: [
    'react-antd',
    //'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    ...COMMON_TRITTICUS,
    { ...COMMON_ARRAY_OPTIONS, label: 'Radio group options' },
    COMMON_TRITTICUS_STATUS
  ],
  'react-material-ui': [
    MUI_TRITTICUS_1,
    MUI_DIRECTION
  ],
  'react-rsuite5': [
    makeColums(
      {
        name: 'inline',
        component: 'toggle',
        label: 'Inline radio'
      },
      {
        name: 'appearance',
        component: 'select',
        label: 'Appearance',
        options: [
          { value: 'default', label: 'Default' },
          { value: 'picker', label: 'Picker' }
        ],
        hint: 'Show as default radio control or stacked buttons',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    RSUITE5_HINT_AS_TOOLTIP
  ],
  'react-bootstrap': [
    {
      name: 'inline',
      component: 'toggle',
      label: 'Inline radio'
    },
    {
      name: 'reverse',
      component: 'toggle',
      label: 'Reverse align'
    }
  ],
  'react-antd': [
    makeColums(
      {
        name: 'optionType',
        component: 'select',
        label: 'Option type',
        placeholder: 'Select option type',
        cleanable: true,
        options: [
          { value: 'default', label: 'Default' },
          { value: 'button', label: 'Button' },
        ],
        script: `
        if (values.optionType === 'button') {
          enable('size');
        } else {
          disable('size');
        }
        `
      },
      ANTD_SIZE
    ),
    {
      name: 'inline',
      component: 'toggle',
      label: 'Inline radio'
    },
    ANTD_HINT_AS_TOOLTIP
  ],
  'react-mantine': [
    { ...MANTINE_ARRAY_OPTIONS, label: 'Radio group options' }, // this overrides the common one
    makeColums(
      MANTINE_SIZE,
      MANTINE_RADIUS,
      {
        name: 'labelPosition',
        component: 'select',
        label: 'Label position',
        block: true,
        options: makeOptions(['left', 'right']),
        searchable: false
      }
    ),
    makeColums(
      {
        ...MANTINE_COLORS,
        label: 'Background color'
      },
      {
        ...MANTINE_COLORS,
        name: 'iconColor',
        label: 'Icon color'
      },
      MANTINE_AUTO_CONTRAST
    )
  ],
  'form-generator': {},
  defaultValues: {
    'react-rsuite5': {
      inline: true,
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ],
      'react-rsuite5': {
        appearance: 'default'
      }
    },
    'react-bootstrap': {
      inline: true,
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react': {
      inline: true,
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react-antd': {
      optionType: 'default',
      inline: true,
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react-material-ui': {
      inline: true,
      row: false,
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    }
  }
};

export { manifest };
