
import { faker } from '@faker-js/faker';

import { reduceFields, FRAMEWORKS } from '../../../components/lets-form';

const contains = (str1, str2) => str1.toLowerCase().includes(str2.toLowerCase());

/**
 * extractParam
 * Extract a parameter "name" from any possible framework in the field definition
 * @param {*} field
 * @param {*} name
 * @param {*} defaultValue
 * @returns
 */
const extractParam = (field, name, defaultValue = undefined) => {
  let result = defaultValue;
  FRAMEWORKS.forEach(framework => {
    if (field[framework] && field[framework][name]) {
      result = field[framework][name];
    }
  });
  return result;
};


const generateFakeData = (form, framework) => {
  // collect all fields with type
  const fieldList = reduceFields(
    form.fields,
    (field, accumulator) => [...accumulator, { ...field }],
    []
  );

  const result = {};
  // generate a value for each field
  fieldList.forEach(field => {
    const { name, component, fields, options = [] } = field;
    if (component === 'input-text') {
      if (contains(name, 'name')) {
        result[name] = faker.name.firstName();
      } else {
        result[name] = faker.lorem.sentence();
      }
    } else if (component === 'textarea') {
      result[name] = faker.lorem.paragraph();
    } else if (component === 'toggle' || component === 'checkbox' || component === 'button') {
      result[name] = faker.datatype.boolean();
    } else if (component === 'date' || component === 'datetime') {
      result[name] = faker.datatype.datetime();
    } else if (component === 'time') {
      const d = faker.datatype.datetime().toTimeString();
      result[name] = d.split(' ')[0];
    } else if (component === 'datetime') {
      result[name] = faker.datatype.datetime();
    } else if (component === 'select' || component === 'radio-group' || component === 'radio-tile') {
      result[name] = faker.helpers.arrayElement(options.map(option => option.value));
    } else if (component === 'multiselect' || component === 'checkbox-group') {
      result[name] = (field.options || [])
        .map(option => faker.datatype.boolean() && option && option.value ? option.value : null)
        .filter(Boolean);
    } else if (component === 'rate') {
      const max = extractParam(field, 'max') || extractParam(field, 'count') || 5;
      result[name] = faker.datatype.number({ min: 1, max });
    } else if (component === 'input-number' || component === 'slider') {
      result[name] = faker.datatype.float({
        min: extractParam(field, 'min'),
        max: extractParam(field, 'max'),
        precision: 0.1
      });
    } else if (component === 'array') {
      const num = faker.datatype.number({ min: 1, max: 5 });
      result[name] = [];
      for(let idx = 0; idx < num; idx++) {
        result[name].push(generateFakeData({ fields }));
      }
    } else if (component === 'upload') {
      const multiple = field[framework] && field[framework].multiple;
      if (multiple) {
        const num = faker.datatype.number({ min: 1, max: 5 });
        result[name] = [];
        for(let idx = 0; idx < num; idx++) {
          result[name].push({
            name: faker.system.fileName(),
            size: faker.datatype.number({ min: 1024, max: 1024*512 }),
            blobFile: new Blob()
          });
        }
      } else {
        result[name] = {
          name: faker.system.fileName(),
          size: faker.datatype.number({ min: 1024, max: 1024*512 }),
          blobFile: new Blob()
        };
      }
    }
  });

  return result;
};

export { generateFakeData };
