import React from 'react';
import _ from 'lodash';

import { ArrowDash } from '../../../assets/icons';

import { useFormBuilderStore } from '../../../pages/builder/state';

import { getEditUsageCount } from '../helpers/edit-usage';


const StartHereArrow = () => {

  const form = useFormBuilderStore(state => state.form);
  const hasEdited = useFormBuilderStore(state => state.hasEdited);

  const editUsageCount = getEditUsageCount();

  return (
    <div className="start-here">
      {!_.isEmpty(form.fields) && editUsageCount < 3 && !hasEdited && (
        <>
          <ArrowDash flip={true}/>
            Hover the field with the mouse and reveal the <em>"Edit"</em> button
            and edit the field parameters
        </>
      )}
    </div>
  );
};

export { StartHereArrow };
