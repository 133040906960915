import React, { useRef } from 'react';
import _ from 'lodash';
import { useToaster, Notification } from 'rsuite';

const notify = (toaster, type, header, message) => {
  let computedMessage;

  if (type === 'error') {
    if (message instanceof Error) {
      computedMessage = (
        <div>
          <b>Something went wrong</b>: {message.toString()}
        </div>
      );
    } else if (type === 'error' && _.isObject(message) && !_.isEmpty(message.error)) {
      computedMessage = (
        <div>
          <b>Something went wrong</b>: <span>{message.error}</span>
          {message.code && <span>({message.code})</span>}
        </div>
      );
    } else if (_.isString(message)) {
      computedMessage = (
        <div>
          <b>Something went wrong</b>: <span>{message}</span>
        </div>
      );
    } else if (React.isValidElement(message)) {
      computedMessage = message;
    } else {
      computedMessage = (
        <div>
          <b>Something went wrong</b>: please try again later
        </div>
      );
    }
  } else {
    computedMessage = (
      <div>
        {message}
      </div>
    );
  }
  
  toaster.push(
    <Notification type={type} header={header} closable>
      {computedMessage}
    </Notification>,
    { placement: 'topEnd' }
  );
}


const useNotification = () => {
  const toaster = useToaster();
  const notificationRef = useRef(null);

  if (notificationRef.current) {
    return notificationRef.current;
  } else {
    notificationRef.current = {
      info: (header, message) => notify(toaster, 'info', header, message),
      success: (header, message) => notify(toaster, 'success', header, message),
      error: (header, message) => notify(toaster, 'error', header, message),
      warning: (header, message) => notify(toaster, 'warning', header, message),
      somethingWentWrong: () => notify(
        toaster, 
        'error', 
        'Error',
        <div>
          <b>Somehting went wrong</b>, try again later
        </div> 
      )
    };
    return notificationRef.current
  }
};

export { useNotification };