import React from 'react';

import { Table } from 'rsuite';
const { Cell } = Table;

export const EditableCell = ({ rowData, dataKey, disabled, onChange, ...props }) => {
  const editing = rowData.status === 'EDIT';
  return (
    <Cell {...props} className={editing ? 'table-content-editing' : ''}>
      {editing ? (
        <input
          style={{ marginTop: '-5px' }}
          className="rs-input rs-input-sm"
          disabled={disabled}
          defaultValue={rowData[dataKey]}
          onChange={event => {
            onChange && onChange(rowData.id, dataKey, event.target.value);
          }}
        />
      ) : (
        <span className="table-content-edit-span">{rowData[dataKey]}</span>
      )}
    </Cell>
  );
};
