export const BOOTSTRAP_SIZE = {
  id: 'size',
  label: 'Size',
  name: 'size',
  component: 'select',
  options: [
    { value: 'lg', label: 'Large (lg)' },
    { value: 'sm', label: 'Small (sm)' }
  ],
  'react-rsuite5': {
    cleanable: true
  }
};

export const BOOTSTRAP_VARIANT = {
  label: 'Variant',
  name: 'variant',
  component: 'select',
  options: [
    { value: 'primary', label: 'Primary' },
    { value: 'secondary', label: 'Secondary' },
    { value: 'success', label: 'Success' },
    { value: 'warning', label: 'Warning' },
    { value: 'danger', label: 'Danger' },
    { value: 'info', label: 'Info' },
    { value: 'light', label: 'Light' },
    { value: 'dark', label: 'Dark' },
    { value: 'link', label: 'Link' }
  ],
  'react-rsuite5': {
    cleanable: true
  }
};

export const BOOTSTRAP_PREFIXES = {
  name: 'fixes',
  component: 'columns',
  columns: [
    { name: 'one', size: 1 },
    { name: 'two', size: 1 }
  ],
  fields: {
    one: [
      {
        label: 'Prefix',
        name: 'prefix',
        component: 'input-text'
      }
    ],
    two: [
      {
        label: 'Postfix',
        name: 'postfix',
        component: 'input-text',
        script: `
        if ((prefix != null && prefix !== '') || (postfix != null && postfix !== '')) {
          disable('floatingLabel');
        } else {
          enable('floatingLabel');
        }
        `
      }
    ]
  }
};

export const BOOTSTRAP_FLOATING_LABELS = {
  name: 'floatingLabel',
  component: 'toggle',
  label: 'Floating label',
  hint: 'Enable floating labels with Bootstrap’s textual form fields, not available with prefix/postfix',
  'react-rsuite5': {
    tooltip: true
  }
};
