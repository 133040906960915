import _ from 'lodash';

import { collectNames } from "../../../components/lets-form";

export const getConflictingFields = (form1, form2) => {
  const names1 = collectNames(form1);
  const names2 = collectNames(form2);
  return _.intersection(names1, names2);
};

export const canMergeForms = (form1, form2) => {
  return getConflictingFields(form1, form2).length === 0;
};
