import { useEffect, useState } from 'react';
import fetch from 'cross-fetch';

const useEndpoint = (
  endpoint, 
  {
    onComplete = () => {},
    onError = () => {},
    start = true
  } = {}
) => {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState();
  const accessToken = localStorage.getItem('access_token');

  useEffect(
    () => {
      const run = async () => {
        try {
          setLoading(true);
          setError(undefined);
          setData(undefined);
          const res = await fetch(endpoint, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            }
          });

          if (res.status === 200) {
            const json = await res.json();
            setData(json);
            setDone(true);
            setLoading(false);
            setError(undefined);
            onComplete(json);
          } else {
            setData(undefined);
            setLoading(false);
            setError(res);
            onError(res);
          }
        } catch(e) {
          setError(e);
          setLoading(false);
          onError(e);
          setData(undefined);
        }
      };
      start && run();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );  

  return {
    loading,
    data,
    error,
    done
  };
};

export { useEndpoint };
