import {
  ANTD_HINT_AS_TOOLTIP,
  ANTD_MIN_MAX,
  ANTD_SIZE,
  COMMON_FULL_WIDTH_SIZE,
  COMMON_PLACEHOLDER,
  COMMON_TRITTICUS,
  COMMON_TRITTICUS_STATUS,
  makeColums,
  RSUITE5_ALLOW_CLEAR,
  RSUITE5_HINT_AS_TOOLTIP,
  RSUITE5_MIN_MAX,
  RSUITE5_PREFIXES,
  RSUITE5_SIZE,
  MUI_SIZE_NO_LARGE,
  MUI_VARIANT,
  MUI_COLORS,
  MUI_FLOATING_LABEL,
  MUI_PREFIX_POSTFIX,
  BOOTSTRAP_PREFIXES,
  COMMON_MIN_MAX,
  BOOTSTRAP_SIZE,
  BOOTSTRAP_FLOATING_LABELS,
  MANTINE_SIZE,
  MANTINE_RADIUS,
  MANTINE_VARIANT,
  MANTINE_POINTER,
  MANTINE_ERROR_WITH_STYLE,
  makeOptions,
  MANTINE_SECTIONS,
  ANTD_VARIANT,
  ANTD_PREFIXES
} from '../costants/index.js';

const manifest = {
  label: 'Input Number',
  category: 'general',
  name: 'input-number',
  frameworks: [
    'react-antd',
    'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  description: 'Basic input number field, returns a numeric value',
  fields: [
    ...COMMON_TRITTICUS,
    COMMON_PLACEHOLDER,
    COMMON_TRITTICUS_STATUS
  ],
  'react-rsuite5': [
    makeColums(
      RSUITE5_SIZE,
      []
    ),
    COMMON_FULL_WIDTH_SIZE,
    RSUITE5_ALLOW_CLEAR,
    RSUITE5_MIN_MAX,
    RSUITE5_PREFIXES,
    RSUITE5_HINT_AS_TOOLTIP
  ],
  'react-material-ui': [
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      MUI_SIZE_NO_LARGE,
      {
        ...MUI_VARIANT,
        script: `
        if (variant === 'filled' || variant === 'standard') {
          enable('disableUnderline');
        } else {
          disable('disableUnderline');
        }
        `
      },
      MUI_COLORS
    ),
    makeColums(
      MUI_FLOATING_LABEL,
      {
        name: 'disableUnderline',
        component: 'toggle',
        label: 'Disable underline',
        hint: 'Disable underline in "filled" and "standard" variant',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    MUI_PREFIX_POSTFIX
  ],
  'react-antd': [
    makeColums(
      ANTD_SIZE,
      ANTD_VARIANT
    ),
    ANTD_MIN_MAX,
    ANTD_PREFIXES,
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      {
        name: 'showControls',
        label: 'Show controls',
        hint: 'Whether to show +- controls, or set custom arrows icon',
        component: 'toggle',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'stringMode',
        label: 'String mode',
        hint: 'Set value as string to support high precision decimals. Will return string value by onChange',
        component: 'toggle',
        'react-rsuite5': {
          tooltip: true
        }
      },
      ANTD_HINT_AS_TOOLTIP
    )
  ],
  'react-bootstrap': [
    COMMON_FULL_WIDTH_SIZE,
    BOOTSTRAP_SIZE,
    BOOTSTRAP_PREFIXES,
    BOOTSTRAP_FLOATING_LABELS,
    COMMON_MIN_MAX
  ],
  'react': [
    COMMON_MIN_MAX,
    COMMON_FULL_WIDTH_SIZE
  ],
  'react-mantine': [
    makeColums(
      MANTINE_SIZE,
      MANTINE_RADIUS,
      MANTINE_VARIANT
    ),
    COMMON_MIN_MAX,
    COMMON_FULL_WIDTH_SIZE,
    MANTINE_SECTIONS,
    makeColums(
      MANTINE_POINTER,
      MANTINE_ERROR_WITH_STYLE
    ),
    makeColums(
      {
        name: 'valueIsNumericString',
        component: 'toggle',
        label: 'Value is numeric string',
        hint: 'If value is passed as string representation of numbers (unformatted) and number is used in any format props like in prefix or suffix in numeric format and format prop in pattern format then this should be passed as true. false by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'hideControls',
        component: 'toggle',
        label: 'Hide controls'
      }
    ),
    makeColums(
      {
        name: 'allowLeadingZeros',
        component: 'toggle',
        label: 'Allow leading zero'
      },
      {
        name: 'allowNegative',
        component: 'toggle',
        label: 'Allow negative'
      }
    ),
    makeColums(
      {
        name: 'clampBehavior',
        component: 'select',
        label: 'Clamp behaviour',
        searchable: false,
        options: makeOptions(['none', 'blur', 'strict']),
        hint: 'Controls how value is clamped, strict – user is not allowed to enter values that are not in [min, max] range, blur – user is allowed to enter any values, but the value is clamped when the input loses focus (default behavior), none – lifts all restrictions, [min, max] range is applied only for controls and up/down keys',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'startValue',
        component: 'input-number',
        label: 'Start value',
        hint: 'Value set to the input when increment/decrement buttons are clicked or up/down arrows pressed if the input is empty, 0 by default',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'allowDecimal',
        component: 'toggle',
        label: 'Allow decimal',
        script: `if (allowDecimal) {
          enable('decimalSeparator');
        } else {
          disable('decimalSeparator');
        }`
      },
      {
        name: 'decimalSeparator',
        component: 'input-text',
        label: 'Decimal separator',
        width: 200,
        hint: 'Character used as a decimal separator, "." by default',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'fixedDecimalScale',
        component: 'toggle',
        label: 'Fixed decimal scale',
        hint: 'If set, 0s are added after decimalSeparator to match given decimalScale. false by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'decimalScale',
        component: 'input-number',
        label: 'Decimal scale',
        min: 1,
        max: 100,
        step: 1,
        hint: 'Limits the number of digits that can be entered after the decimal point',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'thousandsGroupStyle',
        component: 'select',
        label: 'Thousands style',
        options: makeOptions(['none', 'thousand', 'lakh', 'wan']),
        searchable: false,
        hint: 'Defines the thousand grouping style',
        'react-rsuite5': {
          tooltip: true
        },
        script: `if (thousandsGroupStyle === 'none') {
          disable('thousandSeparator');
        } else {
          enable('thousandSeparator');
        }`
      },
      {
        name: 'thousandSeparator',
        component: 'input-text',
        label: 'Thousand separator',
        width: 200,
        hint: 'A character used to separate thousands',
        'react-rsuite5': {
          tooltip: true
        }
      }
    )
  ],
  defaultValues: {
    'react-mantine': {
      'react-mantine': {
        withErrorStyles: true,
        allowDecimal: true,
        allowNegative: true
      }
    },
    'react-antd': {
      'react-antd': {
        showControls: true,
        stringMode: false,
        bordered: true
      },
      step: 1
    },
    'react-material-ui': {
      'react-material-ui': {
        variant: 'standard',
        floatingLabel: true
      }
    },
    'react-bootstrap': {
      'react-bootstrap': {
        floatingLabel: true
      }
    }
  }
};

export { manifest };