import React, { useCallback } from 'react';
import { Whisper, Tooltip } from 'rsuite';
import ShareOutlineIcon from '@rsuite/icons/ShareOutline';
import RemindRoundIcon from '@rsuite/icons/RemindRound';


import Connectors from '../../../connectors';

import './select-connector-modal.scss';

const ConnectorItem = ({ connector, onSelect }) => {

  const handleSelect = useCallback(
    () => onSelect(connector),
    [connector, onSelect]
  );

  return (
    <div className="connector" onClick={handleSelect}>
      {connector.icon && (
        <img className="icon" src={connector.icon} width="16" alt={connector.displayName} />
      )}
      {!connector.icon && (
        <ShareOutlineIcon width={16} height={16} />
      )}
      <div className="name">{connector.displayName}</div>
      <div className="description">{connector.description}</div>
      {!connector.cors && (
        <div className="warning-cors">
          <Whisper
            trigger="hover"
            speaker={<Tooltip>This service doesn't support CORS, a proxy is requested in order to work</Tooltip>}
            placement="top"
          >
            <RemindRoundIcon color="orange" />
          </Whisper>
        </div>
      )}
    </div>
  )
};



const SelectConnectorModal = ({
  onSubmit
}) => {

  return (
    <div className="lf-select-connector-modal">
      <div className="description">
        Select a connector to send the form payload to. Some services doesn't support CORS, which means that API calls
        directly from browsers are not supported (unless it's used a CORS proxy).
      </div>
      <div className="connectors">
        {Connectors.map(connector => (
          <ConnectorItem
            connector={connector}
            onSelect={onSubmit}
            key={connector.name}
          />
        ))}
      </div>
    </div>
  )
};

export { SelectConnectorModal };
