import {
  ANTD_HINT_AS_TOOLTIP,
  COMMON_HIDDEN,
  COMMON_TRITTICUS,
  RSUITE5_HINT_AS_TOOLTIP
} from '../costants/index.js';

const manifest = {
  label: 'Placeholder',
  category: 'layout',
  name: 'placeholder',
  frameworks: [
    'react-antd',
    'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  description: 'Generic placeholder for an image inside the form, supports basic parameters like width, height and margin',
  fields: [
    ...COMMON_TRITTICUS,
    {
      name: 'text',
      component: 'input-text-i18n',
      textarea: true,
      fullWidth: true,
      label: 'Placeholder content',
      rows: 10,
      hint: 'Supports markdown (i.e., **bold**, _italics_)'
    },
    COMMON_HIDDEN
  ],
  'react-material-ui': [],
  'react-rsuite5': [
    RSUITE5_HINT_AS_TOOLTIP
  ],
  'react-antd': [
    ANTD_HINT_AS_TOOLTIP
  ],
  'react-mantine': [],
  'form-generator': {
    //hideValidation: true
  }
};

export { manifest };
