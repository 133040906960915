/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LogoExtended } from '../../assets/logo-large';
import { useTracking } from '../../hooks';
import { SignInUpForm } from '../../components';

import './login.scss';

const QUOTES = [
  'Always code as if the guy who ends up maintaining your code will be a violent psychopath who knows where you live',
  `If you run into an asshole in the morning, you ran into an asshole. If you run into assholes all day, you're the asshole.`,
  `The first principle is that you must not fool yourself—and you are the easiest person to fool.`,
  `Rage Against the Machine never specified what type of machine they were furious with but I reckon it was probably a printer`,
  `Electric power didn't come from the continuos improvement of candles`,
  `Without data you'r just another guy with an opinion`,
  `There are only two kinds of languages: the ones people complain about and the ones nobody uses.`,
  `Make it idiot-proof, and someone will make a better idiot`,
  `The computer was born to solve problems that did not exist before.`,
  `Real programmers don't comment their code. If it was hard to write, it should be hard to understand.`,
  `The purpose of software engineering is to control complexity, not to create it.`,
  `Code is like humor. When you have to explain it, it’s bad`,
  `Sometimes it pays to stay in bed on Monday, rather than spending the rest of the week debugging Monday’s code.`,
  `Perfection is achieved not when there is nothing more to add, but rather when there is nothing more to take away.`
];

const LoginPage = () => {
  useTracking();

  const [params] = useSearchParams();
  const navigate = useNavigate();

  const [randomQuote] = useState(_.random(0, QUOTES.length - 1));
  const [randomImage] = useState(_.random(1, 5));
  
  const handleLogin = useCallback(
    () => {
      navigate(params.get('redirect') || '/dashboard');
    },
    [navigate, params]
  );

  return (
    <div className="lf-login-page">
      <div className="left">
        <div className="logo">
          <LogoExtended width={300} height={90} />
        </div>
        <SignInUpForm 
          onLogin={handleLogin}
        />
        <div className="version">
          v{process.env.REACT_APP_VERSION}
        </div>
      </div>
      <div className={'right bg-' + randomImage}>
        <div className="quote">
          {QUOTES[randomQuote]}
        </div>
      </div>
    </div>
  );
};

export { LoginPage }
