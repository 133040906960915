import { LOCALES } from './locales';

export const RSUITE5_PREFIXES = {
  name: 'fixes',
  component: 'columns',
  columns: [
    { name: 'one', size: 1 },
    { name: 'two', size: 1 }
  ],
  fields: {
    one: [
      {
        label: 'Prefix',
        name: 'prefix',
        component: 'input-text',
      },
      {
        name: 'inside',
        component: 'checkbox',
        label: 'Inside',
        hint: 'Show prefix and postfix inside the input box',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ],
    two: [
      {
        label: 'Postfix',
        name: 'postfix',
        component: 'input-text',
      }
    ]
  }
};

export const RSUITE5_MIN_MAX = {
  name: 'limits',
  component: 'three-columns',
  leftFields: [
    {
      label: 'Min',
      name: 'min',
      component: 'input-number',
    }
  ],
  centerFields: [
    {
      label: 'Step',
      name: 'step',
      component: 'input-number',
    }
  ],
  rightFields: [
    {
      label: 'Max',
      name: 'max',
      component: 'input-number',
    }
  ]
};

export const RSUITE5_WIDTH = {
  name: 'width',
  label: 'Width',
  component: 'input-number',
  allowClear: true,
  min: 1,
  step: 10
};

export const RSUITE5_SIZE = {
  id: 'size',
  label: 'Size',
  name: 'size',
  component: 'select',
  cleanable: true,
  block: true,
  options: [
    { value: 'lg', label: 'Large (lg)' },
    { value: 'md', label: 'Medium (md)' },
    { value: 'sm', label: 'Small (sm)' },
    { value: 'xs', label: 'Extra Small (xs)' }
  ]
};

export const RSUITE5_COLOR = {
  label: 'Color',
  name: 'color',
  component: 'select',
  block: true,
  options: [
    { value: 'red', label: 'Red' },
    { value: 'orange', label: 'Orange' },
    { value: 'yellow', label: 'Yellow' },
    { value: 'green', label: 'Green' },
    { value: 'cyan', label: 'Cyan' },
    { value: 'blue', label: 'Blue' },
    { value: 'violet', label: 'Violet' }
  ]
};

export const RSUITE_BLOCK = {
  id: 'select-rsuite-1',
  label: 'Display as block',
  name: 'block',
  component: 'toggle',
  hint: 'Only in fluid layout',
  'react-rsuite5': {
    tooltip: true
  }
};

export const RSUITE_CLEANABLE = {
  id: 'select-rsuite-3',
  label: 'Cleanable',
  name: 'cleanable',
  component: 'toggle'
};

export const RSUITE5_PLACEMENT = {
  label: 'Placement',
  name: 'placement',
  component: 'select',
  block: true,
  options: [
    { value: 'topStart', label: 'topStart' },
    { value: 'topEnd', label: 'topEnd' },
    { value: 'leftStart', label: 'leftStart' },
    { value: 'rightStart', label: 'rightStart' },
    { value: 'leftEnd', label: 'leftEnd' },
    { value: 'rightEnd', label: 'rightEnd' },
    { value: 'bottomStart', label: 'bottomStart' },
    { value: 'bottomEnd', label: 'bottomEnd' },
    { value: 'auto', label: 'auto' },
    { value: 'autoVerticalStart', label: 'autoVerticalStart' },
    { value: 'autoVerticalEnd', label: 'autoVerticalEnd' },
    { value: 'autoHorizontalStart', label: 'autoHorizontalStart' },
    { value: 'autoHorizontalEnd', label: 'autoHorizontalEnd' }
  ]
};

export const RSUITE5_APPEARANCE = {
  label: 'Appearance',
  name: 'appearance',
  component: 'select',
  cleanable: true,
  block: true,
  placeholder: 'Select appearance',
  options: [
    { value: 'default', label: 'Default' },
    { value: 'subtle', label: 'Subtle' }
  ]
};

export const RSUITE5_APPEARANCE_ALL = {
  label: 'Appearance',
  name: 'appearance',
  component: 'select',
  cleanable: true,
  block: true,
  placeholder: 'Select appearance',
  options: [
    { value: 'default', label: 'Default' },
    { value: 'primary', label: 'Primary' },
    { value: 'subtle', label: 'Subtle' },
    { value: 'link', label: 'Link' },
    { value: 'ghost', label: 'Ghost' }
  ]
};

export const RSUITE5_HINT_AS_TOOLTIP = {
  label: 'Hint as tooltip',
  name: 'tooltip',
  component: 'toggle',
  hint: 'Show hint as tooltip next to label',
  'react-rsuite5': {
    tooltip: true
  }
};

export const RSUITE5_ALLOW_CLEAR = {
  name: 'allowClear',
  label: 'Clear button',
  component: 'toggle'
};

const localesOptions = LOCALES.map(key => ({
  key,
  label: key.replace(/([a-z]{1,})([A-Z]{1,})/,'$1_$2'),
  value: key
}));

export const RSUITE5_LOCALES = {
  name: 'locale',
  label: 'Locale',
  component: 'select',
  options: localesOptions,
  cleanable: true
};
