import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const ShowJson = ({ value }) => {
  return (
    <div className="show-code">
      <div style={{ paddingBottom: '10px' }}>
        This is the JSON representation of the selected field.
        Keys of the object below can be used in JavaScript script to create dinamyc forms (see <em>"Form settings"</em> panel)
      </div>
      <SyntaxHighlighter
        className="syntax"
        showLineNumbers={true}
        language="javascript"
        style={nightOwl}
        customStyle = {{
          marginTop: '0px',
          marginBottom: '0px'
        }}
      >
        {value.json}
      </SyntaxHighlighter>
    </div>
  )
};

export { ShowJson };
