import {
  ANTD_SIZE,
  ANTD_HINT_AS_TOOLTIP,
  ANTD_COUNT_CHARS,
  ANTD_ALLOW_CLEAR,
  BOOTSTRAP_SIZE,
  COMMON_PLACEHOLDER,
  COMMON_TRITTICUS_STATUS,
  makeColums,
  RSUITE5_HINT_AS_TOOLTIP,
  ANTD_MAX_LENGTH,
  COMMON_TRITTICUS,
  COMMON_FULL_WIDTH_SIZE,
  MUI_SIZE_NO_LARGE,
  MUI_VARIANT,
  MUI_COLORS,
  MUI_FLOATING_LABEL,
  RSUITE5_SIZE,
  MANTINE_SIZE,
  MANTINE_RADIUS,
  MANTINE_VARIANT,
  MANTINE_POINTER,
  MANTINE_ERROR_WITH_STYLE,
  MANTINE_SECTIONS,
  ANTD_VARIANT
} from '../costants/index.js';

const manifest = {
  label: 'Textarea',
  category: 'general',
  name: 'textarea',
  frameworks: [
    'react-antd',
    'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  // description: 'Basic input text',
  fields: [
    ...COMMON_TRITTICUS,
    COMMON_PLACEHOLDER,
    COMMON_TRITTICUS_STATUS
  ],
  'react': [
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      {
        name: 'rows',
        label: 'Rows',
        component: 'input-number',
        min: 1,
        step: 1
      },
      []
    )
  ],
  'react-rsuite5': [
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      RSUITE5_SIZE,
      {
        name: 'rows',
        label: 'Rows',
        component: 'input-number',
        min: 1,
        max: 100,
        step: 1
      }
    ),
    RSUITE5_HINT_AS_TOOLTIP
  ],
  'react-bootstrap': [
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      BOOTSTRAP_SIZE,
      {
        name: 'rows',
        component: 'input-number',
        label: 'Rows',
        min: 1,
        step: 1
      }
    )
  ],
  'react-material-ui': [
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      MUI_SIZE_NO_LARGE,
      {
        ...MUI_VARIANT,
        script: `
        if (variant === 'filled' || variant === 'standard') {
          enable('disableUnderline');
        } else {
          disable('disableUnderline');
        }
        `
      },
      MUI_COLORS
    ),
    makeColums(
      MUI_FLOATING_LABEL,
      {
        name: 'disableUnderline',
        component: 'toggle',
        label: 'Disable underline',
        hint: 'Disable underline in "filled" and "standard" variant',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'maxRows',
        component: 'input-number',
        label: 'Max rows',
        min: 0,
        allowClear: true
      }
    ),
  ],
  'react-antd': [
    makeColums(
      ANTD_SIZE,
      ANTD_VARIANT
    ),
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      ANTD_COUNT_CHARS,
      {
        name: 'autoSize',
        label: 'Auto size',
        component: 'toggle'
      },
      ANTD_MAX_LENGTH
    ),
    makeColums(
      ANTD_ALLOW_CLEAR,
      ANTD_HINT_AS_TOOLTIP,
      []
    )
  ],
  'react-mantine': [
    makeColums(
      MANTINE_SIZE,
      MANTINE_RADIUS,
      MANTINE_VARIANT
    ),
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      {
        name: 'autosize',
        component: 'toggle',
        label: 'Autosize',
        script: `if (autosize) {
          enable('minRows');
          enable('maxRows');
        } else {
          disable('minRows');
          disable('maxRows');
        }`
      },
      {
        name: 'minRows',
        component: 'input-number',
        label: 'Min Rows'
      },
      {
        name: 'maxRows',
        component: 'input-number',
        label: 'Max Rows'
      }
    ),
    MANTINE_SECTIONS,
    makeColums(
      MANTINE_POINTER,
      MANTINE_ERROR_WITH_STYLE
    )
  ],
  defaultValues: {
    'react-mantine': {
      'react-mantine': {
        withErrorStyles: true
      }
    },
    'react-antd': {
      'react-antd': {
        bordered: true,
        fullWidth: true
      }
    },
    'react': {
      'react': {
        fullWidth: true
      }
    },
    'react-bootstrap': {
      'react-bootstrap': {
        fullWidth: true
      }
    },
    'react-rsuite5': {
      'react-rsuite5': {
        fullWidth: true
      }
    },
    'react-material-ui': {
      'react-material-ui': {
        fullWidth: true
      }
    }
  }
};

export { manifest };