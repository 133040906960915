import React from 'react';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import { microdown } from '../micro-down';

const MarkdownText = ({ children, className }) => {
  if (!isEmpty(children)) {
    return (
      <div
        className={classNames('lf-markdown-text', className)}
        dangerouslySetInnerHTML={{ __html: microdown.parse(children) }}
      />
    );
  } else {
    return (
      <div
        className={classNames('lf-markdown-text', className)}
      />
    );
  }
};

export { MarkdownText };
