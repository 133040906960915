import { makeColums, makeOptions } from './helpers.js';
import { LOCALES } from './locales.js';

export const MUI_COLORS = {
  component: 'select',
  name: 'color',
  placeholder: 'Select color',
  label: 'Color',
  fullWidth: true,
  options: [
    { value: 'error', label: 'Error' },
    { value: 'info', label: 'Info' },
    { value: 'primary', label: 'Primary' },
    { value: 'secondary', label: 'Secondary' },
    { value: 'success', label: 'Success' },
    { value: 'warning', label: 'Warning' }
  ],
  'react-rsuite5': {
    cleanable: true
  }
};

export const MUI_LABEL_PLACEMENT = {
  component: 'select',
  name: 'labelPlacement',
  label: 'Label placement',
  fullWidth: true,
  options: [
    { value: 'top', label: 'Top' },
    { value: 'start', label: 'Start' },
    { value: 'bottom', label: 'Bottom' },
    { value: 'end', label: 'End' }
  ],
  'react-rsuite5': {
    cleanable: true
  }
};

export const MUI_SIZE = {
  component: 'select',
  name: 'size',
  placeholder: 'Select size',
  label: 'Size',
  fullWidth: true,
  options: [
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'large', label: 'Large' }
  ],
  'react-rsuite5': {
    cleanable: true
  }
};

export const MUI_SIZE_NO_LARGE = {
  component: 'select',
  name: 'size',
  placeholder: 'Select size',
  label: 'Size',
  fullWidth: true,
  options: [
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' }
  ],
  'react-rsuite5': {
    cleanable: true
  }
};

export const MUI_TRITTICUS_1 = {
  component: 'columns',
  columns: [
    { name: 'one', size: 1 },
    { name: 'two', size: 1 },
    { name: 'three', size: 1 }
  ],
  fields: {
    one: [ MUI_SIZE ],
    two: [ MUI_COLORS ],
    three: [ MUI_LABEL_PLACEMENT ]
  }
};

export const MUI_VARIANT = {
  label: 'Variant',
  name: 'variant',
  component: 'select',
  cleanable: true,
  fullWidth: true,
  placeholder: 'Select variant',
  options: [
    { value: 'outlined', label: 'Outlined' },
    { value: 'filled', label: 'Filled' },
    { value: 'standard', label: 'Standard' }
  ]
};

export const MUI_BUTTON_VARIANT = {
  label: 'Variant',
  name: 'variant',
  component: 'select',
  cleanable: true,
  fullWidth: true,
  placeholder: 'Select variant',
  options: [
    { value: 'contained', label: 'Contained' },
    { value: 'outlined', label: 'Outlined' },
    { value: 'text', label: 'Text' }
  ]
};

export const MUI_DISABLE_RIPPLE = {
  label: 'Disable ripple',
  name: 'disableRipple',
  component: 'toggle',
  hint: 'Disable CSS ripple effect',
  'react-rsuite5': {
    tooltip: true
  }
};

export const MUI_FULL_SIZE = {
  component: 'toggle',
  name: 'fullWidth',
  label: 'Full width'
};

export const MUI_FLOATING_LABEL = {
  name: 'floatingLabel',
  label: 'Floating label',
  component: 'toggle',
  hint: 'Show floating label for the control',
  'react-rsuite5': {
    tooltip: true
  }
};

const localesOptions = LOCALES.map(key => ({
  key: key.substring(0, 2),
  label: key.replace(/([a-z]{1,})([A-Z]{1,})/,'$1_$2'),
  value: key.substring(0, 2)
}));

export const MUI_LOCALES = {
  name: 'locale',
  label: 'Locale',
  component: 'select',
  options: localesOptions,
  cleanable: true
};

export const MUI_PREFIX_POSTFIX = makeColums(
  {
      label: 'Prefix',
      name: 'prefix',
      component: 'input-text',
  },
  {
    label: 'Postfix',
    name: 'postfix',
    component: 'input-text',
  }
);

export const MUI_DIRECTION = {
  component: 'toggle',
  name: 'row',
  label: 'Show as row'
};

export const MUI_DATE_FORMAT = {
  name: 'format',
  component: 'input-text',
  label: 'Format',
  hint: 'Check https://date-fns.org library for available formats'
};

export const MUI_FORMAT_DENSITY = {
  name: 'formatDensity',
  component: 'select',
  label: 'Format density',
  options: makeOptions(['dense', 'spacious']),
  fullWidth: true,
  hint: 'Density of the format when rendered in the input. Setting formatDensity to "spacious" will add a space before and after each /, - and . character',
  'react-rsuite5': {
    tooltip: true
  }
}