import { Dropdown } from 'rsuite';
import _ from 'lodash';

import { definePlugin } from '../../code-plug';

definePlugin('builder-toolbar-separator', ({ registerView }, params) => {
  registerView(
    'form-builder-toolbar',
    () => <Dropdown.Separator />,
    {
      key: _.uniqueId('toolbar-separator'),
      ...params
    }
  );
});
