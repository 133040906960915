import { useEffect, useMemo  } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useCurrentUser } from './current-user';



export const useTracking = ({ title } = {}) => {
  const posthog = usePostHog();
  const { user } = useCurrentUser();

  useEffect(() => {
    if (user) {
      posthog?.identify(user.id, {
          email: user.email,
      });
    }
  }, [posthog, user])

  const obj = useMemo(
    () => ({
      sendEvent: (action, props) => {
        if (process.env.NODE_ENV !== 'development') {
          const res = posthog?.capture(action, props);
          console.info('[LetsForm] Event: ' + action, props, res?.uuid);
        } else {
          console.info('[LetsForm] Event: ' + action, props);
        }
      }
    }),
    [posthog]
  );

  return obj;
};
