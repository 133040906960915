import React, { useCallback, useState } from 'react';
import Button from 'rsuite/Button';
import { saveAs } from 'file-saver';
import SelectPicker from 'rsuite/SelectPicker';
import _ from 'lodash';

import { useTracking } from '../../../hooks';
import Frameworks from '../../../frameworks';
import { CopyAndPasteButton, TextareaCopyClipboard } from '../../../components';
import { isEmptyForm } from '../../../components/lets-form';
import { sizeOfObj } from '../../../pages/builder/helpers';
import { getPackageJsonDependencies } from '../../../components/code-generation/get-package-dependencies';
import { generateJson } from '../../../components/code-generation';
import { useRegion } from '../../../code-plug';

import { ButtonExportCRA } from './export-button-cra';
import { ButtonExportVite } from './export-button-vite';
import { ButtonExportImportMap } from './export-button-import-map';

import './export-panel.scss';

const OPTIONS_DEPS_TYPE = [
  { value: 'command', label: 'npm command' },
  { value: 'package', label: 'package.json' }
];
const OPTIONS_DEPS_FILTER = [
  { value: 'framework', label: 'framework' },
  { value: 'all', label: 'all' }
];
const DEPS_EXCLUDE = ['react', 'react-dom', 'lets-form'];

const generateNpmInstall = (deps, filter) => {
  const npmCommand = Object.keys(deps)
    .filter(lib => !filter || !DEPS_EXCLUDE.includes(lib));

  return npmCommand
    .map((lib, idx) => {
      if (idx === 0) {
        return 'npm i ' + lib;
      } else if (idx === (npmCommand.length - 1)) {
        return  '  ' + lib + ' -D';
      }
      return '  ' + lib;
    })
    .join(' \\\n');
};

const generatePackageJson = (deps, filter) => {
  return JSON.stringify(filter ? _.omit(deps, DEPS_EXCLUDE) : deps, null, 2);
};


const ExportPanel = ({
  onShowCode = () => {},
  framework,
  form,
  locale
}) => {
  const { sendEvent } = useTracking();
  const [formSource] = useState(generateJson(form, framework));
  const [exportType, setExportType] = useState('command');
  const [exportFilter, setExportFilter] = useState('framework');
  const renderButtons = useRegion('form-builder-tab-export-buttons');
  const emptyForm = isEmptyForm(form);
  const disabled = emptyForm;

  const handleChangeDepsType = useCallback(
    value => setExportType(value),
    []
  );
  const handleChangeDepsFilter = useCallback(
    value => setExportFilter(value),
    []
  );
  const handleCopyToClipboard = useCallback(
    () => sendEvent('form.copy-clipboard'),
    [sendEvent]
  );
  const handleDownload = useCallback(
    () => {
      sendEvent('form.download');
      const str = generateJson(form, framework);
      const blob = new Blob(
        [str],
        { type: 'text/plain;charset=utf-8' }
      );
      saveAs(blob, 'form.json');
    },
    [form, framework, sendEvent]
  );


  if (!Frameworks[framework]) {
    return (
      <div>This framework doesn't exist</div>
    );
  }

  const deps = getPackageJsonDependencies({ form, locale, framework });

  return (
    <div className="lf-export-panel">
      <div className="legend">
        Export the form in native code for framework <b>{Frameworks[framework].label}</b>.
      </div>

      <p>
        <div>This LetsForm export needs these libraries to be installed in your project</div>
        <div className="menus">
          <SelectPicker
            size="sm"
            data={OPTIONS_DEPS_TYPE}
            searchable={false}
            cleanable={false}
            value={exportType}
            appearance="subtle"
            placement="bottomEnd"
            onChange={handleChangeDepsType}
          />
          <SelectPicker
            size="sm"
            data={OPTIONS_DEPS_FILTER}
            searchable={false}
            cleanable={false}
            value={exportFilter}
            appearance="subtle"
            placement="bottomEnd"
            onChange={handleChangeDepsFilter}
          />
        </div>
        <TextareaCopyClipboard>
          {exportType === 'command' ?
            generateNpmInstall(deps, exportFilter === 'framework') : generatePackageJson(deps, exportFilter === 'framework')}
        </TextareaCopyClipboard>
      </p>

      <table border={0} className="buttons">
        {renderButtons({ disabled })}
        <tr>
          <td className="button" valign="top">
            <Button
              size="sm"
              appearance="primary"
              disabled={disabled}
              onClick={handleDownload}
              >Export to JSON</Button>
          </td>
          <td className="legend" valign="top">
            Download JSON source of the current form (<span className="file-size">{sizeOfObj(form)}</span>)
          </td>
        </tr>
        <tr>
          <td className="button" valign="top">
            <CopyAndPasteButton
              disabled={disabled}
              label="Copy to clipboard"
              appearance="primary"
              size="sm"
              float={null}
              notification="Form source succesfully copied to clipboard."
              text={formSource}
              onClick={handleCopyToClipboard}
            />
          </td>
          <td className="legend" valign="top">
            Copy in the clipboard the JSON source of the current form (<span className="file-size">Size: {sizeOfObj(form)}</span>)
          </td>
        </tr>
        <tr>
          <td className="button" valign="top">
            <ButtonExportCRA
              disabled={disabled}
              framework={framework}
              form={form}
              locale={locale}
            />
          </td>
          <td className="legend" valign="top">
            Export current form to Create React App sample project
          </td>
        </tr>
        <tr>
          <td className="button" valign="top">
            <ButtonExportVite
              disabled={disabled}
              framework={framework}
              form={form}
              locale={locale}
            />
          </td>
          <td className="legend" valign="top">
            Export current form to Vite sample project
          </td>
        </tr>
        <tr>
          <td className="button" valign="top">
            <ButtonExportImportMap
              disabled={disabled}
              framework={framework}
              form={form}
              locale={locale}
            />
          </td>
          <td className="legend" valign="top">
            Export current form to HTML file with Import Maps
          </td>
        </tr>
      </table>
    </div>
  );
};

export { ExportPanel };
