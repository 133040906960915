import {
  ANTD_ALLOW_CLEAR,
  ANTD_HINT_AS_TOOLTIP,
  COMMON_TRITTICUS,
  COMMON_TRITTICUS_STATUS,
  makeColums,
  MUI_SIZE,
  RSUITE5_COLOR,
  RSUITE5_HINT_AS_TOOLTIP,
  RSUITE5_SIZE,
  MANTINE_SIZE,
  MANTINE_COLORS
} from '../costants/index.js';

const manifest = {
  label: 'Rate',
  category: 'general',
  name: 'rate',
  description: 'Five stars rating field',
  frameworks: [
    'react-antd',
    //'react',
    //'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    ...COMMON_TRITTICUS,
    COMMON_TRITTICUS_STATUS
  ],
  'react-rsuite5': [
    makeColums(
      {
        name: 'allowHalf',
        label: 'Allow half votes',
        component: 'toggle'
      },
      {
        name: 'cleanable',
        label: 'Can be cleared',
        component: 'toggle'
      },
      {
        name: 'vertical',
        label: 'Vertical',
        component: 'toggle',
        hint: 'Vertical direction when half selected',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'max',
        label: 'Max rate (stars)',
        component: 'input-number',
        min: 2
      },
      RSUITE5_COLOR,
      RSUITE5_SIZE
    ),
    RSUITE5_HINT_AS_TOOLTIP
  ],
  'react-antd': [
    makeColums(
      {
        name: 'count',
        label: 'Stars count',
        component: 'input-number',
        min: 1,
        step: 1
      },
      {
        name: 'allowHalf',
        label: 'Allow half',
        component: 'toggle',
        hint: 'Allow half votes',
        'react-rsuite5': {
          tooltip: true
        }
      },
      { ...ANTD_ALLOW_CLEAR, label: 'Allow clear' }
    ),
    {
      component: 'array',
      name: 'tooltips',
      label: 'Tooltips',
      hint: 'Customize tooltip for each value',
      fields: [
        {
          name: 'tooltip',
          component: 'input-text',
          placeholder: 'tooltip',
          size: 'sm'
        }
      ],
      'react-rsuite5': {
        tooltip: true
      }
    },
    ANTD_HINT_AS_TOOLTIP
  ],
  'react-material-ui': [
    makeColums(
      MUI_SIZE,
      {
        name: 'precision',
        label: 'Precision',
        component: 'select',
        cleanable: true,
        options: [
          { value: 0.1, label: '+/- 0.1' },
          { value: 0.2, label: '+/- 0.2' },
          { value: 0.5, label: '+/- 0.5' },
          { value: 1, label: '+/- 1' }
        ],
        hint: 'The minimum increment value change allowed',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'max',
        label: 'Max rate (stars)',
        component: 'input-number',
        min: 1
      }
    )
  ],
  'react-mantine': [
    makeColums(
      MANTINE_SIZE,
      MANTINE_COLORS
    ),
    makeColums(
      {
        name: 'count',
        component: 'input-number',
        label: 'Count',
        min: 1,
        step: 1,
        hint: 'Number of controls, 5 by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'fractions',
        component: 'input-number',
        label: 'Fractions',
        min: 1,
        step: 1,
        hint: 'Number of fractions each item can be divided into, 1 by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'highlightSelectedOnly',
        component: 'toggle',
        label: 'Highlight selected',
        hint: 'If set, only the selected symbol changes to full symbol when selected, false by default',
        'react-rsuite5': {
          tooltip: true
        }
      }
    )
  ],
  defaultValues: {
    'react-mantine': {
      count: 5,
      fractions: 1
    },
    'react-antd': {
      count: 5
    },
    'react-rsuite5': {
      max: 5
    },
    'react-material-ui': {
      max: 5
    }
  }
};

export { manifest };