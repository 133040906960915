import { gql } from '@apollo/client';

export const DELETE_PLAYGROUND_FORM = gql`
mutation($id: ID!) {
  delete_playground_forms_item(id: $id) {
    id
  }
}`;

export const CREATE_PLAYGROUND_FORM = gql`
mutation($playgroundForm: create_playground_forms_input!) {
  playgroundForm: create_playground_forms_item(data: $playgroundForm) {
    id,
    formId,
    name,
    shareCode,
    height,
    date_created
  }
}
`;

export const GET_FORM = gql`
query ($formId: ID!) {
  form: forms_by_id(id: $formId) {
    id,
    date_created,
    name,
    versions(sort: "-id", limit: 1) {
      id,
      date_created,
      json
    }
  }
}`;

export const GET_PROJECTS = gql`
query {
  projects(sort: "name") {
    id,
    name
  }
}`;

export const GET_PLAYGROUND = gql`
query($shareCode: String!) {
  playground_forms(filter: {
    shareCode: {
      _eq: $shareCode
    }
  }) {
    id,
    formId,
    json,
    name,
    height,
    shareCode
  }
}`;