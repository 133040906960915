import { COMMON_ALIGN_SELF, COMMON_HIDDEN, COMMON_NAME, makeColums } from '../costants/index.js';

const manifest = {
  label: 'Two Columns',
  category: 'layout',
  name: 'two-columns',
  hidden: true,
  frameworks: [
    'react-antd',
    'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    COMMON_NAME,
    {
      name: 'layout',
      component: 'select',
      cleanable: true,
      label: 'Layout',
      placeholder: 'Select layout',
      options: [
        { value: 'layout-1-1', label: '1 - 1' },
        { value: 'layout-1-2', label: '1 - 2' },
        { value: 'layout-1-3', label: '1 - 3' },
        { value: 'layout-1-4', label: '1 - 4' },
        { value: 'layout-2-1', label: '2 -1' },
        { value: 'layout-3-1', label: '3 - 1' },
        { value: 'layout-4-1', label: '4 - 1' },
        { value: 'layout-2-3', label: '2 - 3' },
        { value: 'layout-3-2', label: '3 - 2' },
        { value: 'layout-0-1', label: 'auto - 1' },
        { value: 'layout-1-0', label: '1 - auto' }
      ]
    },
    makeColums(
      {
        ...COMMON_ALIGN_SELF,
        name: 'leftAlignment',
        label: 'Left align'
      },
      {
        ...COMMON_ALIGN_SELF,
        name: 'rightAlignment',
        label: 'Right align'
      }
    ),
    COMMON_HIDDEN
  ],
  'react-rsuite5': [

  ],
  'form-generator': {
    hideValidation: true,
    addField: [
      'leftFields',
      'rightFields'
    ]
  },
  defaultValues: {
    'react-rsuite5': {
      layout: 'layout-1-1'
    },
    'react': {
      layout: 'layout-1-1'
    },
    'react-bootstrap': {
      layout: 'layout-1-1'
    },
    'react-material-ui': {
      layout: 'layout-1-1'
    },
    'react-antd': {
      layout: 'layout-1-1'
    }
  }
};

export { manifest };
