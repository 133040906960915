import {
  COMMON_PLACEHOLDER,
  COMMON_TRITTICUS_STATUS,
  makeColums,
  RSUITE5_SIZE,
  RSUITE5_HINT_AS_TOOLTIP,
  RSUITE5_WIDTH,
  COMMON_TRITTICUS
} from '../costants/index.js';

const manifest = {
  label: 'Text Input i18n',
  category: 'advanced',
  name: 'input-text-i18n',
  description: 'Input text in different languages',
  fields: [
    ...COMMON_TRITTICUS,
    COMMON_PLACEHOLDER,
    COMMON_TRITTICUS_STATUS
  ],
  'react': [
  ],
  'react-rsuite5': [
    makeColums(
      RSUITE5_SIZE,
      RSUITE5_WIDTH
    ),
    makeColums(
      {
        name: 'textarea',
        label: 'Show as textarea',
        component: 'toggle',
        script: `
        if (values.textarea) {
          enable('rows');
        } else {
          disable('rows');
        }
        `
      },
      {
        name: 'rows',
        component: 'input-number',
        label: 'Rows',
        min: 1,
        step: 1
      }
    ),
    RSUITE5_HINT_AS_TOOLTIP
  ],
  'react-bootstrap': [],
  'react-material-ui': [],
  'react-antd': [],
  defaultValues: {
    'react-rsuite5': {
      textarea: false,
      rows: 3
    }
  }
};

export { manifest };