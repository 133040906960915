import {
  COMMON_ALIGN_OFFSET,
  COMMON_TRITTICUS,
  COMMON_TRITTICUS_STATUS
} from '../costants/index.js';

const manifest = {
  label: 'List Array',
  category: 'general',
  name: 'array',
  frameworks: [
    'react-antd',
    'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    ...COMMON_TRITTICUS,
    COMMON_TRITTICUS_STATUS,
    COMMON_ALIGN_OFFSET,
    {
      name: 'arrayType',
      component: 'select',
      label: 'Result format',
      hint: 'Defines the result of the list component, "arrayOfString" and "commaSeparated" only applies if list field has only one field',
      options: [
        {
          value: 'arrayOfObject',
          label: 'Array of objects'
        },
        {
          value: 'arrayOfString',
          label: 'Array of string (if array contains only 1 field)'
        },
        {
          value: 'commaSeparated',
          label: 'Comma separated values (if array contains only 1 field)'
        }
      ],
      'react-rsuite5': {
        block: true,
        searchable: false
      }
    }
  ],
  'react-antd': [
  ],
  'react': [
  ],
  'react-bootstrap': [
  ],
  'react-mui': [
  ],
  'react-rsuite5': [
  ],
  defaultValues: {
    'react-rsuite5': {
      layout: 'vertical',
      arrayType: 'arrayOfObject'
    },
    'react-antd': {
      layout: 'vertical',
      arrayType: 'arrayOfObject'
    },
    'react-bootstrap': {
      layout: 'vertical',
      arrayType: 'arrayOfObject'
    },
    'react-material-ui': {
      layout: 'vertical',
      arrayType: 'arrayOfObject'
    },
    'react': {
      layout: 'vertical',
      arrayType: 'arrayOfObject'
    },
    'react-mantine': {
      layout: 'vertical',
      arrayType: 'arrayOfObject'
    },
  }
};

export { manifest };
