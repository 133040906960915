import {
  COMMON_TRITTICUS,
  COMMON_TRITTICUS_STATUS,
  MANTINE_COLORS,
  MUI_DISABLE_RIPPLE,
  MUI_TRITTICUS_1,
  RSUITE5_HINT_AS_TOOLTIP,
  MANTINE_SIZE,
  MANTINE_RADIUS,
  makeOptions,
  makeColums,
  RSUITE5_COLOR,
  MANTINE_AUTO_CONTRAST
} from '../costants/index.js';

const manifest = {
  label: 'Checkbox',
  category: 'general',
  name: 'checkbox',
  frameworks: [
    'react-antd',
    'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    ...COMMON_TRITTICUS,
    COMMON_TRITTICUS_STATUS,
  ],
  'react-material-ui': [
    MUI_TRITTICUS_1,
    MUI_DISABLE_RIPPLE
  ],
  'react-rsuite5': [
    makeColums(
      RSUITE5_COLOR,
      []
    ),
    makeColums(
      {
        name: 'indeterminate',
        label: 'Indeterminate',
        component: 'toggle',
        hint: 'Status can be indeterminate (null), cycle values trough false, inderminate, true',
        'react-rsuite5': {
          tooltip: true
        }
      },
      RSUITE5_HINT_AS_TOOLTIP
    )
  ],
  'react-antd': [],
  'react-mantine': [
    makeColums(
      MANTINE_SIZE,
      MANTINE_RADIUS,
      {
        name: 'labelPosition',
        component: 'select',
        label: 'Label position',
        block: true,
        options: makeOptions(['left', 'right']),
        searchable: false
      }
    ),
    makeColums(
      {
        ...MANTINE_COLORS,
        label: 'Background color'
      },
      {
        ...MANTINE_COLORS,
        name: 'iconColor',
        label: 'Icon color'
      },
      MANTINE_AUTO_CONTRAST
    ),
    {
      name: 'indeterminate',
      component: 'toggle',
      label: 'Indeterminate'
    }
  ],
  'form-generator': {
    //hideValidation: true
  }
};

export { manifest };
