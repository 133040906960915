import { makeOptions } from './helpers.js';

export const MANTINE_SIZE = {
  name: 'size',
  label: 'Size',
  component: 'select',
  block: true,
  options: [
    { value: 'xs', label: 'Extra Small' },
    { value: 'sm', label: 'Small' },
    { value: 'md', label: 'Medium' },
    { value: 'lg', label: 'Large' },
    { value: 'xl', label: 'Extra Large' }
  ]
};

export const MANTINE_AUTO_CONTRAST = {
  name: 'autoContrast',
  component: 'toggle',
  label: 'Auto contrast',
  hint: 'Determines whether icon color with filled variant should depend on background-color. If luminosity of the color prop is less than theme.luminosityThreshold, then theme.white will be used for text color, otherwise theme.black. Overrides theme.autoContrast.',
  'react-rsuite5': {
    tooltip: true
  }
};

export const MANTINE_BUTTON_SIZE = {
  name: 'size',
  label: 'Size',
  component: 'select',
  block: true,
  options: [
    { value: 'xs', label: 'Extra Small' },
    { value: 'compact-xs', label: 'Extra Small (compact)' },
    { value: 'sm', label: 'Small' },
    { value: 'compact-sm', label: 'Small (compact)' },
    { value: 'md', label: 'Medium' },
    { value: 'compact-md', label: 'Medium (compact)' },
    { value: 'lg', label: 'Large' },
    { value: 'compact-lg', label: 'Large (compact)' },
    { value: 'xl', label: 'Extra Large' },
    { value: 'compact-xl', label: 'Extra Large (compact)' }
  ]
};

export const MANTINE_RADIUS = {
  name: 'radius',
  label: 'Border radius',
  component: 'select',
  block: true,
  options: [
    { value: 'xs', label: 'Extra Small' },
    { value: 'sm', label: 'Small' },
    { value: 'md', label: 'Medium' },
    { value: 'lg', label: 'Large' },
    { value: 'xl', label: 'Extra Large' }
  ]
};

export const MANTINE_VARIANT = {
  name: 'variant',
  label: 'Variant',
  component: 'select',
  block: true,
  options: [
    { value: 'default', label: 'Default' },
    { value: 'filled', label: 'Filled' },
    { value: 'unstyled', label: 'Unstyled' }
  ]
};

export const MANTINE_BUTTON_VARIANT = {
  name: 'variant',
  label: 'Variant',
  component: 'select',
  block: true,
  options: makeOptions(['default', 'filled', 'light', 'outline', 'subtle', 'transparent', 'white'])
};

export const MANTINE_CLEARABLE = {
  name: 'clearable',
  component: 'toggle',
  label: 'Clearable',
  hint: 'Determines whether the clear button should be displayed in the right section when the component has value, false by default',
  'react-rsuite5': {
    tooltip: true
  }
};

export const MANTINE_WITH_SCROLL_AREA = {
  name: 'withScrollArea',
  component: 'toggle',
  label: 'Scroll area',
  hint: 'Determines whether the options should be wrapped with scroll bars, true by default',
  'react-rsuite5': {
    tooltip: true
  }
};

export const MANTINE_SECTIONS = {
  name: 'group-fixes',
  component: 'group',
  label: 'Sections',
  border: 'topBottom',
  fields: [
    {
      name: 'fixes-1',
      component: 'columns',
      columns: [
        { name: 'one', size: 1 },
        { name: 'two', size: 1 }
      ],
      fields: {
        one: [
          {
            label: 'Left section',
            name: 'leftSection',
            component: 'input-text',
          }
        ],
        two: [
          {
            label: 'Right section',
            name: 'rightSection',
            component: 'input-text',
          }
        ]
      }
    },
    {
      name: 'fixes-2',
      component: 'columns',
      columns: [
        { name: 'one', size: 1 },
        { name: 'two', size: 1 }
      ],
      fields: {
        one: [
          {
            label: 'Left section width',
            name: 'leftSectionWidth',
            component: 'input-number',
            min: 1
          }
        ],
        two: [
          {
            label: 'Right section width',
            name: 'rightSectionWidth',
            component: 'input-number',
            min: 1
          }
        ]
      }
    },
  ]
};

export const MANTINE_POINTER = {
  name: 'pointer',
  component: 'toggle',
  label: 'Pointer',
  hint: 'Determines whether the input should have cursor: pointer style, false by default',
  'react-rsuite5': {
    tooltip: true
  }
};

export const MANTINE_ERROR_WITH_STYLE = {
  name: 'withErrorStyles',
  component: 'toggle',
  label: 'Error border',
  hint: 'Determines whether the input should have red border and red text color when the error prop is set, true by default',
  'react-rsuite5': {
    tooltip: true
  }
};

export const MANTINE_COLORS =     {
  name: 'color',
  component: 'select',
  label: 'Color',
  block: true,
  options: makeOptions(['grey', 'red', 'pink', 'grape', 'violet', 'indigo', 'blue', 'cyan', 'teal', 'green', 'lime', 'yellow', 'orange']),
  searchable: false
};

export const MANTINE_ARRAY_OPTIONS = {
  component: 'array',
  name: 'options',
  label: 'Select options',
  maxHeight: 350,
  fields: [
    {
      component: 'columns',
      columns: [
        { name: 'one', size: 0 },
        { name: 'two', size: 1 }
      ],
      name: 'array-option-item',
      fields: {
        one: [
          {
            name: 'value',
            component: 'input-text',
            placeholder: 'value',
            size: 'sm',
            width: 100,
            fullWidth: false
          }
        ],
        two: [
          {
            name: 'label',
            component: 'input-text-i18n',
            placeholder: 'label',
            size: 'sm',
            fullWidth: true
          }
        ]
      }
    },
    {
      name: 'description',
      component: 'input-text-i18n',
      placeholder: 'description',
      size: 'sm',
      fullWidth: true
    }
  ]
};

export const MANTINE_DROP_DOWN_HEIGHT = {
  name: 'maxDropdownHeight',
  component: 'input-number',
  label: 'Max drop down',
  min: 0,
  step: 10,
  hint: 'Max-height of the dropdown, only applicable when withScrollArea prop is true, 250 by default',
  'react-rsuite5': {
    tooltip: true
  }
};

export const MANTINE_OPTIONS_LIMIT = {
  name: 'limit',
  component: 'input-number',
  label: 'Options limit',
  min: 1,
  step: 1,
  hint: 'Maximum number of options displayed at a time, Infinity by default',
  'react-rsuite5': {
    tooltip: true
  }
};