import { definePlugin } from '../../code-plug';

import { CloseButton } from './views/close-button';

definePlugin('builder-close-button', ({ registerView }) => {

  // register toolbar button to open templates
  registerView(
    'form-builder-page',
    CloseButton,
    {
      key: 'form-builder-close-button'
    }
  );
});
