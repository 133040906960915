import React from 'react';

import { AddFieldButton } from './add-field-button';
import { useEvent } from '../../../code-plug';

const AddFieldButtonSmall = ({ field, target, subtarget, context } = {}) => {

  const callAddField = useEvent('addField');

  return (
    <AddFieldButton
      field={field}
      target={target}
      context={context}
      subtarget={subtarget}
      onClick={callAddField}
    />
  );
};

export { AddFieldButtonSmall };
