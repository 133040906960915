import { useState } from 'react';
import { useQuery, gql, useMutation, useApolloClient } from '@apollo/client';
import _ from 'lodash';

import { useLocalStorage } from '../../../hooks';


const isValidIsoDate = str => {
  const d = new Date(str);
  return d instanceof Date && !isNaN(d);
};

const elapsedHours = d => {
  return (new Date().getTime() - d.getTime()) / (1000 * 60 * 60)
};


const CREATE_FORM = gql`
mutation($form: create_forms_input!) {
  create_forms_item(data: $form) {
    id
  }
}
`;

const SAVE_FORM_VERSION = gql`
mutation ($version: create_forms_versions_input!) {
  create_forms_versions_item(data: $version) {
    id
 }
}
`;

const UPDATE_FORM_VERSION = gql`
mutation (
  $versionId: ID!,
  $version: update_forms_versions_input!) {
    update_forms_versions_item(
      id: $versionId,
      data: $version
    ) {
      id
    }
}
`;

const GET_FORM = gql`
query ($formId: ID!) {
  form: forms_by_id(id: $formId) {
    id,
    date_created,
    name,
    versions(sort: "-id", limit: 1) {
      id,
      date_created,
      json
    },
    projectId {
      name,
      id,
      framework
    }
  }
}
`;

const UPDATE_FORM = gql`
mutation($id: ID!, $form: update_forms_input!) {
  update_forms_item(id: $id, data: $form) {
    id,
    name
  }
}`;

const useFormVersion = (formId, projectId, options = {}) => {
  const [saving, setSaving] = useState(false);
  const client = useApolloClient();
  const [latestSave, setLatestSave] = useLocalStorage('lf_forms', {});

  const { data, loading, error } = useQuery(GET_FORM, {
    fetchPolicy: 'network-only',
    variables: { formId },
    skip: _.isEmpty(formId),
    onCompleted: options.onCompleted
  });

  const [
    saveForm,
    {
      loading: loadingSaveForm,
      error: errorSaveForm
    }
  ] = useMutation(CREATE_FORM);
  const [
    saveFormVersion,
    {
      loading: loadingSaveVersion,
      error: errorSaveVersion
    }
  ] = useMutation(SAVE_FORM_VERSION);
  const [
    updateFormVersion,
    {
      loading: loadingUpdateVersion,
      error: errorUpdateVersion
    }
  ] = useMutation(UPDATE_FORM_VERSION);

  return {
    saveFormVersionWithComment: async function({ name, form, comment }) {
      setSaving(true);
      if (_.isEmpty(formId)) {
        // create new form + version
        const variables = {
          form: {
            name,
            versions: [
              { json: JSON.stringify(form, null, 2), comment }
            ],
            projectId: {
              id: projectId
            }
          }
        };
        const res = await saveForm({ variables });
        setSaving(false);
        return res?.data?.create_forms_item?.id;
      } else {
        await saveFormVersion({
          variables: {
            version: {
              json: JSON.stringify(form),
              comment,
              formId: {
                id: formId
              }
            }
          }
        });
        setSaving(false);
        return;
      }
    },
    /**
     * saveFormVersion
     * Save a form version, if the form doesn't exist and it was justcreated, then it returns the formId
     * @param {*} param
     * @returns
     */
    saveFormVersion: async function({ name, form }) {
      try {
        setSaving(true);
        if (_.isEmpty(formId)) {
          // create new form + version
          const variables = {
            form: {
              name,
              versions: [
                { json: JSON.stringify(form, null, 2) }
              ],
              projectId: {
                id: projectId
              }
            }
          };
          const res = await saveForm({ variables });
          setSaving(false);
          return res?.data?.create_forms_item?.id;
        } else {
          // if latest save is within 12 hours, then update
          let update = true;
          if (latestSave
              && latestSave[formId]
              && isValidIsoDate(latestSave[formId])
              && elapsedHours(new Date(latestSave[formId])) > 12
            ) {
            update = false;
          }

          // if form name changed, change also in db
          if (name !== data.form.name) {
            await client.mutate({
              mutation: UPDATE_FORM,
              variables: {
                id: formId,
                form: { name }
              }
            });
          }

          if (update) {
            // store latest save for this form
            setLatestSave({
              ...latestSave,
              [formId]: new Date().toISOString()
            });
            // update latest version
            updateFormVersion({
              variables: {
                version: {
                  json: JSON.stringify(form),
                },
                versionId: data.form.versions[0].id
              }
            });
          } else {
            // store latest save for this form
            setLatestSave({
              ...latestSave,
              [formId]: new Date().toISOString()
            });
            // create a new version
            await saveFormVersion({
              variables: {
                version: {
                  json: JSON.stringify(form),
                  formId: {
                    id: formId
                  }
                }
              }
            });
          }
          setSaving(false);
        }
      } catch(e) {
        setSaving(false);
        throw e;
      }
    },
    saving: loadingUpdateVersion || loadingSaveVersion || loadingSaveForm || saving,
    loading,
    error: errorSaveVersion || errorUpdateVersion || error || errorSaveForm,
    loadingError: error,
    form: data?.form,
    shareCode: !_.isEmpty(data?.shared_forms) ? data.shared_forms[0].shareCode : null
  };
};

export { useFormVersion };
