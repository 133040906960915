import React, { useState, useEffect, useCallback } from 'react';
import { Drawer, Button, Placeholder, Loader } from 'rsuite';
import _ from 'lodash';

import { useTracking } from '../../../hooks';
import { ErrorPlaceholder, useModal } from '../../../components';
import { fetchTemplates, fetchTemplate } from '../../../helpers';

import { TemplateItem } from './template-item';
import { TemplateSearchToolbar } from './template-search-toolbar';
import { PreviewTemplate } from './preview-template';

import './select-template.scss';

const SelectTemplate = ({
  onCancel = () => {},
  onSelect = () => {}
}) => {
  const { sendEvent } = useTracking();
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
  const [templates, setTemplates] = useState(null);
  const [categories, setCategories] = useState(null);
  const [error, setError] = useState(null);

  const { open, close } = useModal({
    view: PreviewTemplate,
    size: 'sm',
    name: 'previewTemplate',
    align: 'center',
    labelSubmit: null,
    labelCancel: null,
    title: 'Preview template',
  });

  useEffect(
    () => {
      const f = async () => {
        try {
          const { templates, categories } = await fetchTemplates({ component: false });
          setIsLoading(false);
          setTemplates(templates);
          setCategories(categories);
        } catch(e) {
          setIsLoading(false);
          setError(true);
        }
      }
      f();
    },
    []
  );

  const handleSelect = useCallback(
    template => {
      const f = async () => {
        sendEvent('template.select');
        setIsLoadingTemplate(true);
        const fullTemplate = await fetchTemplate(template.id);
        setIsLoadingTemplate(false);
        onSelect(fullTemplate);
      }
      f();
    },
    [onSelect, sendEvent]
  );

  const handlePreviewTemplate = useCallback(
    async template => {
      await open({ template });
      close();
    },
    [close, open]
  );

  const filteredTemplates = (templates || [])
    .filter(template => !filters.categoryId || template.category?.id === filters.categoryId)
    .filter(template => !filters.search || template.name.toLowerCase().includes(filters.search))

  return (
    <Drawer
      open={true}
      onClose={() => onCancel()}
      className="lf-select-templates"
      size="md"
    >
      <Drawer.Header>
        <Drawer.Title>
          LetsForm templates
          {isLoadingTemplate && <Loader />}
        </Drawer.Title>
        <Drawer.Actions>
          <Button onClick={() => onCancel()}>Cancel</Button>
        </Drawer.Actions>
      </Drawer.Header>
      <Drawer.Body>
        {isLoading && <Placeholder.Paragraph />}
        {!isLoading && <TemplateSearchToolbar categories={categories} filters={filters} onChange={setFilters} />}
        {!isLoading && !_.isEmpty(filteredTemplates) && filteredTemplates.map(template => (
          <TemplateItem
            key={template.id}
            template={template}
            disabled={isLoadingTemplate}
            onSelect={handleSelect}
            onPreview={handlePreviewTemplate}
          />
        ))}
        {error && (
          <ErrorPlaceholder>
            Something went wrong loading templates
          </ErrorPlaceholder>
        )}
      </Drawer.Body>
    </Drawer>
  );
};

export { SelectTemplate };
