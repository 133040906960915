import React, { useCallback, useState, useMemo } from 'react';
import _ from 'lodash';
import {
  Container,
  Content,
  Header,
  Button,
  Placeholder,
  ButtonGroup,
  Whisper,
  IconButton,
  Popover,
  Dropdown,
  Grid,
  Row,
  Col
} from 'rsuite';
import ArrowDownIcon from '@rsuite/icons/ArrowDown';
import { useQuery, useMutation, useApolloClient, gql } from '@apollo/client';
import { useParams, useNavigate, createSearchParams } from 'react-router-dom';

import { NavigationBar, Breadcrumbs } from '../../layout';
import {
  FrameworkIcons,
  useModal,
  MarkdownText,
  EmptyPlaceholder,
  ErrorPlaceholder,
  FeatureButton,
  DasboardLabel,
  FormVersionsModal
} from '../../components';
import { FRAMEWORKS_LABELS, FRAMEWORKS } from '../../components/lets-form';
import { useCurrentUser, useTracking } from '../../hooks';
import { CreateNewForm, ProjectEdit, EditFormDescription } from '../../components/lets-form-forms';

import { FormCard, ExportPackage, PreviewForm } from './views';
import { UPDATE_PROJECT, GET_PROJECT, DELETE_FORM, UPDATE_FORM } from './queries';

import './projects.scss';

const FRAMEWORKS_LABELS_MAP = FRAMEWORKS.reduce(
  (acc, value, idx) => ({ ...acc, [value]: FRAMEWORKS_LABELS[idx] }),
  {}
);

const DELETE_PROJECT = gql`
mutation($id: ID!) {
  delete_projects_item(id: $id) {
    id
  }
}
`;

const ProjectPage = () => {
  const { sendEvent } = useTracking();
  const { user } = useCurrentUser({ redirectToLogin: true });
  const { projectId } = useParams();
  const client = useApolloClient();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingFormId, setLoadingFormId] = useState();
  const [initialLoading, setInitialLoading] = useState(true);

  const { open: openCreateNewForm, close: closeCreateNewForm } = useModal({
    view: function({ onCancel, onSubmit }) {
      return (
        <CreateNewForm
          onSubmit={onSubmit}
          onReset={onCancel}
        />
      );
    },
    size: 'sm',
    name: 'createNewForm',
    align: 'center',
    labelSubmit: null,
    labelCancel: null,
    title: 'Create new form'
  });

  const { open: openPreviewForm, close: closePreviewForm } = useModal({
    view: PreviewForm,
    size: 'sm',
    name: 'createNewForm',
    align: 'center',
    labelSubmit: null,
    labelCancel: null,
    title: 'Preview Form'
  });

  const { open: openEditProject, close: closeEditProject } = useModal({
    view: function(props) {
      const { onCancel, onSubmit } = props;
      // TODO implement do you want to loose changes?
      return (
        <ProjectEdit
          defaultValues={props.value}
          onSubmit={onSubmit}
          onReset={onCancel}
        />
      );
    },
    size: 'sm',
    name: 'editProject',
    align: 'center',
    labelSubmit: null,
    labelCancel: null,
    title: 'Edit project'
  });

  const { open: openExportPackage, close: closeExportPackage } = useModal({
    view: ExportPackage,
    size: 'md',
    name: 'exportPackage',
    align: 'center',
    labelSubmit: null,
    labelCancel: null
  });

  const { open: openFormVersions, close: closeFormVersions } = useModal({
    view: FormVersionsModal,
    size: 'lg',
    name: 'formVersions',
    align: 'center',
    title: 'Form versions',
    labelSubmit: 'Close',
    labelCancel: null
  });

  const { open: openFormDescription, close: closeFormDescription } = useModal({
    view: function(props) {
      const { onCancel, onSubmit } = props;
      // TODO implement do you want to loose changes?
      return (
        <EditFormDescription
          defaultValues={props.value}
          onSubmit={onSubmit}
          onReset={onCancel}
        />
      );
    },
    size: 'sm',
    name: 'createNewForm',
    align: 'center',
    labelSubmit: null,
    labelCancel: null,
    title: 'Edit description'
  });

  const { loading: loadingProjects, data, refetch } = useQuery(GET_PROJECT, {
    fetchPolicy: 'network-only',
    variables: {
      id: projectId,
      projectId: projectId
    },
    onCompleted: () => {
      setInitialLoading(false);
    }
  });

  const [saveProject, {
    loading: loadingSaveProject,
    /*error: errorSaveProject*/
  }] = useMutation(UPDATE_PROJECT);

  const [
    deleteForm,
    {
      loading: deletingForm,
      /*error: errorSaveForm*/
    }
  ] = useMutation(DELETE_FORM);

  const handleCreateForm = useCallback(
    async () => {
      sendEvent('form.create');

      const newForm = await openCreateNewForm();
      closeCreateNewForm();

      if (newForm) {
        const searchObject = {
          name: newForm.name,
          framework: data.project.framework
        };
        if (!_.isEmpty(newForm.locales)) {
          searchObject.locales = newForm.locales.toString();
        }

        navigate({
          pathname: `/projects/${projectId}/new`,
          search: createSearchParams(searchObject).toString()
        });
      }

    },
    [closeCreateNewForm, openCreateNewForm, navigate, projectId, sendEvent, data]
  );

  const handleSelect = useCallback(
    async (cmd, form) => {
      if (cmd === 'cmd:formVersions') {
        await openFormVersions({
          formId: form.id,
          framework: data.project.framework
        }, {
          title: `"${form.name}" versions`
        });
        closeFormVersions();
      } else if (cmd === 'cmd:editForm') {
        navigate(`/projects/${form.projectId.id}/forms/${form.id}`);
      } else if (cmd === 'cmd:deleteForm') {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(`Delete form ${form.name}`)) {
          setLoadingFormId(form.id);
          await deleteForm({ variables: { id: form.id }});
          await refetch();
          setLoadingFormId(null);
        }
      } else if (cmd === 'cmd:previewForm') {
        await openPreviewForm(null, { form, framework: data.project.framework });
        closePreviewForm();
      } else if (cmd === 'cmd:editFormDescription') {
        const newForm = await openFormDescription(form);
        closeFormDescription();

        if (newForm) {
          setLoadingFormId(form.id);
          await client.mutate({
            mutation: UPDATE_FORM,
            variables: {
              id: form.id,
              form: _.pick(newForm, 'description')
            }
          });
          setLoadingFormId(null);
        }
      }
    },
    [
      navigate,
      deleteForm,
      refetch,
      openPreviewForm,
      closePreviewForm,
      openFormDescription,
      closeFormDescription,
      client,
      closeFormVersions,
      openFormVersions,
      data
  ]
  );

  const handleEditProject = useCallback(
    async () => {
      const newProject = await openEditProject(data.project);
      closeEditProject();
      if (newProject != null) {
        await saveProject({
          variables: {
            id: newProject.id,
            project: _.pick(
              newProject,
              'name', 'description', 'framework', 'package', 'githubRepository',
              'branch', 'targetPath', 'githubToken'
            )
          }
        });
      }
    },
    [closeEditProject, openEditProject, data, saveProject]
  );

  const handleDeleteProject = useCallback(
    async () => {
      if (window.confirm(`Delete project "${data.project.name}" and all its forms?`)) {
        setIsLoading(true);
        await client.mutate({
          mutation: DELETE_PROJECT,
          variables: {
            id: data.project.id
          }
        });
        navigate('/dashboard');
      }
    },
    [client, data , navigate]
  );

  const projectMenu = useMemo(
    () =>  (props, ref) => {
      const { onClose, left, top, className } = props;
      const emptyProject = _.isEmpty(data.forms);
      const handleSelect = async eventKey => {
        onClose();
        if (eventKey === 'cmd:exportPackage') {
          await openExportPackage(
            {},
            {
              project: data.project,
              projectId,
              projectName: data.project.name,
              framework: data.project.framework,
              package: data.project.package,
              forms: data.forms
                .filter(form => !_.isEmpty(form.versions))
                .reduce(
                  (acc, value) => [...acc, { value: value.id, label: value.name }],
                  []
                )
            });
          closeExportPackage();
        } else if (eventKey === 'cmd:exportGitHub') {
          await openExportPackage(
            {},
            {
              project: data.project,
              exportType: 'github',
              forms: data.forms
                .filter(form => !_.isEmpty(form.versions))
                .reduce(
                  (acc, value) => [...acc, { value: value.id, label: value.name }],
                  []
                ),

                projectId,
                projectName: data.project.name,
                framework: data.project.framework,
                package: data.project.package,
            }
          );
          closeExportPackage();
        } else if (eventKey === 'cmd:createNewForm') {
          handleCreateForm();
        } else if (eventKey === 'cmd:deleteProject') {
          handleDeleteProject();
        }
      };
      // <Dropdown.Item eventKey="cmd:exportNPM" disabled={emptyProject}>Export project to NPM package</Dropdown.Item>
      return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu onSelect={handleSelect}>
          <Dropdown.Item eventKey="cmd:createNewForm">Create new form</Dropdown.Item>
            <Dropdown.Item eventKey="cmd:createNewRelease" disabled={emptyProject}>Create new release</Dropdown.Item>
            <Dropdown.Separator />
            <Dropdown.Item eventKey="cmd:exportPackage" disabled={emptyProject}>Export project to file</Dropdown.Item>
            <Dropdown.Item eventKey="cmd:exportGitHub" disabled={emptyProject}>Export project to GitHub</Dropdown.Item>
            <Dropdown.Separator />
            <Dropdown.Item eventKey="cmd:deleteProject">Delete project</Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      );
    },
    [data, openExportPackage, projectId, closeExportPackage, handleCreateForm, handleDeleteProject]
  );

  const loading = loadingProjects || deletingForm || loadingSaveProject || initialLoading || isLoading;
  const Icon = data && data.project ? FrameworkIcons[data.project.framework] : null;

  // can use: create new project
  let canCreateForm = true;
  let canCreateWarning = undefined;
  if (!initialLoading && data && data.forms && data.forms.length >= user.plan.maxForms) {
    canCreateForm = false;
    canCreateWarning = (
      <span>
        You reached the maximun number of forms ({user.plan.maxForms}) for the current plan <b>{user.plan.name}</b>,
        please upgrade to create more forms
      </span>
    );
  }

  if (!loading && !data.project) {
    return (
      <div>
        <Header>
          <NavigationBar />
        </Header>
        <Container className="lf-page-projects">
          <Content className="error">
            <ErrorPlaceholder>
              An error occurred fetching the form or the project doesn't exist
            </ErrorPlaceholder>
          </Content>
        </Container>
      </div>
    );
  }

  return (
    <div>
      <Header>
        <NavigationBar />
      </Header>
      <Container className="lf-page-projects">
        <Content className="project-detail">
          <Breadcrumbs to="/dashboard" />
          <div>
            <Grid fluid>
              <Row className="show-grid">
                <Col md={13}>
                  <DasboardLabel>Project</DasboardLabel>
                  {!initialLoading && (
                    <>
                      <div className="lf-project-title">
                        {data.project.name}
                      </div>
                      {!_.isEmpty(data.project.description) && (
                        <MarkdownText className="description">
                          {data.project.description}
                        </MarkdownText>
                      )}
                    </>
                  )}
                  {initialLoading && (
                    <Placeholder.Paragraph rows={1} active style={{ maxWidth: '60%' }} />
                  )}
                </Col>
                <Col md={6} mdOffset={1}>
                  <DasboardLabel>DETAILS</DasboardLabel>
                    {!initialLoading && (
                      <>
                        <b>Framework</b> {FRAMEWORKS_LABELS_MAP[data.project.framework]} {Icon && <Icon width={16} height={16} />}
                        {data.project.package && (
                          <div className="truncated">
                            <b>Package</b> {data.project.package}
                          </div>
                        )}
                        {data.project.targetPath && data.project.githubRepository && (
                          <div className="truncated">
                            <b>GitHub</b> {data.project.targetPath}
                          </div>
                        )}
                      </>
                    )}
                    {initialLoading && (
                      <Placeholder.Paragraph rows={1} active />
                    )}
                </Col>
                <Col md={4} style={{ textAlign: 'right' }}>
                  <ButtonGroup className="lf-project-main-menu">
                    <Button
                      appearance="primary"
                      onClick={handleEditProject}
                      disabled={loading}
                    >Edit</Button>
                    <Whisper placement="bottomEnd" trigger="click" speaker={projectMenu}>
                      <IconButton appearance="primary" icon={<ArrowDownIcon />} disabled={loading} />
                    </Whisper>
                  </ButtonGroup>
                </Col>
              </Row>
            </Grid>

            <DasboardLabel className="lf-label-forms">
              Forms
              <FeatureButton
                canUse={canCreateForm}
                warning={canCreateWarning}
                size="xs"
                disabled={loading}
                appearance="ghost"
                style={{ marginLeft: '20px' }}
                onClick={handleCreateForm}
              >
                Create form
              </FeatureButton>
            </DasboardLabel>
            <div className="forms">
              {!initialLoading && (
                <>
                  {data.forms
                    .filter(form => !_.isEmpty(form.versions))
                    .map((form, idx) => {
                      return (
                        <FormCard
                          key={form.id}
                          form={form}
                          disabled={loading || loadingFormId === form.id}
                          onSelect={handleSelect}
                          canUse={idx < user.plan.maxForms}
                        />
                      );
                    })
                  }
                </>
              )}
              {!initialLoading && _.isEmpty(data.forms) && (
                <EmptyPlaceholder>
                  There are no forms in this project yet, click on the button above to create one
                </EmptyPlaceholder>
              )}
              {initialLoading && (
                <Placeholder.Grid rows={3} columns={4} active style={{ maxWidth: '60%' }} />
              )}
            </div>

            <DasboardLabel>
              Releases
              <Button
                size="xs"
                disabled={loading}
                appearance="ghost"
                style={{ marginLeft: '20px' }}
                onClick={() => {
                }}
              >
                New
              </Button>
            </DasboardLabel>
            coming soon...
          </div>
        </Content>
      </Container>
    </div>
  );
};

export { ProjectPage };
