import { COMMON_TRITTICUS } from '../costants';

const manifest = {
  label: 'Module',
  category: 'general',
  name: 'esm-module',
  description: 'Generic ESM module',
  frameworks: [
    //'react-antd',
    //'react',
    //'react-bootstrap',
    //'react-material-ui',
    'react-rsuite5',
    //'react-mantine'
  ],
  fields: [
    ...COMMON_TRITTICUS,
    {
      name: 'componentUrl',
      component: 'input-text',
      label: 'Component URL'
    },
    {
      component: 'columns',
      columns: [
        { name: 'one', size: 0 },
        { name: 'two', size: 1 }
      ],
      name: 'esm-default-columns',
      fields: {
        one: [
          {
            component: 'toggle',
            label: 'Use default export',
            name: 'defaultExport',
            script: `if (defaultExport) {\n  disable('exportedKey');\n} else {\n  enable('exportedKey');\n}`
          }
        ],
        two: [
          {
            component: 'input-text',
            label: 'Exported key',
            name: 'exportedKey',
            'react-rsuite5': {
              fullWidth: false,
              width: 240
            },
            hint: 'The exported key from the ESM module',
          }
        ]
      }
    },
    {
      name: 'count',
      component: 'input-number',
      min: 1,
      max: 10,
      step: 1,
      label: 'Count'
    },
    {
      name: 'placeholder',
      component: 'input-text',
      label: 'Placeholder'
    }
  ],
  'react-rsuite5': [],
  'react-antd': [],
  'react-material-ui': [],
  'react-mantine': [],
  defaultValues: {
    'react-mantine': {
    },
    'react-antd': {
    },
    'react-rsuite5': {
    },
    'react-material-ui': {
    }
  }
};

export { manifest };