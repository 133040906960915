import React, { useCallback } from 'react';
import { Button } from 'rsuite';

import { DownloadGenericPackage } from '../../../components';
import { useModal } from '../../../components';
import { generateViteBundle } from '../../../components/code-generation/generate-vite-code';

const ButtonExportVite = ({ form, framework, locale, disabled }) => {
  const { open, close } = useModal({
    view: DownloadGenericPackage,
    size: 'sm',
    name: 'downloadPackage',
    align: 'center',
    labelSubmit: null,
    labelCancel: null
  });

  const handleExportCRA = useCallback(
    async () => {
      const pack = generateViteBundle({ form, framework, locale });
      await open({
        pack,
        packageName: `${pack.appName}.zip`,
        instructions: `cd ./${pack.appName}\nnpm i\nnpm run dev`
      });
      await close();
    },
    [close, form, framework, locale, open]
  );

  return (
    <Button
      size="sm"
      disabled={disabled}
      appearance="primary"
      onClick={handleExportCRA}
    >Export to Vite</Button>
  );
};

export { ButtonExportVite };
