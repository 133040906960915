/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import './breadcrumbs.scss';

const Breadcrumbs = ({ to }) => {
  const navigate = useNavigate();

  const handleClick = useCallback(
    e => {
      e.preventDefault();
      navigate(to ?? -1);
    },
    [navigate, to]
  );

  return (
    <div className="lf-breadcrumbs">
      <span className="icon">&larr;</span>{' '}
      <a 
        href="#"
        onClick={handleClick}
      >Back</a>
    </div>
  );
}

export { Breadcrumbs };
