import React, { useCallback } from 'react';
import { SelectPicker } from 'rsuite';
import _ from 'lodash';

import { useLocalStorage } from '../../../hooks';
import { getLocales } from '../../../components/lets-form';
import { useFormBuilderStore } from '../../../pages/builder/state';

const LOCALES = getLocales();

const renderValueLanguage = (value) => (
  <span>
    <span style={{ color: '#999999' }}>lang:</span> {value}
  </span>
);

const renderItemLanguage = (label, item) => (
  <div>
    {label} <b>{item.value}</b>
  </div>
);

const LocalesSelector = ({ disabled, persist = false }) => {
  const locale = useFormBuilderStore(state => state.locale);
  const form = useFormBuilderStore(state => state.form);
  const setLocale = useFormBuilderStore(state => state.setLocale);
  const [, setPersistedLocale] = useLocalStorage('locale', '');

  const handleChangeLocale = useCallback(
    newLocale => {
      setLocale(newLocale);
      if (persist) {
        setPersistedLocale(newLocale);
      }
    },
    [persist, setLocale, setPersistedLocale]
  );

  const languageData = LOCALES
    .filter(({ code }) => form.locales && form.locales.includes(code))
    .map(locale => ({ ...locale, value: locale.code }));

  return (
    <>
    {_.isArray(form.locales) && !_.isEmpty(form.locales) && (
        <SelectPicker
          size="sm"
          appearance="subtle"
          value={locale}
          cleanable={false}
          searchable={form.locales.length > 10}
          renderValue={renderValueLanguage}
          renderMenuItem={renderItemLanguage}
          data={languageData}
          disabled={disabled}
          onChange={handleChangeLocale}
        />
      )}
    </>
  );
};

export { LocalesSelector };
