import Manifests from './lets-form-manifests';


import { IconAntD } from '../assets/icons';
import { IconRSuite } from '../assets/icons';
import { IconBootstrap } from '../assets/icons';
import { IconMantine } from '../assets/icons';
import { IconMaterialUI } from '../assets/icons';
import { TbBrandReactNative } from 'react-icons/tb';
import { MdCalendarViewDay } from 'react-icons/md';
import { RxButton } from 'react-icons/rx';
import { MdOutlineSmartButton } from 'react-icons/md';
import { RiCheckboxMultipleLine } from 'react-icons/ri';
import { TbCheckbox } from 'react-icons/tb';
import { BsCalendar2Day } from 'react-icons/bs';
import { RxDividerHorizontal } from 'react-icons/rx';
import { AiOutlineGroup } from 'react-icons/ai';
import { RiInputMethodFill } from 'react-icons/ri';
import { TbNumbers } from 'react-icons/tb';
import { BsTags } from 'react-icons/bs';
import { BsInputCursorText } from 'react-icons/bs';
import { FaLanguage } from 'react-icons/fa';
import { RiCheckboxMultipleBlankLine } from 'react-icons/ri';
import { BsImage } from 'react-icons/bs';
import { GrStatusPlaceholder } from 'react-icons/gr';
import { BsUiRadios } from 'react-icons/bs';
import { FaQrcode } from 'react-icons/fa';
import { BsStarHalf } from 'react-icons/bs';
import { CgSelect } from 'react-icons/cg';
import { RxSlider } from 'react-icons/rx';
import { CgCalendarNext } from 'react-icons/cg';
import { TfiLayoutTab } from 'react-icons/tfi';
import { BsTextareaResize } from 'react-icons/bs';
import { TfiLayoutColumn3Alt } from 'react-icons/tfi';
import { BsToggleOn } from 'react-icons/bs';
import { TfiLayoutColumn2Alt } from 'react-icons/tfi';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { MdOutlineAccessTime } from 'react-icons/md';
import { HiOutlineCodeBracketSquare } from 'react-icons/hi2';

export * from './lets-form-manifests/costants';

Manifests.forms['react-antd'].icon = IconAntD;
Manifests.forms['react-bootstrap'].icon = IconBootstrap;
Manifests.forms['react-mantine'].icon = IconMantine;
Manifests.forms['react-material-ui'].icon = IconMaterialUI;
Manifests.forms['react'].icon = ({ width = 24 }) => <TbBrandReactNative size={width} color="#61dafb" />;
Manifests.forms['react-rsuite5'].icon = IconRSuite;
Manifests.array.icon = MdCalendarViewDay;
Manifests.button.icon = RxButton;
Manifests['buttons-toggle-group'].icon = MdOutlineSmartButton;
Manifests['checkbox-group'].icon = RiCheckboxMultipleLine;
Manifests.checkbox.icon = TbCheckbox;
Manifests.date.icon = BsCalendar2Day;
Manifests.datetime.icon = BsCalendar2Day;
Manifests.divider.icon = RxDividerHorizontal;
Manifests.group.icon = AiOutlineGroup;
Manifests['esm-module'].icon = AiOutlineGroup;
Manifests['esm-module'].hidden = true;
Manifests['input-mask'].icon = RiInputMethodFill;
Manifests['input-number'].icon = TbNumbers;
Manifests['input-tag'].icon = BsTags;
Manifests['input-text-i18n'].icon = BsInputCursorText;
Manifests['input-text'].icon = BsInputCursorText;
Manifests['multiselect-language'].icon = FaLanguage;
Manifests['multiselect'].icon = RiCheckboxMultipleBlankLine;
Manifests['placeholder-image'].icon = BsImage;
Manifests['placeholder'].icon = GrStatusPlaceholder;
Manifests['radio-group'].icon = BsUiRadios;
Manifests['radio-tile'].icon = FaQrcode;
Manifests.rate.icon = BsStarHalf;
Manifests.select.icon = CgSelect;
Manifests.slider.icon = RxSlider;
Manifests.steps.icon = CgCalendarNext;
Manifests.tabs.icon = TfiLayoutTab;
Manifests.textarea.icon = BsTextareaResize;
Manifests['three-columns'].icon = TfiLayoutColumn3Alt;
Manifests['columns'].icon = TfiLayoutColumn3Alt;
Manifests.toggle.icon = BsToggleOn;
Manifests['two-columns'].icon = TfiLayoutColumn2Alt;
Manifests.upload.icon = FaCloudUploadAlt;
Manifests.time.icon = MdOutlineAccessTime;
Manifests.hidden.icon = HiOutlineCodeBracketSquare;

export default Manifests;