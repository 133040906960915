import React, { useCallback } from 'react';

import LetsForm, { formHelper } from '../../../components/lets-form/react-rsuite5';

const ConnectorConfigurationModal = ({ value, onSubmit, onCancel }) => {

  const { form, formCors, values } = value;

  const handleSubmit = useCallback(
    values => {
      onSubmit(values);
    },
    [onSubmit]
  )

  return (
    <div>
      <LetsForm
        form={
          formHelper(form)
            .append(formCors)
            .setValue('labelSubmit', 'Save configuration')
            .setValue('labelCancel', 'Cancel')
            .setValue('buttonsAlign', 'center')
            .dump()
            .form()
        }
        defaultValues={values}
        onSubmit={handleSubmit}
        onReset={onCancel}
      />
    </div>
  );
};

export { ConnectorConfigurationModal };