const replaceConnector = (form, idx, options) => {
  if (form && Array.isArray(form.connectors) && form.connectors[idx]) {
    const newConnectors = [...form.connectors];
    newConnectors[idx] = {
      ...newConnectors[idx],
      options
    };
    return {
      ...form,
      connectors: newConnectors
    };
  }

  return form;
};

const removeConnector = (form, idx) => {
  if (form && Array.isArray(form.connectors) && form.connectors[idx]) {
    const newConnectors = [...form.connectors];
    newConnectors.splice(idx, 1);

    return {
      ...form,
      connectors: newConnectors
    };
  }

  return form;
};

export { replaceConnector, removeConnector };
