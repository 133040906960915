import React, { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from 'rsuite';

import { NotificationFrame } from '../../layout';

const MessagePage = () => {
  const navigate = useNavigate();
  const { code } = useParams();

  const handleGoToLogin = useCallback(
    (e) => {
      e.preventDefault();
      navigate('/login');
    },
    [navigate]
  );

  let inner;
  if (code === 'email_confirmed') {
    inner = (
      <>
        <h3>Email confirmed!</h3>
        Your email is now confirmed and the account is activated, you can close this tab or proceed to the login page
        <Button
          appearance="primary"
          href="/login"
          onClick={handleGoToLogin}
        >Go to login page</Button>
      </>
    );
  } else if (code === 'confirmation_invalid') {
    inner = (
      <>
        <h3>Invalid confirmation link!</h3>
        The confirmation link is invalid or was already used, you can close this tab or proceed to the login page
        <Button
          appearance="primary"
          href="/login"
          onClick={handleGoToLogin}
        >Go to login page</Button>
      </>
    );
  }

  return (
    <NotificationFrame>
      {inner || (
        <>
          <h3>Unknown message</h3>
          Unknown notification code, you shouldn't be here.
        </>
      )}
    </NotificationFrame>
  );
};

export { MessagePage };
