/* eslint-disable no-template-curly-in-string */
import {
  COMMON_FULL_WIDTH_SIZE,
  COMMON_PLACEHOLDER,
  COMMON_TRITTICUS,
  COMMON_TRITTICUS_STATUS,
  makeColums,
  RSUITE5_HINT_AS_TOOLTIP,
  RSUITE5_PREFIXES,
  RSUITE5_SIZE
} from '../costants/index.js';

const manifest = {
  label: 'Input Mask',
  category: 'general',
  name: 'input-mask',
  description: 'Masked input text',
  frameworks: [
    //'react-antd',
    //'react',
    //'react-bootstrap',
    //'react-material-ui',
    'react-rsuite5'
    //'react-mantine'
  ],
  fields: [
    ...COMMON_TRITTICUS,
    COMMON_PLACEHOLDER,
    COMMON_TRITTICUS_STATUS
  ],
  'react-rsuite5': [
    {
      name: 'mask',
      component: 'input-text',
      label: 'Input mask',
      hint: 'Use ${d} - digits, ${D} - any non digits, ${a} - any non alpha chars, ${w} - any word chars '
    },
    makeColums(
      {
        name: 'guide',
        component: 'toggle',
        label: 'Guide mode'
      },
      {
        name: 'keepCharPositions',
        component: 'toggle',
        label: 'Keep char position',
        hint: 'When true, adding or deleting characters will not affect the position of existing characters',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'showMask',
        component: 'toggle',
        label: 'Show mask',
        hint: 'When the input value is empty, the mask is displayed as a placeholder instead of a regular placeholder',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    {
      name: 'placeholderChar',
      component: 'input-text',
      label: 'Placeholder char',
      hint: 'The placeholder character represents the fillable spot in the mask',
      'react-rsuite5': {
        tooltip: true
      }
    },
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      RSUITE5_SIZE,
      []
    ),
    RSUITE5_HINT_AS_TOOLTIP,
    RSUITE5_PREFIXES
  ],
  defaultValues: {
    'react-rsuite5': {
      'react-rsuite5': {
        fullWidth: true
      }
    }
  }
};

export { manifest };