import _ from 'lodash';

import { reduceFields, FRAMEWORKS } from '../components/lets-form';

const EXCLUDED_FIELDS = ['leftFields', 'centerFields', 'rightFields', 'fields'];

const getSourceLocale = obj => {
  if (!_.isEmpty(obj['en-US'])) {
    return 'en-US';
  } else if (!_.isEmpty(obj['en-GB'])) {
    return 'en-GB';
  };
  const notNullKeys = Object
    .keys(obj)
    .filter(key => !_.isEmpty(obj[key]));

  return notNullKeys.length !== 0 ? notNullKeys[0] : null;
}

// TODO maybe a better i18n object recognition
const isI18n = obj => {
  return _.isObject(obj) && Object.keys(obj)
    .every(key => key.length === 2 || (key.length === 5 && key[2] === '-'));
};

const isArrayOfObjects = obj => Array.isArray(obj) && obj.every(item => _.isObject(item));



// TODO don't collect from validation message
const collectTokensFromObject = (field, locales) => {
  let result = [];
  // cycle all fields and take all values which are i18n objects
  Object.keys(field).forEach(key => {
    if (isI18n(field[key])) {
      // find the main locale, favour en-* otherwise the first non null key
      const sourceLocale = getSourceLocale(field[key]);
      if (sourceLocale) {
        // cycle all availables languages and add those to be added
        locales.forEach(locale => {
          // if empty translation then add to the grocery list, with name of field
          // target and source locale
          if (_.isEmpty(field[key][locale])) {
            result = [
              ...result,
              {
                id: _.uniqueId('gc_'),
                name: field.name,
                key,
                targetLocale: locale,
                sourceLocale: sourceLocale,
                source: field[key][sourceLocale],
                translation: null
              }
            ];
          }
        });
      }
    } else if (FRAMEWORKS.includes(key)) {
      // if one of the per framework settings
      const subTokens = collectTokensFromObject(field[key], locales)
        .map(subToken => ({
          ...subToken,
          id: _.uniqueId('gc_'),
          name: field.name,
          framework: key
        }));
      if (!_.isEmpty(subTokens)) {
        result = [...result, ...subTokens];
      }        
     } else if (isArrayOfObjects(field[key]) && !EXCLUDED_FIELDS.includes(key)) {
      console.log('si array of', key)
      field[key].forEach((item, idx) => {
        const subTokens = collectTokensFromObject(item, locales)
          .map(subToken => ({
            ...subToken,
            name: field.name,
            idx,
            key,
            subKey: subToken.key
          }));
        if (!_.isEmpty(subTokens)) {
          result = [...result, ...subTokens];
        }
      });
    } else if (key === 'validation' && isI18n(field.validation.message)) {
      // find the main locale, favour en-* otherwise the first non null key
      const sourceLocale = getSourceLocale(field.validation.message);
      if (sourceLocale) {
        locales.forEach(locale => {
          if (_.isEmpty(field.validation.message[locale])) {
            result = [
              ...result,
              {
                id: _.uniqueId('gc_'),
                name: field.name,
                key: 'validation',
                idx: null,
                subKey: 'message',
                targetLocale: locale,
                sourceLocale: sourceLocale,
                source: field.validation.message[sourceLocale],
                translation: null
              }
            ];
          }
        });
      }
    }
  });

  return result;
};

const getGroceryList = (form) => {
  return reduceFields(
    form.fields,
    (field, accumulator) => {
      const tokens = collectTokensFromObject(field, form.locales);
      if (_.isArray(tokens) && !_.isEmpty(tokens)) {
        return [...accumulator, ...tokens];
      }
      return accumulator;
    },
    []
  );
};

export { getGroceryList };
