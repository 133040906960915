const manifest = {
  label: 'Form Material UI',
  fields: [],
  dependencies: {
    packages: {
      'react': '18.2.0',
      'react-dom': '18.2.0',
      '@mui/material': 'latest',
      '@emotion/react': 'latest',
      '@emotion/styled': 'latest',
    }
  }
};

export { manifest };
