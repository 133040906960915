/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';

import { TagPopover } from '../../../../../components';

const ValidateHelp = () => {
  return (
    <div className="">
      <p>
        Implement a custom JavaScript validator. The function accepts two arguments:
        {' '}
        <TagPopover
          title="value"
          text="the current value of the field"
        >value</TagPopover>
        {' '}and{' '}<TagPopover
          title="formValues"
          text={
            <>
              An hash with all current values of the form, useful to validate two ore more fields.<br/>
              Tipical example is to check if the two passwords match in a form.
            </>
          }
        >formValues</TagPopover>
      </p>
      <p>
      Returning <TagPopover>true</TagPopover> or <TagPopover>null</TagPopover> or <TagPopover>undefined</TagPopover> means the value is valid.<br/>
        Returning <TagPopover>false</TagPopover> or any non null value means the value is <b>not valid</b>,
        if returns a <TagPopover>string</TagPopover> (or a
        {' '}<TagPopover
          title="i18n"
          text={
            <>
              An <em>i18n</em> is an hash representing the same sentence in different languages:
              keys are the locales and values are the related translation, for example:<br/>
              <code>
              {'{'}<br/>
              &nbsp;&nbsp;'en-US': 'This is in English',<br/>
              &nbsp;&nbsp;'it-IT': 'This is in Italian'<br/>
              {'}'}<br/>
              </code>
            </>
          }
        >i18n</TagPopover> object) it will be used as validation message.

      </p>
      <p>
        Example:<br/>
        <code>
          // valid if at least 10 chars
          return value.length {'>='} 10;
        </code><br/>
        or<br/>
        <code>
          if (value.length {'<'} 10) {'{'}<br/>
          &nbsp;&nbsp;return {'{'}<br/>
          &nbsp;&nbsp;&nbsp;&nbsp;'en-US': 'Too short',<br/>
          &nbsp;&nbsp;&nbsp;&nbsp;'it-IT': 'Troppo corta'<br/>
          &nbsp;&nbsp;{'}'};<br/>
          {'}'}
        </code>

      </p>
    </div>
  );
};

export { ValidateHelp };
