import React, { useCallback } from 'react';
import _ from 'lodash';

import LetsForm from '../../../components/lets-form/react-rsuite5';
import { MarkdownText } from '../../../components/markdown-text';
import { replaceTokens } from '../helpers/replace-tokens';

import './install-form-modal.scss';

const FIELD_NAME = {
  label: 'Field name',
  hint: 'Select a field name, avoid existing field names to avoid conflicts',
  validation: {
    validate: `
      if (!value.match(/^[a-zA-Z_]{1,}$/)) {
        return 'Invalid value for field name, just letters';
      }
      if (formValues.existingFields.includes(value)) {
        return 'Field name "' + value + '" already present in the current form';
      }
    `
  }
};

const InstallFormModal = ({ value, onSubmit, onCancel }) => {
  const { template, existingFields = [] } = value;

  const handleSubmit = useCallback(
    values => {
      const newTemplate = { ...template };
      newTemplate.template = replaceTokens(template.template, values);
      onSubmit(newTemplate);
    },
    [onSubmit, template]
  );

  const defaultValues = template.installForm.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]: field.defaultValue ?? undefined
    }),
    {
      existingFields
    }
  );

  const paramsFields = template.installForm
    .map(field => _.omit({ ...field, ...(field.json || {}) }, 'defaultValue', 'json'))
    .map(field => field.name === 'fieldName' ? { ...field, ...FIELD_NAME } : field);

  const installForm = {
    version: 2,
    layout: 'vertical',
    validationMode: 'onSubmit',
    buttonsAlign: 'center',
    labelSubmit: 'Import template',
    showErrors: 'inline',
    labelCancel: 'Cancel',
    fields: [
      {
        component: 'placeholder',
        text: '**Import parameters**',
        name: 'existingFields'
      },
      ...paramsFields
    ]
      /*.push()*/
  };

  return (
    <div className="lf-install-form-modal">
      <MarkdownText className="description">{template.description}</MarkdownText>
      <LetsForm
        className="form-import-form"
        defaultValues={defaultValues}
        form={installForm}
        onSubmit={handleSubmit}
        onReset={onCancel}
      />
    </div>
  );
};

export { InstallFormModal };
