import _ from 'lodash';

import Frameworks from '../../frameworks';

import { generateImports } from './generate-imports';
import { renderImports } from './render-import';

const generateMainCode = ({ form, framework, locale }) => {
  const manifest = Frameworks[framework];

  if (!manifest) {
    return '';
  }

  const imports = generateImports({ form, framework, locale });

  let codeSandbox = '' +
    `import React from 'react';\n` +
    `import { createRoot } from 'react-dom/client';\n` +
    `import LetsForm from '${manifest.letsFormLibrary}';\n` +
    renderImports(imports.js) +
    renderImports(imports.css) +   
    `\n` +
    `import { form } from './form';\n` + 
    `import './form.css';\n` + 
    `\n` +
    `const container = document.getElementById('app');\n` +
    `const root = createRoot(container);\n` +
    `\n` +
    `// Tip: hit reload few times if CodeSandbox fails to load external\n` +
    `// modules or answers with import errors';\n`;

  // separator
  codeSandbox += '\n';

  // add extra code
  if (manifest.extraCode) {
    codeSandbox += manifest.extraCode;
    // separator
    codeSandbox += '\n';
  }

  let letsFormStartWrapper, letsFormEndWrapper;
  if (manifest.letsFormStartWrapper && manifest.letsFormEndWrapper) {
    letsFormStartWrapper = _.isFunction(manifest.letsFormStartWrapper) ? 
      manifest.letsFormStartWrapper({ form, framework, locale }) : manifest.letsFormStartWrapper;
    letsFormEndWrapper = _.isFunction(manifest.letsFormEndWrapper) ? 
      manifest.letsFormEndWrapper({ form, framework, locale }) : manifest.letsFormEndWrapper;
  }

  const tab = letsFormStartWrapper && letsFormEndWrapper ? '  ' : '';

  // vanilla component definition
  const appFunction = [
          `function App() {`,
          `  return (`,
          `    <div className="container">`,
    letsFormStartWrapper ?
          `      ${letsFormStartWrapper}` : null,
    tab + `      <LetsForm`,
    tab + `        form={form}`,
    locale ?
    tab + `        locale="${locale}"` : '',
    tab + `        onSubmit={(values) => {`,
    tab + `          // do something`,
    tab + `          console.log("form values", values);`,
    tab + `        }}`,
    tab + `      />`,
    letsFormEndWrapper ?
          `      ${letsFormEndWrapper}` : null,
          `    </div>`,
          `  );`,
          `}`
  ];
  codeSandbox += appFunction
    .filter(Boolean)
    .join('\n') + '\n';

  // separator
  codeSandbox += '\n';

  // final render
  codeSandbox += `root.render(\n` +
    `  <React.StrictMode>\n` +
    `    <App />\n` +
    `  </React.StrictMode>\n` +
    `);`;

  return codeSandbox;
}

export { generateMainCode };
