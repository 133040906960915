import React, { useState } from 'react';
import { Button } from 'rsuite';
import { gql, useQuery } from '@apollo/client';

import { AlchemicLoader, ErrorPlaceholder } from '../../../components';
import LetsForm from '../../../components/lets-form';

import { replaceTokens } from '../helpers/replace-tokens';
import './preview-template.scss';

const GET_TEMPLATE = gql`
query($id: ID!) {
  template: templates_by_id(id: $id) {
    id,
    installForm,
    template
  }
}`;

const PreviewTemplate = ({ value, onCancel }) => {
  const [currentForm, setCurrentForm] = useState();
  const { loading, error } = useQuery(GET_TEMPLATE, {
    variables: {
      id: value.template.id
    },
    onCompleted: data => {
      const defaultValues = (data.template.installForm || [])
        .reduce(
          (acc, obj) => ({ ...acc, [obj.name]: obj.defaultValue }),
          {}
        );
      const templateWithDefaults = replaceTokens(data.template.template, defaultValues);
      try {
        setCurrentForm(JSON.parse(templateWithDefaults))
      } catch(e) {
        // do nothing
      }
    }
  });

  const isError = error || (!loading && !currentForm);

  return (
    <div className="lf-preview-template">
      {loading && (
        <AlchemicLoader margin={50} />
      )}
      {isError && (
        <ErrorPlaceholder />
      )}
      {!loading && !isError && (
        <LetsForm
          framework="react-rsuite5"
          form={currentForm}
          demo={true}
        />
      )}
      <div className="buttons">
        <Button appearance="primary" onClick={onCancel}>Close preview</Button>
      </div>
    </div>
  );
};

export { PreviewTemplate };
