import React, { useEffect, useRef, useState } from 'react';
import { Container, Content } from 'rsuite';

import { AlchemicLoader } from '../../components/index.js';

import './terms.scss';

const TermsPage = () => {
  const Privacy = useRef(0);
  let [loading, setLoading] = useState(true);

  useEffect(
    ()  => {
      const run = async () => {
        const { TermsContent } = await import('./terms.js');
        Privacy.current = TermsContent
        setLoading(false);
      }
      run();
    },
    []
  );

  const TermsContent = Privacy.current;

  return (
    <div>
      <Container className="lf-page-terms">
        <Content className="privacy-content">
          {loading && (
            <div style={{ paddingTop: '130px' }}>
              <AlchemicLoader />
            </div>
          )}
          {!loading && <TermsContent />}
        </Content>
      </Container>
    </div>
  );
};

export { TermsPage };
