/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useCallback } from 'react';
import { Header, Container, Content, Nav } from 'rsuite';
import _ from 'lodash';
import { gql, useApolloClient } from '@apollo/client';
import { useParams } from 'react-router-dom';

import LetsForm, { formHelper} from '../../components/lets-form/react-rsuite5';
import { UserProfile } from '../../components/lets-form-forms';
import jsonOrganizationProfile from '../../components/lets-form-forms/organization-profile.json';

import { NavigationBar, Breadcrumbs } from '../../layout';
import { useTracking, useCurrentUser, useNotification } from '../../hooks';
import { MembershipPlans } from '../../components';

import { CurrentPlan, TeamMembers } from './views';

import './settings.scss';

const UPDATE_ORGANIZATION = gql`
mutation ($id: ID!, $organization: update_organizations_input!){
  update_organizations_item(
    id: $id,
    data: $organization
  ) {
    id,
    name
  }
}`;


const SettingsPage = () => {
  const { section } = useParams();
  const { user, getMe } = useCurrentUser({ redirectToLogin: true });
  const client = useApolloClient();
  useTracking();

  const [loading, setLoading] = useState(false);
  const notification = useNotification();
  const [activeKey, setActiveKey] = useState(section || 'user');
  
  const handleChangeUserProfile = useCallback(
    async (values) => {
      try {
        setLoading(true);
        const token = localStorage.getItem('access_token');

        // if present upload file
        let avatarId;
        if (values.avatar) {
          const formData = new FormData();
          formData.append('file', values.avatar.blobFile, values.avatar.blobFile.name)
          const response = await fetch('https://dashboard.letsform.dev/files', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: formData,
          });
          const jsonFile = await response.json();
          avatarId = jsonFile?.data?.id
        }

        const res = await fetch(
          `https://dashboard.letsform.dev/users/me`,
          {
            method: 'PATCH',
            headers: {
              Authorization: `Bearer ${token}`,
              'content-type': 'application/json'
            },
            body: JSON.stringify({
              first_name: values.first_name,
              last_name: values.last_name,
              newsletter: values.newsletter ?? false,
              avatar: avatarId ?? undefined
            })
          }
        );
        setLoading(false);

        if (res.status === 200) {
          await getMe({ token });
          notification.success(
            'Profile',
            <div><b>Your profile</b> was updated succesfully</div> 
          );
        } else {
          notification.error(
            'Profile',
            <div><b>Something wen wrong</b> trying to update your profile, try again later.</div>
          );
        }
      } catch(e) {
        setLoading(false);
        notification.error(
          'Profile',
          <div><b>Something went wrong</b> trying to update your profile, try again later.</div>
        );
      }
    },
    [getMe, notification]
  );

  const handleOrganizationProfile = useCallback(
    async (values) => {
      try {
        setLoading(true);
        await client.mutate({
          mutation: UPDATE_ORGANIZATION,
          variables: {
            id: user.organization.id,
            organization: _.pick(values, ['name'])
          }
        });
        const token = localStorage.getItem('access_token');
        await getMe({ token });
        setLoading(false);
        notification.success(
          'Organization',
          <div><b>Your organization</b> was updated succesfully</div> 
        );
      } catch(e) {
        setLoading(false);
        notification.error(
          'Organization',
          <div><b>Something went wrong</b> trying to update your organization, try again later.</div>
        );
      }
    },
    [client, getMe, notification, user]
  );


  const handleKey = useCallback(
    key => {
      window.history.replaceState(null, null, `/settings/${key}`);
      setActiveKey(key);
    },
    []
  );

  return (
    <div>
      <Header>
        <NavigationBar />
      </Header>
      <Container className="lf-page-settings">
        <Content className="settings-detail">
          <Breadcrumbs />
          <div style={{ width: 'auto' }}>
            <Nav 
              appearance="subtle" 
              activeKey={activeKey} 
              onSelect={handleKey} 
            >
              <Nav.Item eventKey="user">
                User profile
              </Nav.Item>
              <Nav.Item eventKey="organization">Organization</Nav.Item>
              <Nav.Item eventKey="plan">Plan</Nav.Item>
              <Nav.Item eventKey="team-members">Team members</Nav.Item>
              <Nav.Item eventKey="danger-zone">Danger zone</Nav.Item>
            </Nav>

            {activeKey === 'user' && (
              <UserProfile
                defaultValues={{ ...user, avatar: null }}
                disabled={loading}
                hideCancel={true}
                onSubmit={handleChangeUserProfile}
              />
            )}

            {activeKey === 'organization' && (
              <LetsForm
                form={
                  formHelper(jsonOrganizationProfile)
                    .setField('name', 'postfix', `(${user.organization.id})`)
                    .form()
                }
                hideCancel={true}
                defaultValues={user.organization}
                disabled={loading}
                onSubmit={handleOrganizationProfile}
              />
            )}
            {activeKey === 'plan' && (            
              user.plan.price === 0 ? <MembershipPlans /> : <CurrentPlan />                                 
            )}
            {activeKey === 'team-members' && (            
              <TeamMembers organizationId={user.organization.id} />                                 
            )}
            {activeKey === 'danger-zone' && (            
              <div><br/>Coming soon</div>                                 
            )}

          </div>
        </Content>
      </Container>
    </div>
  );
};

export { SettingsPage }
