import React from 'react';

import { isEmptyForm } from '../../../components/lets-form';
import { useFormBuilderStore } from '../../../pages/builder/state';

import { LaunchCodeSandbox } from './launch-codesandbox';

const CodeSandboxMenuItem = () => {
  const form = useFormBuilderStore(state => state.form);
  const framework = useFormBuilderStore(state => state.framework);
  const locale = useFormBuilderStore(state => state.locale);

  return (
    <>
      {!isEmptyForm(form) && (
        <LaunchCodeSandbox
          form={form}
          framework={framework}
          locale={locale}
        />
      )}
    </>
  );
};

export { CodeSandboxMenuItem };
