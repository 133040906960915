import React, { useCallback } from 'react';
import { Button } from 'rsuite';
import PropTypes from 'prop-types';

import { useNotification } from '../../hooks';

const CopyAndPasteButton = ({
  text,
  size,
  appearance = 'ghost',
  disabled = false,
  float = 'right',
  label = 'Copy to Clipboard',
  header = 'Copied!',
  notification = 'Text succesfully copied to clipboard',
  onClick = () => {}
}) => {
  const notify = useNotification();

  const handleClick = useCallback(
    () => {
      navigator.clipboard.writeText(text);
      notify.success(
        header,
        <div>{notification}</div>
      );
      onClick();
    },
    [header, notification, notify, onClick, text]
  );
  return (
    <Button
      disabled={disabled}
      size={size}
      appearance={appearance}
      style={{ float }}
      onClick={handleClick}
      className="ui-clipboard-button"
    >
      {label}
    </Button>
  );
};
CopyAndPasteButton.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  notification: PropTypes.string
};

export { CopyAndPasteButton };
