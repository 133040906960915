import React from 'react';

import _ from 'lodash';
import { List, FlexboxGrid, Tag } from 'rsuite';

import { useCurrentUser, useNotification, useEndpoint } from '../../../hooks';
import { AlchemicLoader, ErrorBox, UserAvatar } from '../../../components';

import './team-members.scss';

const byName = (a, b) => {
  if (a.isOrganizationAdmin) {
    return -1;
  } else {
    const nameA = makeName(a);
    const nameB = makeName(b);
    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
};

const makeName = member => {
  if (!_.isEmpty(member.first_name) || !_.isEmpty(member.last_name)) {
    return [member.first_name,member.last_name].filter(Boolean).join(' ');
  } else if (!_.isEmpty(member.email)) {
    return member.email.split('@')[0];
  }
  return 'Anonymous';
};

const MemberItem = ({ member, isYou }) => {
  return (
    <List.Item className="lf-member">
      <FlexboxGrid align="middle">
        <FlexboxGrid.Item colspan={2} className="avatar">
          <UserAvatar id={member.avatar?.id}/>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item className="names" colspan={16}>
          <div className="name">{makeName(member)}</div>
          <div className="email">{member.email}</div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item className="tags" colspan={6}>
          <Tag>{member.isOrganizationAdmin ? 'admin' : 'user'}</Tag>
          {isYou && <Tag className="green">You</Tag>}
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </List.Item>
  );
};

const TeamMembers = () => {
  const { user } = useCurrentUser();
  const notification = useNotification();
  const {
    error, 
    data,
    loading,
    done
  } = useEndpoint(
    `/api/organizations/${user.organization.id}/members`,
    {
      onError: () => {
        notification.error(
          'Members',
          <div>
            Something went wrong trying to fetch your organization's members
          </div>
        );
      }
    }
  );

  return (
    <div className="lf-settings-team-members">
      {loading && (
        <AlchemicLoader margin={60}/>
      )}
      {error && (
        <ErrorBox error="Error loading team members"/>
      )}
      {done && data?.members && (
        <List className="lf-members" hover>
          {data.members.sort(byName).map(member => (
            <MemberItem key={member.id} member={member} isYou={member.id === user.id}/>
          ))}
        </List>
      )}
    </div>
  );
};

export { TeamMembers };
