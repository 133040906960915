import { definePlugin } from '../../code-plug';

import { ExportPanelContainer } from './views/export-panel-container';

definePlugin('builder-export', ({ registerView }) => {

  registerView(
    'builder-tabs',
    ExportPanelContainer,
    {
      tabName: 'Export',
      tabKey: 'export'
    }
  );
});
