import _ from 'lodash';

import { generateFormJs } from './generate-form-js';
import { generateHtmlImportHtml } from './generate-html-import-html';

const generateHtmlImportMapBundle = ({ form, framework, locale }) => {
  const appName = 'html-' + _.kebabCase(form.name);

  return {
    appName,
    files: {
      [`${appName}/form.js`]: {
        content: generateFormJs(form, framework)
      },
      [`${appName}/index.html`]: {
        content: generateHtmlImportHtml(framework, form, locale)
      }
    }
  };
};

export { generateHtmlImportMapBundle };
