import React from 'react';
import {
  Form,
  ButtonToolbar,
  Dropdown
} from 'rsuite';
import _ from 'lodash';

import { useRegion } from '../../../../code-plug';
import { useFormBuilderStore } from '../../state';

import './toolbar.scss';

const Toolbar = ({
  form,
  onSelect
}) => {
  const disableToolbar = useFormBuilderStore(state => state.disableToolbar);
  const renderFormToolbar = useRegion('form-builder-toolbar');

  return (
    <div className="lf-toolbar">
      <Form>
        <div className="form-name">
          <span className="label-name">Name</span>
          <span className="name">{form.name || 'Unnammed'}</span>
        </div>

        <ButtonToolbar style={{ float: 'right', marginTop: '3px' }}>

          {renderFormToolbar({ disabled: disableToolbar }, ({ params }) => _.isEmpty(params?.parent) && params?.start)}
          <Dropdown
            title="Fields"
            className="float-dropdown"
            menuStyle={{ backgroundColor: '#ffffff' }}
            onSelect={onSelect}
            disabled={disableToolbar}
          >
            {renderFormToolbar({ disabled: disableToolbar }, ({ params }) => params?.parent === 'Fields')}
            <Dropdown.Item eventKey="cmd:deleteAllFields">Delete all fields</Dropdown.Item>
            <Dropdown.Separator />
            <Dropdown.Item eventKey="cmd:completeTranslations">Complete translations</Dropdown.Item>
          </Dropdown>

          <Dropdown
            title="Form"
            style={{ backgroundColor: '#ffffff' }}
            onSelect={onSelect}
            disabled={disableToolbar}
          >
            <Dropdown.Item eventKey="cmd:newEmptyForm">New empty form</Dropdown.Item>
            {renderFormToolbar({ disabled: disableToolbar }, ({ params }) => params?.parent === 'Form')}
          </Dropdown>
          {renderFormToolbar({ disabled: disableToolbar }, ({ params }) => _.isEmpty(params?.parent) && params?.end)}
        </ButtonToolbar>
      </Form>
    </div>
  );
}

export { Toolbar };
