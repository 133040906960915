import { formHasComponents } from 'lets-form/utils';

import { notEmpty } from '../helpers/not-empty';
import {
  ANTD_ALLOW_CLEAR,
  ANTD_HINT_AS_TOOLTIP,
  ANTD_SIZE,
  COMMON_TRITTICUS_STATUS,
  makeColums,
  RSUITE5_APPEARANCE,
  RSUITE5_PLACEMENT,
  RSUITE5_SIZE,
  RSUITE_CLEANABLE,
  BOOTSTRAP_SIZE,
  BOOTSTRAP_PREFIXES,
  BOOTSTRAP_FLOATING_LABELS,
  COMMON_PLACEHOLDER,
  COMMON_TRITTICUS,
  COMMON_FULL_WIDTH_SIZE,
  MANTINE_SIZE,
  MANTINE_RADIUS,
  MANTINE_VARIANT,
  makeOptions,
  MANTINE_SECTIONS,
  MANTINE_POINTER,
  MANTINE_ERROR_WITH_STYLE,
  MUI_FLOATING_LABEL,
  MUI_SIZE,
  MUI_VARIANT,
  MUI_COLORS,
  MUI_DATE_FORMAT,
  MUI_FORMAT_DENSITY,
  ANTD_VARIANT
} from '../costants/index.js';

const manifest = {
  label: 'Date',
  category: 'general',
  description: 'Date field, returns a string in ISO-8601 format (i.e., 1972-10-01)',
  name: 'date',
  frameworks: [
    'react-antd',
    'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    ...COMMON_TRITTICUS,
    COMMON_PLACEHOLDER,
    COMMON_TRITTICUS_STATUS
  ],
  'react-bootstrap': [
    BOOTSTRAP_SIZE,
    BOOTSTRAP_PREFIXES,
    BOOTSTRAP_FLOATING_LABELS,
    COMMON_FULL_WIDTH_SIZE
  ],
  'react-material-ui': [
    makeColums(
      MUI_SIZE,
      MUI_VARIANT,
      MUI_COLORS
    ),
    MUI_DATE_FORMAT,
    {
      name: 'views',
      label: 'Views',
      component: 'multiselect',
      cleanable: true,
      fullWidth: true,
      placeholder: 'Select views',
      options: [
        { value: 'day', label: 'day' },
        { value: 'month', label: 'month' },
        { value: 'year', label: 'year' }
      ],
      hint: 'Available views',
      'react-rsuite5': {
        tooltip: true
      }
    },
    makeColums(
      {
        name: 'closeOnSelect',
        component: 'toggle',
        label: 'Close on select',
        hint: 'If true, the popover or modal will close after submitting the full date',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'reduceAnimations',
        label: 'Reduce animations',
        component: 'toggle',
        hint: 'If true, disable heavy animations',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'showDaysOutsideCurrentMonth',
        label: 'Days outside',
        component: 'toggle',
        hint: `If true, days outside the current month are rendered:
- if fixedWeekNumber is defined, renders days to have the weeks requested.
- if fixedWeekNumber is not defined, renders day to fill the first and last week of the current month.
- ignored if calendars equals more than 1 on range pickers`,
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      MUI_FLOATING_LABEL,
      {
        name: 'disableFuture',
        label: 'Disable future',
        component: 'toggle',
        hint: 'If true, disable values after the current date for date components, time for time components and both for date time components',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'disableHighlightToday',
        label: 'Disable today',
        component: 'toggle',
        hint: `If true, today's date is rendering without highlighting with circle`,
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'disableOpenPicker',
        label: 'Disable open',
        component: 'toggle',
        hint: 'If true, the open picker button will not be rendered (renders only the field)',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'disablePast',
        label: 'Disable past',
        component: 'toggle',
        hint: 'If true, disable values before the current date for date components, time for time components and both for date time components',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'displayWeekNumber',
        label: 'Week number',
        component: 'toggle',
        hint: 'If true, the week number will be display in the calendar',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    {
      name: 'loading',
      component: 'toggle',
      label: 'Loading',
      hint: 'If true, calls renderLoading instead of rendering the day calendar. Can be used to preload information and show it in calendar',
      'react-rsuite5': {
        tooltip: true
      }
    },
    makeColums(
      {
        name: 'fixedWeekNumber',
        component: 'input-number',
        label: 'Fixed week number',
        min: 1,
        step: 1,
        hint: 'The day view will show as many weeks as needed after the end of the current month to match this value. Put it to 6 to have a fixed number of weeks in Gregorian calendars',
        'react-rsuite5': {
          tooltip: true
        }
      },
      MUI_FORMAT_DENSITY
    ),
    makeColums(
      {
        name: 'monthsPerRow',
        component: 'select',
        label: 'Months per row',
        options: [{ value: 3, label: '3' }, { value: 4, label: '4' }],
        fullWidth: true,
        hint: 'Months rendered per row',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'yearsPerRow',
        component: 'select',
        label: 'Years per row',
        options: [{ value: 3, label: '3' }, { value: 4, label: '4' }],
        fullWidth: true,
        hint: 'Years rendered per row',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      {
        name: 'minDate',
        component: 'date',
        label: 'Min date'
      },
      {
        name: 'maxDate',
        component: 'date',
        label: 'Max date'
      }
    )
  ],
  'react-rsuite5': [
    {
      name: 'format',
      label: 'Date format',
      component: 'input-text',
      hint: 'Check https://date-fns.org library for available formats'
    },
    makeColums(
      RSUITE5_SIZE,
      RSUITE5_APPEARANCE,
      RSUITE5_PLACEMENT
    ),
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      RSUITE_CLEANABLE,
      {
        name: 'editable',
        label: 'Editable',
        component: 'toggle',
        hint: 'Rendered as an input, the date can be entered via the keyboard',
        'react-rsuite5': {
          tooltip: true
        }
      },
      []
    ),
    makeColums(
      {
        name: 'oneTap',
        label: 'One tap',
        component: 'toggle',
        hint: 'One click to complete the selection date',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'showMeridian',
        label: 'Show meridian',
        component: 'toggle',
        hint: 'Display hours in 12 format',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'showWeekNumbers',
        label: 'Show week numbers',
        component: 'toggle'
      }
    ),
    makeColums(
      {
        name: 'isoWeek',
        label: 'ISO Week',
        component: 'toggle',
        hint: 'ISO 8601 standard, each calendar week begins on Monday and Sunday on the seventh day',
        'react-rsuite5': {
          tooltip: true
        }
      },
      [],
      []
    )
  ],
  'react-antd': [
    makeColums(
      ANTD_SIZE,
      ANTD_VARIANT
    ),
    {
      name: 'format',
      label: 'Date format',
      component: 'input-text',
      hint: 'Dayjs format to display input value, "MMMM D, YYYY" by default. Check https://day.js.org/docs/en/display/format for reference'
    },
    makeColums(
      {
        name: 'picker',
        label: 'Picker type',
        component: 'select',
        cleanable: true,
        placeholder: 'Select picker',
        options: [
          { value: 'date', label: 'Date' },
          { value: 'week', label: 'Week' },
          { value: 'month', label: 'Month' },
          { value: 'quarter', label: 'Quarter' },
          { value: 'year', label: 'Year' },
        ]
      },
      {
        name: 'placement',
        label: 'Placement',
        placeholder: 'Select placement',
        component: 'select',
        cleanable: true,
        options: [
          { value: 'bottomLeft', label: 'Bottom left' },
          { value: 'bottomRight', label: 'Bottom right' },
          { value: 'topLeft', label: 'Top left' },
          { value: 'topRight', label: 'Top right' }
        ]
      },
      {
        name: 'mode',
        label: 'Picker panel mode',
        component: 'select',
        cleanable: true,
        placeholder: 'Select mode',
        options: [
          { value: 'time', label: 'Time' },
          { value: 'date', label: 'Date' },
          { value: 'month', label: 'Month' },
          { value: 'year', label: 'Year' },
          { value: 'decade', label: 'Decade' },
        ]
      }
    ),
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      {
        name: 'minDate',
        component: 'date',
        label: 'Min date'
      },
      {
        name: 'maxDate',
        component: 'date',
        label: 'Max date'
      }
    ),
    makeColums(
      {
        name: 'showTime',
        label: 'Show time',
        component: 'toggle'
      },
      {
        name: 'showNow',
        label: 'Show now',
        component: 'toggle',
        hint: 'Show now button',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'showToday',
        label: 'Show today',
        component: 'toggle',
        hint: 'Show today button',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'preserveInvalidOnBlur',
        component: 'toggle',
        label: 'Preserve Invalid',
        hint: 'Not clean input on blur even when the typing is invalidate',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'inputReadOnly',
        component: 'toggle',
        label: 'Input Read only',
        hint: 'Set the readonly attribute of the input tag (avoids virtual keyboard on touch devices)',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'needConfirm',
        component: 'toggle',
        label: 'Need confirm',
        hint: 'Need click confirm button to trigger value change. Default false when multiple',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      ANTD_ALLOW_CLEAR,
      ANTD_HINT_AS_TOOLTIP,
      []
    ),
  ],
  'react-mantine': [
    makeColums(
      MANTINE_SIZE,
      MANTINE_RADIUS,
      MANTINE_VARIANT
    ),
    {
      name: 'valueFormat',
      component: 'input-text',
      label: 'Value format',
      hint: 'Dayjs format to display input value, "MMMM D, YYYY" by default. Check https://day.js.org/docs/en/display/format for reference',

    },
    makeColums(
      {
        name: 'firstDayOfWeek',
        component: 'select',
        label: 'First day of week',
        options: [
          { value: 0, label: 'Sunday' },
          { value: 1, label: 'Monday' },
          { value: 2, label: 'Tuesday' },
          { value: 3, label: 'Wednesday' },
          { value: 4, label: 'Thursday' },
          { value: 5, label: 'Friday' },
          { value: 6, label: 'Saturday' },
        ],
        hint: 'number 0-6, 0 - Sunday, 6 - Saturday, defaults to 1 - Monday',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'hideOutsideDates',
        component: 'toggle',
        label: 'Hide outside dates',
        hint: 'Determines whether outside dates should be hidden, defaults to false',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'labelSeparator',
        component: 'input-text',
        label: 'Label separator',
        hint: 'Separator between range value',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      {
        name: 'dateType',
        component: 'select',
        label: 'Picker type',
        options: makeOptions(['default', 'range', 'multiple'])
      },
      {
        name: 'dropdownType',
        component: 'select',
        label: 'Dropdown type',
        options: makeOptions(['modal', 'popover']),
        hint: 'Type of dropdown, defaults to popover',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'allowDeselect',
        component: 'toggle',
        label: 'Allow deselect',
        hint: 'Determines whether user can deselect the date by clicking on selected item, applicable only when type="default"',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'clearable',
        component: 'toggle',
        label: 'Clearable',
        hint: 'Determines whether input value can be cleared, adds clear button to right section, false by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'hideWeekdays',
        component: 'toggle',
        label: 'Hide weekdays',
        hint: 'Determines whether weekdays row should be hidden, defaults to false',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'defaultLevel',
        component: 'select',
        label: 'Default level',
        options: makeOptions(['decade', 'month', 'year'])
      },
      {
        name: 'hasNextLevel',
        component: 'toggle',
        label: 'Has next level',
        hint: 'Determines whether next level button should be enabled, defaults to true',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'maxLevel',
        component: 'select',
        label: 'Max level',
        options: makeOptions(['decade', 'month', 'year']),
        hint: 'Max level that user can go up to (decade, year, month), defaults to decade',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'numberOfColumns',
        component: 'input-number',
        label: 'Number of Columns',
        hint: 'Number of columns to render next to each other',
        min: 1,
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'columnsToScroll',
        component: 'input-number',
        label: 'Columns to scroll',
        min: 1,
        hint: 'Number of columns to scroll when user clicks next/prev buttons, defaults to numberOfColumns',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'minDate',
        component: 'date',
        label: 'Min date'
      },
      {
        name: 'maxDate',
        component: 'date',
        label: 'Max date'
      }
    ),
    makeColums(
      {
        name: 'withCellSpacing',
        component: 'toggle',
        label: 'With cell spacing',
        hint: 'Determines whether controls should be separated by spacing, true by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      MANTINE_POINTER,
      MANTINE_ERROR_WITH_STYLE
    ),
    MANTINE_SECTIONS
  ],
  defaultValues: {
    'react-mantine': {
      'react-mantine': {
        dateType: 'default',
        fullWidth: true,
        valueFormat: 'MMMM D, YYYY',
        dropdownType: 'popover',
        firstDayOfWeek: 1,
        hasNextLevel: true,
        withCellSpacing: true
      }
    },
    'react-antd': {
      'react-antd': {
        bordered: true,
        picker: 'date',
        preserveInvalidOnBlur: false,
        inputReadOnly: false
      }
    },
    'react-material-ui': {
      'react-material-ui': {
        views: ['day', 'year']
      }
    },
    'react-rsuite5': {
      'react-rsuite5': {
        format: 'MMMM dd iii, yyyy'
      }
    }
  },
  dependencies: {
    'react-material-ui': {
      imports: {
        locale: ({ locale }) => locale ?
          import(`dayjs/locale/${locale.split('-')[0]}.js`) : null
      },
      js: [
        `import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';`,
        `import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';`,
        ({ form }) => notEmpty(form.locales) ?
          form.locales.map(locale => `import 'dayjs/locale/${locale.split('-')[0]}';`) : null
      ],
      packages: {
        '@mui/x-date-pickers': 'latest',
        'dayjs': 'latest'
      }
    },
    'react-mantine': {
      js: [
        ({ form }) => notEmpty(form.locales) ?
          form.locales.map(locale => `import 'dayjs/locale/${locale.split('-')[0]}';`) : null
      ],
      css: [
        ({ form }) => formHasComponents(form, ['date', 'datetime']) ?
          { package: '@mantine/dates', path: '/styles.css' } : null
          //'@mantine/dates/styles.css' : null
      ],
      imports: {
        locale: ({ locale }) => locale ?
          import(`dayjs/locale/${locale.split('-')[0]}.js`) : null
      },
      packages: {
        '@mantine/dates': '^7.6.2',
        'dayjs': '^1.11.10'
      }
    },
    'react-rsuite5': {
      js: [
        ({ form }) => notEmpty(form.locales) ?
        form.locales.map(locale => `import locale from 'rsuite/locales/${locale.replace('-', '_')}';`) : null
      ],
      css: [],
      imports: {
        locale: ({ locale }) => locale ?
          import(`rsuite/esm/locales/${locale.replace('-', '_')}.js`) : null
      },
      packages: {}
    },
    'react-antd': {
      js: [
        ({ form, locale }) => notEmpty(form.locales) && locale ?
          `import { ConfigProvider } from 'antd';` : null,
        ({ form, locale }) => notEmpty(form.locales) && locale ?
          form.locales.map(locale => `import locale from 'antd/locale/${locale.replace('-', '_')}';`) : null,
        ({ form, locale }) => notEmpty(form.locales) && locale ?
          form.locales.map(locale => `import 'dayjs/locale/${locale.split('-')[0]}';`) : null
      ],
      css: [],
      imports: {
        locale: ({ locale }) => locale ?
          import(`antd/locale/${locale.replace('-', '_')}.js`) : null,
        dayjs: ({ locale }) => locale ?
          import(`dayjs/locale/${locale.split('-')[0]}.js`) : null
      },
      packages: {
        'dayjs': '^1.11.10'
      }
    }
  }
};

export { manifest };
