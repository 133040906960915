import { useState } from 'react';

import { useLocalStorage } from './local-storage';

const getElapsedMs = (ts, delay) => (new Date().getTime()) - ts.getTime() < delay;


// There's a big side effect here, it's only used for persisting the form in local
// storage, must be refactored with a propere useMemo in case of generalized use
let lastCall;

const useThrottledLocalStorage = function(key, intialValue, delay) {
  const [localStorage, setLocalStorage] = useLocalStorage(key, intialValue);

  const [setThrottled] = useState(() => {
    return value => {
      if (!lastCall || getElapsedMs(lastCall.ts) > delay) {
        console.log('[LetsForm] Form was autosaved');
        // if no last call, then persist, save the ts
        setLocalStorage(value);
        lastCall = {
          ts: new Date()
        };
      } if (lastCall && getElapsedMs(lastCall.ts) < delay) {
        // there's a previous call, just replace the payload and set a time
        if (lastCall.timeoutId) {
          // clear timeout if exists
          clearTimeout(lastCall.timeoutId);
        }
        lastCall = {
          ts: lastCall.ts,
          timeoutId: setTimeout(
            () => {
              console.log('[LetsForm] Form was autosaved');
              setLocalStorage(value);
              lastCall = { ts: new Date() };
            },
            delay - getElapsedMs(lastCall.ts)
          )
        };
      }
    };
  });

  return [localStorage, setThrottled];
};

export { useThrottledLocalStorage };
