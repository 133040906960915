import React from 'react';
import { Button } from 'rsuite';

import CloseOutlineIcon from '@rsuite/icons/CloseOutline';

import './index.scss';

const ErrorPlaceholder = ({
  children,
  title = 'Something wrong happened!',
  buttonLabel = 'Close',
  button = false,
  onClick = () => {}
}) => {

  return (
    <div className="lf-error-placeholder">
      <CloseOutlineIcon style={{fontSize: '4em'}} color="#ffa600"/>
      <br />
      <b>{title}</b>
      {children && (
        <>
          <br />
          <div className="legend">{children}</div>
        </>
      )}
      {button && (
        <div style={{ marginTop: '30px' }}>
          <Button
            appearance="primary"
            onClick={onClick}            
          >{buttonLabel}</Button>
        </div>
      )}
    </div>
  );
};

export { ErrorPlaceholder };
