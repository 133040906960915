import React from 'react';
import { Whisper, Tooltip } from 'rsuite';

import { FRAMEWORKS, FRAMEWORKS_LABELS } from '../../../../components/lets-form';
import { FrameworkIcons } from '../../../../components';
import './framework-icons.scss';

const LABELS = FRAMEWORKS.reduce(
  (acc, value, idx) => ({ ...acc, [value]: FRAMEWORKS_LABELS[idx]}),
  {}
);

const FrameworksIcons = ({
  frameworks = [],
  width = 24,
  height = 24
}) => {
  return (
    <div className="lf-frameworks-icons">
      {FRAMEWORKS
        .filter(framework => frameworks.includes(framework))
        .map((framework, idx) => {
          const Icon = FrameworkIcons[framework];
          return (
            <Whisper
              key={framework}
              trigger="hover"
              placement="top"
              speaker={<Tooltip>{LABELS[framework]}</Tooltip>}
            >
              <div className="icon">
                <Icon width={width} height={height} />
              </div>
            </Whisper>
          );
        })
      }
    </div>
  );
};

export { FrameworksIcons };
