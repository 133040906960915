import React from 'react';
import classNames from 'classnames';
import { Whisper, Tooltip } from 'rsuite';
import InfoRoundIcon from '@rsuite/icons/InfoRound';

import './dashboard-label.scss';

const DasboardLabel = ({ children, description, className }) => {
  return (
    <div className={classNames('lf-label', className)}>
      {children}
      {description && (
        <Whisper
          trigger="hover"
          placement="top"
          speaker={<Tooltip>{description}</Tooltip>}
        >
          <InfoRoundIcon />
        </Whisper>
      )}            
    </div>
  );
};

export { DasboardLabel };
