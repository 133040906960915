import _ from 'lodash';

import Frameworks from '../../frameworks';

import { generateImports } from './generate-imports';
import { renderImports } from './render-import';
import { jsonToReact } from '../../helpers/json-to-react';

import { cleanUp, reduceFields } from '../lets-form';

const generateMarkupCode = (form, framework, locale) => {

  const manifest = Frameworks[framework];

  const imports = generateImports({ form, framework, locale });

  const usedComponents = _.uniq(reduceFields(
    form.fields,
    (field, acc) => {
      if (field.component === 'array') {
        return [...acc, 'LfArray'];
      } else if (field.component === 'group') {
        return [...acc, 'LfGroup'];
      } if (field.component === 'steps') {
        return [...acc, 'LfSteps', 'LfStep'];
      } if (field.component === 'tabs') {
        return [...acc, 'LfTabs', 'LfTab'];
      } if (field.component === 'two-columns' || field.component === 'three-columns' || field.component === 'columns') {
        return [...acc, 'LfColums', 'LfColumn'];
      }
      return [...acc, 'LfField'];
    },
    []
  ));

  // start of code
  let markedCode =
    `import React from 'react';\n`
    + `import LetsForm${!_.isEmpty(usedComponents) ? ', { ' + usedComponents.join(', ') + ' }' : ''} from '${manifest.letsFormLibrary}';\n`
    + renderImports(imports.js)
    + renderImports(imports.css);


  // separator
  markedCode += '\n';

  // add extra code
  if (manifest.extraCode) {
    markedCode += manifest.extraCode;
    // separator
    markedCode += '\n';
  }

  let letsFormStartWrapper, letsFormEndWrapper;
  if (manifest.letsFormStartWrapper && manifest.letsFormEndWrapper) {
    letsFormStartWrapper = _.isFunction(manifest.letsFormStartWrapper) ?
      manifest.letsFormStartWrapper({ form, framework, locale }) : manifest.letsFormStartWrapper;
      letsFormEndWrapper = _.isFunction(manifest.letsFormEndWrapper) ?
      manifest.letsFormEndWrapper({ form, framework, locale }) : manifest.letsFormEndWrapper;
  }

  const tab = letsFormStartWrapper && letsFormEndWrapper ? '  ' : '';

  let markedComponent = [
          'const MyForm = () => {',
          '  return (',
    letsFormStartWrapper ?
          `    ${letsFormStartWrapper}` : null,
    jsonToReact(
      cleanUp(form, framework),
      framework,
      {
        tab: tab + '    ',
        props: {
          locale: locale ?? undefined,
          onSubmit: values => {
            console.log('form values', values);
          }
        }
      }),
    letsFormEndWrapper ?
          `    ${letsFormEndWrapper}` : null,
          '  );',
          '};'
  ];

  // vanilla component definition
  markedCode += markedComponent
    .filter(Boolean)
    .join('\n') + '\n';

  // separator
  markedCode += '\n';

  // export statement
  markedCode += `export { MyForm };`

  return markedCode;
};

export { generateMarkupCode };
