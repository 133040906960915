/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState, useEffect } from 'react';
import { Loader, Button } from 'rsuite';

import './waiting-confirmation.scss';

const CHECK_DELAY = 5000; 

const WaitingConfirmation = ({
  user,
  onConfirmation = () => {}
}) => {
  const [loading, setLoading] = useState(false);

  const handleCheck = useCallback(
    async () => {
      setLoading(true);
      try {
        const res = await fetch(`/api/users/${user.id}/status`);
        const json = await res.json(); 
        setLoading(false);

        if (json?.user?.status === 'active') {
          onConfirmation();
        }

      } catch(e) {
        setLoading(false);
      }
    },
    [onConfirmation, user]
  );

  useEffect(
    () => {
      const timerId = setInterval(handleCheck, CHECK_DELAY);
      return () => clearInterval(timerId);
    },
    [handleCheck]
  );

  return (
    <div className="lf-waiting-confirmation">
      An email was sent to <b>{user.email}</b>, click on the confirmation link to activate the account.<br />
      <b>Check also the spam folder</b>
      <div>
        <Button 
          className="btn-check-activation"
          size="xs" 
          disabled={loading}
          onClick={handleCheck}
        >Check now</Button>
      </div>
      {loading && (
        <div>
          <Loader className="loader-activation" content="Checking..." />
        </div>
      )}
      
    </div>
  );
};

export { WaitingConfirmation };
