const manifest = {
  label: 'Form RSuite 5',
  fields: [
    {
      name: 'rsuite-forms-layout',
      component: 'columns',
      columns: [
        { name: 'one', size: 1 },
        { name: 'two', size: 1 }
      ],
      fields: {
        one: [
          {
            name: 'layout',
            label: 'Layout',
            component: 'select',
            options: [
              { value: 'inline', label: 'Inline' },
              { value: 'horizontal', label: 'Horizontal' },
              { value: 'vertical', label: 'Vertical' }
            ]
          }
        ],
        two: [
          {
            name: 'fluid',
            component: 'toggle',
            label: 'Fluid',
            hint: 'Allows the form to fill 100% of the container',
            'react-rsuite5': {
              tooltip: true
            }
          }
        ]
      }
    }
  ],
  dependencies: {
    packages: {
      'react': '18.2.0',
      'react-dom': '18.2.0',
      'rsuite': '5.55.1'
    }
  }
};

export { manifest };
