import React, { useCallback, useState } from 'react';

import { ProBadge } from '../../pro-badge';

import { SignInUpForm } from './sign-in-up';
import { SignupToSaveForm } from './signup-to-save-form';

import './sign-in-up-banner.scss';

const SignInUpBannerForm = ({
  initialMode,
  requestMode,
  onLogin = () => {},
  onCancel = () => {}
}) => {
  const [currentRequestMode, setCurrentRequestMode] = useState(requestMode);
  const handleAccept = useCallback(
    () => setCurrentRequestMode(null),
    []
  );

  return (
    <div className="lf-sign-in-up-banner-modal">
      <div className="left-column">
        {currentRequestMode === 'saveForm' && (
          <SignupToSaveForm
            onCancel={onCancel}
            onAccept={handleAccept}
          />
        )}
        {!currentRequestMode && (
          <SignInUpForm
            initialMode={initialMode}
            onLogin={onLogin}
          />
        )}
      </div>
      <div className="right-column">
        <h3>Sign up for FREE</h3>
        <ul>
          <li>Save your forms and organize them in projects</li>
          <li>Keep track of versions of the forms</li>
          <li>Export forms into React libraries</li>
          <li>Use predefined form templates</li>
          <li>
            <ProBadge>Pro</ProBadge> GitHub integration
          </li>
          <li>
            <ProBadge>Pro</ProBadge> Automatic translations and i18n support
          </li>
        </ul>
      </div>
    </div>
  );
};

export { SignInUpBannerForm };
