import React from 'react';
import { Avatar } from 'rsuite';

import { IconUser } from '../../assets/icons';

const UserAvatar = ({ id, name }) => {
  const token = localStorage.getItem('access_token');
  const url = `https://dashboard.letsform.dev/assets/${id}?key=system-medium-cover&access_token=${token}`;

  if (id) {
    return (
      <Avatar size="md" circle src={url} alt={name || ''}/>
    );
  } else {
    return (
      <Avatar size="md" circle><IconUser /></Avatar>
    );
  }
};

export { UserAvatar };
