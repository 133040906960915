import {
  makeColums,
  COMMON_HIDDEN,
  MANTINE_COLORS,
  makeOptions,
  MANTINE_RADIUS,
  COMMON_NAME,
  MANTINE_AUTO_CONTRAST
} from '../costants/index.js';

const manifest = {
  label: 'Tabs',
  category: 'layout',
  description: 'Layout component for tabs',
  name: 'tabs',
  frameworks: [
    'react-antd',
    //'react',
    //'react-bootstrap',
    //'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    COMMON_NAME,
    {
      component: 'array',
      name: 'tabs',
      label: 'Select tabs',
      maxHeight: 350,
      fields: [
        {
          component: 'columns',
          columns: [
            { name: 'one', size: 0 },
            { name: 'two', size: 1 }
          ],
          name: 'array-option-item',
          fields: {
            one: [
              {
                name: 'value',
                component: 'input-text',
                placeholder: 'value',
                size: 'sm',
                width: 100,
                fullWidth: false
              }
            ],
            two: [
              {
                name: 'label',
                component: 'input-text-i18n',
                placeholder: 'label',
                size: 'sm',
                fullWidth: true
              }
            ]
          }
        }
      ]
    },
    COMMON_HIDDEN
  ],
  'react-material-ui': [
    makeColums(
      {
        name: 'indicatorColor',
        label: 'Indicator color',
        component: 'select',
        placeholder: 'Select color',
        cleanable: true,
        options: [
          { value: 'primary', label: 'Primary' },
          { value: 'secondary', label: 'Secondary' }
        ]
      },
      {
        name: 'textColor',
        label: 'Text color',
        component: 'select',
        placeholder: 'Select color',
        cleanable: true,
        options: [
          { value: 'primary', label: 'Primary' },
          { value: 'secondary', label: 'Secondary' },
          { value: 'inherit', label: 'Inherit' }
        ]
      }
    ),
    makeColums(
      {
        name: 'centered',
        label: 'Centered',
        component: 'toggle',
        hint: 'If true, the tabs are centered. This prop is intended for large views',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'fullWidth',
        label: 'Full width',
        component: 'toggle',
        hint: 'Will make the tabs grow to use all the available space',
        'react-rsuite5': {
          tooltip: true
        }
      }
    )
  ],
  'react-bootstrap': [
    {
      name: 'variant',
      label: 'Variant',
      component: 'select',
      placeholder: 'Select variant',
      cleanable: true,
      options: [
        { value: 'tabs', label: 'Tabs' },
        { value: 'pills', label: 'Pills' },
        { value: 'underline', label: 'Underline' }
      ]
    },
    makeColums(
      {
        name: 'transition',
        label: 'Transition',
        component: 'toggle',
        hint: 'Sets a default animation strategy for all children',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'fill',
        label: 'Fill',
        component: 'toggle',
        hint: 'Have all Tabss proportionately fill all available width',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'justify',
        label: 'Justify',
        component: 'toggle',
        hint: 'Have all Tabs evenly fill all available width',
        'react-rsuite5': {
          tooltip: true
        }
      }
    )
  ],
  'react': [

  ],
  'react-antd': [
    makeColums(
      {
        name: 'size',
        label: 'Size',
        component: 'select',
        placeholder: 'Select size',
        cleanable: true,
        options: [
          { value: 'small', label: 'Small' },
          { value: 'middle', label: 'Middle' },
          { value: 'large', label: 'Large' }
        ]
      },
      {
        name: 'tabType',
        label: 'Type',
        component: 'select',
        placeholder: 'Select tab type',
        cleanable: true,
        options: [
          { value: 'line', label: 'Line' },
          { value: 'card', label: 'Card' }
        ]
      }
    ),
    makeColums(
      {
        name: 'animated',
        component: 'toggle',
        label: 'Animated'
      },
      {
        name: 'centered',
        component: 'toggle',
        label: 'Centered'
      }
    )
  ],
  'react-rsuite5': [
    {
      name: 'appearance',
      component: 'select',
      label: 'Appearance',
      options: [
        { value: 'default', label: 'default' },
        { value: 'tabs', label: 'tabs' },
        { value: 'subtle', label: 'subtle' },
      ]
    },
    makeColums(
      {
        name: 'reversed',
        component: 'toggle',
        label: 'Reversed'
      },
      {
        name: 'justified',
        component: 'toggle',
        label: 'Justified'
      }
    )
  ],
  'react-mantine': [
    // replace tabs definition
    {
      component: 'array',
      name: 'tabs',
      label: 'Select tabs',
      maxHeight: 350,
      fields: [
        {
          component: 'columns',
          columns: [
            { name: 'one', size: 0 },
            { name: 'two', size: 1 }
          ],
          name: 'array-option-item',
          fields: {
            one: [
              {
                name: 'value',
                component: 'input-text',
                placeholder: 'value',
                size: 'sm',
                width: 100,
                fullWidth: false
              }
            ],
            two: [
              {
                name: 'label',
                component: 'input-text-i18n',
                placeholder: 'label',
                size: 'sm',
                fullWidth: true
              }
            ]
          }
        },
        {
          name: 'fixes',
          component: 'columns',
          columns: [
            { name: 'one', size: 1 },
            { name: 'two', size: 1 },
            { name: 'three', size: 1 }
          ],
          fields: {
            one: [
              {
                ...MANTINE_COLORS,
                size: 'sm',
                label: undefined,
                placeholder: 'Color'
              }
            ],
            two: [
              {
                placeholder: 'Left section',
                name: 'leftSection',
                component: 'input-text',
                size: 'sm'
              }
            ],
            three: [
              {
                placeholder: 'Right section',
                name: 'rightSection',
                component: 'input-text',
                size: 'sm'
              }
            ]
          }
        },
        {
          name: 'disabled',
          component: 'checkbox',
          label: 'Disabled'
        }
      ]
    },
    makeColums(
      MANTINE_COLORS,
      {
        name: 'variant',
        component: 'select',
        label: 'Variant',
        options: makeOptions(['default', 'outline', 'pills']),
        block: true,
        searchable: false,
        script: `if (variant && variant !== 'default') {
          enable('radius');
        } else {
          disable('radius');
        }`
      },
      MANTINE_RADIUS
    ),
    makeColums(
      {
        name: 'grow',
        component: 'toggle',
        label: 'Grow',
        hint: 'Determines whether tabs should take all available space, false by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'justify',
        component: 'select',
        label: 'Justify',
        searchable: false,
        block: true,
        options: [
          { value: 'flex-start', label: 'Flex Start'},
          { value: 'center', label: 'Center'},
          { value: 'flex-end', label: 'Flex End'},
          { value: 'space-between', label: 'Space Between'},
        ]
      }
    ),
    makeColums(
      {
        name: 'orientation',
        component: 'select',
        label: 'Orientation',
        searchable: false,
        block: true,
        options: makeOptions(['horizontal', 'vertical']),
        script: `if (orientation === 'vertical') {
          enable('placement');
        } else {
          disable('placement');
        }
        `
      },
      {
        name: 'placement',
        component: 'select',
        label: 'Placement',
        searchable: false,
        block: true,
        options: makeOptions(['left', 'right'])
      }
    ),
    makeColums(
      {
        name: 'activateTabWithKeyboard',
        component: 'toggle',
        label: 'Activate tab',
        hint: 'Determines whether tab should be activated with arrow key press, true by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'allowTabDeactivation',
        component: 'toggle',
        label: 'Tab deactivation',
        hint: 'Determines whether tab can be deactivated, false by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      MANTINE_AUTO_CONTRAST
    ),
    makeColums(
      {
        name: 'inverted',
        component: 'toggle',
        label: 'Inverted',
        hint: 'Determines whether tabs should have inverted styles, false by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'keepMounted',
        component: 'toggle',
        label: 'Keep mounted',
        hint: 'If set to false, Tabs.Panel content will be unmounted when the associated tab is not active, true by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'loop',
        component: 'toggle',
        label: 'Loop',
        hint: 'Determines whether arrow key presses should loop though items (first to last and last to first), true by default',
        'react-rsuite5': {
          tooltip: true
        }
      }
    )
  ],
  'form-generator': {
    hideValidation: true
  },
  defaultValues: {
    'react-mantine': {
      'react-mantine': {
        activateTabWithKeyboard: true,
        keepMounted: true,
        loop: true,
        variant: 'default'
      }
    },
    'react': {

    },
    'react-rsuite5': {
      'react-rsuite5': {
        appearance: 'tabs',
        reversed: false,
        justified: false,
        pullRight: false
      },
      tabs: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react-antd': {
      'react-antd': {
        animated: false,
        centered: false,
        size: 'middle',
        tabType: 'card'
      },
      tabs: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react-material-ui': {
      'react-material-ui': {
        centered: false,
        fullWidth: false,
        indicatorColor: 'primary',
        textColor: 'primary'
      },
      tabs: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react-bootstrap': {
      'react-bootstrap': {
        variant: 'tabs',
        transition: true,
        fill: false,
        justify: false
      },
      tabs: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    }
  }
};

export { manifest };
