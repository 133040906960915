import { COMMON_NAME } from "../costants";

const manifest = {
  label: 'Hidden',
  category: 'general',
  name: 'hidden',
  frameworks: [
    'react-antd',
    'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  description: 'Hidden field, just a placeholder for data, component will not be visible in the form (only during design)',
  fields: [
    COMMON_NAME,
    {
      name: 'info',
      component: 'placeholder',
      label: null,
      text: 'No params available for this field type, the field is only visible in design mode'
    }
  ],
  //'react-rsuite5': [],
  'form-generator': {
    hideValidation: true,
    hideJsSnippet: true
  },
  defaultValues: {}
};

export { manifest };
