/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';

const CSSHelp = () => {
  return (
    <div>
      <p>
        Add here custom CSS for this form.<br />
        CSS rules are document wide valid and are loaded only while the form is visible.<br/>
        To be sure to not to interfere with other forms and to have rules specific enough to override
        the default ones, be sure to scope them with the special class <pre style={{ display: 'inline' }}>.lf-current-form</pre> which
        is always translated to the current form.   

      </p>
      <p>  
        Example:<br/>
        <code>
.lf-current-form [data-lf-field-name=my_field] {'{'}
&nbsp;&nbsp;--lf-field-column-margin: '0px';
{'}'}
        </code>
      </p>
    </div>
  );
};

export { CSSHelp };
