import React from 'react';
import { useLocation } from 'react-router-dom';

export const useURLQuery = () => {
  const { search } = useLocation();
  return React.useMemo(
    () => {
      const obj = new URLSearchParams(search);
      const result = {};
      obj.forEach((value, key) => result[key] = value);
      return result;
    },
    [search]
  );
};
