import _ from 'lodash';

import { generateCDNUrl, tabify } from '../../helpers';
import Frameworks from '../../frameworks';

import { getPackageJsonDependencies } from './get-package-dependencies';
import { generateImports } from './generate-imports';

/**
 * generateHtmlImportHtml
 * Generate HTML for the Html Import map export
 * @param {*} framework
 * @param {*} form
 * @param {*} locale
 * @returns
 */
const generateHtmlImportHtml = (framework, form, locale) => {
  const deps = getPackageJsonDependencies({ framework, form, locale });
  const imps = generateImports({ form, framework, locale });

  // generate import map, lets-form still needs a special case, by default is not
  // picking up esm but umd
  let htmlImports = Object.keys(deps)
    .map(key => {
      if (key === 'lets-form') {
        const packageUrl = generateCDNUrl(key, {
          version: deps[key],
          path: `/dist/${framework}-esm/index.js`
        })
        return `"${key}/${framework}": "${packageUrl}"`
      } else {
        const packageUrl = generateCDNUrl(key, {
          version: deps[key],
          external: key !== 'react' && key !== 'react-dom' ? 'react,react-dom' : null
        });
        return `"${key}": "${packageUrl}"`;
      }
    })
    .join(',\n');

  // include CSS links from CDN
  let stylesheets = imps.cssLinks
    .map(link => `<link rel="stylesheet" href="${link}">`);

  // code for creation of element, use the default one or the html import
  // factory (in case a Provide must be used) in the /framework directory
  let formElementFactory;
  if (_.isString(Frameworks[framework].htmlImportFactory)) {
    formElementFactory = Frameworks[framework].htmlImportFactory;
  } else if (_.isFunction(Frameworks[framework].htmlImportFactory)) {
    formElementFactory = Frameworks[framework].htmlImportFactory({ framework, form, locale });
  } else {
    formElementFactory = `const formElement = React.createElement(
  LetsForm,
  {
    form,
    onSubmit: values => alert(JSON.stringify(values, null, 2))
  }
);`
  }

  return `<html>
  <head>
    <title>${form.name}</title>
${tabify(stylesheets, 2)}
  </head>
  <body>
    <script type="importmap">
      {
        "imports": {
${tabify(htmlImports, 5)}
        }
      }
    </script>
    <script type="module">
      import React from 'react';
      import { createRoot } from 'react-dom';
      import LetsForm from 'lets-form/${framework}';
      import { form } from './form.js';
${tabify(imps.js, 3)}

      const container = document.getElementById('root');
      const root = createRoot(container);

${tabify(formElementFactory, 3)}
      root.render(formElement);
    </script>
    <div id="root" style="padding:20px;width:450px;"></div>
  </body>
</html>
`;
};

export { generateHtmlImportHtml };
