/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { FlexboxGrid, Col, Nav } from 'rsuite';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { useFormBuilderStore, FormBuilderProvider, useRawFormBuilderStore } from './state';
import { useModal } from '../../components/modal';

import FormGenerator, {
  replaceField,
  deleteField,
  addField
} from '../../components/lets-form/index';
import {
  EmptyPlaceholder,
  CreateHelpButton,
  ErrorBoundary,
  AlchemicLoader,
  EnsureFrameworkProvider
} from '../../components';
import {
  getGroceryList,
  reconcileFormWithGroceryList
} from '../../helpers';
import { useTracking } from '../../hooks';
import { useMapRegion, useRegion, useRawRegion, useEvent } from '../../code-plug';

import {
  defaultForm,
  moveAfterField,
  getValidFramework,
  getValidSize,
  getValidLocale,
  moveBeforeField
} from './helpers';
import {
  Toolbar,
  EditWrapper,
  PlaceholderWrapper,
  FormEditor,
  FieldEditor,
  GroupWrapper,
  ShowError,
  CompleteTranslations
} from './components';

import './index.scss';
import './lf-form-editor-fixes.scss';
import './lf-form-framework-resets.scss';

const RawBuilder = ({
  initialEditMode = true,
  formSchema,
  formFramework,
  formLocale,
  formSize = 12,
  features = {
    shareForm: true,
    showProBadge: false,
    badgeContent: 'Pro',
    cancelButton: true,
    saveOptions: true,
    playgroundForm: false,
    connectors: false
  },
  onChange = () => {},
  onChangeLocale = () => {},
  onChangeSize = () => {},
  onChangeFramework = () => {},
  onCancel = () => {},
  onSave = () => {},
  onSelect = () => {}
}) => {
  const [modal, setModal] = useState(null);
  const {
    hasEdited,
    setHasEdited,
    sidebar,
    setSidebar,
    editMode,
    setEditMode,
    size,
    framework,
    form,
    setForm,
    locale,
    setLocale,
    generation,
    setGeneration,
    defaultValues,
    setDefaultValues,
    plaintextMode,
    // setPlaintextMode,
    field,
    setField,
    //formValue,
    setFormValue,
    //errors,
    setErrors,
    generalError,
    setGeneralError,
    //responses,
    setResponses,
    //jsErrors,
    setJsErrors
  } = useFormBuilderStore();
  const store = useRawFormBuilderStore();
  const tracking = useTracking();

  const emitEditField = useEvent('builderEditField');
  const mapTabs = useMapRegion('builder-tabs');
  const renderTabs = useRegion('builder-tabs');
  const renderCanvas = useRegion('form-builder-canvas');
  const renderPageBuilder = useRegion('form-builder-page');
  const renderFormFooter = useRegion('form-builder-footer');
  const regionsInnerFooter = useRawRegion('form-builder-inner-footer');

  // listen to changes to framework, propagate up
  useEffect(
    () => store.subscribe(
      state => state.framework,
      framework => {
        onChangeFramework(framework)
      }
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChangeFramework]
  );

  // listen to changes to size, propagate up
  useEffect(
    () => store.subscribe(
      state => state.size,
      framework => {
        onChangeSize(framework)
      }
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChangeSize]
  );

  // listen to changes to locale, propagate up
  useEffect(
    () => store.subscribe(
      state => state.locale,
      locale => {
        onChangeLocale(locale)
      }
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChangeLocale]
  );

  useEffect(
    () => store.subscribe(
      state => state.closed,
      () => {
        onCancel();
      }
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCancel]
  );

  const { open: openTranslation, close: closeTranslation } = useModal({
    view: CompleteTranslations,
    size: 'lg',
    name: 'import_form',
    align: 'center',
    labelSubmit: 'Save translations',
    labelCancel: 'Cancel',
    title: 'Translations auto-complete',
    custom: CreateHelpButton('https://letsform.dev/lets-form-designer/automatic-translations'),
    enableSubmit: value => {
      const anyTranslation =  (value.groceryList ?? []).some(item => item.translation != null);
      return !value.translating && anyTranslation;
    }
  });

  const handeChange = useCallback(
    (values, errors) => {
      setFormValue(values);
      setErrors({});
    },
    [setErrors, setFormValue]
  );

  useEffect(
    () => {
      if (form) {
        onChange(form);
      }
    },
    [onChange, form]
  );

  const handleReplaceField = useCallback(
    field => {
      // do a functional change here, otherwise having a dependency from "form" here
      // it's triggering a cascade re-render to all subviews
      setForm(form => replaceField(form, field));
      setField(field);
      setJsErrors(null);
    },
    [setField, setForm, setJsErrors]
  );

  const handleOnChangeForm = useCallback(
    form => {
      setForm(form);
      setJsErrors(null);
      if (_.isArray(form.locales) && !_.isEmpty(form.locales)) {
        // check the selected one is within the list
        if (!form.locales.includes(locale)) {
          setLocale(form.locales[0]);
        }
      } else {
        setLocale(null);
      }
    },
    [locale, setForm, setJsErrors, setLocale]
  );

  const handleCompleteTranslation = useCallback(
    async () => {
      tracking.sendEvent('form.translations');
      const value = await openTranslation({
        loading: false,
        groceryList: getGroceryList(form)
      });
      closeTranslation();

      if (value && value.groceryList) {
        handleOnChangeForm(reconcileFormWithGroceryList(form, value.groceryList));
        setField(null);
      }
    },
    [tracking, openTranslation, form, closeTranslation, handleOnChangeForm, setField]
  );

  const Wrapper = useMemo(
    () => (
      ({ field: currentField, fields, children, level, index } = {}) => {
        return (
          <EditWrapper
            level={level}
            field={currentField}
            fields={form.fields}
            selected={currentField.id === field?.id}
            onEdit={editField => {
              tracking.sendEvent('field.edit');
              emitEditField(editField);
              setHasEdited(true);
              setField(editField);
              setSidebar('field');
            }}
            onDelete={fieldToRemove => {
              tracking.sendEvent('field.delete');
              setForm(deleteField(form, fieldToRemove));
              setSidebar('form');
            }}
            onMove={(dragIndex, hoverIndex) => {
              tracking.sendEvent('field.drag');
              setForm({
                ...form,
                fields: moveAfterField(form.fields, dragIndex, hoverIndex)
              });
            }}
            index={index}
            framework={framework}
          >
            {children}
          </EditWrapper>
        );
      }
    ),
    [form, field, framework, tracking, emitEditField, setHasEdited, setField, setSidebar, setForm]
  );

  const PlaceholderWrapperView = useMemo(
    () => (
      ({ nextField, parentField, parentFieldTarget, parentFieldSubTarget, fields, children, level, index } = {}) => {

        return (
          <PlaceholderWrapper
            level={level}
            nextField={nextField}
            parentField={parentField}
            parentFieldTarget={parentFieldTarget}
            parentFieldSubTarget={parentFieldSubTarget}
            fields={form.fields}
            onMove={(dragId, hoverId) => {
              tracking.sendEvent('field.drag');
              // Move the field with id dragId before the field with id hoverId
              setForm({
                ...form,
                fields: moveBeforeField(form.fields, dragId, hoverId)
              });
            }}
            onAppend={(field, toField, toTarget, toSubTarget) => {
              tracking.sendEvent('field.drag');
              // remove from it's original position
              let newForm = deleteField(form, field);
              newForm = addField(
                newForm,
                field,
                toField.id,
                toTarget,
                toSubTarget
              );
              setForm(newForm);
            }}
            index={index}
            framework={framework}
          />
        );
      }
    ),
    [form, framework, tracking, setForm]
  );

  const GroupWrapperView = useMemo(
    () => (
      ({ field: currentField, children, level, index, className, hovering } = {}) => {
        return (
          <GroupWrapper
            level={level}
            field={currentField}
            fields={form.fields}
            hovering={hovering}
            selected={currentField.id === field?.id}
            className={className}
            onEdit={editField => {
              tracking.sendEvent('field.edit');
              !hasEdited && setHasEdited(true);
              emitEditField(editField);
              setField(editField);
              setSidebar('field');
            }}
            onDelete={fieldToRemove => {
              tracking.sendEvent('field.delete');
              setForm(deleteField(form, fieldToRemove));
              setSidebar('form');
            }}
            onMove={(dragIndex, hoverIndex) => {
              tracking.sendEvent('field.drag');
              setForm({
                ...form,
                fields: moveAfterField(form.fields, dragIndex, hoverIndex)
              });
            }}
            index={index}
            framework={framework}
          >
            {children}
          </GroupWrapper>
        );
      }
    ),
    [form, field, framework, tracking, hasEdited, emitEditField, setHasEdited, setField, setSidebar, setForm]
  );


  const handleSubmit = useCallback(
    values => {
      setResponses(null);
      setErrors({});
      setSidebar('debug');
    },
    [setErrors, setResponses, setSidebar]
  );

  const handleSubmitted = useCallback(
    responses => {
      setResponses(responses);
      setErrors({});
      setSidebar('debug');
    },
    [setErrors, setResponses, setSidebar]
  );

  const handleSubmitError = useCallback(
    response => {
      setResponses(response);
      setErrors({});
      setSidebar('debug');
    },
    [setErrors, setResponses, setSidebar]
  );

  const handleSelect = useCallback(
    async eventKey => {
      if (!eventKey || !_.isString(eventKey)) {
        return;
      }
      const [namespace, value] = eventKey.split(':');
      if (namespace === 'cmd') {
        if (value === 'completeTranslations') {
          handleCompleteTranslation();
        } else if (value === 'newEmptyForm') {
          if (window.confirm('Create an empty form and discard current one?')) {
            const emptyForm = defaultForm();
            setForm(defaultForm(emptyForm));
            //setPersistedForm(emptyForm);
            setEditMode(true);
            setSidebar('form');
            setGeneration(generation => generation + 1);
          }
        } else if (value === 'deleteAllFields') {
          if (window.confirm(`Remove all fields from form "${form.name}" ?`)) {
            setFormValue({});
            setDefaultValues(undefined);
            setForm(newForm => ({
              ...newForm,
              fields: []
            }));
            setField(null);
            setSidebar('form');
            setEditMode(true);
          }
        }
      }
      // delegate to upper container
      //onSelect(namespace, value, { form, framework, size });
    },
    [form, handleCompleteTranslation, setForm, setEditMode, setSidebar, setGeneration, setFormValue, setDefaultValues, setField]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      {modal === 'showImportError' && (
        <ShowError
          title="Import error"
          onClose={() => {
            setGeneralError(null);
            setModal(null);
          }}
        >
          <b>Was not possible to import the selected template.</b><br/>
          The current form has some field names which already exists in the selected template: <em>{generalError}</em>.<br/>
          Either rename or remove those field to use this template, cannot exist two field with the same name in a form.
        </ShowError>
      )}
      <div className="pages-builder">
        {renderPageBuilder()}
        <div className="form-previewer">
          <Toolbar
            form={form}
            size={size}
            locale={locale}
            onSelect={handleSelect}
            editMode={editMode}
            framework={framework}
            debug={true}
            features={features}
            hideCancel={!features.cancelButton}
            showProBadge={features.showProBadge}
            badgeContent={features.badgeContent}
          />
          <div className="canvas">
            {renderCanvas()}
            <FlexboxGrid justify="space-around">
              <FlexboxGrid.Item as={Col} colspan={24} md={size} className="previewer">
                {editMode && !plaintextMode && (
                  <div className="label-edit-mode">Edit mode</div>
                )}
                {editMode && plaintextMode && (
                  <div className="label-plaintext-mode">Plaintext mode</div>
                )}
                {!editMode && (
                  <div className="label-preview-mode">Preview mode</div>
                )}
                {form && _.isEmpty(form.fields) && !editMode && (
                  <EmptyPlaceholder>
                    There are no fields defined in this form, to add one click on "Edit form" on the top
                    right corner and then "Add field"
                  </EmptyPlaceholder>
                )}
                <ErrorBoundary>
                  <EnsureFrameworkProvider
                    framework={framework}
                    locale={locale}
                    form={form}
                  >
                    <FormGenerator
                      key={`form_${generation}`}
                      debug={true}
                      defaultValues={defaultValues}
                      locale={locale}
                      form={form}
                      framework={framework}
                      plaintext={plaintextMode}
                      wrapper={editMode && !plaintextMode ? Wrapper : undefined}
                      groupWrapper={editMode && !plaintextMode ? GroupWrapperView : undefined}
                      placeholderWrapper={editMode && !plaintextMode ? PlaceholderWrapperView : undefined}
                      //bottomView={editMode && !plaintextMode ? AddInputView : undefined}
                      bottomView={editMode && !plaintextMode && !_.isEmpty(regionsInnerFooter) ?
                        regionsInnerFooter[0].View : undefined}
                      demo={editMode && !plaintextMode}
                      loader={AlchemicLoader}
                      onChange={handeChange}
                      onJavascriptError={e => {
                        setJsErrors(e);
                        setSidebar('debug');
                      }}
                      onError={errors => {
                        setErrors(errors);
                        setSidebar('debug');
                      }}
                      onSubmit={handleSubmit}
                      onSubmitSuccess={handleSubmitted}
                      onSubmitError={handleSubmitError}
                      footer={editMode && (
                        <>


                          {renderFormFooter()}
                        </>
                      )}
                    >
                    </FormGenerator>
                  </EnsureFrameworkProvider>
                </ErrorBoundary>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </div>
        </div>
        <div className="sidebar">
          <Nav
            onSelect={setSidebar}
            appearance="subtle"
            className="sidebar-navigator"
          >
            <Nav.Item
              eventKey="form"
              disabled={!editMode}
              active={sidebar === 'form'}
            >Form settings</Nav.Item>
            <Nav.Item
              eventKey="field"
              disabled={!editMode}
              active={sidebar === 'field'}
            >Field settings</Nav.Item>
            {mapTabs(({ params }) => (
              <Nav.Item
                key={params.tabKey}
                eventKey={params.tabKey}
                active={sidebar === params.tabKey}
              >{params.tabName}</Nav.Item>
            ))}
          </Nav>
          <div className="sidebar-container">
            {sidebar === 'field' && field && (
              <FieldEditor
                field={field}
                framework={framework}
                onChange={handleReplaceField}
                locales={form.locales}
                locale={locale}
              />
            )}
            {sidebar === 'field' && !field && (
              <EmptyPlaceholder>
                Hover on a field in the preview of the form and click on "edit" button.
              </EmptyPlaceholder>
            )}
            {sidebar === 'form' && (
              <FormEditor
                key={`form_form_${generation}`}
                form={form}
                framework={framework}
                locales={form.locales}
                locale={locale}
                onChange={handleOnChangeForm}
                onCompleteTranslation={handleCompleteTranslation}
              />
            )}
            {renderTabs(null, ({ params }) => params.tabKey === sidebar)}
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

const Builder = props => {
  const {
    initialEditMode = true,
    formSchema,
    formFramework,
    formLocale,
    formSize = 12,
    /*features = {
      shareForm: true,
      showProBadge: false,
      badgeContent: 'Pro',
      cancelButton: true,
      saveOptions: true,
      playgroundForm: false,
      connectors: false
    },*/
    //onChange = () => {},
    //onChangeLocale = () => {},
    //onChangeSize = () => {},
    //onChangeFramework = () => {},
    //onCancel = () => {},
    //onSelect = () => {},
    // disable toolbar (i.e., while saving)
    //disableToolbar = false,
    formId,
    projectId
  } = props;

  return (
    <FormBuilderProvider
      sidebar={initialEditMode ? 'form' : 'debug'}
      editMode={initialEditMode}
      formId={formId}
      projectId={projectId}
      size={getValidSize(formSize)}
      framework={getValidFramework(formFramework)}
      form={defaultForm(formSchema)}
      locale={getValidLocale(formLocale)}
    >
      <RawBuilder {...props} />
    </FormBuilderProvider>
  );
};


export { Builder };
