import React from 'react';

import { Container, Content } from 'rsuite';

import { LogoExtended } from '../../assets/logo-large';

import './notification-frame.scss';

const NotificationFrame = ({ children }) => {
  return (
    <div className="lf-notification">
      <div className="header">
        <LogoExtended width={300} height={90} />
      </div>
      <Container className="pages-notification">
        <Content className="inner">
          {children}
        </Content>
      </Container>
      <div className="footer">
        <a href="/">LetsForm Designer home</a>
        {' '}&#9679;{' '}
        <a href="https://designer.letsform.dev/privacy">Privacy</a>
        {' '}&#9679;{' '}        
        <a href="https://designer.letsform.dev/terms">Terms and Conditions</a>
      </div>
    </div>
  );
};

export { NotificationFrame };
