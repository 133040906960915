import React from 'react';
import { Button, Table } from 'rsuite';

const { Cell } = Table;

export const ActionCell = ({ rowData, dataKey, disabled, onClick, ...props }) => {
  return (
    <Cell {...props} style={{ padding: '6px' }}>
      <Button
        appearance="link"
        disabled={disabled}
        onClick={() => {
          onClick(rowData.id);
        }}
      >
        {rowData.status === 'EDIT' ? 'Save' : 'Edit'}
      </Button>
    </Cell>
  );
};
