
import LZString from 'lz-string';

import { generateMainCode } from './generate-main-code';
import { generateFormJs } from './generate-form-js';
import { getPackageJsonDependencies } from './get-package-dependencies';
import { generateFormCss } from './generate-form-css';

function compress(string) {
  return LZString.compressToBase64(string)
    .replace(/\+/g, `-`) // Convert '+' to '-'
    .replace(/\//g, `_`) // Convert '/' to '_'
    .replace(/=+$/, ``); // Remove ending '='
}

const generateCodeSandbox = ({ framework, form, locale }) => {
  //alert(getMainCode({ form, framework, locale }));
  let parameters = {
    files: {
      'package.json': {
        content: {
          dependencies: getPackageJsonDependencies({ form, locale, framework })
        }
      },
      'index.js': {
        content: generateMainCode({ form, framework, locale }),
      },
      'form.js': {
        content: generateFormJs(form, framework)
      },
      'form.css': {
        content: generateFormCss()
      },
      'index.html': {
        content: '<div id="app"></div>'
      }
    }
  }

  return compress(JSON.stringify(parameters));
};

export { generateCodeSandbox };
