import React, { useCallback } from 'react';
import { Button, Whisper, Tooltip, Badge } from 'rsuite';
import classNames from 'classnames';

import { useMembership } from '../../hooks/membership';

import './feature-button.scss';

const FeatureButton = ({ 
  onClick = () => {},
  canUse = true,
  children,
  badge = null,
  opacity = true,
  warning = 'This feature is not available on the Free plan, click for more info',
  ...props 
}) => {
  const { openMembershipPlans, closeMembershipPlans} = useMembership();

  const handleClick = useCallback(
    async () => {
      if (canUse) {
        onClick();
      } else {
        await openMembershipPlans();
        closeMembershipPlans();
      }
    },
    [canUse, closeMembershipPlans, onClick, openMembershipPlans]
  );

  const button = (
    <Button            
      onClick={handleClick}
      {...props}
    >
      {children}
    </Button>
  );

  const inner = badge ? <Badge content={badge}>{button}</Badge> : button;

  return (
    <div className={classNames('lf-feature-button', { 'cannot-use': !canUse && opacity })}>
      {canUse ? 
        button : 
        <Whisper     
          speaker={<Tooltip>{warning}</Tooltip>}
          placement="top"
          trigger="hover"
        >
          {inner}
        </Whisper>}
    </div>
  );
};

export { FeatureButton };
