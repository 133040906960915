import React, { useCallback } from 'react';
import { Dropdown } from 'rsuite';
import { useNavigate } from 'react-router-dom';

import { useFormBuilderStore } from '../../../pages/builder/state';

const PlaygroundMenuItem = () => {
  const formId = useFormBuilderStore(state => state.formId);
  const projectId = useFormBuilderStore(state => state.projectId);
  const navigate = useNavigate();

  const handleClick = useCallback(
    () => {
      navigate(`/playground/new?projectId=${projectId}&formId=${formId}`);
    },
    [formId, navigate, projectId]
  );

  return (
    <Dropdown.Item onSelect={handleClick}>Create form playground</Dropdown.Item>
  );
};

export { PlaygroundMenuItem };
