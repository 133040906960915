import _ from 'lodash';

import { generateFormJs } from './generate-form-js';
import { generateMainCode } from './generate-main-code';
import { getPackageJsonDependencies } from './get-package-dependencies';
import { generateFormCss } from './generate-form-css';
import { generateViteConfig, generateViteManifestJson, generateViteIndex } from './generate-vite-assets';

const generateViteBundle = ({ form, framework, locale }) => {

  const appName = 'vite-' + _.kebabCase(form.name);

  const deps = getPackageJsonDependencies({ framework, form, locale });
  let depsJSON = Object.keys(deps)
    .map(dep => `    "${dep}": "${deps[dep]}"`);


  const packageJson = `{
  "name": "${appName}",
  "private": true,
  "version": "0.0.0",
  "type": "module",
  "scripts": {
    "dev": "vite",
    "build": "vite build",
    "lint": "eslint . --ext js,jsx --report-unused-disable-directives --max-warnings 0",
    "preview": "vite preview"
  },
  "dependencies": {
${depsJSON.join(',\n')}
  },
  "devDependencies": {
    "@types/react": "^18.2.66",
    "@types/react-dom": "^18.2.22",
    "@vitejs/plugin-react": "^4.2.1",
    "eslint": "^8.57.0",
    "eslint-plugin-react": "^7.34.1",
    "eslint-plugin-react-hooks": "^4.6.0",
    "eslint-plugin-react-refresh": "^0.4.6",
    "postcss": "^8.4.38",
    "postcss-preset-mantine": "^1.14.4",
    "postcss-simple-vars": "^7.0.1",
    "vite": "^5.2.0"
  }
}
`;

  return {
    appName,
    files: {
      [`${appName}/package.json`]: {
        content: packageJson
      },
      [`${appName}/src/form.js`]: {
        content: generateFormJs(form, framework)
      },
      [`${appName}/vite.config.js`]: {
        content: generateViteConfig()
      },
      [`${appName}/src/form.css`]: {
        content: generateFormCss()
      },
      [`${appName}/public/manifest.json`]: {
        content: generateViteManifestJson()
      },
      [`${appName}/src/main.jsx`]: {
        content: generateMainCode({
          form,
          framework,
          locale
        })
      },
      [`${appName}/index.html`]: {
        content: generateViteIndex()
      }
    }
  };
};

export { generateViteBundle };