import React, { useCallback } from 'react';

import { ImportForm } from './views/import-form';

import {
  useSearchParams,
  useNavigate
} from 'react-router-dom';

const ImportPage = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const handleImported = useCallback(
    (imported) => {
      navigate('/');
    },
    [navigate]
  );

  return (
    <ImportForm
      form={params.get('form')}
      onImported={handleImported}
    />
  );
};

export { ImportPage };
