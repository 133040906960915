import React from 'react';

import { OpenCodesandbox } from './button-codesanbox';

const ModalCodesandbox = ({ value }) => {
  const { form, locale, framework } = value;
  
  return (
    <div className="lf-modal-codesandbox">
      <div className="claim">
        Open the current form in <b>Codesandbox</b> to see in action with a miminalist code example      
      </div>
      <OpenCodesandbox 
        form={form}
        locale={locale}
        framework={framework}
      />
      <span className="hint">Opens in a new window</span>
    </div>
  );
};

export { ModalCodesandbox };
