import { definePlugin } from '../../code-plug';

import { SelectTemplateMenuItem } from './views/select-template-menu-item';

definePlugin('builder-select-template', ({ registerView }) => {

  // register toolbar button to open templates
  registerView(
    'form-builder-toolbar',
    SelectTemplateMenuItem,
    {
      key: 'form-builder-select-template',
      event: 'installTemplate',
      start: true
    }
  );
});
