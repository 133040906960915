import React from 'react';
import _ from 'lodash';
import ReloadIcon from '@rsuite/icons/Reload';
import { Whisper, IconButton, Tooltip } from 'rsuite';

import { PreviewJson, EmptyPlaceholder } from '../../../components';

import { beautifyJson } from '../helpers/beautify-json';
import { PreviewResponse } from './preview-response';

import './debug-panel.scss';

const DebugPanel = ({
  value,
  errors,
  jsErrors,
  responses,
  onRegenerate = () => {}
}) => {

  console.log('responses', responses)

  return (
    <div className="debug-panel">
      <div className="title">
        Form values
      </div>
      {!_.isEmpty(value) && (
        <PreviewJson json={beautifyJson(value)} />
      )}
      {_.isEmpty(value) && (
        <EmptyPlaceholder>
          Current value of the form is empty, type anything in the preview form to see
          how the form is handling user input data
        </EmptyPlaceholder>
      )}
      {!_.isEmpty(errors) && (
        <>
          <div className="title">
            Validation errors
          </div>
          <PreviewJson json={errors} />
        </>
      )}
      {responses && (
        <>
          <div className="title">
            Submit responses
          </div>
          {(Array.isArray(responses) ? responses : [responses]).map(response => (
            <PreviewResponse
              key={response.url || String(response)}
              response={response}
            />
          ))}
        </>
      )}
      {!_.isEmpty(jsErrors) && (
        <>
          <div className="title">
            Javascript errors
            <Whisper
              placement="left"
              speaker={
                <Tooltip>
                  Reload the form
                </Tooltip>
              }
              trigger="hover"
            >
              <IconButton
                style={{ float: 'right' }}
                appearance="subtle"
                size="xs"
                icon={<ReloadIcon/>}
                onClick={onRegenerate}
              />
            </Whisper>
          </div>
          <div className='javascript-errors'>
            <div className="message">{jsErrors.message}</div>
            <div className="errorType">Error type: {jsErrors.errorType}</div>
            {!_.isEmpty(jsErrors.sourceCode) && (
              <pre className="source-code">{jsErrors.sourceCode}</pre>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export { DebugPanel };
