import { definePlugin } from '../../code-plug';

import { CodeSandboxMenuItem } from './views/codesandbox-menu-item';

definePlugin('builder-codesandbox', ({ registerView }) => {

  // register toolbar button to open templates
  registerView(
    'form-builder-toolbar',
    CodeSandboxMenuItem,
    {
      key: 'form-builder-codesandbox',
      start: true
    }
  );
});
