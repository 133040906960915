import _ from 'lodash';

import { reduceFields } from '../lets-form';

import Manifests from '../../manifest';
import Frameworks from '../../frameworks';
import { generateCDNUrl } from '../../helpers';

const generateImports = ({ form, locale, framework }) => {
  const manifest = Frameworks[framework];

  // collect all used components
  const components = _.uniq(reduceFields(
    form.fields,
    (field, acc) => [...acc, field.component],
    []
  ));

  // chain if there are js or css imports for the specific component / framework
  let jsImportDefinitions = _.chain(components)
    .map(component => {
      if (Manifests[component] &&
        Manifests[component].dependencies &&
        Manifests[component].dependencies[framework] &&
        Manifests[component].dependencies[framework].js
      ) {
        return Manifests[component].dependencies[framework].js;
      }
      return null;
    })
    .compact()
    .flatten()
    .value();

  let cssImportDefinitions = _.chain(components)
    .map(component => {
      if (Manifests[component] &&
        Manifests[component].dependencies &&
        Manifests[component].dependencies[framework] &&
        Manifests[component].dependencies[framework].css
      ) {
        return Manifests[component].dependencies[framework].css;
      }
      return null;
    })
    .compact()
    .flatten()
    .value();

  // given definition, create real import for JS, can be plain string or a function
  // accepting form, locale or framework
  let jsImports = [];
  if (manifest.jsImports) {
    jsImportDefinitions = _.isArray(manifest.jsImports) ?
      [...jsImportDefinitions, ...manifest.jsImports] : [...jsImportDefinitions, manifest.jsImports];
  }
  jsImportDefinitions
    .forEach(imp => {
      const value = _.isFunction(imp) ? imp({ locale, form, framework }) : imp;
      if (value) {
        jsImports = _.isArray(value) ? [...jsImports, ...value] : [...jsImports, value];
      }
    });

  // given definition, create real import for CSS, can be plain string or a function
  // accepting form, locale or framework
  let cssImports = [], cssImportsFromCDN = [];
  if (manifest.cssImports) {
    cssImportDefinitions = _.isArray(manifest.css) ?
      [...cssImportDefinitions, ...manifest.css] : [...cssImportDefinitions, manifest.css];
  }
  cssImportDefinitions
    .forEach(imp => {
      const value = _.isFunction(imp) ? imp({ locale, form, framework }) : imp;
      if (value) {
        // generate JS import for CSS
        const jsImportForCss = (_.isArray(value) ? value : [value])
          .map(css => `import '${css.package}${css.path}';`);
        console.log('jsImportForCss', jsImportForCss)
        cssImports = [...cssImports, ...jsImportForCss];
        const cdnLinkImportForCss = (_.isArray(value) ? value : [value])
          .map(cssFile => generateCDNUrl(cssFile.package, {
            // TODO should put the right version
            version: 'latest',
            path: cssFile.path
          }));
        cssImportsFromCDN = [...cssImportsFromCDN, ...cdnLinkImportForCss]
      }

    });

  // return unique array
  return {
    js: _.uniq(jsImports),
    css: _.uniq(cssImports),
    cssLinks: _.uniq(cssImportsFromCDN)
  };
};

export { generateImports };