import React, { useCallback, useState } from 'react';
import { Button } from 'rsuite';

import { useModal } from '../../../components';

import { ConnectorItem } from './connector-item';
import { SelectConnectorModal } from './select-connector-modal';
import { ConnectorConfigurationModal } from './connector-configuration-modal';

import './connectors-panel.scss';

const ConnectorsPanel = ({
  form,
  onChangeConnector = () => {},
  onRemoveConnector = () => {},
  onAddConnector = () => {}
}) => {
  const [loading, setLoading] = useState(false);
  const { connectors } = form;
  const { open, close } = useModal({
    view: SelectConnectorModal,
    labelSubmit: 'Close ',
    labelCancel: null,
    align: 'center',
    size: 1080
  });
  const { open: openConfigurationForm, close: closeConfigurationForm } = useModal({
    view: ConnectorConfigurationModal,
    title: 'Configure connector',
    labelSubmit: null,
    labelCancel: null,
    size: 'sm'
  })

  const handleAddConnector = useCallback(
    async () => {
      // select which connector
      const connector = await open();
      await close();

      if (connector) {
        // load connector form
        setLoading(true);
        let connectorConfigurationCors;
        const connectorConfigurationForm = await import(`../../../connectors/configuration-forms/${connector.configurationForm}`)
        if (!connector.cors) {
          connectorConfigurationCors = await import('../../../components/lets-form-forms/cors-proxy.json');
        }
        setLoading(false);

        // open config modal
        const options = await openConfigurationForm(
          {
            form: connectorConfigurationForm,
            formCors: connectorConfigurationCors,
            values: connector.defaultValues ? { ...connector.defaultValues } : {}
          },
          {
            title: `Configure connector "${connector.displayName}"`
          }
        );
        await closeConfigurationForm();

        if (options) {
          onAddConnector(connector, options);
        }
      }

    },
    [close, closeConfigurationForm, onAddConnector, open, openConfigurationForm]
  );

  return (
    <div className="lf-connectors-panel">
      <b>Connectors</b> are small JavaScript snippets of code to send the form payload on <em>"Submit"</em>
      <div className="connectors">
        {(connectors || []).map((connector, idx) => (
          <ConnectorItem
            key={connector.name}
            connector={connector}
            idx={idx}
            disabled={loading}
            onChange={onChangeConnector}
            onRemove={onRemoveConnector}
          />
        ))}
      </div>
      <div className="buttons">
        <Button
          disabled={loading}
          appearance="ghost" size="xs" onClick={handleAddConnector}
        >Add connector</Button>
      </div>
    </div>
  );
};

export { ConnectorsPanel };
