import {
  COMMON_ARRAY_OPTIONS,
  COMMON_TRITTICUS,
  COMMON_TRITTICUS_STATUS,
  makeColums,
  MANTINE_DROP_DOWN_HEIGHT,
  MANTINE_OPTIONS_LIMIT,
  MANTINE_POINTER,
  MANTINE_SECTIONS,
  RSUITE5_HINT_AS_TOOLTIP,
  RSUITE5_SIZE,
  RSUITE_BLOCK,
  MANTINE_SIZE,
  MANTINE_RADIUS,
  MANTINE_VARIANT,
  MANTINE_ERROR_WITH_STYLE,
  MANTINE_WITH_SCROLL_AREA
} from '../costants/index.js';

const manifest = {
  label: 'Tag Input',
  category: 'general',
  name: 'input-tag',
  description: 'Input tags, create tags, returns an array of string',
  frameworks: [
    //'react-antd',
    //'react',
    //'react-bootstrap',
    //'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    ...COMMON_TRITTICUS,
    COMMON_TRITTICUS_STATUS
  ],
  'react-rsuite5': [
    {
      name: 'trigger',
      label: 'Trigger',
      component: 'checkbox-group',
      hint: 'Set the trigger for creating tags',
      options: [
        { value: 'Enter', label: 'Enter' },
        { value: 'Space', label: 'Space' },
        { value: 'Comma', label: 'Comma' }
      ],
      'react-rsuite5': {
        tooltip: true
      }
    },
    makeColums(
      RSUITE_BLOCK,
      RSUITE5_SIZE
    ),
    RSUITE5_HINT_AS_TOOLTIP
  ],
  'react-mantine': [
    makeColums(
      MANTINE_SIZE,
      MANTINE_RADIUS,
      MANTINE_VARIANT
    ),
    {
      ...COMMON_ARRAY_OPTIONS,
      name: 'data',
      hint: 'Data displayed in the dropdown',
      label: 'Data',
      'react-rsuite5': {
        tooltip: true
      }
    },
    MANTINE_SECTIONS,
    makeColums(
      {
        name: 'maxTags',
        component: 'input-number',
        label: 'Max tags',
        min: 1,
        step: 1,
        clearable: true,
        hint: 'Maximum number of tags, Infinity by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      MANTINE_DROP_DOWN_HEIGHT,
      MANTINE_OPTIONS_LIMIT
    ),
    makeColums(
      {
        name: 'allowDuplicates',
        component: 'toggle',
        label: 'Allow duplicates'
      },
      {
        name: 'clearable',
        component: 'toggle',
        label: 'Clearable'
      },
      {
        name: 'defaultDropdownOpened',
        component: 'toggle',
        label: 'Default open'
      }
    ),
    makeColums(
      MANTINE_POINTER,
      {
        name: 'searchable',
        component: 'toggle',
        label: 'Searchable',
        hint: 'Determines whether the select should be searchable, false by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'selectFirstOptionOnChange',
        component: 'toggle',
        label: 'First option',
        hint: 'Determines whether the first option should be selected when value changes, false by default',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'withCheckIcon',
        component: 'toggle',
        label: 'Check icon',
        hint: 'Determines whether check icon should be displayed near the selected option label, true by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      MANTINE_ERROR_WITH_STYLE,
      MANTINE_WITH_SCROLL_AREA
    )
  ],
  'react-material-ui': [

  ],
  defaultValues: {
    'react-mantine': {
      'react-mantine': {
        withCheckIcon: true,
        withErrorStyles: true,
        withScrollArea: true
      }
    }
  }
};

export { manifest };