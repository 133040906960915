import { formHelper } from 'lets-form/utils';
import {
  COMMON_ARRAY_OPTIONS_WITH_IMAGE,
  COMMON_TRITTICUS,
  COMMON_TRITTICUS_STATUS,
  RSUITE5_SIZE,
  ANTD_SIZE,
  makeColums,
  makeOptions
} from '../costants/index.js';

const manifest = {
  label: 'Toggle Buttons',
  category: 'layout',
  description: 'List of toggle buttons, works like a radio group, returns an array of string',
  name: 'buttons-toggle-group',
  frameworks: [
    'react-antd',
    //'react',
    //'react-bootstrap',
    //'react-material-ui',
    'react-rsuite5',
    //'react-mantine'
  ],
  fields: [
    ...COMMON_TRITTICUS,
    ...formHelper()
      .append(COMMON_ARRAY_OPTIONS_WITH_IMAGE)
      .setField('options', 'label', null)
      .setField('select-group-options', 'label', 'Buttons')
      .setField('showImageOptions', 'label', 'Buttons with images')
      .fields(),
    {
      name: 'multiple',
      component: 'toggle',
      label: 'Multiple values',
      hint: 'Allow to select multiple values',
      'react-rsuite5': {
        tooltip: true
      }
    },
    COMMON_TRITTICUS_STATUS,
    makeColums(
      {
        name: 'fullWidth',
        component: 'toggle',
        label: 'Full width',
        script: `if (fullWidth) {
          disable('justifyContent');
        } else {
          enable('justifyContent');
        }`
      },
      {
        name: 'justifyContent',
        component: 'select',
        label: 'Justify content',
        options: makeOptions(['center', 'flex-start', 'flex-end', 'space-between', 'space-around']),
        cleanable: true
      }
    )
  ],
  'react-rsuite5': [
    makeColums(
      RSUITE5_SIZE,
      []
    )
  ],
  'react-antd': [
    makeColums(
      ANTD_SIZE,
      []
    )
  ],
  'react-bootstrap': [

  ],
  'react-material-ui': [

  ],
  'form-generator': {
  },
  defaultValues: {
    'react-rsuite5': {
      multiple: false,
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react': {
    },
    'react-bootstrap': {
      multiple: false,
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react-material-ui': {
      multiple: false,
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react-antd': {
      multiple: false,
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    }
  }
};

export { manifest };
