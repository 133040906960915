import {
  COMMON_ARRAY_OPTIONS,
  COMMON_TRITTICUS,
  COMMON_TRITTICUS_STATUS,
  MUI_TRITTICUS_1,
  RSUITE5_HINT_AS_TOOLTIP,
  makeColums,
  makeOptions,
  MANTINE_SIZE,
  MANTINE_RADIUS,
  MANTINE_COLORS,
  MANTINE_ARRAY_OPTIONS,
  RSUITE5_COLOR,
  MANTINE_AUTO_CONTRAST
} from '../costants/index.js';

const manifest = {
  label: 'Checkbox Group',
  category: 'general',
  name: 'checkbox-group',
  frameworks: [
    'react-antd',
    //'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    ...COMMON_TRITTICUS,
    { ...COMMON_ARRAY_OPTIONS, label: 'Checkbox group options' },
    COMMON_TRITTICUS_STATUS
  ],
  'react-material-ui': [
    MUI_TRITTICUS_1
  ],
  'react-rsuite5': [
    makeColums(
      RSUITE5_COLOR,
      []
    ),
    makeColums(
      {
        name: 'inline',
        component: 'toggle',
        label: 'Inline checkboxes'
      },
      RSUITE5_HINT_AS_TOOLTIP
    )
  ],
  'react-bootstrap': [
    {
      name: 'inline',
      component: 'toggle',
      label: 'Inline checkboxes'
    },
    {
      name: 'reverse',
      component: 'toggle',
      label: 'Reverse align'
    }
  ],
  'react-mantine': [
    { ...MANTINE_ARRAY_OPTIONS, label: 'Checkbox group options' }, // this overrides the common one
    makeColums(
      MANTINE_SIZE,
      MANTINE_RADIUS,
      {
        name: 'labelPosition',
        component: 'select',
        label: 'Label position',
        block: true,
        options: makeOptions(['left', 'right']),
        searchable: false
      }
    ),
    makeColums(
      {
        ...MANTINE_COLORS,
        label: 'Background color'
      },
      {
        ...MANTINE_COLORS,
        name: 'iconColor',
        label: 'Icon color'
      },
      MANTINE_AUTO_CONTRAST
    )
  ],
  defaultValues: {
    'react-mantine': {
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react-rsuite5': {
      inline: true,
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react-bootstrap': {
      inline: true,
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react': {
      inline: true,
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react-antd': {
      optionType: 'default',
      inline: true,
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react-material-ui': {
      inline: true,
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    }
  },
  'form-generator': {
    //hideValidation: true
  }
};

export { manifest };
