import React, { useCallback, useState, useRef } from 'react';
import { Header, Container, Content, Button, Notification, useToaster } from 'rsuite';
import { useParams } from 'react-router-dom';
import { useQuery, useApolloClient } from '@apollo/client';
import _ from 'lodash';

import LetsForm, { formHelper } from '../../components/lets-form/react-rsuite5';
import PlaygroundConfiguration from '../../components/lets-form-forms/playground-configuration.json';
import { NavigationBar, Breadcrumbs } from '../../layout';
import { useTracking, useURLQuery } from '../../hooks';
import { LetsFormPlayground, TextareaCopyClipboard } from '../../components';

import { getPlaygroundIframe } from './helpers/get-playground-iframe';

import {
  CREATE_PLAYGROUND_FORM,
  GET_PROJECTS,
  DELETE_PLAYGROUND_FORM,
  GET_FORM,
  GET_PLAYGROUND
} from './queries';
import './playground-configurator.scss';

// https://www.jsdelivr.com/package/npm/qrcode.react
// https://esm.sh/antd?external=react,react-dom
// https://esm.sh/qrcode.react?external=react,react-dom
// componentUrl="https://cdn.jsdelivr.net/npm/qrcode.react@3.1.0/+esm"
//componentUrl="https://cdn.jsdelivr.net/npm/qrcode.react@3.1.0/+esm"
// exportedKey="QRCodeSVG"

const tryParse = str => {
  try {
    return JSON.parse(str);
  } catch(e) {
    return null;
  }
};



const PlaygroundConfiguratorPage = () => {
  useTracking();
  const previewer = useRef();
  const toaster = useToaster();
  const client = useApolloClient();
  const { formId, projectId } = useURLQuery();
  const [saveLoading, setLoading] = useState(false);
  const [form, setForm] = useState();
  const [playgroundConfigurationForm, setPlaygroundConfigurationForm] = useState(PlaygroundConfiguration);
  const [playgroundForm, setPlaygroundForm] = useState();
  const [version, forceRefresh] = useState(1);
  const [playgroundFormId, setPlaygroundFormId] = useState();
  // this is the record from the database
  const [sharedPlaygroundForm, setSharedPlaygroundForm] = useState();
  // current configuration of the playground
  const [playgroundConfiguration, setPlaygroundConfiguration] = useState({
    defaultExport: true,
    configPanelWidth: 350,
    projectId: projectId ?? undefined,
    configurationForm: formId ?? undefined
  });
  const [isError, setIsError] = useState(false);
  const { shareCode } = useParams();
  //const { user, isAuthenticated, hasFeatureFlag } = useCurrentUser({ redirectToLogin: true });

  console.log('isError', isError)

  const { loading: loadingPlayground } = useQuery(GET_PLAYGROUND, {
    skip: _.isEmpty(shareCode),
    variables: {
      shareCode
    },
    onCompleted: async data => {

      if (!_.isEmpty(data.playground_forms)) {
        const playgroundForm = data.playground_forms[0];
        const playgroundJson = tryParse(playgroundForm.json);

        // fetch the form name, so we can populate the options of the form in view mode
        const form = await client.query({
          query: GET_FORM,
          variables: {
            formId: playgroundForm.formId
          }
        });
        if (form?.data?.form) {
          // functional set state to avoid race condition
          setPlaygroundConfigurationForm(currentForm => {
            return formHelper(currentForm)
              .setField(
                'configurationForm',
                'options',
                [{ value: form.data.form.id, label: form.data.form.name }]
              )
              .form();
          });
        }

        // if found, update the state
        if (playgroundJson) {
          setSharedPlaygroundForm(playgroundForm);
          setPlaygroundConfiguration(_.omit(playgroundJson, 'form'));
          forceRefresh(value => value + 1); // force re-render of letsform
          setPlaygroundFormId(playgroundJson.configurationForm);
        }
      }
    }
  });

  const { loading: loadingProjects } = useQuery(GET_PROJECTS, {
    onCompleted: (data) => {
      // functional set state to avoid race condition
      setPlaygroundConfigurationForm(
        currentForm => {
          return formHelper(currentForm)
            .setField(
              'projectId',
              'options',
              (data?.projects ?? []).map(o => ({
                value: o.id,
                label: o.name
              }))
            )
            //.debug()
            .form();
        });
    }
  });



  const { loading: formLoading } = useQuery(GET_FORM, {
    variables: { formId: playgroundFormId },
    skip: _.isEmpty(playgroundFormId),
    onCompleted: data => {
      let formSchema;
      console.log('loaded form for configurator', data)

      try {
        setForm(data.form);
        const json = data.form.versions[0].json;
        formSchema = JSON.parse(json);
        // ensure ids
        setPlaygroundForm(formSchema);
      } catch (e) {
        // set error in case of parsing problems
        setIsError(true);
      }
    }
  });

  console.log('formLoading',formLoading)

  const loading = saveLoading || loadingPlayground || loadingProjects;

  const handleCreateLink = useCallback(
    async () => {
      try {
        const jsonPlaygroundForm = {
          ...playgroundConfiguration,
          form: playgroundForm
        }

        setLoading(true);
        const shareCode = Math.random().toString(36).slice(2);
        const response = await client.mutate({
          mutation: CREATE_PLAYGROUND_FORM,
          variables: {
            playgroundForm: {
              json: JSON.stringify(jsonPlaygroundForm, null, 2),
              name: jsonPlaygroundForm.name,
              formId: form.id,
              height: document.getElementById('lf-playground').offsetHeight,
              shareCode
            }
          }
        });
        setSharedPlaygroundForm(response?.data?.playgroundForm);
        setLoading(false);
        toaster.push(
          <Notification type="info" header="Created!" closable>
            <div>
              The form playground was created succesfully
            </div>
          </Notification>,
          { placement: 'topEnd' }
        );
        window.history.pushState({}, null, `/playground/${shareCode}`);
      } catch(e) {
        toaster.push(
          <Notification type="error" header="Error!" closable>
            <div>
              <b>Something went wrong</b> creating the playground form, please try again later.
            </div>
          </Notification>,
          { placement: 'topEnd' }
        );
        setLoading(false);
      }
    },
    [client, form, playgroundConfiguration, playgroundForm, toaster]
  );

  const handleChangePlaygroundConfiguration = useCallback(
    values => {
      // set the selected form id for playground properties
      setPlaygroundFormId(values.configurationForm);
      // set other playground configurations
      setPlaygroundConfiguration(values, 'configurationForm');
    },
    []
  );


  const handleDelete = useCallback(
    async () => {
      if (!window.confirm(`Delete form playground "${sharedPlaygroundForm.name}"?`)) {
        return;
      }
      try {
        setLoading(true);
        await client.mutate({
          mutation: DELETE_PLAYGROUND_FORM,
          variables: {
            id: sharedPlaygroundForm.id
          }
        });
        setLoading(false);
        setSharedPlaygroundForm(null);
        toaster.push(
          <Notification type="info" header="Deleted!" closable>
            <div>
              The form playground was deleted successfully!
            </div>
          </Notification>,
          { placement: 'topEnd' }
        );
        window.history.pushState({}, null, `/playground/new`);
      } catch(e) {
        toaster.push(
          <Notification type="error" header="Error" closable>
            <div>
              <b>Something went wrong</b> deleting the form playground, please try again later.
            </div>
          </Notification>,
          { placement: 'topEnd' }
        );
        setLoading(false);
      }
    },
    [client, sharedPlaygroundForm, toaster]
  );


  return (
    <div>
      <Header>
        <NavigationBar />
      </Header>
      <Container className="lf-page-playground">
        <Content className="lf-page-playground-detail">
          <Breadcrumbs />
          <div className="lf-playground-header">
            <div className="left">
              <div className="lf-title">
                LetsForm Playground
              </div>

              <div className="playground-incipit">
                The <b>LetsForm Playground</b> allows to create a fully customizable playgrounds for your
                UI components
              </div>


            </div>

            <div className="right">
              <div className="playground-configuration">
                <LetsForm
                  key={version}
                  form={playgroundConfigurationForm}
                  disabled={loadingProjects}
                  defaultValues={playgroundConfiguration}
                  plaintext={sharedPlaygroundForm != null}
                  onChange={handleChangePlaygroundConfiguration}
                  hideToolbar={true}
                />
              </div>
              <div className="buttons">
                {!sharedPlaygroundForm && (
                  <div>
                    <Button
                      appearance="primary"
                      onClick={handleCreateLink}
                      disabled={loading}
                    >Create link for this playground</Button>
                  </div>
                )}
                {sharedPlaygroundForm && (
                  <div>
                    <Button
                      appearance="primary"
                      color="orange"
                      onClick={handleDelete}
                    >Delete playground</Button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {sharedPlaygroundForm && (
            <>
              <div className="lf-title">
                Include Form Playground
              </div>
              <TextareaCopyClipboard
                  truncate
                  width={900}
                >
                {getPlaygroundIframe({
                  shareCode: sharedPlaygroundForm.shareCode,
                  name: sharedPlaygroundForm.name,
                  height: sharedPlaygroundForm.height
                })}
              </TextareaCopyClipboard>
            </>
          )}

          {playgroundForm && playgroundFormId && (
            <>
              <div className="lf-title">
                Preview
              </div>
              <LetsFormPlayground
                ref={previewer}
                name={playgroundConfiguration.name}
                description={playgroundConfiguration.description}
                componentUrl={playgroundConfiguration.componentUrl}
                defaultExport={playgroundConfiguration.defaultExport}
                exportedKey={playgroundConfiguration.exportedKey}
                configPanelWidth={playgroundConfiguration.configPanelWidth}
                zoom={parseFloat(playgroundConfiguration.zoom)}
                form={playgroundForm}
              />
            </>
          )}
        </Content>
      </Container>
    </div>
  );
};

export { PlaygroundConfiguratorPage };
