
const Connectors = [
  {
    displayName: 'Http Call',
    name: 'HttpCall',
    description: 'Simple HTTP connector, sends form payload with a POST call. Supports basic authentication and bearer token',
    configurationForm: 'http-call.json',
    cors: true,
    defaultValues: {
      authorization: 'none',
      method: 'POST'
    }
  },
  {
    displayName: 'AirTable',
    name: 'AirTable',
    description: 'Airtable is a low‒code platform to build next‒gen apps. Move beyond rigid tools, operationalize your critical data, and reimagine workflows with AI.',
    configurationForm: 'air-table.json',
    cors: false
  },
  {
    displayName: 'GetForm.io',
    name: 'GetFormIo',
    description: 'Getform is a headless form backend that enables you to collect form submissions, receive email notifications, and integrate your forms with your favorite apps.',
    configurationForm: 'get-form-io.json',
    icon: 'https://getform.io/favicon.ico',
    cors: true
  },
  {
    displayName: 'FormSpark.io',
    name: 'FormSparkIo',
    description: 'Keep full control over the look and feel of your forms. Let Formspark take care of the rest. Solutions for agencies, freelancers, developers and marketing teams.',
    configurationForm: 'form-spark-io.json',
    icon: 'https://formspark.io/favicon.ico',
    cors: true
  },
  {
    displayName: 'IFTT',
    name: 'IFTT',
    description: 'Automation for business and home',
    configurationForm: 'iftt.json',
    icon: 'https://getform.io/favicon.ico',
    cors: true
  },
  {
    displayName: 'Notion',
    name: 'Notion',
    description: 'Write,plan,organize,play. Turn ideas into action with Notion\'s AI-powered workspace.',
    configurationForm: 'notion.json',
    icon: 'https://www.notion.so/images/favicon.ico',
    cors: false
  },
  {
    displayName: 'Zapier',
    name: 'Zapier',
    description: 'Zapier Enterprise empowers everyone in your business to securely automate their work in minutes, not months—no coding required.',
    configurationForm: 'zapier.json',
    icon: 'https://cdn.zapier.com/zapier/images/favicon.ico',
    cors: true
  },
  {
    displayName: 'FormCarry',
    name: 'FormCarry',
    description: 'Never worry about the backend of your form again. Create your HTML form, connect to our API, get email notifications, block spam, and use over 3000 integrations.',
    configurationForm: 'form-carry.json',
    icon: 'https://formcarry.com/favicon.ico',
    cors: true
  }
];

export default Connectors;
