const beautifyValue = value => {
  // simplify File object
  if (value instanceof File) {
    return { file: value.name };
  }
  return value;
}

const beautifyJson = json => {
  return Object.keys(json)
    .reduce(
      (acc, key) => {
        if (Array.isArray(json[key])) {
          return {
            ...acc,
            [key]: json[key].map(beautifyValue)
          };
        }
        return {
          ...acc,
          [key]: beautifyValue(json[key])
        };
      },
      {}
    );
};

export { beautifyJson };
