import { createContext, useRef, useContext } from 'react';
import { createStore, useStore } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import _ from 'lodash';

const createFormBuilderStore = (initialProps) => {
  return createStore()(subscribeWithSelector((set) => ({
    hasEdited: false,
    editMode: false,
    sidebar: 'debug',
    size: 12,
    framework: null,
    form: null,
    locale: null,
    generation: 1,
    defaultValues: undefined,
    plaintextMode: false,
    field: null,
    formValue: {},
    errors: {},
    generalError: null,
    responses: null,
    jsErrors: null,
    disableToolbar: false,
    closed: false,
    changed: false,
    ...initialProps,
    setHasEdited: edited => set({ hasEdited :edited }),
    setSidebar: sidebar => set({ sidebar }),
    setEditMode: editMode => set({ editMode }),
    setSize: size => set({ size }),
    setFramework: framework => set({ framework }),
    setForm: form => {
      set(_.isFunction(form) ? ({ form: currentForm }) => ({ form: form(currentForm), hasEdited: true }) : { form, hasEdited: true });
    },
    setLocale: locale => set({ locale }),
    setGeneration: generation => {
      set(_.isFunction(generation) ?
        ({ generation: currentGeneration }) => ({ generation: generation(currentGeneration)}): { generation }
      );
    },
    setDefaultValues: defaultValues => set({ defaultValues }),
    setPlaintextMode: plaintextMode => set({ plaintextMode }),
    setField: field => set({ field }),
    setFormValue: formValue => set({ formValue}),
    setErrors: errors => set({ errors }),
    setGeneralError: generalError => set({ generalError }),
    setResponses: responses => set({ responses }),
    setJsErrors: jsErrors => set({ jsErrors }),
    setDisabledToolbar: disableToolbar => set({ disableToolbar }),
    close: closed => set({ closed })
  })));
};

const FormBuilderContext = createContext(null);

const FormBuilderProvider = ({
  children,
  ...props
}) => {
  const storeRef = useRef();
  if (!storeRef.current) {
    storeRef.current = createFormBuilderStore(props);
  }
  return (
    <FormBuilderContext.Provider value={storeRef.current}>
      {children}
    </FormBuilderContext.Provider>
  );
};

function useFormBuilderStore(selector) {
  const store = useContext(FormBuilderContext)
  if (!store) throw new Error('Missing FormBuilderContext.Provider in the tree')
  return useStore(store, selector);
}

function useRawFormBuilderStore(selector) {
  return useContext(FormBuilderContext)
}

export { FormBuilderProvider, useFormBuilderStore, useRawFormBuilderStore };
