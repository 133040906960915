import React from 'react';

import { IsoLang } from '../iso-lang';
import _ from 'lodash';
import { Whisper, Tooltip } from 'rsuite';

export const LocalesList = ({
  locales = [],
  max = 5
}) => {

  const sortedLocales = _.clone(locales).sort((localeA, localeB) => {
    // prefer most common language to be first
    if (['en-US', 'en-UK', 'it-IT', 'fr-FR', 'es-ES', 'de-DE'].includes(localeA)) {
      return -1;
    } else if (localeA < localeB) {
      return -1;
    } else if (localeA > localeB) {
      return 1;
    }
    return 0;
  });

  const toDisplay = Array.isArray(sortedLocales) && sortedLocales.length > max ?
    sortedLocales.slice(0, max) : sortedLocales;

  return (
    <span>
      {toDisplay.map(locale => <IsoLang locale={locale} key={locale}/>)}
      {sortedLocales.length > toDisplay.length && (
        <Whisper
          trigger="hover"
          placement="autoVerticalStart"
          speaker={<Tooltip>{sortedLocales.join(', ')}</Tooltip>}
        >
          <span> and <b>{sortedLocales.length - toDisplay.length} more</b></span>
        </Whisper>
      )}
    </span>
  );
};
