import { definePlugin } from '../../code-plug';

import { StartEmptyForm } from './views/start-empty-form';

definePlugin('builder-start-empty-form', ({ registerView, listenTo }) => {

  // register the big button at the bottom of the form to add a field
  registerView(
    'form-builder-footer',
    StartEmptyForm,
    {
      key: 'form-builder-start-empty-form'
    }
  );
});
