import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LetsFormPlayground } from '../../components/lest-form-playground';

const PlaygroundPage = () => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [playground, setPlayground] = useState();

  const { formHash } = useParams();

  useEffect(
    () => {
      const f = async () => {
        if (formHash) {
          // to avoid scrollbars in iframe
          document.body.style.overflow = 'hidden';
          try {
            const res = await fetch(`/playground_forms/${formHash}`);
            const form = await res.json();

            if (true) {
              setLoading(false);
              setPlayground({
                ...form,
                zoom: form.zoom ? parseFloat(form.zoom) : undefined
              });

              console.log('playground-->', form)

              return;
            }
            setError('Invalid form');
          } catch (e) {
            setLoading(false);
            setError(e);
          }
          setLoading(false);
          return;
        }
      }
      f();
    },
    [formHash]
  );

  return (
    <div>
      {loading && (
        <div>loading</div>
      )}
      {error && (
        <div>something went wrong</div>
      )}
      {!loading && playground && (
        <div style={{ overflow: 'hidden' }}>
          <LetsFormPlayground
            {...playground}
          />
        </div>
      )}
    </div>
  );
};

export { PlaygroundPage };
