import _ from 'lodash';

const tab = (n = 1) => {
  let i, result = '';
  for (i = 1; i <= n; i++) {
    result += '  ';
  }
  return result;
};

const tabify = (str, n = 1) => {
  return (_.isArray(str) ? str : str.split('\n'))
    .map(s => tab(n) + s)
    .join('\n');
};

export { tabify };