import { definePlugin } from '../../code-plug';

import { PlaygroundMenuItem } from './views/playground-menu-item';

definePlugin('builder-playground', ({ registerView }) => {

  registerView(
    'form-builder-toolbar',
    PlaygroundMenuItem,
    {
      parent: 'Form',
      key: 'create-form-playground'
    }
  );
});