import { definePlugin } from '../../code-plug';

import { FrameworksMenuItem } from './views/frameworks-menu-item';

definePlugin('builder-frameworks', ({ registerView }) => {

  registerView(
    'form-builder-toolbar',
    FrameworksMenuItem,
    {
      key: 'form-builder-select-framework',
      parent: 'Fields'
    }
  );
});
