import React from 'react';
import { Header, Container, Content } from 'rsuite';
import { useParams, Link } from 'react-router-dom';

import { NavigationBar } from '../../layout';
import { useTracking, useCurrentUser } from '../../hooks';
import { SuccessPlaceholder } from '../../components';

import './payment.scss';

const PaymentPage = () => {
  const { user } = useCurrentUser();
  const { paymentId } = useParams();
  useTracking();

  return (
    <div>
      <Header>
        <NavigationBar />
      </Header>
      <Container className="lf-page-payment">
        <Content className="lf-payment">
          <SuccessPlaceholder
            title="Payment completed successfully"
          >
            Your payment <code>{paymentId}</code> was completed succesfully, 
            enjoy your <span style={{ fontWeight: 'bold' }}>{user.plan.name}</span> membership!<br/>
            <br/>
            <Link to="/dashboard">Go back to the dashboard</Link>
          </SuccessPlaceholder>
        </Content>
      </Container>
    </div>
  );
};

export { PaymentPage }
