import _ from 'lodash';

import Frameworks from '../../frameworks';

import { generateImports } from './generate-imports';
import { generateJson } from './generate-json';
import { renderImports } from './render-import';

const generateVanillaCode = ({ form, framework, locale }) => {
  const manifest = Frameworks[framework];

  const imports = generateImports({ form, framework, locale });

  // start of code
  let vanillaCode =
    `import React from 'react';\n`
    + `import LetsForm from '${manifest.letsFormLibrary}';\n`
    + renderImports(imports.js)
    + renderImports(imports.css);

  // separator
  vanillaCode += '\n';

  // import form schema
  vanillaCode += `import myFormSchema from './form.json';\n`;

  // separator
  vanillaCode += '\n';

  // add extra code
  if (manifest.extraCode) {
    vanillaCode += manifest.extraCode;
    // separator
    vanillaCode += '\n';
  }

  let letsFormStartWrapper, letsFormEndWrapper;
  if (manifest.letsFormStartWrapper && manifest.letsFormEndWrapper) {
    letsFormStartWrapper = _.isFunction(manifest.letsFormStartWrapper) ?
      manifest.letsFormStartWrapper({ form, framework, locale }) : manifest.letsFormStartWrapper;
      letsFormEndWrapper = _.isFunction(manifest.letsFormEndWrapper) ?
      manifest.letsFormEndWrapper({ form, framework, locale }) : manifest.letsFormEndWrapper;
  }

  const tab = letsFormStartWrapper && letsFormEndWrapper ? '  ' : '';

  let vanillaComponent = [
          'const MyForm = () => {',
          '  return (',
    letsFormStartWrapper ?
          `    ${letsFormStartWrapper}` : null,
    tab + '    <LetsForm',
    tab + '      form={myFormSchema}',
    locale ?
    tab + `      locale="${locale}"` : null,
    tab + '      onSubmit={values => {',
    tab + '        // do something',
    tab + `        console.log('form values', values)`,
    tab + '      }}',
    tab + '    />',
    letsFormEndWrapper ?
          `    ${letsFormEndWrapper}` : null,
          '  );',
          '};'
  ];

  // vanilla component definition
  vanillaCode += vanillaComponent
    .filter(Boolean)
    .join('\n') + '\n';

  // separator
  vanillaCode += '\n';

  // export statement
  vanillaCode += `export { MyForm };`

  return vanillaCode;
};

const generateVanillaJsonCode = (form, framework) => {
  const json = generateJson(form, framework);
  return `const form = ${json};
export { form };`;
};

export { generateVanillaCode, generateVanillaJsonCode };
