import React, { useCallback, useState } from 'react';
import posthog from 'posthog-js';
import Button from 'rsuite/Button';

import './cookie-banner.scss';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(process.env.NODE_ENV !== 'development');
  const acceptCookies = useCallback(
    () => {
      posthog.opt_in_capturing();
      setShowBanner(false);
    },
    []
  );
  const declineCookies = useCallback(
    () => {
      posthog.opt_out_capturing();
      setShowBanner(false);
    },
    []
  );

  const hasOpted = posthog.has_opted_out_capturing() || posthog.has_opted_in_capturing();

  if (!hasOpted && showBanner) {
    return (
      <div className="lf-cookie-banner">

        <p>
          We use cookies to enhance your browsing experience and analyze our traffic.
          By clickng "Accept cookies", you consent to our use of cookies.
          See our <a href="/privacy">privacy page</a>
        </p>
        <div className="buttons">
        <Button
          type="button"
          appearance="primary"
          size="xs"
          onClick={acceptCookies}>Accept cookies</Button>
        <Button
          appearance="primary"
          type="button"
          size="xs"
          onClick={declineCookies}
        >Decline cookies</Button>
        </div>
      </div>
    );
  }
  return <></>;
};

export { CookieBanner };
