import React, { useCallback } from 'react';
import { Button } from 'rsuite';

import { DownloadGenericPackage } from '../../../components';
import { useModal } from '../../../components';
import { generateHtmlImportMapBundle } from '../../../components/code-generation/generate-html-import-code';

const ButtonExportImportMap = ({ form, framework, locale, disabled }) => {
  const { open, close } = useModal({
    view: DownloadGenericPackage,
    size: 'sm',
    name: 'downloadPackage',
    align: 'center',
    labelSubmit: null,
    labelCancel: null
  });

  const handleExportCRA = useCallback(
    async () => {
      const pack = generateHtmlImportMapBundle({ form, framework, locale });
      await open({
        pack,
        packageName: `${pack.appName}.zip`,
        instructions: `npm i serve -g\ncd ./${pack.appName}\nserve -p 3001`
      });
      await close();
    },
    [close, form, framework, locale, open]
  );

  return (
    <Button
      size="sm"
      appearance="primary"
      disabled={disabled}
      onClick={handleExportCRA}
    >Export to HTML (β)</Button>
  );
};

export { ButtonExportImportMap };
