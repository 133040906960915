import _ from 'lodash';
import React from 'react';

import './error-box.scss';

const ErrorBox = ({ error }) => (
  <div className="lf-error-box">
    {_.isString(error) ? error : 'Something went wrong, try again later' }
  </div>
);

export { ErrorBox };
