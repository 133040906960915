import _ from 'lodash';

import { FRAMEWORKS } from '../../../components/lets-form';
import { LOCALES } from '../../../components/lets-form/common/data/locales';

const ALL_LOCALES = Object.keys(LOCALES['language-names']);

export const getValidFramework = (framework, defaultValue) => {
  if (FRAMEWORKS.includes(framework)) {
    return framework;
  } else {
    return 'react-rsuite5';
  }
};

export const getValidSize = (size) => {
  if (_.isNumber(size)) {
    return Math.min(size, 24);
  } else if (_.isString(size) && !isNaN(parseInt(size, 10))) {
    return Math.min(parseInt(size, 10), 24);
  }
  return 12;
};

export const getValidLocale = locale => {
  return ALL_LOCALES.includes(locale) ? locale : 'en-US';
};
