import React, { useCallback } from 'react';

import { useFormBuilderStore } from '../../../pages/builder/state';

import { DebugPanel } from './debug-panel';

const DebugPanelContainer = () => {

  const formValue = useFormBuilderStore(state => state.formValue);
  const errors = useFormBuilderStore(state => state.errors);
  const responses = useFormBuilderStore(state => state.responses);
  const framework = useFormBuilderStore(state => state.framework);
  const locale = useFormBuilderStore(state => state.locale);
  const editMode = useFormBuilderStore(state => state.editMode);
  const jsErrors = useFormBuilderStore(state => state.jsErrors);
  const form = useFormBuilderStore(state => state.form);
  const setGeneration = useFormBuilderStore(state => state.setGeneration);
  const setJsErrors = useFormBuilderStore(state => state.setJsErrors);

  const handleRegenerate = useCallback(
    () => {
      setJsErrors(null);
      // trigger redesign
      setGeneration(generation => generation + 1);
    },
    [setGeneration, setJsErrors]
  );

  return (
    <DebugPanel
      value={formValue}
      errors={errors}
      responses={responses}
      framework={framework}
      locale={locale}
      editMode={editMode}
      jsErrors={jsErrors}
      form={form}
      onRegenerate={handleRegenerate}
    />
  );
};

export { DebugPanelContainer };
