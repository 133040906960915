import {
  COMMON_TRITTICUS_STATUS,
  makeColums,
  RSUITE5_APPEARANCE,
  RSUITE5_PLACEMENT,
  RSUITE5_SIZE,
  RSUITE_CLEANABLE,
  BOOTSTRAP_SIZE,
  BOOTSTRAP_PREFIXES,
  BOOTSTRAP_FLOATING_LABELS,
  COMMON_PLACEHOLDER,
  COMMON_TRITTICUS,
  COMMON_FULL_WIDTH_SIZE,
  MANTINE_SIZE,
  MANTINE_RADIUS,
  MANTINE_VARIANT,
  makeOptions,
  MANTINE_SECTIONS,
  MANTINE_POINTER,
  MANTINE_ERROR_WITH_STYLE,
  MUI_FLOATING_LABEL,
  MUI_SIZE,
  MUI_VARIANT,
  MUI_COLORS,
  MUI_DATE_FORMAT,
  MUI_FORMAT_DENSITY
} from '../costants/index.js';

import { manifest as DateManifest } from './date.js';

const manifest = {
  label: 'Date Time',
  category: 'general',
  name: 'datetime',
  frameworks: [
    'react-antd',
    'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    ...COMMON_TRITTICUS,
    COMMON_PLACEHOLDER,
    COMMON_TRITTICUS_STATUS
  ],
  'react-bootstrap': [
    BOOTSTRAP_SIZE,
    BOOTSTRAP_PREFIXES,
    BOOTSTRAP_FLOATING_LABELS,
    COMMON_FULL_WIDTH_SIZE
  ],
  'react-material-ui': [
    makeColums(
      MUI_SIZE,
      MUI_VARIANT,
      MUI_COLORS
    ),
    MUI_DATE_FORMAT,
    {
      name: 'views',
      label: 'Views',
      component: 'multiselect',
      cleanable: true,
      fullWidth: true,
      placeholder: 'Select views',
      options: [
        { value: 'hours', label: 'hours' },
        { value: 'minutes', label: 'minutes' },
        { value: 'seconds', label: 'seconds' },
        { value: 'day', label: 'day' },
        { value: 'month', label: 'month' },
        { value: 'year', label: 'year' }
      ]
    },
    makeColums(
      {
        name: 'closeOnSelect',
        component: 'toggle',
        label: 'Close on select',
        hint: 'If true, the popover or modal will close after submitting the full date',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'reduceAnimations',
        label: 'Reduce animations',
        component: 'toggle',
        hint: 'If true, disable heavy animations',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'showDaysOutsideCurrentMonth',
        label: 'Days outside',
        component: 'toggle',
        hint: `If true, days outside the current month are rendered:
- if fixedWeekNumber is defined, renders days to have the weeks requested.
- if fixedWeekNumber is not defined, renders day to fill the first and last week of the current month.
- ignored if calendars equals more than 1 on range pickers`,
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      MUI_FLOATING_LABEL,
      {
        name: 'disableFuture',
        label: 'Disable future',
        component: 'toggle',
        hint: 'If true, disable values after the current date for date components, time for time components and both for date time components',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'disableHighlightToday',
        label: 'Disable today',
        component: 'toggle',
        hint: `If true, today's date is rendering without highlighting with circle`,
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'disableOpenPicker',
        label: 'Disable open',
        component: 'toggle',
        hint: 'If true, the open picker button will not be rendered (renders only the field)',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'disablePast',
        label: 'Disable past',
        component: 'toggle',
        hint: 'If true, disable values before the current date for date components, time for time components and both for date time components',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'displayWeekNumber',
        label: 'Week number',
        component: 'toggle',
        hint: 'If true, the week number will be display in the calendar',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'ampm',
        component: 'toggle',
        label: 'AM or PM',
        hint: '12h/24h view for hour selection clock',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'ampmInClock',
        component: 'toggle',
        label: 'AM/PM bottom',
        hint: 'Display ampm controls under the clock (instead of in the toolbar)',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'forceMobile',
        component: 'toggle',
        label: 'Force mobile',
        hint: 'Force mobile component',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    {
      name: 'loading',
      component: 'toggle',
      label: 'Loading',
      hint: 'If true, calls renderLoading instead of rendering the day calendar. Can be used to preload information and show it in calendar',
      'react-rsuite5': {
        tooltip: true
      }
    },
    makeColums(
      {
        name: 'fixedWeekNumber',
        component: 'input-number',
        label: 'Fixed week number',
        min: 1,
        step: 1,
        hint: 'The day view will show as many weeks as needed after the end of the current month to match this value. Put it to 6 to have a fixed number of weeks in Gregorian calendars',
        'react-rsuite5': {
          tooltip: true
        }
      },
      MUI_FORMAT_DENSITY
    ),
    makeColums(
      {
        name: 'monthsPerRow',
        component: 'select',
        label: 'Months per row',
        options: [{ value: 3, label: '3' }, { value: 4, label: '4' }],
        fullWidth: true,
        hint: 'Months rendered per row',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'yearsPerRow',
        component: 'select',
        label: 'Years per row',
        options: [{ value: 3, label: '3' }, { value: 4, label: '4' }],
        fullWidth: true,
        hint: 'Years rendered per row',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      {
        name: 'minDate',
        component: 'date',
        label: 'Min date'
      },
      {
        name: 'maxDate',
        component: 'date',
        label: 'Max date'
      }
    ),
    makeColums(
      {
        name: 'minDateTime',
        component: 'datetime',
        label: 'Min date-time',
        format: 'dd/MM/yyyy HH:mm:ss'
      },
      {
        name: 'maxDateTime',
        component: 'datetime',
        label: 'Max date-time',
        format: 'dd/MM/yyyy HH:mm:ss'
      }
    ),
    makeColums(
      MUI_FORMAT_DENSITY,
      {
        name: 'minutesStep',
        component: 'input-number',
        label: 'Minute step',
        hint: 'Step over minutes',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    /*
    DOES NOTHING
    {
      name: 'orientation',
      component: 'select',
      label: 'Orientation',
      options: makeOptions(['landscape', 'portrait'])
    },
    */
    makeColums(
      {
        name: 'timeStepsHours',
        component: 'input-number',
        label: 'Steps hours',
        min: 1,
        step: 1,
        hint: 'The time steps between two time unit options. For example, if timeStep.minutes = 8, then the available minute options will be [0, 8, 16, 24, 32, 40, 48, 56]',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'timeStepsMinutes',
        component: 'input-number',
        label: 'Steps mins',
        min: 1,
        step: 1
      },
      {
        name: 'timeStepsSeconds',
        component: 'input-number',
        label: 'Steps secs',
        min: 1,
        step: 1
      }
    )






  ],
  'react-rsuite5': [
    {
      name: 'format',
      label: 'Date format',
      component: 'input-text',
      hint: 'Check https://date-fns.org library for available formats'
    },
    makeColums(
      RSUITE5_SIZE,
      RSUITE5_APPEARANCE,
      RSUITE5_PLACEMENT
    ),
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      RSUITE_CLEANABLE,
      {
        name: 'editable',
        label: 'Editable',
        component: 'toggle',
        hint: 'Rendered as an input, the date can be entered via the keyboard',
        'react-rsuite5': {
          tooltip: true
        }
      },
      []
    ),
    makeColums(
      {
        name: 'oneTap',
        label: 'One tap',
        component: 'toggle',
        hint: 'One click to complete the selection date',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'showMeridian',
        label: 'Show meridian',
        component: 'toggle',
        hint: 'Display hours in 12 format',
        'react-rsuite5': {
          tooltip: true
        }
      },
      []
    ),
    makeColums(
      'weekOptions',
      {
        name: 'isoWeek',
        label: 'ISO Week',
        component: 'toggle',
        hint: 'ISO 8601 standard, each calendar week begins on Monday and Sunday on the seventh day',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'showWeekNumbers',
        label: 'Show week numbers',
        component: 'toggle'
      },
      []
    )
  ],
  'react-antd': DateManifest['react-antd'], // same as Date
  'react-mantine': [
    makeColums(
      MANTINE_SIZE,
      MANTINE_RADIUS,
      MANTINE_VARIANT
    ),
    {
      name: 'valueFormat',
      component: 'input-text',
      label: 'Value format',
      hint: 'Dayjs format to display input value, "DD/MM/YYYY HH:mm" by default. Check https://day.js.org/docs/en/display/format for reference',

    },
    makeColums(
      {
        name: 'firstDayOfWeek',
        component: 'select',
        label: 'First day of week',
        options: [
          { value: 0, label: 'Sunday' },
          { value: 1, label: 'Monday' },
          { value: 2, label: 'Tuesday' },
          { value: 3, label: 'Wednesday' },
          { value: 4, label: 'Thursday' },
          { value: 5, label: 'Friday' },
          { value: 6, label: 'Saturday' },
        ],
        hint: 'number 0-6, 0 - Sunday, 6 - Saturday, defaults to 1 - Monday',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'hideOutsideDates',
        component: 'toggle',
        label: 'Hide outside dates',
        hint: 'Determines whether outside dates should be hidden, defaults to false',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'labelSeparator',
        component: 'input-text',
        label: 'Label separator',
        hint: 'Separator between range value',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      {
        name: 'withSeconds',
        component: 'toggle',
        label: 'With seconds'
      },
      {
        name: 'dropdownType',
        component: 'select',
        label: 'Dropdown type',
        options: makeOptions(['modal', 'popover']),
        hint: 'Type of dropdown, defaults to popover',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'allowDeselect',
        component: 'toggle',
        label: 'Allow deselect',
        hint: 'Determines whether user can deselect the date by clicking on selected item, applicable only when type="default"',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'clearable',
        component: 'toggle',
        label: 'Clearable',
        hint: 'Determines whether input value can be cleared, adds clear button to right section, false by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'hideWeekdays',
        component: 'toggle',
        label: 'Hide weekdays',
        hint: 'Determines whether weekdays row should be hidden, defaults to false',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'defaultLevel',
        component: 'select',
        label: 'Default level',
        options: makeOptions(['decade', 'month', 'year'])
      },
      {
        name: 'hasNextLevel',
        component: 'toggle',
        label: 'Has next level',
        hint: 'Determines whether next level button should be enabled, defaults to true',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'maxLevel',
        component: 'select',
        label: 'Max level',
        options: makeOptions(['decade', 'month', 'year']),
        hint: 'Max level that user can go up to (decade, year, month), defaults to decade',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'numberOfColumns',
        component: 'input-number',
        label: 'Number of Columns',
        hint: 'Number of columns to render next to each other',
        min: 1,
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'columnsToScroll',
        component: 'input-number',
        label: 'Columns to scroll',
        min: 1,
        hint: 'Number of columns to scroll when user clicks next/prev buttons, defaults to numberOfColumns',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      {
        name: 'minDate',
        component: 'date',
        label: 'Min date'
      },
      {
        name: 'maxDate',
        component: 'date',
        label: 'Max date'
      }
    ),
    makeColums(
      {
        name: 'withCellSpacing',
        component: 'toggle',
        label: 'With cell spacing',
        hint: 'Determines whether controls should be separated by spacing, true by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      MANTINE_POINTER,
      MANTINE_ERROR_WITH_STYLE
    ),
    MANTINE_SECTIONS
  ],
  defaultValues: {
    'react-mantine': {
      'react-mantine': {
        fullWidth: true,
        valueFormat: 'MMMM D, YYYY',
        dropdownType: 'popover',
        firstDayOfWeek: 1,
        hasNextLevel: true,
        withCellSpacing: true
      }
    },
    'react-antd': {
      'react-antd': {
        bordered: true,
        picker: 'date'
      }
    },
    'react-material-ui': {
      'react-material-ui': {
        views: ['year', 'day', 'hours', 'minutes']
      }
    }
  },
  // same as Date
  dependencies: DateManifest.dependencies
};

export { manifest };
