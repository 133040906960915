import React, { useCallback} from 'react';

import { useFormBuilderStore } from '../../../pages/builder/state';

import { ConnectorsPanel } from './connectors-panel';
import { replaceConnector, removeConnector } from '../helpers/replace-connector';

const ConnectorsPanelContainer = () => {
  const framework = useFormBuilderStore(state => state.framework);
  const form = useFormBuilderStore(state => state.form);
  const setForm = useFormBuilderStore(state => state.setForm);
  const locale = useFormBuilderStore(state => state.locale);
  const editMode = useFormBuilderStore(state => state.editMode);

  const handleChangeConnector =  useCallback(
    (idx, _connector, options) => {
      setForm(replaceConnector(form, idx, options));
    },
    [form, setForm]
  );

  const handleRemoveConnector = useCallback(
    (idx, _connector) => {
      if (window.confirm('Remove connector?')) {
        setForm(removeConnector(form, idx));
      }
    },
    [form, setForm]
  );

  const handleAddConnector = useCallback(
    (connector, options) => {
      setForm({
        ...form,
        connectors: [
          ...(form.connectors ?? []),
          {
            name: connector.name,
            options
          }
        ]
      });
    },
    [form, setForm]
  );

  return (
    <ConnectorsPanel
      framework={framework}
      form={form}
      locale={locale}
      editMode={editMode}
      onChangeConnector={handleChangeConnector}
      onRemoveConnector={handleRemoveConnector}
      onAddConnector={handleAddConnector}
    />
  );
};

export { ConnectorsPanelContainer };
