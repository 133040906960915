import React from 'react';
import { gql, useQuery } from '@apollo/client';

import LetsForm from '../../../../components/lets-form';
import { ErrorPlaceholder, EnsureFrameworkProvider } from '../../../../components';

const GET_FORM_VERSION = gql`
query($id: ID!) {
  form: forms_versions_by_id(id: $id) {
    id,
    json
  }
}`;

const PreviewForm = ({ value, form, framework, onSubmit }) => {
  const { loading, data, error } = useQuery(GET_FORM_VERSION, {
    variables: {
      id: form.versions[0].id
    }
  })

  let formSchema;
  if (data && data.form) {
    try {
      formSchema = JSON.parse(data.form.json);
    } catch(e) {
      formSchema = null;
    }
  }

  return (
    <div className="lf-preview-form">
      {loading && (
        <div>loading...</div>
      )}
      {!loading && !error && (
        <div>
          <style>
            ss
          </style>
          <EnsureFrameworkProvider framework={framework}>
            <LetsForm 
              form={formSchema} 
              onReset={onSubmit}
              framework={framework}
              demo
            />
          </EnsureFrameworkProvider>
        </div>  
      )}
      {(error || formSchema === null) && (
        <ErrorPlaceholder>
          Something went wrong trying to preview the form
        </ErrorPlaceholder>
      )}
    </div>
  );
};

export { PreviewForm };
