import LetsForm from 'lets-form/react-rsuite5';

import jsonCourtDriveForm from './court-drive-form.json';
import jsonProjectEdit from './project-edit.json';
import jsonEditFormDescription from './editform-description.json';
import jsonLogin from './login.json';
import jsonCreateNewForm from './create-new-form.json';
import jsonRequestBeta from './request-beta.json';
import jsonSaveIntoProject from './save-into-project.json';
import jsonUserProfile from './user-profile.json';
import jsonOrganizationProfile from './organization-profile.json';
import jsonExportToGithub from './export-to-github.json';
import jsonSignUp from './sign-up.json';
import jsonPromoCode from './promo-code.json';
import jsonSaveComment from './save-comment.json';
import jsonMobileForm from './mobile-form.json';
import jsonPlaygroundConfiguration from './playground-configuration.json';
import jsonCorsProxy from './cors-proxy.json';
import jsonPublishForm from './publish-form.json';

export const CourtDriveForm = props => <LetsForm form={jsonCourtDriveForm} {...props} />;
export const ProjectEdit = props => <LetsForm form={jsonProjectEdit} {...props} />;
export const EditFormDescription = props => <LetsForm form={jsonEditFormDescription} {...props} />;
export const Login = props => <LetsForm form={jsonLogin} {...props} />;
export const CreateNewForm = props => <LetsForm form={jsonCreateNewForm} {...props} />;
export const RequestBeta = props => <LetsForm form={jsonRequestBeta} {...props} />;
export const SaveIntoProject = props => <LetsForm form={jsonSaveIntoProject} {...props} />;
export const UserProfile = props => <LetsForm form={jsonUserProfile} {...props} />;
export const OrganizationProfile = props => <LetsForm form={jsonOrganizationProfile} {...props} />;
export const ExportToGithub = props => <LetsForm form={jsonExportToGithub} {...props} />;
export const SignUp = props => <LetsForm form={jsonSignUp} {...props} />;
export const PromoCode = props => <LetsForm form={jsonPromoCode} {...props} />;
export const SaveComment = props => <LetsForm form={jsonSaveComment} {...props} />;
export const MobileForm = props => <LetsForm form={jsonMobileForm} {...props} />;
export const PlaygroundConfiguration = props => <LetsForm form={jsonPlaygroundConfiguration} {...props} />;
export const CorsProxy = props => <LetsForm form={jsonCorsProxy} {...props} />;
export const PublishForm = props => <LetsForm form={jsonPublishForm} {...props} />;

