import _ from 'lodash';

const makeName = function() {
  let result = [];
  let idx = 0;
  for(idx = 0; idx < arguments.length; idx++) {
    if (_.isArray(arguments[idx]) && !_.isEmpty(arguments[idx])) {
      result.push(arguments[idx][0].name);
    } else if (arguments[idx]) {
      result.push(arguments[idx].name);
    }
  }
  return result.length !== 0 ? result.join('-') : '';
};

export const threeColumns = (left, center, right, groupName) => ({
  component: 'columns',
  columns: [
    { name: 'one', size: 1 },
    { name: 'two', size: 1 },
    { name: 'three', size: 1}
  ],
  name: groupName ?? 'three-columns-' + makeName(left, center, right),
  fields: {
    one: _.isArray(left) ? left : [left],
    two: _.isArray(center) ? center : [center],
    three: _.isArray(right) ? right : [right]
  }
});

export const twoColumns = (left, right, groupName) => ({
  component: 'columns',
  name: groupName ?? 'columns-' + makeName(left, right),
  columns: [
    { name: 'one', size: 1 },
    { name: 'two', size: 1 }
  ],
  fields: {
    one: _.isArray(left) ? left : [left],
    two: _.isArray(right) ? right : [right]
  }
});

export const makeColums = function() {
  let groupName;
  let args = [...arguments];
  if (_.isString(args[0])) {
    groupName = args[0];
    args = args.slice(1);
  }

  if (args.length === 1) {
    return twoColumns(args[0], [], groupName);
  } else if (args.length === 2) {
    return twoColumns(args[0], args[1], groupName);
  } else if (args.length === 3) {
    return threeColumns(args[0], args[1], args[2], groupName);
  }
};

export const makeOptions = arr => arr.map(str => ({
  value: str,
  label: _.capitalize(str)
}));