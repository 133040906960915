/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';

import { ErrorPlaceholder, AlchemicLoader } from '../../components';

import './github-landing-page.scss';

const REDIRECT_URL = process.env.NODE_ENV === 'development' ?
  'http://localhost:3000/dashboard' : 'https://designer.letsform.dev/dashboard';

const GitHubLandingPage = () => {
  const [error, setError] = useState();
  const [params] = useSearchParams();

  useEffect(
    () => {
      const run = async () => {
        if (params.get('reason') === 'INVALID_CREDENTIALS') {
          setError(true);
        } else {
          const res = await fetch('https://dashboard.letsform.dev/auth/refresh', {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ mode: 'cookie' })
          });
          const data = await res.json();

          if (data && data.data && data.data.access_token) {
            localStorage.setItem('access_token', data.data.access_token);
            // then redirect
            window.location = REDIRECT_URL;
          } else {
            setError(true);
          }
        }
      };
      run();
    },
    [params]
  );

  return (
    <div className="lf-page-github-landing-page">
      {!error && (
        <>
          <AlchemicLoader />
        </>
      )}
      {error && (
        <>
          <ErrorPlaceholder
            title="Invalid credentials"
          >
            Something went wrong with GitHub autentication. Likely it's because you didn't marked your emai{' '}
            as public in your GitHub profile settings:{' '}
            <a target="_blank" href="https://github.com/settings/profile">click here</a> to fix, then try to <Link to="/login">login again</Link>
          </ErrorPlaceholder>
          <div className="preview" />
        </>
      )}
    </div>
  );
};

export { GitHubLandingPage };
