import React from 'react';

import { useFormBuilderStore } from '../../../pages/builder/state';

import { ExportPanel } from './export-panel';

const ExportPanelContainer = () => {
  const framework = useFormBuilderStore(state => state.framework);
  const form = useFormBuilderStore(state => state.form);
  const locale = useFormBuilderStore(state => state.locale);
  const editMode = useFormBuilderStore(state => state.editMode);

  return (
    <ExportPanel
      framework={framework}
      form={form}
      locale={locale}
      editMode={editMode}
    />
  );
};

export { ExportPanelContainer };
