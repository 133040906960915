/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback } from 'react';
import { Form, Input } from 'rsuite';
import _ from 'lodash';


import { RequiredIcon, I18N } from '../../../../components/lets-form/components';
import { validateJSONForm } from '../../../../components/lets-form';

import './json.scss';

const ControlTextare = (props) => <Input as="textarea" {...props} />


/**
 * JSONEditor
 * Custom JSON editor for forms
*/

const JSONEditor = I18N(
  ({
    name,
    label,
    hint,
    value,
    placeholder,
    tooltip = false,
    disabled = false,
    readOnly = false,
    required = false,
    onChange,
    onBlur,
    rows = 10
  }) => {
    const [jsonError, setJsonError] = useState(false);
    const [currentJSON, setCurrentJSON] = useState(JSON.stringify(value, null, 2));

    const handleSelectTemplate = useCallback(
      e => {
        e.preventDefault();
      },
      []
    );

    const handleChange = useCallback(
      value => {
        if (_.isEmpty(value)) {
          setCurrentJSON('');
          setJsonError(null);
          return;
        }
        let json;
        try {
          json = JSON.parse(value);
        } catch(e) {
          // do nothing
          setJsonError('Invalid JSON');
          setCurrentJSON(value);
          return;
        }

        if (json) {
          if (_.isArray(json)) {
            //if array, put as is
            setCurrentJSON(value);
            setJsonError();
            onChange(json);
          } else if (_.isObject(json)) {
            console.log('ok oggetto', json, validateJSONForm(json))
            // if is object, then check if it's a valid form schema
            const formValidation = validateJSONForm(json);
            if (formValidation) {
              // if not valid, then show error
              setCurrentJSON(value);
              setJsonError(formValidation);
              return;
            } else {
              // if valid schema, then keep just the fields
              setCurrentJSON(JSON.stringify(json.fields, null, 2));
              setJsonError(null);
              onChange(json.fields);
            }
          }
        } else {
          // just update the state if it's not a valid json
          setCurrentJSON(value);
        }

      },
      [onChange]
    );

    const inner = (
      <Form.Control
        name={name}
        accepter={ControlTextare}
        rows={rows}
        value={currentJSON}
        onChange={handleChange}
        onBlur={onBlur}
        disabled={disabled}
        placeholder={placeholder}
        readOnly={readOnly}
        errorMessage={jsonError}
      />
    );

    return (
      <Form.Group
        className="lf-control-json"
        data-lf-field-name={name}
      >
        {label && <Form.ControlLabel>
          {label}
          {hint && tooltip && <Form.HelpText tooltip>{hint}</Form.HelpText>}
          {required && <RequiredIcon />}
        </Form.ControlLabel>}
        {inner}
        <div>
          <a
            href="#"
            onClick={handleSelectTemplate}
          >Select from template</a>
        </div>
        {hint && !tooltip && <Form.HelpText>{hint}</Form.HelpText>}
      </Form.Group>
    );
  },
  ['label', 'hint', 'placeholder']
);

export { JSONEditor };