import React from 'react';
import { Whisper, Tooltip, ButtonGroup, IconButton } from 'rsuite';
import ReloadIcon from '@rsuite/icons/Reload';
import NumbersIcon from '@rsuite/icons/Numbers';
import EditIcon from '@rsuite/icons/Edit';
import PlayOutlineIcon from '@rsuite/icons/PlayOutline';
import TreeIcon from '@rsuite/icons/Tree';
import PageIcon from '@rsuite/icons/Page';

import './test-toolbar.scss';

const TestToolbar = ({
  onRegenerate,
  onTestData,
  editMode,
  plaintextMode,
  onEditMode,
  onPlaintextMode
}) => {
  return (
    <ButtonGroup className="lf-test-toolbar">
      <Whisper
        placement="topStart"
        speaker={
          <Tooltip>
            Reload the form
          </Tooltip>
        }
        trigger="hover"
      >
        <IconButton
          appearance="ghost"
          data-testid="lf-btn-reload-form"
          icon={<ReloadIcon/>}
          onClick={onRegenerate}
        />
      </Whisper>
      <Whisper
        placement="topStart"
        speaker={
          <Tooltip>
            Generate test data for the form
          </Tooltip>
        }
        trigger="hover"
      >
        <IconButton
          appearance="ghost"
          data-testid="lf-btn-generate-test-data"
          icon={<NumbersIcon/>}
          onClick={onTestData}
        />
      </Whisper>
      {!plaintextMode && (
        <Whisper
          placement="topStart"
          speaker={
            <Tooltip>
              Set the form in "plaintext" mode, all fields all read-only, how it would
              look like in a confirmation box with a summary of inserted values
            </Tooltip>
          }
          trigger="hover"
        >
          <IconButton
            appearance="ghost"
            data-testid="lf-btn-plaintext-mode"
            icon={<PageIcon/>}
            onClick={() => onPlaintextMode(true)}
          />
        </Whisper>
      )}
      {plaintextMode && (
        <Whisper
          placement="topStart"
          speaker={
            <Tooltip>
              Set the form in "insert" mode, all fields are editable, how it would
              look like at startup, ready to accept input from user
            </Tooltip>
          }
          trigger="hover"
        >
          <IconButton
            appearance="ghost"
            data-testid="lf-btn-insert-mode"
            icon={<TreeIcon/>}
            onClick={() => onPlaintextMode(false)}
          />
        </Whisper>
      )}
      {editMode && (
        <Whisper
          placement="topStart"
          speaker={
            <Tooltip>
              Set the form in "preview" mode
            </Tooltip>
          }
          trigger="hover"
        >
          <IconButton
            appearance="ghost"
            data-testid="lf-btn-preview-mode"
            icon={<PlayOutlineIcon/>}
            onClick={() => onEditMode(false)}
          />
        </Whisper>
      )}
      {!editMode && (
        <Whisper
          placement="topStart"
          speaker={
            <Tooltip>
              set the form in "edit" mode
            </Tooltip>
          }
          trigger="hover"
        >
          <IconButton
            appearance="ghost"
            data-testid="lf-btn-edit-mode"
            icon={<EditIcon/>}
            onClick={() => onEditMode(true)}
          />
        </Whisper>
      )}
    </ButtonGroup>
  );
};

export { TestToolbar };
