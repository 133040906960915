import { definePlugin } from '../../code-plug';

import { SaveButtonWithLogin } from './views/save-button-with-login';

definePlugin('builder-save-anonymous', ({ registerView }) => {

  registerView(
    'form-builder-toolbar',
    SaveButtonWithLogin,
    {
      end: true,
      key: 'form-builder-toolbar-save-anonymous'
    }
  );
});
