import React, { useCallback } from 'react';
import Button from 'rsuite/Button';
import { GrCodeSandbox } from 'react-icons/gr';
import { Whisper, Tooltip } from 'rsuite';

import { useTracking } from '../../../hooks';
import { useModal } from '../../../components';

import { ModalCodesandbox } from './modal-codesandbox';

const LaunchCodeSandbox = ({
  disabled = false,
  form,
  locale,
  framework
}) => {
  const { sendEvent } = useTracking();

  const { open, close } = useModal({
    view: ModalCodesandbox,
    size: 'sm',
    name: 'modalCodeSandbox',
    align: 'center',
    labelSubmit: null,
    labelCancel: null,
    title: ' '
  });

  const handleOpenCodeSandbox = useCallback(
    async () => {
      sendEvent('form.code-sandbox')
      await open({ form, locale, framework });
      close();
    },
    [close, form, framework, locale, open, sendEvent]
  );

  return (
    <Whisper
      speaker={<Tooltip>Open the current form in a working project in CodeSandbox</Tooltip>}
      placement="top"
      trigger="hover"
    >
      <Button
        size="sm"
        className="lf-open-modal-codesandbox"
        startIcon={<GrCodeSandbox color="#ffffff"/>}
        appearance="primary"
        disabled={disabled}
        onClick={handleOpenCodeSandbox}
      >Codesandbox</Button>
    </Whisper>
  );
};

export { LaunchCodeSandbox };
