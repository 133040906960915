import { mapFields, filterFields, findField } from '../../../components/lets-form';

const moveBeforeField = (fields, fieldId, afterFieldId) => {
  const fieldToMove = findField(fields, f => f.id === fieldId);

  // remove field from its original position
  let newFields = filterFields(
    fields,
    f => f.id !== fieldId 
  );

  // return the moved field
  newFields = mapFields(
    newFields,
    f => {
      if (f.id === afterFieldId) {
        return [fieldToMove, f];
      }
      return f;
    }
  )

  return newFields;
} 

export { moveBeforeField };