import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';

import { AuthenticationContext } from '../helpers';
import { AlchemicLoader } from '../components';

const getUser = async ({ token }) => {
  try {
    const res = await fetch(
      '/api/me',
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      }
    );
    const data = await res.json();

    return data?.user;
  } catch(e) {
    console.log('[LetsForm Auth] Error fetching user', e);
    return null;
  }
};

const refreshToken = async () => {
  try {
    const res = await fetch('https://dashboard.letsform.dev/auth/refresh', {
      method: 'POST',
      credentials: 'include', // this is required in order to send the refresh token cookie
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ mode: 'cookie' })
    });
    const data = await res.json();
    return data?.data;
  } catch(e) {
    return null;
  }
};

const AuthenticationProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(undefined);

  const getMe = useCallback(
    async ({ token }) => {
      if (!token) {
        console.log('[LetsForm] missing or invalid token while fetching /me');
        return null;
      }
      const user = await getUser({ token });
      if (user) {
        setCurrentUser(user);
        localStorage.setItem('lf_email', user.email);
        localStorage.setItem('lf_user_id', user.id);
        if (user.downgraded) {
          // eslint-disable-next-line no-restricted-globals
          alert(user.downgraded);
        }
        return user;
      } else {
        return null;
      }
    },
    []
  );

  useEffect(
    () => {
      const f = async () => {
        let token = localStorage.getItem('access_token');
        // TODO also if is expired
        if (_.isEmpty(token)) {
          console.log('[LetsForm Auth] Missing or expired token, refreshing');
          const credentials = await refreshToken();
          if (credentials) {
            token = credentials.access_token;
            localStorage.setItem('access_token', credentials.access_token);
            localStorage.setItem('token_expires_at', new Date(new Date().getTime() + credentials.expires).toISOString());
          }
        }
        if (token) {
          const user = await getMe({ token });
          if (process.env.NODE_ENV === 'development') {
            console.log('[LetsForm Auth] Current user', user);
          }
          setCurrentUser(user);
          return;
        }
        console.log('[LetsForm Auth] no token, anonymous');
        setCurrentUser(null);
      }
      f();
    },
    [getMe]
  );

  if (currentUser !== undefined) {
    return (
      <AuthenticationContext.Provider value={{
        user: currentUser,
        setCurrentUser,
        getMe
      }}>
        {children}
      </AuthenticationContext.Provider>
    );
  } else {
    return (
      <div style={{ paddingTop: '200px' }}>
        <AlchemicLoader/>
      </div>
    );
  }
};

export { AuthenticationProvider };
