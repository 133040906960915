export const sizeOfObj = (obj, decimals = 2) => {
  const bytes = JSON.stringify(obj).length;

  if (!+bytes) {
    return (
      <span className="size">
        <span className="figure">0</span>
        {' '}
        <span className="unit">bytes</span>
      </span>
    );
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return (
    <span className="size">
      <span className="figure">{parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}</span>
      {' '}
      <span className="unit">{sizes[i]}</span>
    </span>
  );
};
