import { definePlugin } from '../../code-plug';

import { ExportToReactCodeButton } from './views/export-to-react-code-button';
import { ExportToReactCodeMenuItem } from './views/export-to-react-code-menu-item';

definePlugin('form-builder-export-to-react-code', ({ registerView }) => {

  // register export to react button in export tab
  registerView(
    'form-builder-tab-export-buttons',
    ExportToReactCodeButton,
    {
      key: 'form-builder-tab-export-buttons-export-to-react'
    }
  );
  // register menu item in Form
  registerView(
    'form-builder-toolbar',
    ExportToReactCodeMenuItem,
    {
      parent: 'Form',
      key: 'form-builder-toolbar-export-to-react-code'
    }
  );
});
