import {
  COMMON_TRITTICUS,
  COMMON_DUOS_STATUS,
  makeColums,
  RSUITE5_HINT_AS_TOOLTIP
} from '../costants/index.js';

const manifest = {
  label: 'Radio Tile',
  description: 'Radio control with card, description and icon',
  category: 'general',
  name: 'radio-tile',
  frameworks: [
    //'react-antd',
    //'react',
    //'react-bootstrap',
    //'react-material-ui',
    'react-rsuite5'
    //'react-mantine'
  ],
  fields: [
    ...COMMON_TRITTICUS,
    {
      component: 'array',
      name: 'options',
      label: 'Radio tile options',
      maxHeight: 350,
      fields: [
        {
          component: 'columns',
          columns: [
            { name: 'one', size: 0 },
            { name: 'two', size: 1 }
          ],
          name: 'array-option-item',
          fields: {
            one: [
              {
                name: 'value',
                component: 'input-text',
                placeholder: 'value',
                size: 'sm',
                width: 100,
                fullWidth: false
              }
            ],
            two: [
              {
                name: 'label',
                component: 'input-text-i18n',
                placeholder: 'label',
                size: 'sm',
                fullWidth: true
              }
            ]
          }
        },
        {
          name: 'description',
          component: 'input-text-i18n',
          placeholder: 'Option description',
          size: 'sm',
          block: true
        },
        {
          name: 'icon',
          component: 'input-text',
          placeholder: 'Option icon',
          size: 'sm',
          block: true
        }
      ]
    },
    {
      name: 'initalOption',
      component: 'input-text',
      label: 'Initial option'
    },
    COMMON_DUOS_STATUS
  ],
  'react-rsuite5': [
    {
      name: 'inline',
      component: 'toggle',
      label: 'Inline',
      hint: 'Use inline layout',
      'react-rsuite5': {
        tooltip: true
      }
    },
    makeColums(
      {
        name: 'iconWidth',
        component: 'input-number',
        label: 'Icon width',
        min: 0,
        step: 5,
        postfix: 'px',
        allowClear: true
      },
      {
        name: 'iconHeight',
        component: 'input-number',
        label: 'Icon height',
        min: 0,
        step: 5,
        postfix: 'px',
        allowClear: true
      }
    ),
    RSUITE5_HINT_AS_TOOLTIP
  ],
  'react-material-ui': [],
  'react-bootstrap': [],
  'react-antd': [],
  'form-generator': {},
  defaultValues: {
    'react-rsuite5': {
      inline: true,
      options: [
        {
          value: 'one',
          label: 'One',
          description: 'First option',
          icon: 'https://unpkg.com/lets-form-icons@1.0.2/one.svg'
        },
        {
          value: 'two',
          label: 'Two',
          description: 'Second option',
          icon: "https://unpkg.com/lets-form-icons@1.0.2/two.svg"
        },
        {
          value: 'three',
          label: 'Three',
          description: 'Third option',
          icon: 'https://unpkg.com/lets-form-icons@1.0.2/three.svg'
        }
      ]
    }
  }
};

export { manifest };
