import {
  ANTD_ALLOW_CLEAR,
  ANTD_PLACEMENT,
  ANTD_HINT_AS_TOOLTIP,
  ANTD_SIZE,
  BOOTSTRAP_FLOATING_LABELS,
  BOOTSTRAP_SIZE,
  COMMON_PLACEHOLDER,
  COMMON_TRITTICUS_STATUS,
  MUI_FLOATING_LABEL,
  COMMON_FULL_WIDTH_SIZE,
  MUI_SIZE_NO_LARGE,
  MUI_VARIANT,
  RSUITE5_APPEARANCE,
  RSUITE5_PLACEMENT,
  RSUITE5_SIZE,
  RSUITE_CLEANABLE,
  COMMON_TRITTICUS,
  RSUITE5_HINT_AS_TOOLTIP,
  COMMON_FILTER_OPTIONS,
  COMMON_ARRAY_OPTIONS_WITH_IMAGE,
  makeColums,
  makeOptions,
  MANTINE_POINTER,
  MANTINE_SIZE,
  MANTINE_RADIUS,
  MANTINE_VARIANT,
  MANTINE_ERROR_WITH_STYLE,
  MANTINE_SECTIONS,
  MANTINE_CLEARABLE,
  MANTINE_WITH_SCROLL_AREA,
  ANTD_VARIANT
} from '../costants/index.js';

const manifest = {
  label: 'Select',
  category: 'general',
  description: 'Select / Combo box',
  frameworks: [
    'react-antd',
    'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  name: 'select',
  fields: [
    ...COMMON_TRITTICUS,
    COMMON_PLACEHOLDER,
    COMMON_ARRAY_OPTIONS_WITH_IMAGE,
    COMMON_FILTER_OPTIONS,
    COMMON_TRITTICUS_STATUS
  ],
  'react-material-ui': [
    makeColums(
      MUI_SIZE_NO_LARGE,
      {
        component: 'toggle',
        name: 'autoWidth',
        label: 'Auto width',
        hint: 'If true, the width of the popover will automatically be set according to the items inside the menu, otherwise it will be at least the width of the select input.',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      MUI_FLOATING_LABEL,
      MUI_VARIANT
    )
  ],
  'react-bootstrap': [
    BOOTSTRAP_SIZE,
    BOOTSTRAP_FLOATING_LABELS
  ],
  'react': [
    COMMON_FULL_WIDTH_SIZE
  ],
  'react-antd': [
    makeColums(
      ANTD_SIZE,
      ANTD_VARIANT
    ),
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      ANTD_PLACEMENT,
      {
        name: 'listHeight',
        label: 'List height',
        hint: 'Config popup height',
        component: 'input-number',
        min: 0,
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      ANTD_ALLOW_CLEAR,
      {
        name: 'popupMatchSelectWidth',
        label: 'Popup match',
        hint: 'Determine whether the popup menu and the select input are the same width',
        component: 'toggle',
        'react-rsuite5': {
          tooltip: true
        }
      },
      ANTD_HINT_AS_TOOLTIP
    ),
    makeColums(
      {
        name: 'showSearch',
        label: 'Show search',
        hint: 'Whether select is searchable',
        component: 'toggle',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'virtual',
        label: 'Virtual scroll',
        component: 'toggle',
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'showArrow',
        component: 'toggle',
        label: 'Show arrow'
      }
    )
  ],
  'react-rsuite5': [
    {
      component: 'columns',
      columns: [
        { name: 'one', size: 1 },
        { name: 'two', size: 1 }
      ],
      fields: {
        one: [
          RSUITE5_PLACEMENT
        ],
        two: [
          RSUITE5_SIZE
        ]
      }
    },
    COMMON_FULL_WIDTH_SIZE,
    makeColums(
      {
        label: 'Searchable',
        name: 'searchable',
        component: 'toggle'
      },
      RSUITE_CLEANABLE,
      RSUITE5_HINT_AS_TOOLTIP
    ),
    RSUITE5_APPEARANCE
  ],
  'react-mantine': [
    makeColums(
      MANTINE_SIZE,
      MANTINE_RADIUS,
      MANTINE_VARIANT
    ),
    COMMON_FULL_WIDTH_SIZE,
    MANTINE_SECTIONS,
    makeColums(
      {
        name: 'withCheckIcon',
        component: 'toggle',
        label: 'Check icon',
        hint: 'Determines whether check icon should be displayed near the selected option label, true by default',
        script: `if (withCheckIcon) {
          enable('checkIconPosition');
        } else {
          disable('checkIconPosition');
        }`,
        'react-rsuite5': {
          tooltip: true
        }
      },
      {
        name: 'checkIconPosition',
        component: 'select',
        label: 'Check icon position',
        options: makeOptions(['left', 'right']),
        searchable: false
      }
    ),
    makeColums(
      {
        name: 'allowDeselect',
        component: 'toggle',
        label: 'Allow deselect',
        hint: 'Determines whether it should be possible to deselect value by clicking on the selected option, true by default',
        'react-rsuite5': {
          tooltip: true
        }
      },
      MANTINE_CLEARABLE,
      {
        name: 'defaultDropdownOpened',
        component: 'toggle',
        label: 'Initially open',
        hint: 'Uncontrolled dropdown initial opened state',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      MANTINE_WITH_SCROLL_AREA,
      MANTINE_POINTER,
      MANTINE_ERROR_WITH_STYLE
    )
  ],
  defaultValues: {
    'react-mantine': {
      'react-mantine': {
        allowDeselect: true,
        withCheckIcon: true,
        withScrollArea: true,
        maxDropdownHeight: 250
      },
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react': {
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react-rsuite5': {
      'react-rsuite5': {
        cleanable: false,
        searchable: false,
      },
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react-antd': {
      'react-antd': {
        popupMatchSelectWidth: true,
        bordered: true,
        listHeight: 256,
        fullWidth: true,
        virtual: true,
        showArrow: true,
      },
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react-material-ui': {
      'react-material-ui': {
        fullWidth: true
      },
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    },
    'react-bootstrap': {
      options: [
        { value: 'one', label: 'One' },
        { value: 'two', label: 'Two' },
        { value: 'three', label: 'Three' }
      ]
    }
  }
};

export { manifest };
