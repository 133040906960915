import React, { useCallback, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Button } from 'rsuite';
import _ from 'lodash';

import { useCurrentUser, useNotification } from '../../../hooks';

const GET_PLAN = gql`
query($id: ID!) {
  plan: plans_by_id(id: $id) {
    id,
    name,
    short_description,
    long_description,
    price
  }
}`;

const CurrentPlan = () => {
  const { user, getMe } = useCurrentUser();
  const notification = useNotification();
  const [cancelling, setCancelling] = useState(false);
  const { data, loading } = useQuery(GET_PLAN, {
    variables: {
      id: user.organization.planId
    }
  });

  const handleCancelSubscription = useCallback(
    async () => {
      try {
        const subscriptionExpiresAt = new Date(user.organization.expire_at);
        if (!window.confirm(`Cancel subscription to LetsForm? You will not be charged anymore for LetsForm, your ${user.plan.name} subscription will expire on ${subscriptionExpiresAt}`)) {
          return;
        }
        setCancelling(true);
        const response = await fetch('/api/subscription', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          },
          method: 'DELETE',
          body: JSON.stringify({
            organizationId: user.organization.id
          })
        });
        const json = await response.json();
        setCancelling(false);

        if (response.status === 200) {
          // reload credentials
          await getMe({ token: localStorage.getItem('access_token') });

          notification.success(
            'Subscription',
            <div>
              <b>Subscription cancelled succesfully</b>, on xxx your subscription will turn to <b>LetsForm Free</b>
            </div>
          );
        } else {
          notification.error(
            'Subscription',
            <div>
              <b>Error cancelling Subscription</b>: {json.error}
            </div>
          );
        }
      } catch(e) {        
        notification.somethingWentWrong();
        setCancelling(false);
      }
    },
    [getMe, notification, user]
  );

  const subscriptionExpiresAt = new Date(user.organization.expire_at);
  const canCancelSubscription = !_.isEmpty(user.organization.stripe_subscription)
    && _.isEmpty(user.organization.date_canceled);

  return (
    <div className="membership-plan">
      <div className="label">Current plan</div> 
      <div className="value">{user.plan.name}</div>
      
      <div className="label">{!_.isEmpty(user.organization.date_canceled) ? 'Expires on' : 'Renews on'}</div> 
      <div className="value">
        {subscriptionExpiresAt.toLocaleDateString()} {subscriptionExpiresAt.toLocaleTimeString()}
        {canCancelSubscription && (
          <>
            {' '}
            <Button
              color="orange"
              size="xs"
              appearance="ghost"
              disabled={cancelling}
              onClick={handleCancelSubscription}
            >Cancel subscription</Button>
          </>
        )}
        {' '}
      </div>

      {!loading && (
        <>
          <div className="label">Description</div>
          <div className="value">
            {data.plan.short_description}
          </div>
        </>
      )}  
    </div>
  );
};

export { CurrentPlan };