import { definePlugin } from '../../code-plug';

import { ConnectorsPanelContainer } from './views/connectors-panel-container';

definePlugin('builder-connectors', ({ registerView }) => {

  registerView(
    'builder-tabs',
    ConnectorsPanelContainer,
    {
      tabName: 'Connectors',
      tabKey: 'connectors'
    }
  );
});
