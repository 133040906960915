import React from 'react';
import { Container, Content } from 'rsuite';

import { Block404 } from '../../components/404';

import './404.scss';

const EmptyPage = () => {
  return (
    <div>
      <Container className="lf-page-missing-page">
        <Content className="privacy-content">
          <Block404/>
        </Content>
      </Container>
    </div>
  );
};

export { EmptyPage };
