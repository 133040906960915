import {
  ANTD_SIZE,
  ANTD_TYPE,
  COMMON_FULL_WIDTH_SIZE,
  COMMON_HIDDEN,
  COMMON_NAME,
  RSUITE5_APPEARANCE_ALL,
  RSUITE5_SIZE,
  BOOTSTRAP_VARIANT,
  makeColums,
  BOOTSTRAP_SIZE,
  RSUITE5_HINT_AS_TOOLTIP,
  MUI_SIZE,
  MUI_VARIANT,
  MUI_COLORS,
  MANTINE_BUTTON_SIZE,
  MANTINE_BUTTON_VARIANT,
  MANTINE_COLORS,
  MANTINE_RADIUS
} from '../costants/index.js';


const manifest = {
  label: 'Button',
  category: 'layout',
  description: 'Icon button, can be used as toggle button or just a link button',
  name: 'button',
  frameworks: [
    'react-antd',
    'react',
    'react-bootstrap',
    'react-material-ui',
    'react-rsuite5',
    'react-mantine'
  ],
  fields: [
    COMMON_NAME,
    {
      "component": "radio-group",
      "label": "Button type",
      "name": "buttonType",
      "options": [
        {
          "value": "toggle",
          "label": "Toggle button"
        },
        {
          "value": "link",
          "label": "Link button"
        }
      ],
      script: `
      if (buttonType === 'toggle') {
        show('groupButtonOff');
        show('groupButtonOn');
        show('initialValue');
        hide('groupButton');
        hide('href');
      } else {
        hide('groupButtonOn');
        hide('groupButtonOff');
        hide('initialValue');
        show('groupButton');
        show('href');
      }`,
      'react-rsuite5': {
        appearance: 'picker',
        inline: true,
        tooltip: true
      },
      hint: 'Type of button: toggle 2-states button or link button'
    },
    {
      component: 'group',
      name: 'groupButtonOn',
      label: 'Button state ON',
      collapsible: false,
      align: 'left',
      fields: [
        makeColums(
          {
            component: 'input-text-i18n',
            name: 'labelOn',
            placeholder: 'Button label',
            'react-rsuite5': {
              size: 'sm',
              textarea: false,
              rows: 3
            }
          },
          {
            component: 'input-text',
            name: 'iconOn',
            'react-rsuite5': {
              fullWidth: true,
              size: 'sm'
            },
            placeholder: 'Button icon'
          }
        )
      ]
    },
    {
      component: 'group',
      name: 'groupButtonOff',
      label: 'Button state OFF',
      collapsible: false,
      align: 'left',
      fields: [
        makeColums(
          {
            "component": "input-text-i18n",
            "name": "labelOff",
            "placeholder": "Button label",
            "react-rsuite5": {
              "size": "sm",
              "textarea": false,
              "rows": 3
            }
          },
          {
            "component": "input-text",
            "name": "iconOff",
            "react-rsuite5": {
              "fullWidth": true,
              "size": "sm"
            },
            "placeholder": "Button icon"
          }
        )
      ]
    },
    {
      name: 'initialValue',
      label: 'Initial status',
      component: 'toggle',
      'react-rsuite5': {
        checkedChildren: 'checked',
        unCheckedChildren: 'unchecked'
      }
    },
    {
      component: 'group',
      name: 'groupButton',
      label: 'Button',
      collapsible: false,
      hidden: true,
      align: 'left',
      fields: [
        makeColums(
          {
            component: 'input-text-i18n',
            name: 'labelLink',
            placeholder: 'Button label',
            'react-rsuite5': {
              size: 'sm',
              textarea: false,
              rows: 3
            }
          },
          {
            component: 'input-text',
            name: 'iconLink',
            'react-rsuite5': {
              fullWidth: true,
              size: 'sm'
            },
            placeholder: 'Button icon'
          }
        )
      ]
    },
    {
      component: 'input-text',
      name: 'href',
      label: "Link",
      'react-rsuite5': {
        fullWidth: true,
        size: 'sm'
      }
    },
    {
      name: 'hint',
      label: 'Hint',
      component: 'input-text-i18n',
      hint: 'Show hint in a tooltip',
      'react-rsuite5': {
        tooltip: true
      }
    },
    COMMON_FULL_WIDTH_SIZE,
    COMMON_HIDDEN
  ],
  'react-rsuite5': [
    makeColums(
      RSUITE5_SIZE,
      {
        ...RSUITE5_APPEARANCE_ALL,
        hint: 'Only available for link-type buttons',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    RSUITE5_HINT_AS_TOOLTIP
  ],
  'react-antd': [
    makeColums(
      ANTD_SIZE,
      {
        ...ANTD_TYPE,
        hint: 'Only available for link-type buttons',
        'react-rsuite5': {
          tooltip: true
        }
      }
    )
  ],
  'react-mantine': [
    makeColums(
      MANTINE_BUTTON_SIZE,
      MANTINE_RADIUS,
      {
        ...MANTINE_BUTTON_VARIANT,
        hint: 'Only available for link-type buttons',
        'react-rsuite5': {
          tooltip: true
        }
      }
    ),
    makeColums(
      MANTINE_COLORS,
      RSUITE5_HINT_AS_TOOLTIP,
      []
    )
  ],
  'react-bootstrap': [
    makeColums(
      BOOTSTRAP_VARIANT,
      BOOTSTRAP_SIZE
    )
  ],
  'react-material-ui': [
    makeColums(
      MUI_SIZE,
      MUI_VARIANT,
      MUI_COLORS
    )
  ],
  'form-generator': {
    hideValidation: true
  },
  defaultValues: {
    'react-mantine': {
      labelOn: 'on',
      labelOff: 'off',
      buttonType: 'toggle'
    },
    'react-rsuite5': {
      labelOn: 'on',
      labelOff: 'off',
      buttonType: 'toggle'
    },
    'react': {
      labelOn: 'on',
      labelOff: 'off',
      buttonType: 'toggle'
    },
    'react-bootstrap': {
      labelOn: 'on',
      labelOff: 'off',
      buttonType: 'toggle'
    },
    'react-material-ui': {
      labelOn: 'on',
      labelOff: 'off',
      buttonType: 'toggle'
    },
    'react-antd': {
      labelOn: 'on',
      labelOff: 'off',
      buttonType: 'toggle'
    }
  }
};

export { manifest };
